import React from "react";
import moment from "moment";
import { PDFExport } from "@progress/kendo-react-pdf";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { Card, Button, Container, Col, Row } from "react-bootstrap";
import CustomTextProgressbar from "./CustomTextProgressbar";
import "react-circular-progressbar/dist/styles.css";
import "../../styles/kendo.css";
import { display, padding } from "@mui/system";

const SprintReportPdf = ({ selctedSprint, pdfData }) => {
  const pdfExportComponent = React.useRef(null);

  const exportPDFWithComponent = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };

  return (
    <>
      <div id="sprint-pdf-container">
        {selctedSprint.map((sprintItem, index) => {
          return (
            <>
              <div
                style={{
                  position: "absolute",
                  left: "-10000000px",
                  top: 0,
                }}
              >
                <PDFExport
                  forcePageBreak=".page-break"
                  paperSize="auto"
                  margin="20"
                  ref={pdfExportComponent}
                  fileName={`${pdfData.projectName}-${moment(new Date()).format(
                    "MMM-DD"
                  )}`}
                >
                  <div
                    class="sprint-headerbg"
                    id="one"
                    data-color="#ffffff"
                    style={{ width: "760px", margin: "0px auto" }}
                  >
                    {/* <div class="headerimg"></div> */}
                    <div
                      className="div-center"
                      style={{ margin: "1rem", position: "relative" }}
                    >
                      <img
                        style={{ zIndex: "10" }}
                        src="../images/logo.png"
                        alt=""
                      />
                    </div>
                    <div
                      className="content-center flex-col"
                      style={{
                        border: "0",
                        backgroundImage: "",
                        backgroundRepeat: "no-repeat",
                        objectFit: "fill",
                        height: "100%",
                        width: "100%",
                        backgroundSize: "cover",
                        paddingTop: "4rem",
                        justifyContent: "flex-start",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          background: "#fff",
                          borderRadius: "10px",
                          paddingBottom: "30px",
                        }}
                        className="border box-shadow-pdf"
                      >
                        <Row>
                          <Col style={{ marginTop: "30px" }}>
                            <div
                              style={{ paddingLeft: "50px" }}
                              className="pdf-heading-text sprint-bold-text sprint-heading-imp"
                            >
                              <span
                                className="sprint-bold-text sprint-heading-imp"
                                style={{ color: "#092ea8" }}
                              >
                                {" "}
                                {pdfData.projectName} - {sprintItem.sprint_name}
                              </span>
                            </div>
                          </Col>
                        </Row>
                        <Row
                          className="d-flex"
                          style={{ margin: "0px", padding: "0px" }}
                        >
                          <div
                            className="d-flex content-center"
                            style={{ width: "50%", marginRight: "10px" }}
                          >
                            <div
                              style={{ width: "100%", padding: "10px 20px" }}
                              className="d-flex flex-col "
                            >
                              <div
                                className="d-flex content-center"
                                style={{ marginBottom: "20px" }}
                              >
                                <div
                                  className="text-center"
                                  style={{
                                    position: "relative",
                                    textAlign: "center",
                                  }}
                                >
                                  <CustomTextProgressbar
                                    //value={Math.ceil(sprintItem.issuesCompleted)}
                                    value={sprintItem.inscope_completed || 0}
                                    counterClockwise
                                    strokeWidth={16}
                                    styles={buildStyles({
                                      textColor: "#092ea8",
                                      pathColor: "#092ea8",
                                      trailColor: "#d1d3d4",
                                    })}
                                    circleWidth={"200px"}
                                    circleHeight={"200px"}
                                  >
                                    <div
                                      style={{
                                        position: "relative",
                                        top: "0px",
                                        left: "5px",
                                        textAlign: "center",
                                        marginRight: "5px",
                                        fontFamily: "Monstserrat",
                                        fontSize: "22px",
                                        color: "#092ea8",
                                      }}
                                    >
                                      <strong>
                                        {" "}
                                        {`${Math.round(
                                          sprintItem.inscope_completed || 0
                                        )}%`}
                                      </strong>
                                    </div>
                                  </CustomTextProgressbar>
                                </div>
                              </div>
                              <Row
                                className="d-flex"
                                style={{ padding: "0px 30px" }}
                              >
                                <Col
                                  className="sprint-heading-pdf"
                                  style={{ width: "50%", padding: "5px" }}
                                >
                                  {" "}
                                  <span
                                    className="sprint-bold-text sprint-heading-imp"
                                    style={{ fontSize: "20px" }}
                                  >
                                    {`${Math.round(
                                      sprintItem.inscope_completed || 0
                                    )}%`}{" "}
                                  </span>{" "}
                                  <span className="font-lightgray">
                                    of in Scope Items Completed{" "}
                                  </span>
                                </Col>
                                <Col
                                  className="sprint-heading-pdf"
                                  style={{ width: "50%", padding: "5px" }}
                                >
                                  {" "}
                                  <span
                                    className="sprint-bold-text sprint-heading-imp"
                                    style={{ fontSize: "20px" }}
                                  >{`${Math.round(
                                    sprintItem.outofscope_completed || 0
                                  )}% `}</span>{" "}
                                  <span className="font-lightgray">
                                    {" "}
                                    of Out of Scope Items Completed{" "}
                                  </span>
                                </Col>
                              </Row>
                            </div>
                          </div>
                          <div
                            className="d-flex flex-col"
                            style={{
                              width: "45%",
                              padding: "50px 0px 40px 30px",
                              marginRight: "1rem",
                              marginTop: "35px",
                              backgroundImage: "url(assets/images/BG.png)",
                              backgroundRepeat: "no-repeat",
                              objectFit: "fill",
                              height: "340px",
                              backgroundPosition: "center",
                              backgroundSize: "100% 100%",
                            }}
                          >
                            <div
                              className="d-flex mtb-10"
                              style={{
                                marginTop: "10px",
                                marginBottom: "20px",
                              }}
                            >
                              <span
                                className="content-center"
                                style={{
                                  backgroundColor: "#e2e2e2",
                                  padding: "5px",
                                  borderRadius: "5px",
                                  width: "40px",
                                  height: "40px",
                                  marginRight: "20px",
                                }}
                              >
                                <img
                                  src="assets/images/Grupo_.svg"
                                  alt="icon"
                                  style={{ width: "20px" }}
                                />
                              </span>
                              <span className="d-flex flex-col">
                                <h6 className="sprint-heading-pdf">
                                  {" "}
                                  Sprint Length :
                                </h6>
                                <span>
                                  <h6
                                    className="sprint-main-heading-pdf sprint-heading-imp"
                                    style={{
                                      fontSize: "20px",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    {sprintItem.sprint_length} Days
                                  </h6>
                                </span>
                                <h6 className="sprint-heading-pdf">
                                  {" "}
                                  Start Date :
                                  {moment(pdfData.startDate).format(
                                    "MM/DD/YYYY"
                                  )}
                                </h6>
                              </span>
                            </div>
                            <div
                              className="d-flex mtb-10"
                              style={{
                                marginTop: "10px",
                                marginBottom: "20px",
                              }}
                            >
                              <span
                                className="content-center"
                                style={{
                                  backgroundColor: "#e2e2e2",
                                  padding: "5px",
                                  borderRadius: "5px",
                                  width: "40px",
                                  height: "40px",
                                  marginRight: "20px",
                                }}
                              >
                                <img
                                  src="assets/images/Grupo_ 1.svg"
                                  alt="icon"
                                  style={{ width: "20px" }}
                                />
                              </span>
                              <span className="d-flex flex-col">
                                <h6 className="sprint-heading-pdf">
                                  {" "}
                                  Story Points Estimated :
                                </h6>
                                <span>
                                  <h6
                                    className="sprint-main-heading-pdf sprint-heading-imp"
                                    style={{ fontSize: "20px" }}
                                  >
                                    {sprintItem.story_point_estimated || 0} pts.
                                  </h6>
                                </span>
                              </span>
                            </div>
                            <div
                              className="d-flex mtb-10"
                              style={{
                                marginTop: "10px",
                                marginBottom: "20px",
                                paddingBottom: "20px",
                              }}
                            >
                              <span
                                className="content-center"
                                style={{
                                  backgroundColor: "#e2e2e2",
                                  padding: "5px",
                                  borderRadius: "5px",
                                  width: "40px",
                                  height: "40px",
                                  marginRight: "20px",
                                }}
                              >
                                <img
                                  src="assets/images/Trazado compuesto_.svg"
                                  alt="icon"
                                  style={{ width: "20px" }}
                                />
                              </span>
                              <span className="d-flex flex-col">
                                <h6 className="sprint-heading-pdf">
                                  {" "}
                                  Avg. Story Points / Sprint :
                                </h6>
                                <span>
                                  <h6
                                    className="sprint-main-heading-pdf sprint-heading-imp"
                                    style={{
                                      fontSize: "20px",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    {Math.round(sprintItem.total_story_poins)}{" "}
                                    pts.
                                  </h6>
                                </span>
                                <h6 className="sprint-heading-pdf">
                                  {" "}
                                  Extraction Date :
                                  {moment(new Date()).format("MM/DD/YYYY")}
                                </h6>
                              </span>
                            </div>
                          </div>
                        </Row>
                      </div>
                      <div className="d-flex mt-20" style={{ width: "100%" }}>
                        <div
                          style={{
                            width: "33%",
                            background: "#fff",
                            borderRadius: "10px",
                          }}
                          className="border box-shadow-pdf "
                        >
                          <div
                            className="d-flex flex-col"
                            style={{ borderRadius: "10px", padding: "15px" }}
                          >
                            <div
                              style={{
                                position: "relative",
                                top: "5px",
                                display: "flex",
                              }}
                            >
                              <CustomTextProgressbar
                                value={Math.round(
                                  sprintItem.issuesCompleted || 0
                                )}
                                counterClockwise
                                strokeWidth={7}
                                styles={buildStyles({
                                  textColor: "#092ea8",
                                  pathColor: "#092ea8",
                                  trailColor: "#d1d3d4",
                                })}
                                circleWidth={"100px"}
                                circleHeight={"100px"}
                              >
                                <div
                                  style={{
                                    textAlign: "left",
                                    marginRight: "2px",
                                    fontSize: "16px",
                                    color: "#092ea8",
                                    marginBottom: "2px",
                                    fontFamily: "Monstserrat",
                                  }}
                                >
                                  <strong>{`${Math.round(
                                    sprintItem.storeisCompleted || 0
                                  )}%`}</strong>
                                </div>
                              </CustomTextProgressbar>
                              <span
                                className="graph-text"
                                style={{ fontSize: "12px", alignSelf: "auto" }}
                              >
                                The percentage of <br></br>
                                <strong>Kanban</strong> that <br></br> were
                                completed <br></br> during sprint
                              </span>
                            </div>
                            <div
                              className="sprint-fotter-container"
                              style={{
                                marginLeft: "5px",
                                marginRight: "5px",
                                marginTop: "50px",
                              }}
                            >
                              <span
                                className="sprint-heading"
                                style={{ width: "100%" }}
                              >
                                <span style={{ float: "left", width: "45%", fontWeight:"bold" }}>
                                  {`${
                                    Math.round(sprintItem.storiesCreated) || 0
                                  }`}{" "}
                                  user stories created
                                </span>
                                <span style={{ float: "right", width: "45%", fontWeight:"bold" }}>
                                  {`${
                                    Math.round(sprintItem.storiesClosed) || 0
                                  }`}{" "}
                                  user stories completed
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>

                        {/* secnod start from here  */}

                        <div
                          style={{
                            width: "33%",
                            background: "#fff",
                            borderRadius: "10px",
                            paddingRight: "0",
                            paddingLeft: "0",
                            margin: "0px 15px",
                          }}
                          className="border box-shadow-pdf "
                        >
                          <div
                            className="d-flex flex-col"
                            style={{ borderRadius: "10px", padding: "15px" }}
                          >
                            <div
                              style={{
                                position: "relative",
                                top: "5px",
                                display: "flex",
                              }}
                            >
                              <CustomTextProgressbar
                                value={Math.round(
                                  sprintItem.issuesCompleted || 0
                                )}
                                counterClockwise
                                strokeWidth={7}
                                styles={buildStyles({
                                  textColor: "#092ea8",
                                  pathColor: "#092ea8",
                                  trailColor: "#d1d3d4",
                                })}
                                circleWidth={"100px"}
                                circleHeight={"100px"}
                              >
                                <div
                                  style={{
                                    textAlign: "left",
                                    marginRight: "2px",
                                    fontSize: "16px",
                                    color: "#092ea8",
                                    marginBottom: "2px",
                                    fontFamily: "Monstserrat",
                                  }}
                                >
                                  <strong>{`${Math.round(
                                    sprintItem.issuesCompleted || 0
                                  )}%`}</strong>
                                </div>
                              </CustomTextProgressbar>
                              <span
                                className="graph-text"
                                style={{ fontSize: "12px", alignSelf: "auto" }}
                              >
                                The percentage of <br></br>
                                <strong>issues</strong> that <br></br> were
                                completed <br></br> during sprint
                              </span>
                            </div>
                            <div
                              className="sprint-fotter-container"
                              style={{
                                marginLeft: "5px",
                                marginRight: "5px",
                                marginTop: "50px",
                              }}
                            >
                              <span
                                className="sprint-heading"
                                style={{ width: "100%" }}
                              >
                                <span style={{ float: "left", width: "45%", fontWeight:"bold" }}>
                                  {`${
                                    Math.round(sprintItem.issuesCreated) || 0
                                  }`}{" "}
                                  user stories created
                                </span>
                                <span style={{ float: "right", width: "45%", fontWeight:"bold" }}>
                                  {`${
                                    Math.round(sprintItem.issuesClosed) || 0
                                  }`}{" "}
                                  user stories completed
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>

                        {/* third bar  start from here  */}

                        <div
                          style={{
                            width: "33%",
                            background: "#fff",
                            borderRadius: "10px",
                          }}
                          className="border box-shadow-pdf "
                        >
                          <div
                            className="d-flex flex-col"
                            style={{ borderRadius: "10px", padding: "15px" }}
                          >
                            <div
                              style={{
                                position: "relative",
                                top: "5px",
                                display: "flex",
                              }}
                            >
                              <CustomTextProgressbar
                                value={
                                  Math.round(sprintItem.changeRequest) || 0
                                }
                                counterClockwise
                                strokeWidth={7}
                                styles={buildStyles({
                                  textColor: "#092ea8",
                                  pathColor: "#092ea8",
                                  trailColor: "#d1d3d4",
                                })}
                                circleWidth={"100px"}
                                circleHeight={"100px"}
                              >
                                <div
                                  style={{
                                    textAlign: "left",
                                    marginRight: "2px",
                                    fontSize: "16px",
                                    color: "#092ea8",
                                    marginBottom: "2px",
                                    fontFamily: "Monstserrat",
                                  }}
                                >
                                  <strong>{`${
                                    Math.round(sprintItem.outOfScopeClosed) || 0
                                  }`}</strong>
                                </div>
                              </CustomTextProgressbar>
                              <span
                                className="graph-text"
                                style={{ fontSize: "12px", alignSelf: "auto" }}
                              >
                                The number of <br></br>
                                <strong>out-of-scope-issues</strong> that
                                <br></br> were completed <br></br> during sprint
                              </span>
                            </div>
                            <div
                              className="sprint-fotter-container"
                              style={{
                                marginLeft: "5px",
                                marginRight: "5px",
                                marginTop: "50px",
                              }}
                            >
                              <span
                                className="sprint-heading"
                                style={{ width: "100%" }}
                              >
                                <span style={{ float: "left", width: "45%", fontWeight:"bold" }}>
                                  {`${
                                    Math.round(sprintItem.outOfScopeCreated) ||
                                    0
                                  }`}{" "}
                                  issues created
                                </span>
                                <span style={{ float: "right", width: "45%", fontWeight:"bold" }}>
                                  {`${
                                    Math.round(sprintItem.outOfScopeClosed) || 0
                                  }`}{" "}
                                  issues completed
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {sprintItem?.outScopeListing?.length !== 0 && (
                        <Container
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItem: "center",
                          }}
                        >
                          <Card
                            style={{
                              marginTop: "20px",
                              marginBottom: "20px",
                              backgroundColor: "transparent",
                              border: "none",
                              width:"100%"
                            }}
                            className="d-flex"
                          >
                            <Row className="d-flex flex-wrap">
                              {sprintItem.outScopeListing &&
                                sprintItem.outScopeListing
                                  .sort(
                                    (a, b) =>
                                      a.subject.length - b.subject.length
                                  )
                                  .map((sprintList) => {
                                    return (
                                      <Col style={{ flex: "0 0 50%" }}>
                                        <div
                                          style={{
                                            margin: "0px 0px 15px 0px",
                                            padding: "10px",
                                            width: "100%",
                                          }}
                                          className="pdf-outofScope-listing"
                                        >
                                          <span
                                            style={{
                                              marginRight: "5px",
                                              width: "100%",
                                            }}
                                          >
                                            <strong>{`#${sprintList.ref}  `}</strong>
                                          </span>
                                          <span
                                            style={{ fontWeight: "600" }}
                                          >{`${sprintList.subject} `}</span>
                                        </div>
                                      </Col>
                                    );
                                  })}
                            </Row>
                          </Card>
                        </Container>
                      )}
                    </div>
                  </div>
                </PDFExport>
              </div>
            </>
          );
        })}
        <div className="example-config">
          <Button className="k-button" onClick={exportPDFWithComponent}>
            <img className="pdf-img" src="../assets/images/pdf.png" /> Download
          </Button>
        </div>
      </div>
    </>
  );
};

export default SprintReportPdf;
