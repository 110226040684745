import React from "react";
import "../styles/page404.css";

const PageNotFound = () => {
  return (
    <div className="page404">
      <h1>404</h1>
      <p>Oops! Something is wrong.</p>
      <p>
        {" "}
        <a href="/login"> Go back in initial page.</a>
      </p>
    </div>
  );
};

export default PageNotFound;
