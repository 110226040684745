import ReactPDF, { StyleSheet } from "@react-pdf/renderer";

// Create styles
export const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#1f2f98",
    width: "100%",
    orientation: "portrait",
  },
  view: {
    width: "100%",
    height: "100%",
    padding: 0,
    backgroundColor: "white",
    //  position: "relative",
  },
  image: {
    objectFit: "cover",
    // left: "0",
    // top: "-200px",
    position: "relative",
  },

  textCenter: {
    textAlign: "center",
    fontSize: "12px",
  },
  textCenterOne: {
    textAlign: "center",
    fontSize: "12px",
    bottom: "400px",
    position: "absolute",
    //zIndex: "100",
  },
  heading: {
    textAlign: "center",
    fontSize: "18px",
    fontWeight: "900",
    color: "black",
    marginTop: "20%",
    textDecoration: "underline",
    position: "absolute",
  },
  paragrpah: {
    fontSize: "12px",
    fontWeight: "300",
    color: "black",
    marginTop: "40px",
    marginLeft: "20px",
    marginRight: "20px",
  },

  paragrpahOne: {
    fontSize: "12px",
    fontWeight: "300",
    color: "black",
    marginLeft: "20px",
    marginRight: "20px",
    position: "absolute",
    top: "160px",
  },
  sideHeading: {
    fontSize: "16px",
    textAlign: "left",
    fontWeight: "900",
    color: "black",
    marginLeft: "20px",
    marginTop: "5%",
    position: "absolute",
    top: "180px",
  },
  paragrpahUlOne: {
    fontSize: "12px",
    fontWeight: "300",
    color: "black",
    marginTop: "10px",
    marginLeft: "40px",
    marginRight: "20px",
    position: "absolute",
    top: "240px",
  },
  paragrpahUl: {
    fontSize: "12px",
    fontWeight: "300",
    color: "black",
    marginTop: "10px",
    marginLeft: "40px",
    marginRight: "20px",
    position: "absolute",
    top: "280px",
  },

  paragrpahUlTwo: {
    fontSize: "12px",
    fontWeight: "300",
    color: "black",
    marginTop: "40px",
    marginLeft: "20px",
    marginRight: "20px",
    position: "absolute",
    top: "320px",
  },

  paraCenter: {
    textAlign: "center",
    fontSize: "12px",
    color: "black",
    marginTop: "8px",
    position: "absolute",
    top: "150px",
  },
  paragrpahSingle: {
    fontSize: "12px",
    fontWeight: "300",
    color: "black",
    marginTop: "10px",
    marginLeft: "20px",
    marginRight: "20px",
  },
  paragrpahTopLowMargin: {
    fontSize: "12px",
    fontWeight: "300",
    color: "black",
    marginTop: "20px",
    marginLeft: "20px",
    marginRight: "20px",
    position: "absolute",
    top: "280px",
  },
  paragrpahP3: {
    fontSize: "12px",
    fontWeight: "",
    color: "black",
    marginLeft: "20px",
    marginRight: "20px",
    position: "absolute",
    top: "200px",
  },
  divPostion: {
    marginLeft: "20px",
    marginRight: "20px",
    position: "absolute",
    bottom: "600px",
    marginBottom: "600px",
  },
  headingTwo: {
    textAlign: "center",
    fontSize: "18px",
    fontWeight: "900",
    color: "black",
    marginTop: "10%",
    textDecoration: "underline",
  },
  paraCenterOne: {
    textAlign: "center",
    fontSize: "12px",
    color: "black",
    marginTop: "8px",
    marginBottom: "8px",
  },
  paragrpahP4: {
    fontSize: "12px",
    fontWeight: 100,
    color: "black",
    marginLeft: "20px",
    marginRight: "20px",
  },
  developmentServicesHeading: {
    textAlign: "center",
    fontSize: "18px",
    fontWeight: "900",
    color: "black",
    marginTop: "10%",
    textDecoration: "underline",
    marginBottom: "3%",
  },
  pageMargin: {
    paddingTop: "50px",
    paddingBottom: "50px",
  },
});
