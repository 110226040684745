import React, { useState } from "react";
import {
  Card,
  Form,
  Button,
  Dropdown,
  ButtonToolbar,
  OverlayTrigger,
} from "react-bootstrap";
import {
  doYouKnowValues,
  options,
  marketingOptions,
  addServices,
  needService,
  incubation,
  design,
  development,
} from "../constents/dropdown-options";
import Tooltip from "./tooltip";
import { toolData } from "../constents/tooltip";
import { questionnaireTooltip } from "../constents/tooltip";
import { questionnaire } from "../service/questionnaire";
import Multiselect from "multiselect-react-dropdown";
import { slackServices } from ".././service/slackService";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import BootstraptoolTip from "./bootstraptooltip";
import "bootstrap/dist/css/bootstrap.css";
import Questionnaire from "./Questionnaire";



const ServiceQuestionnaire = (props) => {
  const [name, setName] = useState("");
  const [helpIdentifying, setHelpIdentifying] = useState("");
  const [servicesNeed, setServicesNeed] = useState(null);
  const [marketServices, setMarketServices] = useState(null);
  const [advertisingServices, setAdvertisingServices] = useState(null);
  const [detail, setDetail] = useState("");
  const [developmentServices, setDevelopmentServices] = useState(null);
  const [designServices, setDesignServices] = useState(null);
  const [incubatinSerivces, setIncubationServices] = useState(null);
  const [marketingDropVisible, setMarketingDropVisible] = useState(false);
  const [adsVisible, setAdsVisible] = useState(false);
  const [developmentVisibile, setDevelopmentVisible] = useState(false);
  const [designVisibile, setDesignVisible] = useState(false);
  const [incubationVisibile, setIncubationVisible] = useState(false);
  const [questionnaireSubmit,setQuestionnaireSubmit] = useState(false)
  const [email,setEmail] = useState(null)
  const [error, setError] = useState({
    orgName: "",
    identifyingService: "",
    serviceNeed: "",
    marketingService: "",
    advertisingService: "",
    discription: "",
    incubation: "",
    design: "",
    development: "",
    email:""
  });

  const [expendDropDown, setExpendDropDown] = useState([]);
  // const renderTooltip = (props) => (
  //   <Tooltip id="button-tooltip" {...props}>
  //     {questionnaireTooltip}
  //   </Tooltip>
  // );

  const handleExpendDropDown = (data, item) => {
    setServicesNeed(data);

    if (item && item.key === "Marketing") {
      setMarketingDropVisible(true);
    }
    if (item && item.key === "Advertising") {
      setAdsVisible(true);
    }
    if (item && item.key === "Design") {
      setDesignVisible(true);
    }
    if (item && item.key === "Development") {
      setDevelopmentVisible(true);
    }
    if (item && item.key === "Incubation") {
      setIncubationVisible(true);
    }
  };

  const handleRemove = (data, item) => {
  
    if (item && item.key === "Marketing") {
      setMarketingDropVisible(false);
      setMarketServices(null)
     
    }
    if (item && item.key === "Advertising") {
      setAdsVisible(false);
      setAdvertisingServices(null)
      
    }
    if (item && item.key === "Design") {
      setDesignVisible(false);
      setDesignServices(null)
     
    }
    
    if (item && item.key === "Devlopment" || item && item.key ==="Development"){
      setDevelopmentVisible(false);
      setDevelopmentServices(null)
    
    }
    if (item && item.key === "Incubation") {
      setIncubationVisible(false);
      setIncubationServices(null)
     
    }
  };

  const redirectPipeDrive = () => {
    setTimeout(() => {
      window.open(
        " https://rainmakers.pipedrive.com/scheduler/JJ5xxH3/intro-call",
        "_self"
      );
    }, 3000);
  }



  const sendSlackMessage = (id,admin) => {
    slackServices.sendMessage(
      `${admin  ? "Request for Admin -":"Request for Teams -"} <${process.env.REACT_APP_HOST}/services#/${admin ? "admin-servicecost":"serviceandcost"}/?request_id=${id}| ${process.env.REACT_APP_HOST}/services#/${admin ? "admin-servicecost":"serviceandcost"}/?request_id=${id} >
      `
    );

    // slackServices.sendMessage(
    //   `<http://localhost:3000/${admin !==undefined ? "admin-servicecost":"serviceandcost"}/?request_id=${id}| https://portal.marketingmachine.ai/project/new-services/request>
    //   <<http://34.216.220.172| portal.marketingmachine.ai>
    //    MarketMachine.Ai
    //    MarketMachine.Ai is pletform to interect efficiently and effectively with out team of agile developers and designers and industry exports to build
    //                  technologies platforms and companies in a smooth and predictale manner.`
    // );
  };

 

  const onDropDownValueSelect = () => {
    let data ; 
    if (name) {
      if(email){
        if (helpIdentifying ) {
          if(helpIdentifying[0].key =="I need helping finding the best strategy"){
                      if (detail) {
                        setQuestionnaireSubmit(true)
                         data = {
                          name: name,
                          identifyServices:
                            helpIdentifying && helpIdentifying.map((a) => a.key),
                          servicesNeed:
                            servicesNeed && servicesNeed.map((a) => a.key),
                          developmentServices:
                            developmentServices &&
                            developmentServices.map((a) => a.key),
                          marketingServices:
                            marketServices && marketServices.map((a) => a.key),
                          advertisingServices:
                            advertisingServices &&
                            advertisingServices.map((a) => a.key),
                          discription: detail,
                          incubationServices:
                            incubatinSerivces &&
                            incubatinSerivces.map((a) => a.key),
                          designServices:
                            designServices && designServices.map((a) => a.key),
                        };
                          
                     
            questionnaire
          .sendQuestionnaireForm(data)
          .then((response) => response.json())
          .then((response) => {
            if (response.id) {
            
              sendSlackMessage(response.id,true);
              redirectPipeDrive()
              toast.success(`Submit successfully
                Message send on slack
            `);
              setError({});
            }
          })
          .catch((err) => {
            toast.error("Error");
           
          });
                      } else {
                        setError({
                          ...error,
                          discription: "Please  fill discription",
                        });
                      }
                   
          }
          
          else {
            if (servicesNeed) {
              if (developmentServices || !developmentVisibile) {
                if (marketServices || !marketingDropVisible) {
                  if (advertisingServices || !adsVisible) {
                    if (designServices || !designVisibile) {
                      if (incubatinSerivces || !incubationVisibile) {
                        if (detail) {
                          setQuestionnaireSubmit(true)
                           data = {
                            name: name,
                            email,
                            identifyServices:
                              helpIdentifying && helpIdentifying.map((a) => a.key),
                            servicesNeed:
                              servicesNeed && servicesNeed.map((a) => a.key),
                            developmentServices:
                              developmentServices &&
                              developmentServices.map((a) => a.key),
                            marketingServices:
                              marketServices && marketServices.map((a) => a.key),
                            advertisingServices:
                              advertisingServices &&
                              advertisingServices.map((a) => a.key),
                            discription: detail,
                            incubationServices:
                              incubatinSerivces &&
                              incubatinSerivces.map((a) => a.key),
                            designServices:
                              designServices && designServices.map((a) => a.key),
                          };
  
                          questionnaire
                          .sendQuestionnaireForm(data)
                          .then((response) => response.json())
                          .then((response) => {
                            if (response.id) {
                              sendSlackMessage(response.id);
                              redirectPipeDrive()
                              toast.success(`Submit successfully
                                Message send on slack
                            `);
                              setError({});
                            }
                          })
                          .catch((err) => {
                            toast.error("Error");
                           
                          });
  
                         
  
                        } else {
                          setError({
                            ...error,
                            discription: "Please  fill discription",
                          });
                        }
                      } else {
                        setError({
                          ...error,
                          incubation: "Please  fill incubation service field",
                        });
                      }
                    } else {
                      setError({
                        ...error,
                        design: "Please  fill design service field",
                      });
                    }
                  } else {
                    setError({
                      ...error,
                      advertisingService: "Please  fill advertising service field",
                    });
                  }
                } else {
                  setError({
                    ...error,
                    marketingService: "Please  fill marketing service field",
                  });
                }
              } else {
                if (developmentVisibile) {
                  setError({
                    ...error,
                    development: "Please  fill development service field",
                  });
                }
              }
            } else {
              setError({
                ...error,
                serviceNeed: "Please  fill service need  field",
              });
            }
          }
          
        } else {
          setError({
            ...error,
            identifyingService: "Please enter identifying service field",
          });
        }

      } else{
        setError({
          ...error,
          email: "Please enter email ",
        });
      }
      
    } else {
      setError({
        ...error,
        orgName: "Please enter organization name",
      });
    }
  
    

      

  };

  return <>
  <Questionnaire sendSlackMessage={sendSlackMessage} redirectPipeDrive={redirectPipeDrive}/>
  </>

  // return (
  //   <div
  //   // style={{backgroundImage:"url(../assets/images/question_backgroud.png)",backgroundRepeat:"no-repeat" , backgroundSize: "cover"}}
  //     className="div-center  flex-col question-background question-container">
  //     <div className=" d-flex justify-between w-60 mt-2rem ">
  //     <img
  //      src="../assets/images/WHITE_RainMakers_Logo_RGB.png"
  //     alt=""
  //     className="question-logo"
  //     />
    
  //     {/* <span className="circle d-flex justify-center mt-1rem" >
  //     <img
  //     src="../assets/images/user.png"
  //     alt=""
  //     className="user-icon"
  //     />
      
  //     </span> */}
  //     </div>
  //     <div className="width-60 ServiceQuestionnaire ServiceQuestionnaire-width mb-3rem">
  //       <Card>
  //         <Card.Header className="mainHeading" >
  //           Service Questionnaire{" "}
  //           <OverlayTrigger placement="bottom" overlay={BootstraptoolTip}>
  //             <button className="all-unset" data-tip data-for="registerTip">
  //               <img
  //                 src="../assets/images/info.png"
  //                 alt=""
  //                 className="info-icon"
  //               />
  //             </button>
  //             {/* <Tooltip /> */}
  //           </OverlayTrigger>
  //         </Card.Header>
  //         <Card.Body>
  //           <Form>
  //             <div tabindex="1">
  //               <Form.Group  controlId="exampleForm.ControlInput1">
  //                 <Form.Label className="questionnaire-label" >Name / Organization Name* </Form.Label>
  //                 <Form.Control
  //                   placeholder=""
  //                   onChange={(e) => {
  //                     setName(e.target.value);
  //                     setError({
  //                       ...error,
  //                       orgName: "",
  //                     });
  //                   }}
  //                 />
  //               </Form.Group>
  //               <p className="error-text-questionnaire">{error.orgName}</p>
  //             </div>
  //             <div tabindex="3">
  //               <Form.Group  controlId="exampleForm.ControlInput1">
  //                 <Form.Label className="questionnaire-label" >Email* </Form.Label>
  //                 <Form.Control
  //                   placeholder=""
  //                   onChange={(e) => {
  //                     setEmail(e.target.value);
  //                     setError({
  //                       ...error,
  //                       email: "",
  //                     });
  //                   }}
  //                 />
  //               </Form.Group>
  //               <p className="error-text-questionnaire">{error.email}</p>
  //             </div>
  //             <div tabindex="2">
  //               <Form.Group
  //                 className=" mt-15"
  //                 controlId="exampleForm.ControlInput1"
  //               >
  //                 <Form.Label className="questionnaire-label">
  //                   Do you know the services you need or need help identifying
  //                   the best strategy?*
  //                 </Form.Label>

  //                 <Multiselect
  //                   displayValue="key"
  //                   onRemove={function noRefCheck() {}}
  //                   onSelect={(selecteList, item) => {
  //                     setHelpIdentifying(selecteList);
  //                     setError({
  //                       ...error,
  //                       identifyingService: "",
  //                     });
  //                   }}
  //                   options={doYouKnowValues}
  //                   placeholder={""}
  //                   style={{ caretColor: "transparent" }}
  //                   showArrow
  //                   singleSelect
  //                   avoidHighlightFirstOption={true}
  //                 />
  //               </Form.Group>
  //               <p className="error-text-questionnaire">
  //                 {error.identifyingService}
  //               </p>
  //             </div>
  //             <Form.Group
  //               className=" mt-15"
  //               controlId="exampleForm.ControlInput1"
  //             >
  //               <Form.Label className="questionnaire-label">Which services do you need?(multiple selection allowed)</Form.Label>
  //             </Form.Group>
  //             <Multiselect
  //               displayValue="key"
  //               // groupBy="cat"
  //               onRemove={
  //                 ((selecteList, item) => setServicesNeed(selecteList),
  //                 (selecteList, item) => handleRemove(selecteList, item))
  //               }
  //               onSelect={(selecteList, item) => {
  //                 setServicesNeed(selecteList, item);
  //                 handleExpendDropDown(selecteList, item);
  //                 setError({
  //                   ...error,
  //                   serviceNeed: "",
  //                 });
  //               }}
  //               options={needService}
  //               placeholder={""}
  //               avoidHighlightFirstOption={true}
  //               showArrow
  //             />
  //             <p className="error-text-questionnaire">{error.serviceNeed}</p>
  //             {developmentVisibile && (
  //               <div>
  //                 <Form.Group
  //                   className=" mt-15"
  //                   controlId="exampleForm.ControlInput1"
  //                 >
  //                   <Form.Label className="questionnaire-label">
  //                     What development services do you need?(multiple selection allowed)
  //                   </Form.Label>
  //                 </Form.Group>
  //                 <Multiselect
  //                   displayValue="key"
  //                   onRemove={
  //                     (selecteList, item) => {
  //                         handleRemove(selecteList,item);
  //                       }
  //                 }
  //                   onSelect={(selecteList, item) => {
  //                     setDevelopmentServices(selecteList);
  //                     setError({
  //                       ...error,
  //                       development: "",
  //                     });
  //                   }}
  //                   options={development}
  //                   avoidHighlightFirstOption={true}
  //                   placeholder={""}
  //                   showArrow
  //                 />
  //                 <p className="error-text-questionnaire">
  //                   {error.development}
  //                 </p>
  //               </div>
  //             )}
  //             {marketingDropVisible && (
  //               <div>
  //                 <Form.Group
  //                   className=" mt-15"
  //                   controlId="exampleForm.ControlInput1"
  //                 >
  //                   <Form.Label className="questionnaire-label">
  //                     What marketing services do you need?(multiple selection allowed)
  //                   </Form.Label>
  //                 </Form.Group>
  //                 <Multiselect
  //                   displayValue="key"
  //                   onRemove={(selecteList, item) =>
  //                     setMarketServices(selecteList)
  //                   }
  //                   onSelect={(selecteList, item) => {
  //                     setMarketServices(selecteList, item);
  //                     setError({
  //                       ...error,
  //                       marketingService: "",
  //                     });
  //                   }}
  //                   options={marketingOptions}
  //                   placeholder={""}
  //                   avoidHighlightFirstOption={true}
  //                   showArrow
  //                 />

  //                 <p className="error-text-questionnaire">
  //                   {error.marketingService}
  //                 </p>
  //               </div>
  //             )}
  //             {adsVisible && (
  //               <div>
  //                 <Form.Group
  //                   className=" mt-15"
  //                   controlId="exampleForm.ControlInput1"
  //                 >
  //                   <Form.Label className="questionnaire-label">
  //                     What advertising services do you need?(multiple selection allowed)
  //                   </Form.Label>
  //                 </Form.Group>
  //                 <Multiselect
  //                   displayValue="key"
  //                   onRemove={function noRefCheck() {}}
  //                   onSelect={(selecteList, item) => {
  //                     setAdvertisingServices(selecteList);
  //                     setError({
  //                       ...error,
  //                       advertisingService: "",
  //                     });
  //                   }}
  //                   options={addServices}
  //                   placeholder={""}
  //                   avoidHighlightFirstOption={true}
  //                   showArrow
  //                 />
  //                 <p className="error-text-questionnaire">
  //                   {error.advertisingService}
  //                 </p>
  //               </div>
  //             )}

  //             {designVisibile && (
  //               <div>
  //                 <Form.Group
  //                   className=" mt-15"
  //                   controlId="exampleForm.ControlInput1"
  //                 >
  //                   <Form.Label className="questionnaire-label">What design services do you need?(multiple selection allowed)</Form.Label>
  //                 </Form.Group>
  //                 <Multiselect
  //                   displayValue="key"
  //                   onRemove={function noRefCheck() {}}
  //                   onSelect={(selecteList, item) => {
  //                     setDesignServices(selecteList);
  //                     setError({
  //                       ...error,
  //                       design: "",
  //                     });
  //                   }}
  //                   options={design}
  //                   placeholder={""}
  //                   avoidHighlightFirstOption={true}
  //                   showArrow
  //                 />
  //                 <p className="error-text-questionnaire">{error.design}</p>
  //               </div>
  //             )}
  //             {incubationVisibile && (
  //               <div>
  //                 <Form.Group
  //                   className=" mt-15"
  //                   controlId="exampleForm.ControlInput1"
  //                 >
  //                   <Form.Label className="questionnaire-label">
  //                     What incubation services do you need?(multiple selection allowed)
  //                   </Form.Label>
  //                 </Form.Group>
  //                 <Multiselect
  //                   displayValue="key"
  //                   onRemove={function noRefCheck() {}}
  //                   onSelect={(selecteList, item) => {
  //                     setIncubationServices(selecteList);
  //                     setError({
  //                       ...error,
  //                       incubation: "",
  //                     });
  //                   }}
  //                   options={incubation}
  //                   placeholder={""}
  //                   avoidHighlightFirstOption={true}
  //                   showArrow
  //                 />
  //                 <p className="error-text-questionnaire">{error.incubation}</p>
  //               </div>
  //             )}
  //             <Form.Group
  //               className=" mt-15"
  //               controlId="exampleForm.ControlInput1"
  //             >
  //               <Form.Label className="questionnaire-label">
  //                 To finish up, please provide a brief yet detailed description
  //                 of the work needed{" "}*
  //               </Form.Label>
  //               <Form.Control
  //                 value={detail}
  //                 onChange={(e) => {
  //                   setDetail(e.target.value);
  //                   setError({
  //                     ...error,
  //                     discription: "",
  //                   });
  //                 }}
  //                 as="textarea"
  //                 rows={3}
  //               />
  //               <p className="error-text-questionnaire">{error.discription}</p>
  //             </Form.Group>
  //             <div className="d-flex justify-center">
  //             <Button
  //             className="mt-15 question-background font-Adob-Garamond-Regular"
  //              style={{width:'150px',height:'50px',fontSize:'18px',backgroundColor:'#273691',borderRadius:'0',margin:'0',padding:'0'}}
  //              onClick={onDropDownValueSelect}
  //              >
  //               Submit
  //             </Button>
  //             </div>
  //           </Form>
  //         </Card.Body>
  //       </Card>
  //     </div>
  //   </div>
  // );
};

export default ServiceQuestionnaire;
