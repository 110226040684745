import React from 'react'


const QuestionFooter = () => {
    return (<>
    <footer className="footer">

<div className="container-fluid space_left no_space">
  <div className="footer-info">
    <div className="row">
      <div className="col-md-12 col-lg-3">
        <div className="footer-logo">
          <a href="#"><img src="../images/footer-logo.png" alt=""/></a>
          <span>Copyrights © 2022</span>
        </div>
      </div>
      <div className="col-md-12 col-lg-9">
        <div className="footer-content">
          <div className="row">
            <div className="col-md-3 col-6 mt-4">
              <h6>Quick links</h6>
              <ul className="footer-list no_padding">
                <li><a href="https://portal.rainmakers.org">portal.rainmakers.org</a></li>
                <li><a href="https://intake.rainmakers.org/services#">intake.rainmakers.org</a></li>
                {/* <li><a href="https://www.buydomains.com/lander/rainmakers.org?domain=rainmakers.org&utm_source=rainmakers.org&utm_medium=click&utm_campaign=tdfs-AprTest&traffic_id=AprTest&traffic_type=tdfs&redirect=ono-redirect">news.rainmakers.org</a></li> */}
                <li><a href=""></a></li>
              </ul>
            </div>
            <div className="col-md-3 col-6 mt-4">
              <h6>Company</h6>
              <ul className="footer-list no_padding">
                <li><a href="https://rainmakers.org/#about">About</a></li>
                <li><a href="https://rainmakers.org/#process">Process</a></li>
                <li><a href="https://rainmakers.org/team/">Team</a></li>
              </ul>
            </div>
            <div className="col-md-3 col-6 mt-4">
              <h6>Services</h6>
              <ul className="footer-list no_padding">
                <li><a href="/services">Development</a></li>
                <li><a href="/services">Marketing</a></li>
                <li><a href="/services">Consulting</a></li>
              </ul>
            </div>
            <div class="col-md-3 col-6 mt-4">
                  <h6>Follow us on</h6>
                  <ul class="footer-list social-list">
                    <li>
                      <a href="https://www.facebook.com/Rainmakers.org/" target="_blank">
                      <i class="fa fa-linkedin-square fa-lg"></i>
                       <span>Facebook</span></a>
                    </li>
                    <li>
                      <a href="https://twitter.com/Rainmakers_org" target="_blank">
                      <i class="fa fa-twitter fa-lg"></i>
                      <span>Twitter</span></a>
                    </li>
                    <li><a href="https://www.linkedin.com/company/rainmakers.org/" target="_blank">
                    <i style={{marginLeft:"0.3rem"}} class="fa fa-facebook-official fa-lg"></i>
                      <span>linkedin</span>
                      </a>
                    </li>
                  </ul>
                </div>
                {/* <div className="mobile-footer-logo">
          <a href="#"><img src="../images/footer-logo.png" alt=""/></a>
          <span>Copyrights © 2022</span>
        </div> */}
        <div className='bottom-copy '>
          <p>
            <span className='buttom-footer-logo'>
            <img src="../images/footer-logo.png" alt=""/>
            <span>Copyrights © 2022</span>
            </span>
          </p>

        </div>
                
          </div>
        </div>
      </div>
    </div>
    {/* <p className="bottom-copy"><span>Copyrights © 2022</span></p> */}
  </div>
</div>

</footer>

    
    </>)
}

export default QuestionFooter