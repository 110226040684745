import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {useSelector,useDispatch} from 'react-redux'
import {getCreatedProjectsDetails} from '../../store/actions/portal-report'
import Loader from '../../components/loader'
import _ from 'lodash'




const Sprintreportsend = () => {
  const createdSprints = useSelector((state) => state.portal.createdSprints)
  const loading = useSelector((state) => state.portal.emailLoading)
  const isError = !_.isEmpty(useSelector((state) => state.portal.error))
  const [isLoading ,setIsLoading] = useState(loading)
  const params = useParams()
  const dispatch = useDispatch()
  const [createSprintReports,setCreateSprintReports] = useState([createdSprints])
  



    useEffect(()=>{
      if(params.id) {
         dispatch(getCreatedProjectsDetails(params.id))
      }
    },[])

    useEffect(()=>{
       setCreateSprintReports(createdSprints)
    },[createdSprints])

   useEffect(()=>{
    setIsLoading(loading)
   },[loading])


  


  return  <>
  <div className='email-confimation-page'>
    <div className='email-confimation-page-child'> 
     {!loading ? isError ? <h1 style={{color:"red"}}>Link is not valid</h1> : <h1>All users informed </h1> :   <Loader/>}
    </div>

  </div>
  </>
}

export default Sprintreportsend