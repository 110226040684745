import React, { useState ,useEffect} from "react";
import { useSelector } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import {createAgencyUser,updateUser} from '../../store/actions/inviteagency'
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import _ from 'lodash'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};




const portalError = {
  name: "",
  address: "",
  email:"",
  phone:"",
};

const InviteAgencyModal = (props) => {
  const portalInitialState = {
    name: _.isEmpty(props.selectedRow) ? null : props.selectedRow.name ,
    address:  _.isEmpty(props.selectedRow) ? null : props.selectedRow.address,
    email: _.isEmpty(props.selectedRow) ? null : props.selectedRow.email,
    phone: _.isEmpty(props.selectedRow) ? null : props.selectedRow.phone
  };

  const [agencyData, setAgencyData] = useState(portalInitialState);
  const [error, setError] = useState(portalError);
  const dispatch = useDispatch();
  const history = useHistory()
  const theme = useTheme();
  const [projectName, setProjectName] = React.useState();
  const id =  _.isEmpty(props.selectedRow) ? null : props.selectedRow.id

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

//   useEffect(()=>{
//     setTaigaProjectList(tempTaigaProjectList)
//   },[tempTaigaProjectList])



  
  const handleSubmit = () => {
    if (agencyData.name) {
      if ( agencyData.address) {
        if (agencyData.email) {
            if(agencyData.phone){
              if(props.update){
                dispatch(updateUser(agencyData,id,props.onHide))
              }else{
                dispatch(createAgencyUser(agencyData,props.onHide))
              }
            }else{
                setError({
                    ...error,
                    phone: "Please enter phone",
                  });
            }
          } else {
            setError({
              ...error,
              email: "Please enter email",
            });
          }
       
      } else {
        setError({
          ...error,
          address: "Please enter Agency Address",
        });
      }
    } else {
      setError({
        ...error,
        name: "Please enter Agency Name",
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAgencyData({
      ...agencyData,
      [name]: value,
    });
    setError({
      ...error,
      [name]: null,
    });
  };


  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           {props.modalTitile}
          </Modal.Title>
        </Modal.Header>
         <Modal.Body style={{margin:"auto"}}>
            <Box
            sx={{
              width: 700,
              maxWidth: '100%',
              m: 1,
            }}
          >
            <TextField 
            fullWidth label="Agency Name"
            id="fullWidth"
            name="name"
            onChange={handleChange}
            value={agencyData.name}
            />
          </Box>
          <p style={{marginLeft:"10px"}} className="text-red margin-bottom-5">{error.name}</p>
          <Box
            sx={{
              width: 700,
              maxWidth: '100%',
              m: 1,
            }}
            style={{marginTop:"25px"}}
          >
            <TextField
            fullWidth
            label="Agency Address"
            id="fullWidth"
            name="address"
            value={agencyData.address}
            onChange={handleChange}
            //disabled={props.questionnaireData.createdProject[0]?._id ? true : false}

            />
          </Box>
          <Box
            sx={{
              width: 700,
              maxWidth: '100%',
              m: 1,
            }}
            style={{marginTop:"25px"}}
          >
            <TextField
            fullWidth
            label="Email"
            id="fullWidth"
            name="email"
            type='email'
            value={agencyData.email}
            onChange={handleChange}
            disabled={props.update}
            />
          </Box>
          <p style={{marginLeft:"10px"}} className="text-red margin-bottom-5">{error.email}</p>
          <Box
            sx={{
              width: 700,
              maxWidth: '100%',
              m: 1,
            }}
            style={{marginTop:"25px"}}
          >
            <TextField
            fullWidth
            label="Phone"
            id="fullWidth"
            name="phone"
            type='number'
            value={agencyData.phone}
            onChange={handleChange}
            //disabled={props.questionnaireData.createdProject[0]?._id ? true : false}

            />
          </Box>
          <p style={{marginLeft:"10px"}} className="text-red margin-bottom-5">{error.phone}</p>
         
        </Modal.Body>
        <Modal.Footer style={{marginRight:"4%"}}>
          <Button onClick={props.onHide}>Close</Button>
          <Button   onClick={handleSubmit}>Submit</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
InviteAgencyModal.defaultProps = {
  modalTitile: " Invite Agency",
  update: false,
}

export default InviteAgencyModal;
