import {
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
} from "../../constents/actionType";

const initialState = {
  payload: [],
  isLoading: false,
  error: null,
  logoutLoading: false,
  userData: [],
  loginId: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case LOGIN_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        payload: action.payload.payload,
        userData: action.payload.userData,
        loginId: action.payload.userData.id,
      };
    }
    case LOGIN_FAILED: {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    }

    default:
      return state;
  }
};
