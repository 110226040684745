import { authHeader } from "./auth-header";
import ApiConfig from "./ApiConfig";
import { secureFetch } from "./securefetch";
import { toast } from "react-toastify";

export const serviceRequest = {
  getAllServiceRequest,
  updateServiceRequest,
  updateCostApproved,
  getApprovedCost,
  approvedCostUpdate
  
  // getServiceRequest,
};

function getAllServiceRequest() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  // return secureFetch(`${ApiConfig.baseURL}questionnaire/`, requestOptions)
  //   .then((user) => {
  //     return user;
  //   })
  //   .catch((err) => {
  //     return err;
  //   });
  const res = secureFetch(`${ApiConfig.baseURL}questionnaire/`, requestOptions)
  return res
}




function updateServiceRequest(params, id) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(params),
  };
  return (
    secureFetch(`${ApiConfig.baseURL}service-request/${id}`, requestOptions)
      // return secureFetch(
      //   "http://34.216.220.172/api/v1/service-request/",
      //   requestOptions
      // )

      .then((user) => {
        return user;
      })
      .catch((err) => {
        return err;
      })
  );
}

function updateCostApproved(serviceCostId, questionnaireId) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
  };
  return (
    secureFetch(
      `${ApiConfig.baseURL}service-request/${serviceCostId}/${questionnaireId}`,
      requestOptions
    )
      // return secureFetch(
      //   "http://34.216.220.172/api/v1/service-request/",
      //   requestOptions
      // )

      .then((user) => {
        return user;
      })
      .catch((err) => {
        return err;
      })
  );
}


function getApprovedCost(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return (
    secureFetch(`${ApiConfig.baseURL}cost-approvel/${id}`, requestOptions)
      .then((user) => {
        return user;
      })
      .catch((err) => {
        return err;
      })
  );
}


function approvedCostUpdate(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return (
    secureFetch(`${ApiConfig.baseURL}/cost-approvel/`, requestOptions)
      .then((user) => {
        return user;
      })
      .catch((err) => {
        return err;
      })
  );
}

