import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { serviceRequest  } from "../service/service-request";
import {getApprovedCost} from '../store/actions/service-request'
import { toast } from "react-toastify";
import { useHistory } from "react-router";

const DisplayFilledCost = ({
  consultingService,
  onDropDownValueSelect,
  serviceRequestStatus,
  getQuestionnaire,
  getQuestionnaireDetailWithCost,
  findParams,
  development,
  marketingServices,
  incubation,
  design,
  advertisingServices,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [advertisingCost, setAdvertisingCost] = useState({});
  const [marketingCost, setMarketingCost] = useState({});
  const [designCost, setDesignCost] = useState({});
  const [consultingCost, setConsultingCost] = useState({});
  const [incubationCost, setIncubationCost] = useState({});
  const [costState, setCostState] = useState({});
  const [userId, setUserId] = useState(
    JSON.parse(localStorage.getItem("user_id"))
  );
  const [error,setError] = useState(null)
  const questionnaireData1 = useSelector(
    (state) => state.questionnaire.questionnaireDetailCost
  );
  const [editindex, setEditIndex] = useState();
  const [isLogin, setIsLogin] = useState(
    localStorage.getItem("userAccessToken")
  );

  const tempapprovedCost = useSelector(state => state.serviceRequest.approvedCost)
  const [approvedCost ,setApprovedCost] = useState(tempapprovedCost.length  > 0  ? tempapprovedCost[0] : null )

  const [questionnaireData, setQuestionnaireData] =
    useState(questionnaireData1);

  const [devCost, setDevCost] = useState({});

  const [data, setData] = useState({});

  const handleSubmit = () => {


    onDropDownValueSelect({
      advertisingCost,
      incubationCost,
      designCost,
    });
  };

  const orgName =
    serviceRequestStatus &&
    serviceRequestStatus.map((value) => value.organization);

  let result;
  const status =
    serviceRequestStatus &&
    serviceRequestStatus.map((value) => {
      value.advertisingCost.map((cost) => {
        result = Object.keys(cost);
      });
    });

  const handleChange = (name, value, type) => {
    setCostState({
      ...costState,
      name: value,
    });
  };

  useEffect(() => {
    const request_id = findParams();
    // setQuestionnaireData(serviceRequestStatus);
    if(request_id && isLogin !== null) {
    dispatch(getQuestionnaire(request_id));
    dispatch(getQuestionnaireDetailWithCost(request_id));
     dispatch(getApprovedCost(request_id));
    }
  }, []);

  useEffect(() => {
    setUserId(JSON.parse(localStorage.getItem("user_id")));
    setQuestionnaireData(questionnaireData1);
    setApprovedCost(tempapprovedCost.length  > 0  ? tempapprovedCost[0] : null )
  }, [questionnaireData1 , tempapprovedCost]);
  
  const handleUpdate = () => {
    //const {advertisingCost,designCost,developmentCost,incubationCost,marketingCost} = questionnaireData.costs
    const {
      advertisingCost,
      designCost,
      consultingCost,
      developmentCost,
      incubationCost,
      marketingCost,
      userId,
      questionnaireId,
      organization,
      _id,
    } = questionnaireData.costs[editindex];
    const params = {
      organization,
      advertisingCost,
      designCost,
      marketingCost,
      developmentCost,
      consultingCost,
      incubationCost,
      userId,
      questionnaireId,
    };

    if(Object.keys(developmentCost && developmentCost[0]).length === Object.values(developmentCost && developmentCost[0]).filter(item => item !=='').length &&
    Object.keys(marketingCost && marketingCost[0]).length === Object.values(marketingCost && marketingCost[0]).filter(item => item !=='').length &&
    Object.keys(advertisingCost && advertisingCost[0]).length === Object.values(advertisingCost && advertisingCost[0]).filter(item => item !=='').length &&
    Object.keys(incubationCost && incubationCost[0]).length === Object.values(incubationCost && incubationCost[0]).filter(item => item !=='').length  &&
    Object.keys(designCost && designCost[0]).length === Object.values(designCost && designCost[0]).filter(item => item !=='').length &&
    Object.keys(consultingCost && consultingCost[0]).length === Object.values(consultingCost && consultingCost[0]).filter(item => item !=='').length 
    )
      
    {
      serviceRequest
      .updateServiceRequest(params, _id)
      .then((response) => {
        toast.success("Record update sucessfully");
        history.push("/dashboard");
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
    } else {
      setError('Please fill all fields')
    }

   
  };

  const showHaddingText = (text) => {
    let heading;
    return (heading =
      text === "marketingCost"
        ? "Marketing Cost"
        : text === "developmentCost"
        ? "Development Cost"
        : text === "advertisingCost"
        ? "Advertising Cost"
        : text === "incubationCost"
        ? "Incubation Cost"
        : "Design Cost");
  };
 
  const displayApprovedCost = (costType , list , obj) => {
      if(approvedCost === null) return
      const selectedApprovedCost =  (approvedCost && approvedCost[costType] && approvedCost[costType].filter(approvedItem =>( approvedItem.userId === list.userId && approvedItem.category == obj))[0])
     
      if(selectedApprovedCost && selectedApprovedCost.category === obj){
         return <> 
          <input type="checkbox"
          checked = {true}
            />
          </>
      } 
  }

  const showGreenBorder = (costType , list , obj) => {
     if(approvedCost === null) return
      const selectedApprovedCost =  (approvedCost && approvedCost[costType] && approvedCost[costType].filter(approvedItem =>( approvedItem.userId === list.userId && approvedItem.category == obj))[0])
     
      if(selectedApprovedCost && selectedApprovedCost.category === obj){
         return  true
      } 

  }
  
  
  return (
    <div className="">
      {questionnaireData &&
        questionnaireData.costs &&
        questionnaireData.costs.map((list, index) => {
          
          return (
            <div className="d-flex flex-wrap ">
              <div className=" mxt-20  w-100 d-flex justify-between  ">
              </div>
             <div className="dummy d-flex">
                 <div className="d-flex flex-col border-gray">

                 {index  === 0 && <div className="height-60 ">
                    <div className="filled-cost-org text-center"> Organization Name</div>
                  </div>}
                  <div className={`mlr-8  ${index !==0 ? "mt-08":""}`}>
                    <input
                      style={{paddingTop:"0px"}}
                      className="input-pd "
                      disabled
                      value={
                        list.userId === userId ? list.organization : "*******"
                      }
                    />
                  </div>
                </div>
              {Object.keys(list).map((costType) => {
                if (Array.isArray(list[costType])) {
                  return list[costType].map((item, index2) => {
                    return Object.keys(item).filter(x => !x.includes("Timeline")).map((obj,rowIndex) => {
                      return (
                        <>
                          <div className="mx-20  table-style tb-width">
                            {index === 0 && <div className="myt-5  height-60 ">
                              <h6 className="padding-8 text-center">
                                  {`${obj} / ${showHaddingText(costType)} / time(months)`}
                              </h6>
                            </div>}
                            <div className="m-8 d-flex justify-around ">
                              <div className={`input-checkbox-border ${questionnaireData.status === "approved" ? "disabledBackground" : list.userId === userId ? "" : "disabledBackground"} ${showGreenBorder(costType,list,obj) ? "border-green disabledBackground" :"" }`}>
                              <input
                                className={`pl-10 myt-5 mx-50  outline-none  input-checkbox ${
                                  questionnaireData.status === "approved" ? 
                                  "bg-none": 
                                   showGreenBorder(costType,list,obj)
                                   ? "bg-none"
                                   : list.userId === userId
                                   ? false
                                   : true ? "bg-none" : ""
                                }`}
                                type="number"
                                min={0}
                                //value={`${questionnaireData.costs[index][costType][index2][obj]}`}
                                //disabled={list.userId === userId ? false : true}
                                value={
                                  questionnaireData.costs[index][costType][
                                    index2
                                  ][obj]
                                }
                                disabled={
                                   questionnaireData.status === "approved" ? 
                                   true : 
                                    showGreenBorder(costType,list,obj)
                                    ? true
                                    : list.userId === userId
                                    ? false
                                    : true
                                }
                                onChange={(e) => {
                                  let costListArr = questionnaireData.costs;
                                  costListArr[index][costType][index2][obj] =
                                    e.target.value;
                                  setQuestionnaireData({
                                    ...questionnaireData,
                                    costs: costListArr,
                                  });

                                  setEditIndex(index);
                                }}
                              />
                              {displayApprovedCost(costType,list,obj)}
                              </div>
                              <div className={`input-checkbox-border ${questionnaireData.status === "approved" ? "disabledBackground" : list.userId === userId ? "" : "disabledBackground"} ${showGreenBorder(costType,list,obj) ? "border-green disabledBackground" :"" }`}>
                              <input
                                className={`pl-10 myt-5 mx-50  outline-none  input-checkbox ${
                                  questionnaireData.status === "approved" ? 
                                  "bg-none": 
                                   showGreenBorder(costType,list,obj)
                                   ? "bg-none"
                                   : list.userId === userId
                                   ? false
                                   : true ? "bg-none" : ""
                                }`}
                                style={{maxWidth:'100px'}}
                                type="number"
                                min={0}
                                //disabled={list.userId === userId ? false : true}
                                value={
                                  questionnaireData.costs[index][costType][
                                    index2
                                  ][`${obj}Timeline`]
                                }
                                disabled={
                                   questionnaireData.status === "approved" ? 
                                   true : 
                                    showGreenBorder(costType,list,obj)
                                    ? true
                                    : list.userId === userId
                                    ? false
                                    : true
                                }
                                onChange={(e) => {
                                  let costListArr = questionnaireData.costs;
                                  costListArr[index][costType][index2][`${obj}Timeline`] =
                                    e.target.value;
                                  setQuestionnaireData({
                                    ...questionnaireData,
                                    costs: costListArr,
                                  });

                                  setEditIndex(index);
                                }}
                              />
                              {displayApprovedCost(costType,list,obj)}
                              </div>
                              
                               </div>
                               
                              
                          </div>
                             
                       
                        </>
                      );
                    });
                  });
                }
              })}
              </div>
            </div>
          
          );
        })}
          {error &&    <p className="text-red">{error}</p>}
      {editindex >= 0 && <div className="d-flex justify-center">  <Button   style={{width:'120px',height:'40px',fontSize:'18px',backgroundColor:'#0e7999',borderRadius:'0',fontFamily:'Montserrat' ,margin:'0',padding:'0'}} onClick={handleUpdate}>Update</Button> </div>}
    </div>
  );
};

export default DisplayFilledCost;
