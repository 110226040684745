import {
  GET_QUESTIONNAIRE_START,
  GET_QUESTIONNAIRE_SUCCESS,
  GET_QUESTIONNAIRE_FAILED,
  GET_QUESTIONNAIRE_DETAILS_WITHCOST_START,
  GET_QUESTIONNAIRE_DETAILS_WITHCOST_SUCCESS,
  GET_QUESTIONNAIRE_DETAILS_WITHCOST_FAILED,
  GET_QUESTIONNAIRE_ADMIN_LIST_START,
  GET_QUESTIONNAIRE_ADMIN_LIST_SUCCESS,
  GET_QUESTIONNAIRE_ADMIN_LIST_FAILED,
  SAVE_SERVICE_REQUEST_DATA,
  UPDATE_SERVICE_REQUEST_START,
  UPDATE_SERVICE_REQUEST_SUCCESS,
  UPDATE_SERVICE_REQUEST_FAILED,
} from "../../constents/actionType";

const initialState = {
  questionnaireData: [],
  questionnaireId: "",
  questionnaireLoading: false,
  error: null,
  questionnaireDetailCost: {},
  questionnaireAdminList: [],
  serviceRequestStatus: "",
  serviceUpdateLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_QUESTIONNAIRE_START: {
      return {
        ...state,
        questionnaireLoading: true,
      };
    }
    case GET_QUESTIONNAIRE_SUCCESS: {
      return {
        ...state,
        questionnaireLoading: false,
        questionnaireData: action.payload,
        questionnaireId: action.payload.id,
      };
    }
    case GET_QUESTIONNAIRE_FAILED: {
      return {
        ...state,
        questionnaireLoading: false,
        error: action.payload,
      };
    }
    case GET_QUESTIONNAIRE_DETAILS_WITHCOST_START: {
      return {
        ...state,
        questionnaireLoading: true,
        error: action.payload,
      };
    }
    case GET_QUESTIONNAIRE_DETAILS_WITHCOST_SUCCESS: {
      return {
        ...state,
        questionnaireLoading: false,
        questionnaireDetailCost: action.payload,
        error: null,
      };
    }
    case GET_QUESTIONNAIRE_DETAILS_WITHCOST_FAILED: {
      return {
        ...state,
        questionnaireLoading: false,
        error: action.payload,
      };
    }
    case GET_QUESTIONNAIRE_ADMIN_LIST_START: {
      return {
        ...state,
        questionnaireLoading: true,
        error: action.payload,
      };
    }
    case GET_QUESTIONNAIRE_ADMIN_LIST_SUCCESS: {
      return {
        ...state,
        questionnaireAdminList: action.payload,
        questionnaireLoading: false,
        error: null,
      };
    }
    case GET_QUESTIONNAIRE_ADMIN_LIST_FAILED: {
      return {
        ...state,
        questionnaireLoading: false,
        error: action.payload,
      };
    }
    case SAVE_SERVICE_REQUEST_DATA: {
      return {
        ...state,
        serviceRequestStatus: action.payload,
      };
    }
    case UPDATE_SERVICE_REQUEST_START: {
      return {
        ...state,
        serviceUpdateLoading: true,
      };
    }
    case UPDATE_SERVICE_REQUEST_SUCCESS: {
      return {
        ...state,
        serviceUpdateLoading: false,
        questionnaireDetailCost: action.payload,
      };
    }
    case UPDATE_SERVICE_REQUEST_FAILED: {
      return {
        ...state,
        serviceUpdateLoading: false,
        questionnaireDetailCost: action.payload,
      };
    }

    default:
      return state;
  }
};
