import React, { useState, useEffect } from "react";
import { useHistory , useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as authActions from "../store/actions/auth";
import "../styles/login.css";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import Loader from './loader'
import { createPassword,resetPassword } from "../store/actions/inviteagency";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';



const ResetPassword = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLogin, setIsLogin] = useState(false);
  const isLoginLoading = useSelector((state) => state.auth.isLoading)
  const [password, setPassword] = useState(null);
  const [error, setError] = useState({ password: "",confirmPassword: "",notMatched:""});
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [showPassword,setShowPassword] = useState(false)
  const [showConfirmPassword,setShowConfirmPassword] = useState(false)
  const [authData, setAuthData] = useState(
    useSelector((state) => {
      return state && state.auth;
    })
  );



 
 
  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  function validatePassword(passord) {
    //const re = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&+=]).{10,16}$/;
    const re = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
    return re.test(passord);
  }
  const sendToLogin = () => {
    history.push("/login");
  }
  const params = useParams()




  const handleLogin = (e) => {
    e.preventDefault();
    if (validatePassword(password)) {
      if (validatePassword(confirmPassword)) {
          if(password === confirmPassword){
            if(window.location.pathname.includes("reset-password")){
              dispatch(createPassword({password ,token : params?.token},sendToLogin))
            } else {
              dispatch(resetPassword({password ,token : params?.token},sendToLogin))
            }
          } else{
            setError({
                ...error,
                notMatched:
                  "Password and Confirm Password are not matched",
              });

          }
        //dispatch(authActions.tryLogin({ passord, password, name }));
      } else {
        setError({
          ...error,
          confirmPassword:
            "Minimum eight characters, at least one letter, one number and one special character",
        });
      }
    } else {
      setError({
        ...error,
        password: "Minimum eight characters, at least one letter, one number and one special character",
      });
    }
  };

  const handlePasswordChange = (e) => {
      setPassword(e.target.value)
    if (validatePassword(password)) {
      setError({
        ...error,
        password: "",
      });
    }
  };
  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    if (validatePassword(password)) {
      setError({
        ...error,
        confirmPassword: "",
      });
    }
  };


  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onConfirmPasswordClick = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };


  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  useEffect(() => {
    const request_id = props.location.state && props.location.state.request_id;
  });

  const renderHeadingText = () => {
    if(window.location.pathname.includes("reset-password")){
      return <span>Create Password</span>
    } else {
      return <span>Reset Password</span>
    }
    
  }
  return (
    <form className="reset-password login-background">
      <div className="wrapper fadeInDown position-absolute">
        <div id="formContent">
        <div className="reset-password-container">  <img className="reset-password-icon" src="../assets/images/reset-password.png" alt='resetpassword icon'/></div>
        <div className="d-flex resetPassword"><span> </span> {renderHeadingText()}</div>
          <div className="fadeIn first ">
            {/* <input
              type="password"
              id="login"
              className="fadeIn second"
              name="login"
              placeholder="Password"
              onChange={handlePasswordChange}
              autoComplete="off"
            /> */}
            <FormControl sx={{ m: 1, width: '90%' }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password"> New Password</InputLabel>
          <OutlinedInput
            style={{textAlign:"left"}}
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={handlePasswordChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="New Password"
          />
        </FormControl>
            {error && error.password && (
              <p className="error-text">{error.password}</p>
            )}
            
            <FormControl sx={{ m: 1, width: '90%' }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Confirm New Password</InputLabel>
          <OutlinedInput
            style={{textAlign:"left"}}
            id="outlined-adornment-password"
            type={showConfirmPassword ? 'text' : 'password'}
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={onConfirmPasswordClick}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Confirm New Password"
          />
        </FormControl>
        {error && error.confirmPassword && (
              <p className="error-text">{error.confirmPassword}</p>
            )}
            {error && error.notMatched && (
              <p className="error-text">{error.notMatched}</p>
            )}
            <span>
            <button
              type="submit"
              className="fadeIn fourth login-btn"
              value="Log In"
              onClick={handleLogin}
              required
            >
             <div className="d-flex justify-center aligin-center"> {renderHeadingText()} <span>{isLoginLoading && <Loader button={true} topPosition={"top-30"}/>} </span>  </div>
            </button>
            </span>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ResetPassword;
