import React, { useState ,useEffect} from "react";
import { Modal, Button } from "react-bootstrap";
import {createAgencyUser,updateUser,getDocFileUrl} from '../../store/actions/inviteagency'
import { useDispatch,useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import _ from 'lodash'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import ProjectReportPdf from '../../components/ProjectReportPdf'
import AgreementPdf from '../pdf/agreementpdf'
import { updateQuestionnireData } from "../../store/actions/questionnaire";
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};





const AgreementModal = (props) => {
  const {name,address,_id,firstName,lastName} = props.cost
  const dispatch = useDispatch();
  const tempFileName = useSelector(state => state.users.docFileName)
  const history = useHistory()
  const theme = useTheme();
  const [projectName, setProjectName] = React.useState();
  const id =  _.isEmpty(props.selectedRow) ? null : props.selectedRow.id
  const [showPdf,setShowPdf] = useState(false)
  const [agreementData,setAgreementData] = useState({legalBusinessName:firstName ? `${firstName} ${lastName}` : `${name}`,address:address})
  const [error, setError] =  useState({legalBusinessName:"",address:""})
  const [copyIcon,setActvieCopyIcon] = useState(false)
  const [fileName,setFileName] = useState(tempFileName)


  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const handleChange = (e) => {
    const { name, value } = e.target;

    setAgreementData({
      ...agreementData,
      [name]: value,
    });
    setError({
      ...error,
      [name]: null,
    });
  };

  const handleSubmit = () => {
    if (agreementData.legalBusinessName) {
      if ( agreementData.address) {
          dispatch(updateQuestionnireData(_id,{
            name:agreementData.legalBusinessName,
            address : agreementData.address
           }))
      }
       else {
        setError({
          ...error,
          address: "Please enter address",
        });
      }
    } else {
      setError({
        ...error,
        legalBusinessName: "Please enter Business Name",
      });
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword = (event) => {
    event.stopPropagation();
    if(props.cost.contractFileUrl) {
      navigator.clipboard.writeText(`${process.env.REACT_APP_HOST}/docs/${props.cost.contractFileUrl}`)
    }
    if(fileName){
      navigator.clipboard.writeText(`${process.env.REACT_APP_HOST}/docs/${fileName}`)
    }
    setActvieCopyIcon(!copyIcon);
  };


  useEffect(()=>{
    setFileName(tempFileName)
  },[tempFileName])

  const onLinkClick = (event) => {
    event.stopPropagation();
    if(props.cost.contractFileUrl || fileName){
      window.open(`${props.cost.contractFileUrl ? `${process.env.REACT_APP_HOST}/docs/${props.cost.contractFileUrl}` : fileName ?  `${process.env.REACT_APP_HOST}/docs/${fileName}` : "" }`, '_blank')
    }
  }

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           {props.modalTitile}
          </Modal.Title>
        </Modal.Header>
         <Modal.Body style={{margin:"auto",width:"100%"}}>
        <div className="d-flex flex-col  w-100">
          <div className="d-flex flex-end">
          <Button style={{width:"30%"}} disabled={!props.status} className="d-flex" onClick={()=>setShowPdf(true)}><span>{props.status && <ProjectReportPdf cost={props.cost} adminCost={props.adminCost} filledCost={props.filledCost} approvedcosts ={props.approvedcosts}/>}</span> </Button>
          <Button style={{width:"30%",marginLeft:"2rem"}} disabled={typeof(address) === "undefined"} className="d-flex" onClick={()=>setShowPdf(true)}><span>{props.status && <AgreementPdf cost={props.cost} adminCost={props.adminCost} filledCost={props.filledCost} approvedcosts ={props.approvedcosts} getDocFileUrl={getDocFileUrl} _id={_id} onHide={props.onHide}/>}</span> </Button>
          </div>
        
        </div>
        <div style={{paddingTop:"20px"}} className="">

        {/* <Box
            style={{marginBottom:"20px"}}
            sx={{
              width: '100%',
              maxWidth: '100%',
              m: 1,
            }}
          >
            <TextField 
            fullWidth label="Contract document file url"
            id="fullWidth"
            value={`D:\ReactProject\mmi-funnel-portal-taiga\docfile`}
            disabled={true}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  { <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            />

          </Box> */}

        {  <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Contract document file url</InputLabel>
          <OutlinedInput
            style={{color:"blue",textDecoration:"underline",cursor:"pointer"}}
            id="outlined-adornment-password"
            onClick={onLinkClick}
            type="text"
            disableUnderline={true}
            value={props.cost.contractFileUrl ? `${process.env.REACT_APP_HOST}/docs/${props.cost.contractFileUrl}` : fileName ?  `${process.env.REACT_APP_HOST}/docs/${fileName}` : "" }
            autoComplete='off'
            disabled ={props.cost.contractFileUrl ?  false : fileName ?  false : true }
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {copyIcon ? <ContentCopyIcon color="primary" /> : <ContentCopyIcon   />}
                </IconButton>
              </InputAdornment>
            }
            label="Contract document file url"
          />
        </FormControl>} 

        <Box
             style={{marginBottom:"20px"}}
            sx={{
              width: '100%',
              maxWidth: '100%',
              m: 1,
            }}
          >
          { !address && <p>Note :- Please update address to download contract </p>}
            <TextField 
            fullWidth label="Legal Business Name"
            id="fullWidth"
            name="legalBusinessName"
            onChange={handleChange}
            value={agreementData.legalBusinessName}
            //disabled={props.questionnaireData.createdProject[0]?._id ? true : false}
            />

          </Box>
          <p style={{marginLeft:"10px"}} className="text-red margin-bottom-5">{error.legalBusinessName}</p>
        </div>
        <div className="">
        <Box
            sx={{
              width: '100%',
              maxWidth: '100%',
              m: 1,
            }}
          >
            <TextField 
            fullWidth label="Address"
            id="fullWidth"
            name="address"
            onChange={handleChange}
            value={agreementData.address}
            //disabled={props.questionnaireData.createdProject[0]?._id ? true : false}
            />

          </Box>
          <p style={{marginLeft:"10px"}} className="text-red margin-bottom-5">{error.address}</p>
        </div>       
        </Modal.Body>
        <Modal.Footer >
          <Button onClick={props.onHide}>Close</Button>
          <Button   onClick={handleSubmit}>Submit</Button>
        </Modal.Footer>
      {/* {<ProjectReportPdf cost={props.cost} adminCost={props.adminCost} filledCost={props.filledCost} approvedcosts ={props.approvedcosts}/>} */}
      </Modal>
    </>
  );
};
AgreementModal.defaultProps = {
  modalTitile: "Agreement Modal",
  status: false,
}

export default AgreementModal;
