import { Route, Redirect } from "react-router-dom";
import React,{useState,useEffect} from "react";
import { connect } from "react-redux";
import Cookies from "js-cookie";
const AdminAuthRoute = ({ component: Component, ...rest }) => {
    const accessToken = Cookies.get("userAccessToken")
    const [isLogin, setIsLogin] = useState(accessToken);
    const [isAdmin, setIsAdmin] = useState(JSON.parse(localStorage.getItem("isAdmin")));

   useEffect(() => {
    setIsAdmin(Cookies.get("isAdmin"));
    setIsLogin(accessToken)
 }, [accessToken]);
 
 console.log("typeOf===",typeof(isAdmin))
  return (
    <Route
      {...rest}
      render={props =>
        isLogin && isAdmin ? (
          <Component  {...props}/>
        ) : (
            <Redirect
              to={{
                pathname: "/admin-login",
                state: { from: props.location }
              }}
            />
          )
      }
    />
  );
};


export default AdminAuthRoute;