import React, { useState, useEffect, useRef } from "react";
import { Card, Header, Body, Button, Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { getKanbanSprint } from "../../store/actions/portal-report";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Loader from '../../components/loader';
import addDays from 'date-fns/addDays'
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import DatePicker from "react-datepicker";
import Cookies from "js-cookie";
import InputLabel from '@mui/material/InputLabel';
import { PDFExport, Component, ViewEncapsulation, savePDF } from "@progress/kendo-react-pdf";
import '../../styles/kendo.css'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import SprintReportPdf from './SprintReportPdf'



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};



const tempData = Array.from({ length: 10 }, () => ({ sprintId: "#210", sprintName: "Sprint 1" }))


const ProjectReportingSprint = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [isLogin, setIsLogin] = useState(Cookies.get("userAccessToken"));
  const [isAdmin, setIsAdmin] = useState(Cookies.get("isAdmin"));
  const pdfExportComponent = React.useRef(null);
  const container = React.useRef(null);

  const issuesCsvData = useSelector((state) => state.portal)
  const processedData = useSelector((state) => state.portal.usersSprintData)
  const issuesProcessedData = useSelector((state) => state.portal.issueSprintData)
  const kanbanLoading = useSelector((state) => state.portal.isKanbanLoading)
  const combineData = issuesProcessedData[0] !== null && processedData[0] !== null ? processedData.map(users => ({ ...users, ...issuesProcessedData.find(issues => users.sprint_id === issues.sprint_id) })).reverse() : []
  const combineSprintName = combineData.map(sprintItem => sprintItem.sprint_name)
  const estDate = combineData.map(sprintItem => sprintItem.sprint_estimated_start)
  const estEndDate = combineData.map(sprintItem => sprintItem.sprint_estimated_finish)
  const ref = useRef();
  const [selctedSprint, setSelectedSprint] = useState(combineData)
  const theme = useTheme();
  const [startDate, setStartDate] = useState(new Date);
  const [endDate, setEndDate] = useState(new Date);
  const [personName, setPersonName] = React.useState([combineSprintName[0]]);
  const projectId = props.history.location.state ? props.history.location.state.projectId : null;
  const projectName = props.history.location.state ? props.history.location.state.projectName : null;


  useEffect(() => {
    if (personName.toString() === "") {
      setPersonName([combineSprintName[0]])
      setStartDate(new Date(estDate[0]))
      setEndDate(new Date(estEndDate[0]))
    } else {
      if (!combineSprintName.includes(personName.toString())) {
        setPersonName([combineSprintName[0]])
      }
    }
    setSelectedSprint(combineData.filter(x => x.sprint_name === personName.toString()))
  }, [issuesProcessedData, processedData])

  useEffect(() => {
    setSelectedSprint(combineData.filter(x => x.sprint_name === personName.toString()))
    if (combineData.length !== 0) {
      setStartDate(new Date(combineData.filter(x => x.sprint_name === personName.toString())[0].sprint_estimated_start))
      setEndDate(new Date(combineData.filter(x => x.sprint_name === personName.toString())[0].sprint_estimated_finish))
    }
  }, [personName])

  useEffect(() => {
    dispatch(getKanbanSprint(projectId))
  }, [])


  useEffect(() => {
    if (projectId == null) {
      history.push("/admindashboard")
    }
  })

  const onBackClick = () => {
    history.push("/project")
  }

  const onEndDateChange = (date) => {

    const params = {
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
      type: "fullRecord"
    }
    dispatch(getKanbanSprint(projectId, params))
  }



  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const handleChange = (event) => {
    const {
      target: { value }
    } = event;
    setPersonName(
      typeof value === "string" ? value.split(",") : value
    );

    setSelectedSprint(combineData.filter(x => x.sprint_name === event.target.value))
    setStartDate(new Date(selctedSprint[0].sprint_estimated_start))
    setEndDate(new Date(selctedSprint[0].sprint_estimated_finish))
    dispatch(getKanbanSprint(projectId))
  };

  const onResetClick = () => {
    dispatch(getKanbanSprint(projectId))
  }

  const exportPDFWithComponent = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };



  return (
    <>

      <div id='sprint-reporting'>
        <div>
          <Card.Header>Portal Reporting</Card.Header>
          <Card.Body className="mx-20 my-20" style={{ padding: "1rem 0" }}>
            <h2 className="margin-left-none">Mmi Funnel Portal</h2>

            <div className={`loading-container ${kanbanLoading ? "div-center" : ""}`}>
              {!kanbanLoading ? selctedSprint.length !== 0 && combineData.length !== 0 ? selctedSprint.map((sprintItem, index) => {
                return <>
                  <div className="mt-20 sprint-container" key={index} style={{ padding: "20px 0" }}>
                    <div id='sprint-inner-container'>
                      <div className="d-flex space-between">
                        <FormControl sx={{ m: 1, width: 300, mt: 3 }}>
                          <InputLabel id="demo-multiple-name-label">Sprint Name</InputLabel>
                          <Select
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            displayEmpty
                            value={personName}
                            onChange={handleChange}
                            input={<OutlinedInput label="Sprint Name" />}
                            renderValue={(selected) => {
                              return selected.join(", ");
                            }}
                            MenuProps={MenuProps}
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            {combineSprintName.map((name) => (
                              <MenuItem
                                key={name}
                                value={name}
                                style={getStyles(name, personName, theme)}
                              >
                                {name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <div style={{ marginTop: "2%" }}>
                          <SprintReportPdf selctedSprint={selctedSprint} pdfData={{ projectName: projectName, startDate: startDate }} />
                        </div>
                      </div>
                      <div>
                        <div className="my-20 d-flex justify-between align-center" style={{ position: "relative" }}>
                          <span className="sprint-filter-text"> Sprint Name :  {sprintItem.sprint_name}</span>
                          <span className="d-flex ">
                            <span className="sprint-filter-text" style={{ marginRight: "10px", alignSelf: "center" }}>Sprint Estimated Start   </span>
                            <>
                              <DatePicker
                                style={{ width: "150px" }}
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                dateFormat="dd/MM/yyyy"
                              />
                            </>
                          </span>
                          <span className="d-flex "> <span style={{ marginRight: "10px", alignSelf: "center" }} className="sprint-filter-text">Sprint Estimated Finish </span>
                            <>
                              <DatePicker selected={endDate}
                                minDate={startDate}
                                onChange={(date) => setEndDate(date)}
                                dateFormat="dd/MM/yyyy"
                                minDate={startDate}
                              />
                              <Button style={{ width: "70px", height: "30px", fontSize: "10px" }}
                                onClick={onEndDateChange}>Submit</Button>
                              <Button style={{ width: "70px", height: "30px", fontSize: "10px", marginLeft: "10px" }}
                                onClick={onResetClick}>Reset</Button>
                            </>
                          </span>
                        </div>
                      </div>


                    </div>


                    <div>


                      <div class="sprint-headerbg" id="one" data-color="#ffffff">

                        {/* <div class="headerimg"></div> */}
                        <div className="div-center" style={{ margin: "1rem", position: "relative" }}>
                          <img style={{ zIndex: "10" }} src="../images/logo.png" alt="" />
                        </div>
                        <div className="" style={{ marginTop: "50px" }}>
                          <Container style={{ width: "70%" }}>
                            <Card style={{ borderRadius: "10px", padding: "10px 0px 30px" }} >
                              <Row>
                                <Col style={{ margin: "30px" }}>
                                  <div style={{ paddingLeft: "40px" }} className="sprint-main-heading sprint-bold-text"> {projectName} - {sprintItem.sprint_name}</div>
                                </Col>
                              </Row>
                              <Row >
                                <Col>
                                  <div style={{ margin: "20px 0px" }}>
                                    <Row className=" content-center">
                                      {/* <RadialBar series={[82]} color={"#107b9b"} seriesFont = {"32px"}/>  */}
                                      <div style={{ width: "60%" }} className="text-center ">
                                        <CircularProgressbar
                                          value={sprintItem.inscope_completed || 0}
                                          text={`${Math.round(sprintItem.inscope_completed || 0)}%`}
                                          strokeWidth={16}
                                          styles={buildStyles({
                                            textColor: "#092ea8",
                                            pathColor: "#092ea8",
                                            trailColor: "#d1d3d4",
                                            strokeLinecap: 'butt',
                                          })}
                                          counterClockwise
                                        />
                                      </div>
                                    </Row>
                                    <Row className="content-center" style={{ marginLeft: "60px", marginTop: "30px" }}>
                                      <Col style={{ padding: "0px" }} className="sprint-heading"> <span className="sprint-bold-text" style={{ fontSize: "20px" }}>{`${Math.round(sprintItem.inscope_completed || 0)}%`} </span> <span className="font-lightgray">of in scope items completed </span></Col>
                                      <Col style={{ padding: "0px" }} className="sprint-heading"> <span className="sprint-bold-text" style={{ fontSize: "20px" }}>{`${Math.round(sprintItem.outofscope_completed || 0)}%`} </span> <span className="font-lightgray">of out of scope items completed </span></Col>
                                    </Row>
                                  </div>
                                </Col>

                                <Col style={{ padding: "0px 20px", marginRight: "40px" }}>
                                  <div className="pdf-box" style={{ padding: "30px 0px 45px 30px", backgroundColor: "#fcfcfc" }}>
                                    <Row style={{ height: "33.33%", marginTop: "20px", marginBottom: "35px" }}>
                                      <div className="d-flex mtb-10" style={{ marginTop: "10px" }}>
                                        <span className="content-center" style={{ backgroundColor: "#e2e2e2", padding: "5px", borderRadius: "5px", width: "60px", height: "60px", marginRight: "20px" }}>
                                          <img src="assets/images/Grupo_.svg" />
                                        </span>
                                        <span className="d-flex flex-col">
                                          <h6 className="sprint-heading"> Sprint Length :</h6>
                                          <span><h6 className="pdf-text-color sprint-main-heading">{sprintItem.sprint_length} Days</h6></span>
                                          <h6 className="sprint-heading"> Start Date : {moment(startDate).format('MM/DD/YYYY')}</h6>
                                        </span>
                                      </div>
                                    </Row>
                                    <Row style={{ height: "33.33%", marginBottom: "35px" }}>
                                      <div className="d-flex mtb-10">
                                        <span className="content-center" style={{ backgroundColor: "#e2e2e2", padding: "5px", borderRadius: "5px", width: "60px", height: "60px", marginRight: "20px" }}>
                                          <img src="assets/images/Grupo_ 1.svg" />
                                        </span>
                                        <span className="d-flex flex-col">
                                          <h6 className="sprint-heading"> Story Points Estimated : </h6>
                                          <span><h6 className="pdf-text-color sprint-main-heading">{sprintItem.story_point_estimated || 0} pts.</h6></span>
                                        </span>
                                      </div>
                                    </Row>
                                    <Row style={{ height: "33.33%", marginBottom: "30px" }}>
                                      <div className="d-flex mtb-10">
                                        <span className="content-center" style={{ backgroundColor: "#e2e2e2", padding: "5px", borderRadius: "5px", width: "60px", height: "60px", marginRight: "20px" }}>
                                          <img src="assets/images/Trazado compuesto_.svg" />
                                        </span>
                                        <span className="d-flex flex-col">
                                          <h6 className="sprint-heading"> Avg. Story Points / Sprint : </h6>

                                          <span className="pdf-text-color sprint-main-heading">{Math.round(sprintItem.total_story_poins)} pts.</span>
                                          <h6 className="sprint-heading"> Extraction Date : {moment(new Date()).format('MM/DD/YYYY')}</h6>
                                        </span>
                                      </div>
                                    </Row>
                                  </div>

                                </Col>
                              </Row>
                            </Card>
                          </Container>
                          <Container style={{ width: "70%", marginTop: "20px" }}>

                            <Row style={{ marginLeft: "0", marginRight: "0", marginTop: "30px" }} className="d-flex" id="sprint-bottom-container">
                              <Col style={{ width: "33%", paddingRight: "0", paddingLeft: "0" }}>
                                <Card className="d-flex flex-row" style={{ borderRadius: "10px", paddingBottom: "20px" }}>
                                  <div style={{ marginTop: "40px" }} className="d-flex flex-col " >
                                    <div style={{ marginLeft: "20px", marginRight: "20px" }}>
                                      <div style={{ width: "40%", display: "inline-block", float: "left" }}>
                                        <CircularProgressbar
                                          value={Math.round(sprintItem.storeisCompleted || 0)}
                                          text={`${Math.round(sprintItem.storeisCompleted || 0)}%`}
                                          strokeWidth={8}
                                          styles={buildStyles({
                                            textColor: "#092ea8",
                                            pathColor: "#092ea8",
                                            trailColor: "#d1d3d4",
                                          })}
                                          counterClockwise
                                        />
                                      </div>
                                      <span className="graph-text">The percentage of <br></br> <strong>Kanban</strong> that <br></br> were completed <br></br> during sprint </span>
                                    </div>
                                    <div className="sprint-fotter-container" style={{ marginLeft: "20px", marginRight: "20px", marginTop: "70px" }}>
                                      <span className="sprint-heading" style={{ width: "100%" }}>
                                        <span style={{ float: "left", width: "45%", fontWeight:"bold" }}>{`${Math.round(sprintItem.storiesCreated) || 0}`} user stories created</span>
                                        <span style={{ float: "right", width: "45%", fontWeight:"bold" }}>{`${Math.round(sprintItem.storiesClosed) || 0}`} user stories completed</span>
                                      </span>
                                    </div>
                                  </div>
                                </Card>
                              </Col>

                              <Col style={{ width: "33%", paddingRight: "0", paddingLeft: "0", margin: "0px 20px" }}>
                                <Card className="d-flex flex-row" style={{ borderRadius: "10px", paddingBottom: "20px" }}>
                                  <div style={{ marginTop: "40px" }} className="d-flex flex-col " >
                                    <div style={{ marginLeft: "20px", marginRight: "20px" }}>
                                      <div style={{ width: "40%", display: "inline-block", float: "left" }}>
                                        <CircularProgressbar
                                          value={Math.round(sprintItem.issuesCompleted || 0)}
                                          text={`${Math.round(sprintItem.issuesCompleted || 0)}%`}
                                          strokeWidth={8}
                                          styles={buildStyles({
                                            textColor: "#092ea8",
                                            pathColor: "#092ea8",
                                            trailColor: "#d1d3d4"
                                          })}
                                          counterClockwise
                                        />
                                      </div>
                                      <span className="graph-text">The percentage of <br></br> <strong>issues</strong> that <br></br> were completed <br></br> during sprint </span>
                                    </div>
                                    <div className="sprint-fotter-container" style={{ marginLeft: "20px", marginRight: "20px", marginTop: "70px" }}>
                                      <span className="sprint-heading" style={{ width: "100%" }}>
                                        <span style={{ float: "left", width: "45%", fontWeight:"bold" }}>{`${Math.round(sprintItem.issuesCreated) || 0}`} user stories created</span>
                                        <span style={{ float: "right", width: "45%", fontWeight:"bold" }}>{`${Math.round(sprintItem.issuesClosed) || 0}`} user stories completed</span>
                                      </span>
                                    </div>
                                  </div>
                                </Card>
                              </Col>

                              <Col style={{ width: "33%", paddingRight: "0", paddingLeft: "0" }}>
                                <Card className="d-flex flex-row" style={{ paddingBottom: "20px", borderRadius: "10px" }}>
                                  <div style={{ marginTop: "40px" }} className="d-flex flex-col " >
                                    <div style={{ marginLeft: "20px", marginRight: "20px" }}>
                                      <div style={{ width: "40%", display: "inline-block", float: "left" }}>
                                        <CircularProgressbar
                                          value={Math.round(sprintItem.changeRequest) || 0}
                                          text={`${Math.round(sprintItem.outOfScopeClosed) || 0}`}
                                          strokeWidth={8}
                                          styles={buildStyles({
                                            textColor: "#092ea8",
                                            pathColor: "#092ea8",
                                            trailColor: "#d1d3d4",
                                          })}
                                          counterClockwise
                                        />
                                      </div>
                                      <span className="graph-text">The number of <br></br> <strong>out-of-scope-issues</strong> that <br></br> were completed <br></br> during sprint </span>
                                    </div>
                                    <div className="sprint-fotter-container" style={{ marginLeft: "20px", marginRight: "20px", marginTop: "70px" }}>
                                      <span className="sprint-heading" style={{ width: "100%" }}>
                                        <span style={{ float: "left", width: "45%", fontWeight:"bold" }}>{`${Math.round(sprintItem.outOfScopeCreated) || 0}`} issues created</span>
                                        <span style={{ float: "right", width: "45%", fontWeight:"bold" }}>{`${Math.round(sprintItem.outOfScopeClosed) || 0}`} issues completed</span>
                                      </span>
                                    </div>

                                  </div>

                                </Card>
                              </Col>

                            </Row>

                          </Container>
                          <Container style={{ display: "flex", justifyContent: "center", alignItem: "center" }}>
                            <Card style={{ width: "70%", marginTop: "20px", marginBottom: "20px", backgroundColor: "transparent", border: "none" }}>

                              <Row className="d-flex flex-wrap">
                                {sprintItem.outScopeListing && sprintItem.outScopeListing.sort((a, b) => a.subject.length - b.subject.length).map((sprintList) => {

                                  return <Col style={{ flex: "0 0 50%" }}>
                                    <div className="outofScope-listing sprint-lesting-container" style={{ margin: "0px 0px 15px 0px", padding: "15px", width: "100%" }}>
                                      <span style={{ marginRight: "5px", width: "100%", fontWeight: "900", fontSize: "22px" }}>{`#${sprintList.ref}  `}</span>
                                      <span>{`${sprintList.subject} `}</span>
                                    </div>
                                  </Col>
                                })}
                              </Row>
                            </Card>
                          </Container>



                        </div>
                      </div>

                    </div>
                  </div>
                </>
              })


                : <div style={{ marginBottom: "5%" }} className="d-flex empty-container justify-center align-center mb-20"> <span> <h2>No Sprint data present </h2> </span> </div>


                : <div style={{ marginBottom: "5%" }}><Loader /> </div>}
            </div>
            <Button style={{ fontSize: "10px", width: "100px", margin: "10px 10px 10px 0" }} onClick={onBackClick}>
              <div style={{ marginRight: "10px" }}>
                <span> <img className="pdf-img" src="../assets/images/back.png" alt="" /></span>
                Back
              </div>
            </Button>
          </Card.Body>

        </div>
      </div>


    </>
  );
};

export default ProjectReportingSprint;
