import React from  "react" 
import { Link } from "react-router-dom";
import Loader from '../../components/loader'
import {useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";

const ProjectTable = ({project,getKanbanReport,indexOFFirstResult}) => {
  const isLoading = useSelector((state)=>state.portal.portalLoading)
    return <>
   { isLoading ? <Loader  styles={{zIndex:99999 , position: "absolute" , top:"50vh" , width:"100%" ,left:"50%",transform:"translate(-50%, 0)"}}/> :
    <div className="container table-responsive ">

  
    <table  id="adminDashboard" className="table-size-11">
        <tr>
                <th>#</th>
                <th>Project title</th>
                <th>Portal url</th>
                <th>Week wise reporting </th>
                <th>Sprint wise reporting </th>
              
              </tr>
             {Object.keys(project).length !== 0 && project.map((list,index)=>{
              
               return  <tr key={index} className="">
               <td>{index + 1 + indexOFFirstResult}</td>
               <td style={{minWidth:"100px"}} ><p className="p mb-none font-600">{list.projectTitle}</p></td>
               <td  ><span>{list.portalUrl} </span></td>
               <td>
               <Link
                       className="d-flex justify-center mr-10"
                       data-tip data-for="kanbanTip"
                       onClick={()=>getKanbanReport(list.taigaProjectId )}
                        to={{
                          pathname: `/project-reporting`,
                          state: { 
                           projectId : list.taigaProjectId
                          }
                        }}
                       >
                       <img className="pdf-img" src="../assets/images/report.png" alt="" />
                       <ReactTooltip id="kanbanTip" place="top" effect="solid">
                        Kanban protal reporting 
                       </ReactTooltip>
                     </Link>
                 </td>
               <td >
               <div className="td-link">
                   <span className="d-flex">
                     
                     <Link
                       data-tip data-for="registerTip"
                       className="d-flex justify-center"
                       //onClick={()=>getUserstories(list.taigaProjectId || 435063 ,"sprint")}
                        to={{
                          pathname: `/project-reporting-sprint`,
                          state: { 
                           projectId : list.taigaProjectId,
                           projectName: list.projectTitle
                          }
                        }}
                       >
                       <img className="pdf-img" src="../assets/images/report.png" alt="" />
                       <ReactTooltip id="registerTip" place="top" effect="solid">
                        Sprint portal reporting
                       </ReactTooltip>
                     </Link>
                     
                   </span>
                 </div>
               
                 </td>
              
             </tr>
             })} 
       
           
      </table>
      </div>}
    </>  
}
export default ProjectTable