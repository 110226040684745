import React,{useState} from 'react'
import { Button } from "react-bootstrap";
import { Card } from "react-bootstrap";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Switch from '@mui/material/Switch';




const AdminNav = (props) => {
     const history = useHistory();
    const [modalShow, setModalShow] = React.useState(false);
    const [toggle, setToggle] = useState(true);

  const onAgencyInvite = (item) => {
    setModalShow(true)
  }

  const handleLogout = () => {
    localStorage.clear();
    Cookies.remove("userLogin");
    Cookies.remove("isAdmin")
    Cookies.remove("userAccessToken")
    Cookies.remove("isAdmin",{path:"/admin-servicecost"})
    Cookies.remove("userAccessToken",{path:"/admin-servicecost"})
    history.push("/admin-login");
  };

    return  <body>
    <div className="d-flex" id="wrapper">
      {toggle && (
        <div className="border-end bg-white side-nav-dashboard" id="sidebar-wrapper">
          <div className="sidebar-heading border-bottom bg-light">
            Mmi funnel portal
          </div>
          <div className="list-group list-group-flush">
            <Link
              className=" list-group-item-action list-group-item-light p-3"
              to="/admindashboard"
            >
              <span >  Dashboard{" "} </span>
            </Link>
            <Link
              className=" list-group-item-action list-group-item-light p-3"
              to="/project"
            >
              Projects
            </Link>
            <Link
              className=" list-group-item-action list-group-item-light p-3"
              to="/invite-agency"
            >
               <span className='seleted-item'>Invite Agency </span>
            </Link>
          </div>
        </div>
      )}

      <div id="page-content-wrapper">
        <nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom nav-padding-balance">
          <div className="container-fluid">
            <div className="">
               <Switch
                    value={toggle}
                    defaultChecked={false}
                    onChange={()=> setToggle(!toggle)}
                    color="info"

                  />
            </div>

            <div
              className="collapse navbar-collapse "
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto mt-2 mt-lg-0">
                <button
                  onClick={handleLogout}
                  className="btn btn-primary"
                  id="sidebarToggle"
                >
                  Sign out
                </button>
              </ul>
            </div>
          </div>
        </nav>
        {props.children}
        </div>
        </div>
        </body>
}

export default AdminNav