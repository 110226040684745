import  React,{useEffect ,useState} from "react";
import { PDFExport , Component, ViewEncapsulation ,savePDF } from "@progress/kendo-react-pdf";
import moment from "moment";
import {getPdfCategoryText,
  showHeadingText,selectedCategoryText,
  developmentServiceShortDis ,
  renderDevelopmentShortDiscription ,
  renderMarketingCostShortDiscription,
  advertisingShortDiscription,
  designShortDiscription,
  incubationShortDiscription,
  parentCategorySummary,
  developmentServices,
  advertisingServicesDiscription,
  marketServicesDiscription,
  designServicesDiscription,
  incubationServicesDiscription,
  renderShortDiscription,
  consultingServiceDiscription
} from '../constents/PdfText'

import '../styles/kendo.css'






const ProjectReportPdf = (props) => {

  const {cost,filledCost} = props
   let parent_category = getPdfCategoryText(props.cost)
   const subCategory = selectedCategoryText(props.cost)
 
   let parentCategorySuffix = parent_category === "Marketing" ? "Marketing campaign": parent_category === "Technology" ? "Technology processes" : "Technology processes and Marketing campaign"

  const pdfExportComponent = React.useRef(null);
    const container = React.useRef(null);


    const exportPDFWithMethod = () => {
    let element = container.current ;
    savePDF(element, {
      paperSize: "auto",
      margin: 40,
      fileName: `Report for hello`,
    });
  };

  const exportPDFWithComponent = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };

    const tempDate = moment(cost.createdAt).format('Do MMMM YYYY')

   //var  d= txt1.slice(0, 3) + "bar" + txt1.slice(3);
   const date = tempDate.slice(0,4) + " of " + tempDate.slice(4)


   const showTimeLine = (approvedCost) => {
    // const {advertisingCost,designCost,developmentCost,incubationCost,marketingCost,consultingCost} = approvedCost
    // const adsTime = advertisingCost.length !==0 ? advertisingCost.map(approveCost => approveCost.time) : null
    // const devTime = developmentCost.length !==0 ? developmentCost.map(approveCost => approveCost.time) : []
    // const designTime = designCost.length !==0 ? designCost.map(approveCost => approveCost.time) : null
    // const incubationTime = incubationCost.length !==0 ? incubationCost.map(approveCost => approveCost.time) : null
    // const marketTime = marketingCost.length !==0 ? marketingCost.map(approveCost => approveCost.time) : null
    // const consulTime = consultingCost.length !==0 ? consultingCost.map(approveCost => approveCost.time) : null
    // const  allTime = devTime.concat(adsTime,designTime,incubationTime,marketTime,consulTime).filter(x => x !== null && x !== undefined).reduce((partialSum, a) => partialSum + a, 0);
    // return  `${allTime} months`
    let totalTime = 0
    for(let cost in approvedCost) {
      if(Array.isArray(approvedCost[cost])){
         for(let times of approvedCost[cost]){
              totalTime += times.time           }
      }
    }
    return `${totalTime} months`
}

 
  const time = showTimeLine(Object.assign({},props.approvedcosts[0]))

  const totalCost = (approvedCost,list ,price = cost.price || 20) => {
    // const {advertisingCost,designCost,developmentCost,incubationCost,marketingCost,consultingCost} = approvedCost
    // const adsTime = advertisingCost.length !==0 ? advertisingCost.map(approveCost =>  parseInt(approveCost.cost)) : null
    // const devTime = developmentCost.length !==0 ? developmentCost.map(approveCost => parseInt(approveCost.cost)) : []
    // const designTime = designCost.length !==0 ? designCost.map(approveCost => parseInt(approveCost.cost)) : null
    // const incubationTime = incubationCost.length !==0 ? incubationCost.map(approveCost => parseInt(approveCost.cost)) : null
    // const marketTime = marketingCost.length !==0 ? marketingCost.map(approveCost => parseInt(approveCost.cost)) : null
    // const consultingTime = consultingCost.length !==0 ? consultingCost.map(approveCost => parseInt(approveCost.cost)) : null

    // let  allTime = devTime.concat(adsTime,designTime,incubationTime,marketTime,consultingTime).filter(x => x !== null && x !== undefined).reduce((partialSum, a) => partialSum + a, 0);
    // return  `${allTime += allTime * price / 100 }`
    let totalTime = 0
    for(let cost in approvedCost) {
      if(Array.isArray(approvedCost[cost])){
         for(let times of approvedCost[cost]){
              totalTime += Number(times.cost)         }
      }
    }
    return  `${totalTime += totalTime * price / 100 }`

}



 

    
  
  return ( 
    <div>
      <div className="example-config">
        <button
          className="k-button"
          style={{all:"unset",cursor:"pointer"}}
          onClick={exportPDFWithComponent}
        >
           <img className="pdf-img" src="../assets/images/pdf.png" />
           Download Proposal
        </button>
      </div>

      <div
       style={{
        position: "absolute",
        left: "-10000000px",
        top: 0,
        zIndex :9999999
        }}
      >
        <PDFExport forcePageBreak=".page-break" paperSize="A4"  ref={pdfExportComponent}  fileName={`mmi-funnel-${`${cost.firstName} ${cost.lastName}`}`}  >
               
              <>
               <div style={{height:"900px"}}>
               <div style={{backgroundImage: "url(assets/images/propsal-background.png)",backgroundRepeat:"no-repeat",objectFit:"fill" ,width:"100%",height:"100%",backgroundPosition:"center",backgroundSize:"100% 100%"}}> 
               
                <div className="d-flex" style={{justifyContent:"center",paddingTop:"200px"}} >
                   <img style={{width:"150px"}} src="assets/images/WHITE_RainMakers_Logo_RGB.png"/> 
                   </div> 
                <div className="front-page d-flex justify-center align-center my-50">
                  <p className="front-page-title-text" >{showHeadingText(cost && `${cost.firstName} ${cost.lastName}` , date)}</p>
               
              </div>
              </div>
              </div>
               
              </>
            <div style={{height:"900px"}}>
            <div className="page-body py-50" style={{backgroundImage: "url(assets/images/propsal-background.png)",backgroundRepeat:"no-repeat",objectFit:"fill",height:"100%", width:"100%",backgroundSize:"cover"}}>
               <div style={{paddingLeft:'50',paddingRight:'50px'}}>
              <h4 className="heading-text-dark-blue" style={{marginTop:"50px" }}>{` Summation of Marketing Machine, ${parent_category} Strategy`}</h4>
              </div>
              <div className="margin-top-5"  >
                 <div> <p className="para-text"> Marketing Machine will deploy a multi-faceted {subCategory}  strategy--replete with a digital {parent_category} approach to take command of {`${cost.firstName} ${cost.lastName}`}’s digital and technology presence in the market.
                   Marketing Machine will provide setup, development, and management of finely-tuned {parentCategorySuffix}.  By commanding a strong execution strategy while also observing and reacting to a rigorous tracked testing model, {`${cost.firstName} ${cost.lastName}`} will see a marked rise in business ROI.  
                      </p> </div>
                 {/* <div> <span className="heading-text-dark-blue text-left">Roadmap </span> <span className="heading-text-light">Strategy</span> </div> */}
                 <div className="my-10 text-left"> <span className="heading-text-dark-blue">Roadmap </span> <span className="heading-text-light">Strategy </span> </div>
                 <div style={{marginTop:"10px",marginBottom:"40px"}}>
                   {renderShortDiscription(props.approvedcosts)}
                   <div> {parentCategorySummary(`${cost.firstName} ${cost.lastName}`)}  </div>
                   <div> {developmentServices(filledCost,cost.price ,props.approvedcosts ,`${cost.firstName} ${cost.lastName}`)} </div>
                   <div> {advertisingServicesDiscription(filledCost,cost.price, props.approvedcosts ,`${cost.firstName} ${cost.lastName}`)} </div>
                   <div>{marketServicesDiscription(filledCost,cost.price , props.approvedcosts ,`${cost.firstName} ${cost.lastName}`)} </div>
                    <div>{designServicesDiscription(filledCost,cost.price , props.approvedcosts ,`${cost.firstName} ${cost.lastName}`)}</div>
                   <div>{incubationServicesDiscription(filledCost,cost.price , props.approvedcosts ,`${cost.firstName} ${cost.lastName}`)}</div>
                 {Object.assign({},props.approvedcosts[0]).hasOwnProperty('consultingCost') && <div>{consultingServiceDiscription(filledCost,cost.price , props.approvedcosts ,`${cost.firstName} ${cost.lastName}`)}</div>}
                   </div>
                  </div>
                  <div className="my-10 text-left"> <span className="heading-text-dark-blue">Delivery </span> <span className="heading-text-light">timeline </span> </div>
                  <p className="para-text d-flex"><span><img className="bullet-img" src="assets/images/triangle.png"/> </span> <span> The timeline for delivery of the staking requirements is  <b className="text-semibold">{time}</b>  from the receipt of this
                   signed proposal and the required payment. </span></p>

                  <div className="my-10 text-left"> <span className="heading-text-dark-blue">Pricing  </span> <span className="heading-text-light"> & Payment Terms </span> </div>

                  <p className="para-text d-flex"><span><img className="bullet-img" src="assets/images/triangle.png"/> </span> <span>Compensation due will be   <b className="text-semibold">{totalCost(Object.assign({},props.approvedcosts[0]))}$</b>  at the time of signing. The ongoing services selected are
                   payable monthly in advance.</span></p>
                   <p className="para-text d-flex"><span><img className="bullet-img" src="assets/images/triangle.png"/> </span> <span>The initial agreement shall be for six months. Thereafter, the agreement shall be ongoing and may be terminated upon ninety days written notice.</span></p>
                   

                      
            </div>
           </div>
           <div style={{height:"900px"}}>
                   <div className="page-body py-50" style={{backgroundImage: "url(assets/images/propsal-background.png)",backgroundRepeat:"no-repeat",objectFit:"fill",height:"100%", width:"100%",backgroundSize:"cover"}}>
                   <div className="d-flex  flex-col" style={{paddingLeft:'50',paddingRight:'50px'}}>
                  <div>
                  <div className="my-10 text-left"> <span className="heading-text-dark-blue">Proposal </span> <span className="heading-text-light">Acceptance</span> </div>
                  </div>
                  </div>
                  <div className="bullet-container">
                  <div className="bullet-text">   <span className="font-500">{"COMPANY"}</span></div>
                  <div className="bullet-text">   <span className="font-500">{"CUSTOMER"}</span></div>
                  <div className="bullet-text">  <span>{"Marketing Machine Inc"}</span></div>
                  <div className="bullet-text">  <span>{`${cost.firstName} ${cost.lastName}`}</span></div>
                  <div className="bullet-text"> <div className="w-90 mt-20"><hr style={{marginBottom:"0px"}}/></div></div>
                  <div className="bullet-text">  <div className="w-90 mt-20"> <hr style={{marginBottom:"0px"}}/></div> </div>
                  <div className="bullet-text">   <span>{"Signature"}</span></div>
                  <div className="bullet-text">   <span>{"Signature"}</span></div>
                  <div className="bullet-text"> <div className="w-90 mt-20"><hr style={{marginBottom:"0px"}}/></div></div>
                  <div className="bullet-text">  <div className="w-90 mt-20"> <hr style={{marginBottom:"0px"}}/></div> </div>
                  <div className="bullet-text">   <span>{"Print Name"}</span></div>
                  <div className="bullet-text">   <span>{"Print Name"}</span></div>
                  <div className="bullet-text"> <div className="w-90 mt-20"><hr style={{marginBottom:"0px"}}/></div></div>
                  <div className="bullet-text">  <div className="w-90 mt-20"> <hr style={{marginBottom:"0px"}}/></div> </div>
                  <div className="bullet-text">   <span>{"Title"}</span></div>
                  <div className="bullet-text">   <span>{"Title"}</span></div>
                  <div className="bullet-text"> <div className="w-90 mt-20"><hr style={{marginBottom:"0px"}}/></div></div>
                  <div className="bullet-text">  <div className="w-90 mt-20"> <hr style={{marginBottom:"0px"}}/></div> </div>
                  <div className="bullet-text">   <span>{"Date"}</span></div>
                  <div className="bullet-text">   <span>{"Date"}</span></div>
                  <div className="bullet-text"> <div className="w-90 mt-20"><hr style={{marginBottom:"0px"}}/></div></div>
                  <div className="bullet-text">  <div className="w-90 mt-20"> <hr style={{marginBottom:"0px"}}/></div> </div>
                  </div>
                      </div>
                    </div>
         
        </PDFExport>
      </div>
    </div>
  );
};

export default ProjectReportPdf
