import {
    CREATE_AGENCY_USER_START,
    CREATE_AGENCY_USER_SUCCESS,
    CREATE_AGENCY_USER_FAILED,
    GET_ALL_USERS_START,
    GET_ALL_USERS_SUCCESS,
    GET_ALL_USERS_FAILED,
    SET_NEW_PASSWORD_START,
    SET_NEW_PASSWORD_SUCCESS,
    SET_NEW_PASSWORD_FAILED,
    UPDATE_USER_START,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAILED,
    FORGOT_PASSWORD_START,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAILED,
    DELETE_USER_START,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAILED,
    GET_QUESTIONNAIRE_ADMIN_LIST_START,
    GET_QUESTIONNAIRE_ADMIN_LIST_SUCCESS,
    GET_QUESTIONNAIRE_ADMIN_LIST_FAILED,
    GET_AGREEMENT_DOCFILE_NAME
  } from "../../constents/actionType";
import axios from "axios";
import { authHeader } from "../../service/auth-header";
import ApiConfig from '../../service/ApiConfig'
import { toast } from "react-toastify";
import {getQuestionnaireAdminList} from './questionnaire'


  export const createAgencyUser = (data,onHide) => {
    return async (dispatch) => {
      dispatch({ type: CREATE_AGENCY_USER_START });
      try{
          const res = await axios.post(`${ApiConfig.baseURL}users/send-invite` , data , {
              headers: authHeader(),
            })
            dispatch({ type: CREATE_AGENCY_USER_SUCCESS, payload: res.data });
            onHide()
            toast.success("Please check your email to set a password")
      } catch (err) {
        console.log(err.response)
        dispatch({type :CREATE_AGENCY_USER_FAILED , payload: err})
        if(err.response.data){
          toast.error(`${err.response.data.message}`)
        }else{
          toast.error(`Something went wrong!`)
        }
      }
    }
  }

  export const getUsersList = (data,onHide) => {
    return async (dispatch) => {
      dispatch({ type: GET_ALL_USERS_START });
      try{
          const res = await axios.get(`${ApiConfig.baseURL}/users` , {
              headers: authHeader(),
            })
            dispatch({ type: GET_ALL_USERS_SUCCESS, payload: res.data.data });
            onHide()
      } catch (err) {
        dispatch({type :GET_ALL_USERS_FAILED , payload: err})
      }
    }
  }
  

  export const createPassword = (data,navigate) => {
    return async (dispatch) => {
      dispatch({ type: SET_NEW_PASSWORD_START });
      try{
          const res = await axios.post(`${ApiConfig.baseURL}auth/verify-invite` , data , {
              headers: authHeader(),
            })
            dispatch({ type: SET_NEW_PASSWORD_SUCCESS, payload: res.data });
            toast.success(`Password successfully created`)
            setTimeout(()=>{
              navigate()
            },500)
      } catch (err) {
        dispatch({type :SET_NEW_PASSWORD_FAILED , payload: err})
        toast.error(`Something went wrong!`)
      }
    }
  }


  export const updateUser = (data, id,onHide) => {
    return async (dispatch) => {
      dispatch({ type: UPDATE_USER_START });
      try{
          const res = await axios.patch(`${ApiConfig.baseURL}users/${id}` , data , {
              headers: authHeader(),
            })
            dispatch({ type: UPDATE_USER_SUCCESS, payload: res.data });
            dispatch(getUsersList())
            onHide()
            toast.success("Record update successfully")
      } catch (err) {
        dispatch({type :UPDATE_USER_FAILED , payload: err})
        toast.error(`Something went wrong!`)
      }
    }
  }

  export const forgotPassword = (data,onHide) => {
    return async (dispatch) => {
      dispatch({ type: FORGOT_PASSWORD_START });
      try{
          const res = await axios.post(`${ApiConfig.baseURL}auth/forgot-password` , data , {
              headers: authHeader(),
            })
            dispatch({ type: FORGOT_PASSWORD_SUCCESS, payload: res.data });
            toast.success("Please check your email to set a password")
            setTimeout(()=>{
              onHide()
            },500)

      } catch (err) {
        dispatch({type:FORGOT_PASSWORD_FAILED , payload: err})
        toast.error(`Something went wrong!`)
      }
    }
  }
  
  export const resetPassword = (data,navigate) => {
    return async (dispatch) => {
      dispatch({ type: SET_NEW_PASSWORD_START });
      try{
          const res = await axios.post(`${ApiConfig.baseURL}auth/reset-password` , data , {
              headers: authHeader(),
            })
            dispatch({ type: SET_NEW_PASSWORD_SUCCESS, payload: res.data });
            toast.success(`Password successfully created`)
            setTimeout(()=>{
              navigate()
            },500)
      } catch (err) {
        dispatch({type :SET_NEW_PASSWORD_FAILED , payload: err})
        toast.error(`Something went wrong!`)
      }
    }
  }
  
  export const deleteUser = (id) => {
    return async (dispatch) => {
      dispatch({ type: DELETE_USER_START });
      try{
          const res = await axios.delete(`${ApiConfig.baseURL}users/${id}` , {
              headers: authHeader(),
            })
            dispatch({ type: DELETE_USER_SUCCESS, payload: res.data });
            dispatch(getUsersList())
            toast.success("User deleted successfully")
      } catch (err) {
        dispatch({type :DELETE_USER_FAILED , payload: err})
        toast.error(`Something went wrong!`)
      }
    }
  }

  export const getAdminList = () => {
    return async (dispatch) => {
      //  dispatch({ type: GET_QUESTIONNAIRE_ADMIN_LIST_START });
      try{
          const res = await axios.get(`${ApiConfig.baseURL}/questionnaire/admin-list` , {
              headers: authHeader(),
            })
            dispatch({ type: GET_QUESTIONNAIRE_ADMIN_LIST_SUCCESS, payload: res.data.data });
      } catch (err) {
        dispatch({type :GET_QUESTIONNAIRE_ADMIN_LIST_FAILED , payload: err})
      }
    }
  }

  

  export const getDocFileUrl = (id,onHide) => {
    return async (dispatch) => {
      try{
          const res = await axios.get(`${ApiConfig.baseURL}create-project/get-docfile/${id}` , {
              headers: authHeader(),
          })
            dispatch({type:GET_AGREEMENT_DOCFILE_NAME,payload: res.data})
      } catch (err) {
      }
    }
  }
