import React, { useState, useEffect } from "react";
import ServiceQuestionnaire from "./components/ServiceQuestionnaire";
import "./styles/App.css";
import Login from "./components/Login";
import ServiceAndCost from "./components/ServiceAndCost";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Dashborad from "./dashboard/index";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminDashboard from "./admin/component/admindashboard";
import AdminLogin from "./admin/component/AdminLogin";
import AdminServiceCost from "./admin/component/adminServiceCost";
import PageNotFound from "./components/pagenotfound";
import Project from "./admin/reporting/project";
import PorjectPortalReporting from "./admin/reporting/projectPortalReporting";
import ProjectReportingSprint from "./admin/reporting/Projectreportingsprint";
import InviteAgency from './admin/inviteagency/inviteAgency'
import AdminNav from "./admin/component/AdminNav";
import ForgetPassword from "./components/resetpassword";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import AdminAuthRoute from "./authRoute";
import UserAuthRoute from "./userAuth";
import Sprintreportsend from './admin/reporting/Sprintreportsend'

function App() {
  const [auth, setauth] = useState(localStorage.getItem("user"));
  return (
    <div className="main-container">
      <BrowserRouter>
      {/* <AdminNav/> */}

        <div>
          <ToastContainer autoClose={2000} />
          <Switch>
            {/* admin route */}
            <AdminAuthRoute path="/admindashboard" exact component={AdminDashboard} />
            <AdminAuthRoute path="/project" exact component={Project} />
            <AdminAuthRoute path="/invite-agency" exact component={InviteAgency} />
            <AdminAuthRoute path="/project-reporting" exact component={PorjectPortalReporting}/>
            <AdminAuthRoute path="/project-reporting-sprint" exact component={ProjectReportingSprint}/>
            <Route path="/admin-servicecost" exact component={AdminServiceCost}/>
            {/** users route */}
            <Route path="/serviceandcost" exact component={ServiceAndCost} />
            <UserAuthRoute path="/dashboard" component={Dashborad} />
            {/***public route */}
            <Route path="/" exact component={Login} />
            <Route path="/login" exact component={Login} />
            <Route path="/serviceandcost/:request_id" component={Login} />
            <Route path="/services"component={ServiceQuestionnaire}/>
            <Route path="/reset-password/:token" component={ForgetPassword} />
            <Route path="/forgot-password/:token" component={ForgetPassword} />
            <Route path="/admin-login" exact component={AdminLogin} />
            <Route path="/sprint-report-send/:id" component={Sprintreportsend} />
            
            <Route component={PageNotFound} />
          </Switch>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
