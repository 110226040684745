import React, { useState ,useEffect} from "react";
import { useSelector } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import { savePortalReportData ,updateTaigaProject } from "../../store/actions/portal-report";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from '@mui/material/Select';
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};




const portalError = {
  projectTitle: "",
  portalUrl: "",
  projectName:"",
};

const PortalReport = (props) => {
  const portalInitialState = {
    projectTitle: props.questionnaireData.createdProject[0]?.projectTitle,
    portalUrl: props.questionnaireData.createdProject[0]?.portalUrl,
    taigaProjectId:null,
    taigaProjectName:null
  };

  const [portalReportData, setPortalReportData] = useState(portalInitialState);
  const [error, setError] = useState(portalError);
  const dispatch = useDispatch();
  const history = useHistory()
  const theme = useTheme();
  const tempTaigaProjectList = useSelector((state)=> state.portal.taigaProjectList.data)
  const [taigaProjectList,setTaigaProjectList] = useState(tempTaigaProjectList)
  const [projectName, setProjectName] = React.useState();

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  useEffect(()=>{
    setTaigaProjectList(tempTaigaProjectList)
  },[tempTaigaProjectList])


  
  
  const handleSubmit = () => {
    if (portalReportData.projectTitle) {
      if ( portalReportData.portalUrl) {
        if (projectName) {
          portalReportData.questionnaireId = props.questionnaireData._id;
          if(props.questionnaireData.createdProject[0]?._id){
            dispatch( updateTaigaProject(props.questionnaireData.createdProject[0]?._id ,portalReportData) );
            setTimeout(()=>{
            history.push("/project")
            },1000)
          } else{
             dispatch( savePortalReportData(portalReportData) );
            setTimeout(()=>{
              history.push("/project")
            },1000)
          }
           
          } else {
            setError({
              ...error,
              projectName: "Please selecte project",
            });
          }
       
      } else {
        setError({
          ...error,
          portalUrl: "Please enter Valid url",
        });
      }
    } else {
      setError({
        ...error,
        projectTitle: "Please enter project Title",
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setPortalReportData({
      ...portalReportData,
      [name]: value,
    });
    setError({
      ...error,
      [name]: null,
    });
  };

  const handleProjectSelect = (event) => {
    const {
      target: { value }
    } = event;
    setProjectName(value);
    setError({
      ...error,
     projectName:null
    });

  }

  const  handleMenuItemClick = (e,items) => {
    setPortalReportData({...portalReportData,taigaProjectId : items.id,taigaProjectName:items.name})
  }


  
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Create Project
          </Modal.Title>
        </Modal.Header>
         <Modal.Body style={{margin:"auto"}}>
            <Box
            sx={{
              width: 700,
              maxWidth: '100%',
              m: 1,
            }}
          >
            <TextField 
            fullWidth label="Project Title"
            id="fullWidth"
            name="projectTitle"
            onChange={handleChange}
            value={portalReportData.projectTitle}
            disabled={props.questionnaireData.createdProject[0]?._id ? true : false}
            />

          </Box>
          <p style={{marginLeft:"10px"}} className="text-red margin-bottom-5">{error.projectTitle}</p>
          <Box
            sx={{
              width: 700,
              maxWidth: '100%',
              m: 1,
            }}
            style={{marginTop:"25px"}}
          >
            <TextField
            fullWidth
            label="Portal Url"
            id="fullWidth"
            name="portalUrl"
            value={portalReportData.portalUrl}
            onChange={handleChange}
            disabled={props.questionnaireData.createdProject[0]?._id ? true : false}

            />
          </Box>
          <p style={{marginLeft:"10px"}} className="text-red margin-bottom-5">{error.portalUrl}</p>
          <FormControl sx={{ m: 1, width: 700, mt: 3 }}>
          <InputLabel id="demo-multiple-name-label">Project List</InputLabel>
        <Select
           labelId="demo-multiple-name-label"
           id="demo-multiple-name"
          displayEmpty
          value={projectName}
          onChange={handleProjectSelect}
          input={<OutlinedInput onChange={handleProjectSelect} label="Sprint Name" />}
          defaultValue ={ props.questionnaireData.createdProject[0]?.taigaProjectName}
          renderValue={(selected) => {
            return selected
          }}
          MenuProps={MenuProps}
          inputProps={{ "aria-label": "Without label" }}
        >
          {taigaProjectList && taigaProjectList.map((items) => (
            <MenuItem
              key={items.id}
              value={items.name}
              style={getStyles(items, taigaProjectList , theme)}
              onClick={(event) => handleMenuItemClick(event, items)}
              disabled={props.questionnaireData.createdProject[0]?._id ? true : false}

            >
              {items.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <p style={{marginLeft:"10px"}} className="text-red margin-bottom-5">{error.projectName}</p>
        </Modal.Body>
        <Modal.Footer style={{marginRight:"4%"}}>
          <Button onClick={props.onHide}>Close</Button>
          <Button  disabled={props.questionnaireData.createdProject[0]?._id ? true : false} onClick={handleSubmit}>Submit</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PortalReport;
