import React, { useEffect, useState } from "react";
import DashboardContainer from "./DashboardContainer";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as serviceRequestAction from "../store/actions/service-request";
import Loader from '../components/loader'
import {getAllServiceRequest} from '../store/actions/service-request'
import ReactPaginate from 'react-paginate';
import Switch from '@mui/material/Switch';

import "../styles/dashboard.css";


const Dashborad = () => {
  const dispatch = useDispatch();
  const [toggel, setToggle] = useState(true);
  const history = useHistory();
  const questionnaireLoading = useSelector((state)=>state.questionnaire.questionnaireLoading)
  const questionairList = useSelector((state) => {
    return state.serviceRequest && state.serviceRequest.questionairList;
  });
   const [postperpage,setPostperpage] = useState(10)
  const [currentpage,setCurrentPage] = useState(1)
    const isLoading = useSelector((state)=>state.serviceRequest.questionairListLoading)


  const handleLogout = () => {
    localStorage.clear();
    Cookies.remove("userLogin");
    Cookies.remove("isAdmin")
    Cookies.remove("userAccessToken")
    history.push("/login");
  };

  useEffect(() => {
      dispatch(serviceRequestAction.getAllServiceRequest());
      dispatch(getAllServiceRequest())
  }, []);

    const onPageChange = (item) => {
    setCurrentPage(item + 1)
  }

   const indexOfTheLastResult = currentpage * postperpage ;
  const indexOFFirstResult = indexOfTheLastResult  - postperpage ;
  const currentResult = questionairList.slice(indexOFFirstResult,indexOfTheLastResult)
  
  

  return (
    <>
     {questionnaireLoading &&  <Loader
        styles={{zIndex:99999 , position: "absolute" , top:"50vh" , width:"100%"}}
      />  }
    <body>
      <div className="d-flex" id="wrapper">
        {toggel && <div className="border-end bg-white" id="sidebar-wrapper">
          <div className="sidebar-heading border-bottom bg-light">
            Mmi funnel portal
          </div>
          <div className="list-group list-group-flush">
            <Link
              className=" list-group-item-action list-group-item-light p-3"
              to="/dashboard"
            >
             <span className="seleted-item">  Dashboard </span>
            </Link>
            {/* <Link
              className="list-group-item list-group-item-action list-group-item-light p-3"
              to=""
            >
              Profile
            </Link> */}
          </div>
        </div>}

        <div id="page-content-wrapper">
          <nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom">
            <div className="container-fluid nav-padding">
              {/* <button className="btn btn-primary" id="sidebarToggle">Toggle Menu</button> */}
              <div className="">
                 <Switch
                       value={toggel}
                       defaultChecked={false}
                       onChange={()=> setToggle(!toggel)}
                       color="info"
                    />
              </div>
              <div
                className="collapse navbar-collapse "
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ms-auto mt-2 mt-lg-0">
                  {/* <li className="nav-item active"><a className="nav-link" href="#!">Home</a></li> */}
                  <button
                    onClick={handleLogout}
                    className="btn btn-primary"
                    id="sidebarToggle"
                  >
                    Sign out
                  </button>
                </ul>
              </div>
            </div>
          </nav>
           <div className="div-col">
          <DashboardContainer questionairList={currentResult}  indexOFFirstResult={indexOFFirstResult}/>
           {!isLoading && questionairList.length > 10 && <div className="pagination-outer-div pagenation-margin "> 
             <div className="pagination-inner-div">
             <ReactPaginate
                      pageCount={Math.ceil(questionairList.length / postperpage)}
                       //onPageChange={({ selected }) => setFrom(selected * size)}
                      onPageChange={({ selected }) => onPageChange(selected)}
                      // forcePage={
                      //   currentpage !== undefined ? Math.ceil(currentpage / postperpage) : 1
                      // }
                      disabledClassName="disabled"
                      initialPage={0}
                      previousLabel="previous"
                      nextLabel="next"
                      breakLabel="..."
                      breakClassName="break-me"
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      subContainerClassName="pages pagination"
                      breakLinkClassName="page-link"
                      containerClassName="pagination"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      activeClassName="active"
                    />
                    </div>
                
          </div>}
          </div>
        </div>
      </div>
    </body>
    </>
  );
};
export default Dashborad;
