import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from '../components/loader'

const UserResponseDetailTable = ({ questionairList,indexOFFirstResult, ...props }) => {
  const isLoading = useSelector((state)=>state.serviceRequest.questionairListLoading)
  return (
    <>
   {isLoading ? <Loader  styles={{zIndex:99999 , position: "absolute" , top:"50vh" , width:"100%" ,left:"50%",transform:"translate(-50%, 0)"}}/> : <div>

   
    <table>
      <tr>
        <th>#</th>
        <th>Client</th>
        <th>Id</th>
        <th>Link</th>

        <th> Price change </th>
        <th> Payout </th>
        <th> Action </th>
      </tr>
      {questionairList &&
        questionairList.map((value, index) => {
          let serviceNeed;
          serviceNeed = value.questionnaire.servicesNeed.map((element) => {
            return element.key;
          });

          return (
            <tr key={value._id.questionnaireId}>
              <td>{index + 1 + indexOFFirstResult}</td>
              <td>
                <p className="p mb-none font-600">
  
                {value.questionnaire?.firstName ?   `${value.questionnaire?.firstName} ${value.questionnaire?.lastName}` : value.questionnaire?.name}
                </p>
              </td>
              {/* <td>{value.questionnaire._id}</td> */}
              <td>-</td>
              <td>-</td>

              <td>#</td>
              <td>#</td>
              <td>
                <Link
                  className="d-flex justify-center"
                  to={`serviceandcost/?request_id=${value.questionnaire._id}`}
                >
                  <img src="../assets/images/link.png" alt="" />
                </Link>
              </td>
            </tr>
          );
        })}
    </table>
    </div>}
    </>
    
  );
};

export default UserResponseDetailTable;
