import React,{useState ,useEffect} from 'react'
import InviteAgencyModal from './inviteagencymodal'
import { Button } from "react-bootstrap";
import { Card } from "react-bootstrap";
import AgencyUsersTable from './agencyusertable'
import AdminNav from '../component/AdminNav';
import {getUsersList} from '../../store/actions/inviteagency'
import { useDispatch ,useSelector } from 'react-redux';
import Cookies from "js-cookie";
import {useHistory} from 'react-router-dom'



const InviteAgency = () => {
    const [modalShow, setModalShow] = React.useState(false);
    const dispatch = useDispatch()
    const tempUser = useSelector((state) => state.users.allUsers)
    const [users,setUsers] = useState(tempUser)
    const [isAdmin, setIsAdmin] = useState( Cookies.get("isAdmin"));
    const [isLogin, setIsLogin] = useState(Cookies.get("userAccessToken") );
    const history = useHistory()


    useEffect(()=>{
      setUsers(tempUser)
    },[tempUser])


    useEffect(() => {
      dispatch(getUsersList())
    }, []);

  const onAgencyInvite = (item) => {
    setModalShow(true)
    // dispatch(getTaigaProjectName())
  }
    return <AdminNav>
           <div className='invite-container flex-col padding-1'>
               
               <div className='d-flex w-100 space-between'>
                   <div className='agency-heading flex-3'>
                      <h4>Agency List</h4>
                   </div>
                   <div className='pr-1 pb-2'>
                       <Button  onClick={onAgencyInvite}>Add Agency</Button>
                   </div>
               </div>
               <div className='d-flex w-100 flex-col font-Adob-Garamond-Regular'>
                   <AgencyUsersTable users={users}/>
               </div>

           </div>
           {modalShow &&   <InviteAgencyModal
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            />}
          </AdminNav>
}

export default InviteAgency