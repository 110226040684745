import { authHeader,taigaAuthHeader } from "./auth-header";
import ApiConfig from "./ApiConfig";
import { secureFetch } from "./securefetch";
import tolApi from "./tolApi";

const userAccessToken = localStorage.getItem("userAccessToken");

export const createPortal = {
  savePortalData,
  getAllCreateProjectList,
  // getKanban,
  kanbanReport,
  getAllProject,
  kanbanSprintReport,
  getTaigaProject,
};

function savePortalData(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return secureFetch(`${ApiConfig.baseURL}create-project/`, requestOptions)
    .then((user) => {
      return user;
    })
    .catch((err) => {
      return err;
    });
}

function getAllCreateProjectList() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),

  };
  return secureFetch(`${ApiConfig.baseURL}create-project/`, requestOptions)
    .then((user) => {
      return user;
    })
    .catch((err) => {
      return err;
    });
}


// function getKanban (id) {
//   const requestOptions = {
//     method: "GET",
//     headers: authHeader(),
//     body: JSON.stringify({projectId:id}),
//   };
//   return secureFetch(`${ApiConfig.baseURL}kanban/`, requestOptions)
//   .then((user) => {
//     return user;
//   })
//   .catch((err) => {
//     return err;
//   });
// }

function kanbanReport(id,params) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
   if(params !== undefined){
    const {startDate,endDate} = params
    return secureFetch(`${ApiConfig.baseURL}kanban/${id}/?startDate=${startDate}&endDate=${endDate}`, requestOptions)
    .then((user) => {
      return user;
    })
    .catch((err) => {
      return err;
    });

   }else {
    return secureFetch(`${ApiConfig.baseURL}kanban/${id}`, requestOptions)
    .then((user) => {
      return user;
    })
    .catch((err) => {
      return err;
    });
   }
 
}

function kanbanSprintReport(id,params) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
   if(params !== undefined){
    const {startDate,endDate} = params
    return secureFetch(`${ApiConfig.baseURL}kanban/${id}/sprint/?startDate=${startDate}&endDate=${endDate}`, requestOptions)
    .then((user) => {
      return user;
    })
    .catch((err) => {
      return err;
    });

   }else {
    return secureFetch(`${ApiConfig.baseURL}kanban/${id}/sprint`, requestOptions)
    .then((user) => {
      return user;
    })
    .catch((err) => {
      return err;
    });
   }
 
}

async function  getAllProject  ()  {
  const requestOptions = {
        method: "GET",
        headers: authHeader(),
      };
      const res =  secureFetch(`${ApiConfig.baseURL}create-project/`, requestOptions)
      return res
  //  const res = await tolApi.get(`create-project/`, {
  //   headers : {
  //     "Content-Type": "application/json",
  //     Authorization: "Bearer " + userAccessToken,
  //   }
  //  })
   
}

function getTaigaProject() {
  const requestOptions = {
    method: "GET",
    headers: taigaAuthHeader(),

  };
  return secureFetch(`https://api.taiga.io/api/v1/projects?member=500818`, requestOptions)
    .then((user) => {
      return user;
    })
    .catch((err) => {
      return err;
    });
}

