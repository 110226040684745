import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch,useSelector,shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";
import { getAllCreateProjectList, getKanban , } from "../../store/actions/portal-report";
import ProjectTable from "./projectTable";
import ReactPaginate from 'react-paginate';
import Switch from '@mui/material/Switch';
import Cookies from "js-cookie";

const Project = () => {
  const [toggle, setToggle] = useState(true);
  const projectDetails = useSelector((state)=> state.portal.allCreateProtal && state.portal.allCreateProtal)
  const taigaProject = useSelector((state)=> state.portal.taigaProjectList && state.portal.taigaProjectList)
  const [project, setProject] = useState(projectDetails)
  const [taigaList , setTaigaList] = useState(taigaProject)
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLogin, setIsLogin] = useState(Cookies.get("userAccessToken") );
  const [isTaigaLogin , setisTaigaLogin] = useState(Cookies.get("taigaUserAccessToken"))
  const [isAdmin, setIsAdmin] = useState( Cookies.get("isAdmin"));
   const [postperpage,setPostperpage] = useState(10)
  const [currentpage,setCurrentPage] = useState(1)
  const isLoading = useSelector((state)=>state.portal.portalLoading)
  const [modalShow, setModalShow] =useState(false);
  const [showTaiga, setshowTaigaProject] =useState(false);

 
  const handleLogout = () => {
    localStorage.clear();
    Cookies.remove("userLogin");
    Cookies.remove("isAdmin")
    Cookies.remove("userAccessToken")
    Cookies.remove("isAdmin",{path:"/admin-servicecost"})
    Cookies.remove("userAccessToken",{path:"/admin-servicecost"})
    history.push("/admin-login");
  };

  const getKanbanReport  =(id)  =>{
      dispatch(getKanban(id))
  }

  useEffect(() => {
    setisTaigaLogin(Cookies.get("taigaUserAccessToken"))
    dispatch(getAllCreateProjectList());
  }, []);

  useEffect(()=>{
    setisTaigaLogin(Cookies.get("taigaUserAccessToken"))
  })

  useEffect(()=>{
    setProject(projectDetails) 
    setTaigaList(taigaProject)
  },[projectDetails ,taigaProject ])

    const onPageChange = (item) => {
    setCurrentPage(item + 1)
  }
  const indexOfTheLastResult = currentpage * postperpage ;
  const indexOFFirstResult = indexOfTheLastResult  - postperpage ;
  const currentResult = project.length !== undefined && project.slice(indexOFFirstResult,indexOfTheLastResult)

  return (
    <body>
      <div className="d-flex" id="wrapper">
        {toggle && (
          <div className="border-end bg-white" id="sidebar-wrapper">
            <div className="sidebar-heading border-bottom bg-light">
              Mmi funnel portal
            </div>
            <div className="list-group list-group-flush">
              <Link
                className=" list-group-item-action list-group-item-light p-3"
                to="/admindashboard"
              >
                <span >  Dashboard{" "} </span>
              </Link>
               <Link
                className=" list-group-item-action list-group-item-light p-3"
                to="/project"
              >
               <span className="seleted-item">  Projects{" "} </span>
              </Link>
              <Link
              className=" list-group-item-action list-group-item-light p-3"
              to="/invite-agency"
            >
              Invite Agency
            </Link>
            </div>
          </div>
        )}

        <div id="page-content-wrapper">
          <nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom nav-padding-balance">
            <div className="container-fluid">
              <div className="">
                 <Switch
                       value={toggle}
                       defaultChecked={false}
                       onChange={()=> setToggle(!toggle)}
                       color="info"
                    />
              </div>
              <div
                className="collapse navbar-collapse "
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ms-auto mt-2 mt-lg-0">
                  <button
                    onClick={handleLogout}
                    className="btn btn-primary"
                    id="sidebarToggle"
                  >
                    Sign out
                  </button>
                </ul>
              </div>
            </div>
          </nav>
         
           <div className="div-col-nohight">
          <ProjectTable project={currentResult} getKanbanReport={getKanbanReport}  indexOFFirstResult={indexOFFirstResult}/>

            {!isLoading && project.length > 10 && <div style={{marginTop:"5px"}} className="pagination-outer-div"> 
            <div className="pagination-inner-div">
             <ReactPaginate
                      pageCount={Math.ceil(project.length / postperpage)}
                       //onPageChange={({ selected }) => setFrom(selected * size)}
                      onPageChange={({ selected }) => onPageChange(selected)}
                      // forcePage={
                      //   currentpage !== undefined ? Math.ceil(currentpage / postperpage) : 1
                      // }
                      disabledClassName="disabled"
                      initialPage={0}
                      previousLabel="previous"
                      nextLabel="next"
                      breakLabel="..."
                      breakClassName="break-me"
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      subContainerClassName="pages pagination"
                      breakLinkClassName="page-link"
                      containerClassName="pagination"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      activeClassName="active"
                    />
                    </div>
          </div>}
          </div>

         
        </div>
      </div>
    </body>
  );
};

export default Project;
