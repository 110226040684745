import {
  GET_ALL_SERVICE_REQUEST_START,
  GET_ALL_SERVICE_REQUEST_SUCCESS,
  GET_ALL_SERVICE_REQUEST_FAILED,
  GET_SERVICE_REQUEST_START,
  GET_SERVICE_REQUEST_SUCCESS,
  GET_SERVICE_REQUEST_FAILED,
  UPDATE_SERVICE_REQUEST_START,
  UPDATE_SERVICE_REQUEST_SUCCESS,
  UPDATE_SERVICE_REQUEST_FAIL,
  UPDATE_COST_APPROVED_START,
  UPDATE_COST_APPROVED_SUCCESS,
  UPDATE_COST_APPROVED_FAILED,
  GET_APPROVED_COST_START ,
  GET_APPROVED_COST_SUCCESS,
  GET_APPROVED_COST_FAILED,
  UPDATE_COST_APPROVE_START,
  UPDATE_COST_APPROVE_SUCCESS,
  UPDATE_COST_APPROVE_FAILED
} from "../../constents/actionType";
import { toast } from "react-toastify";

import { serviceRequest } from "../../service/service-request";
import {
  getQuestionnaireDetailWithCost,
  getQuestionnaireAdminList,
} from "../../store/actions/questionnaire";
import Cookies from "js-cookie";

// export const getAllServiceRequest = () => {
//   const failureMessage = "Something wrong happened";

//   return (dispatch) => {
//     dispatch(allServiceRequestStart());
//     serviceRequest
//       .getAllServiceRequest()
//       .then((rawData) => {
//         return rawData.json();
//       })
//       .then((response) => {
//         if (response.code === 401) {
//           localStorage.clear();
//         }
//         if (response) {
//           dispatch(getAllServiceRequestSuccess(response.data));
//         } else {
//           dispatch(getAllServiceRequestFailed(response.data));
//         }
//       });
//   };
// };

 const clearCookies = () => {
    localStorage.clear();
    Cookies.remove("userLogin");
    Cookies.remove("isAdmin")
    Cookies.remove("userAccessToken")
    Cookies.remove("isAdmin",{path:"/admin-servicecost"})
    Cookies.remove("userAccessToken",{path:"/admin-servicecost"})
}

export const getAllServiceRequest = () => {
  return async(dispatch)=>{
    dispatch(allServiceRequestStart());
  try{
    const res = await  serviceRequest.getAllServiceRequest()
    const response = await res.json()
    dispatch(getAllServiceRequestSuccess(response.data));
  }
  catch (err){
     if (err.status === 401) {
          clearCookies()
        }
    dispatch(getAllServiceRequestFailed(err.data));
  }
  
}
}




// export const updateServiceRequest = (data) => {
//   const failureMessage = "Something wrong happened";
//   return (dispatch) => {
//     dispatch(serviceRequestStart());
//     serviceRequest
//       .updateServiceRequest(data)
//       .then((rawData) => {
//         dispatch(saveServiceRequestData(rawData.status));
//         localStorage.setItem("serviceRequestStatus", rawData.status);
//         return rawData.json();
//       })
//       .then((response) => {

//         toast.success("Record  submit sucessfully");
//       })
//       .catch((err) => {
//         toast.error(err.message);
//       });
//   };
// };

export const selectedCostApproved = (costId, questionnaireId) => {
  const failureMessage = "Something wrong happened";
  return (dispatch) => {
    dispatch(approvedCostStart());
    serviceRequest
      .updateCostApproved(costId, questionnaireId)
      .then((rawData) => {
        return rawData.json();
      })
      .then((response) => {
        dispatch(approvedCostSuccess(response.data));
        dispatch(getQuestionnaireDetailWithCost(questionnaireId));
        dispatch(getQuestionnaireAdminList());
        toast.success("Status update successfully ");
      })
      .catch((err) => {
        dispatch(approvedCostFailed(err));
      });
  };
};

export const getApprovedCost = (id) => {
   
  const failureMessage = "Something wrong happened";

  return (dispatch) => {
    dispatch(getApprovedCostStart());
    serviceRequest
      .getApprovedCost(id)
      .then((rawData) => {
        return rawData.json();
      })
      .then((response) => {
        if (response) {
          dispatch(getApprovedCostSuccess(response.data));
        } 
      })
      .catch((err) => {
        dispatch(getAllServiceRequestFailed(failureMessage));
       
      });
  };
};


export const updateApprovedCost = (data ,id) => {
   console.log("id==",id)
   const failureMessage = "Something wrong happened";
   return (dispatch) => {
     dispatch(updateApprovedCostStart());
     serviceRequest.approvedCostUpdate(data)
     .then((rawData) => {
        return rawData.json();
      })
      .then((response) => {
        if (response) {
          dispatch(updateApprovedCostSuccess(response.data));
          dispatch(getApprovedCost(id))
          toast.success("cost approved successfully")
        } 
      })
      .catch((err) => {
        dispatch(updateApprovedCostFailed(failureMessage));
       
      });
      

   }
}





const allServiceRequestStart = () => {
  return {
    type: GET_ALL_SERVICE_REQUEST_START,
  };
};

const getAllServiceRequestSuccess = (data) => {
  return {
    type: GET_ALL_SERVICE_REQUEST_SUCCESS,
    payload: data,
  };
};

const getAllServiceRequestFailed = (data) => {
  return {
    type: GET_ALL_SERVICE_REQUEST_FAILED,
    payload: data,
  };
};

const approvedCostStart = () => {
  return {
    type: UPDATE_COST_APPROVED_START,
  };
};
const approvedCostSuccess = (data) => {
  return {
    type: UPDATE_COST_APPROVED_SUCCESS,
    payload: data,
  };
};

const approvedCostFailed = (data) => {
  return {
    type: UPDATE_COST_APPROVED_FAILED,
    payload: data,
  };
};

const getApprovedCostStart = (data) => {
  return {
    type : GET_APPROVED_COST_START
  }
}

const getApprovedCostSuccess = (data) =>{
  return {
    type :GET_APPROVED_COST_SUCCESS,
    payload:data
  }
}

const getApprovedCostFailed = (data) => {
  return {
    type : GET_APPROVED_COST_FAILED ,
    payload:data
  }
}


const updateApprovedCostStart = (data) => {
  return {
    type : UPDATE_COST_APPROVE_START
  }
}

const updateApprovedCostSuccess = (data) =>{
  return {
    type :UPDATE_COST_APPROVE_SUCCESS,
    payload:data
  }
}

const updateApprovedCostFailed = (data) => {
  return {
    type : UPDATE_COST_APPROVE_FAILED ,
    payload:data
  }
}

