export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT_START = "LOGOUT_START";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILED = "LOGOUT_FAILED";
export const QUESTIONNAIRE_START = "QUESTIONNAIRE_START";
export const GET_QUESTIONNAIRE_START = "GET_QUESTIONNAIRE_START";
export const GET_QUESTIONNAIRE_SUCCESS = "GET_QUESTIONNAIRE_SUCCESS";
export const GET_QUESTIONNAIRE_FAILED = "GET_QUESTIONNAIRE_FAILDED";
export const REQUEST_SERVICE_START = "REQUEST_SERVICE_START";
export const REQUEST_SERVICE_SUCESS = "REQUEST_SERVICE_SUCESS";
export const REQUEST_SERVICE_FAILED = "REQUEST_SERVICE_FAILED";
export const GET_ALL_SERVICE_REQUEST_START = "GET_ALL_SERVICE_REQUEST_START";
export const GET_ALL_SERVICE_REQUEST_SUCCESS =
  "GET_ALL_SERVICE_REQUEST_SUCCESS";
export const GET_ALL_SERVICE_REQUEST_FAILED = "GET_ALL_SERVICE_REQUEST_FAILED";
export const GET_SERVICE_REQUEST_START = "GET_SERVICE_REQUEST_START";
export const GET_SERVICE_REQUEST_SUCCESS = "GET_SERVICE_REQUEST_SUCCESS";
export const GET_SERVICE_REQUEST_FAILED = "GET_SERVICE_REQUEST_FAILED";
export const GET_QUESTIONNAIRE_DETAILS_WITHCOST_START =
  "GET_QUESTIONNAIRE_DETAILS_WITHCOST_STAR";
export const GET_QUESTIONNAIRE_DETAILS_WITHCOST_SUCCESS =
  "GET_QUESTIONNAIRE_DETAILS_WITHCOST_SUCCESS";
export const GET_QUESTIONNAIRE_DETAILS_WITHCOST_FAILED =
  "GET_QUESTIONNAIRE_DETAILS_WITHCOST_FAILED";
export const GET_QUESTIONNAIRE_ADMIN_LIST_START =
  "GET_QUESTIONNAIRE_ADMIN_LIST_START";
export const GET_QUESTIONNAIRE_ADMIN_LIST_SUCCESS =
  "GET_QUESTIONNAIRE_ADMIN_LIST_SUCCESS";
export const GET_QUESTIONNAIRE_ADMIN_LIST_FAILED = "GET_QUESTIONNAIRE_FAILDED";
export const SAVE_SERVICE_REQUEST_DATA = "SAVE_SERVICE_REQUEST_DATA";
export const UPDATE_SERVICE_REQUEST_START = "UPDATE_SERVICE_REQUEST_START";
export const UPDATE_SERVICE_REQUEST_SUCCESS = "UPDATE_SERVICE_REQUEST_SUCCESS";
export const UPDATE_SERVICE_REQUEST_FAILED = "UPDATE_SERVICE_REQUEST_FAILED";
export const UPDATE_COST_APPROVED_START = "UPDATE_COST_APPRPVED_START";
export const UPDATE_COST_APPROVED_SUCCESS = "UPDATE_COST_APPROVED_SUCCESS";
export const UPDATE_COST_APPROVED_FAILED = "UPDATE_COST_APPROVED_FAILED";
export const SAVE_CREATE_PROJECT_DATA_START = "SAVE_CREATE_PROJECT_DATA_START";
export const SAVE_CREATE_PROJECT_DATA_SUCCESS =
  "SAVE_CREATE_PROJECT_DATA_SUCCESS";
export const SAVE_CREATE_PROJECT_DATA_FAILED =
  "SAVE_CREATE_PROJECT_DATA_FAILED";
export const GET_ALL_PORTAL_REPORT_START = "GET_ALL_PORTAL_REPORT_START";
export const GET_ALL_PORTAL_REPORT_SUCCESS = "GET_ALL_PORTAL_REPORT_SUCCESS";
export const GET_ALL_PORTAL_REPORT_FAILED = "GET_ALL_PORTAL_REPORT_FAILED";
export const GET_KANBAN_START = "GET_KANBAN_START";
export const GET_KANBAN_SUCCESS = "GET_KANBAN_SUCCESS";
export const GET_KANBAN_FAILED = "GET_KANBAN_FAILEDS";
export const GET_APPROVED_COST_START = "const GET_APPROVED_COST_START" ;
export const GET_APPROVED_COST_SUCCESS = "const GET_APPROVED_COST_SUCCESS" ;
export const GET_APPROVED_COST_FAILED ="const GET_APPROVED_COST_FAILED" ;
export const UPDATE_COST_APPROVE_START = "UPDATE_COST_APPRPVE_START";
export const UPDATE_COST_APPROVE_SUCCESS = "UPDATE_COST_APPROVE_SUCCESS";
export const UPDATE_COST_APPROVE_FAILED = "UPDATE_COST_APPROVE_FAILED";
export const GET_KANBAN_SPRINT_START = "GET_KANBAN_SPRINT_START";
export const GET_KANBAN_SPRINT_SUCCESS = "GET_KANBAN_SPRINT_SUCCESS";
export const GET_KANBAN_SPRINT_FAILED = "GET_KANBAN_SPRINT_FAILED";
export const GET_PROJECT_FROM_TAIGA_START = "GET_PROJECT_FROM_TAIGA_START";
export const GET_PROJECT_FROM_TAIGA_SUCCESS = "GET_PROJECT_FROM_TAIGA_SUCCESS";
export const GET_PROJECT_FROM_TAIGA_FAILED = "GET_PROJECT_FROM_TAIGA_FAILED";
export const UPDATE_TAIGA_PROJECT_START = "UPDATE_TAIGA_PROJECT_START";
export const UPDATE_TAIGA_PROJECT_SUCCESS = "UPDATE_TAIGA_PROJECT_SUCCESS";
export const UPDATE_TAIGA_PROJECT_FAILED = "UPDATE_TAIGA_PROJECT_FAILED";
export const CREATE_AGENCY_USER_START = "CREATE_AGENCY_USER_START";
export const CREATE_AGENCY_USER_SUCCESS = "CREATE_AGENCY_USER_START";
export const CREATE_AGENCY_USER_FAILED = "CREATE_AGENCY_USER_FAILED";
export const GET_ALL_USERS_START = "GET_ALL_USERS_START";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_FAILED = "GET_ALL_USERS_FAILED";
export const SET_NEW_PASSWORD_START = 'SET_NEW_PASSWORD_START';
export const SET_NEW_PASSWORD_SUCCESS = 'SET_NEW_PASSWORD_SUCCESS';
export const SET_NEW_PASSWORD_FAILED = 'SET_NEW_PASSWORD_FAILED';
export const UPDATE_USER_START = "UPDATE_USER_START";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILED = "UPDATE_USER_FAILED";
export const FORGOT_PASSWORD_START = 'FORGOT_PASSWORD_START';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED';
export const DELETE_USER_START = "DELETE_USER_START";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILED = "DELETE_USER_FAILED";
export const UPDATE_QUESTIONNAIRE_START = 'UPDATE_QUESTIONNAIRE_START'
export const UPDATE_QUESTIONNAIRE_SUCCESS = 'UPDATE_QUESTIONNAIRE_SUCCESS'
export const UPDATE_QUESTIONNAIRE_FAILED = 'UPDATE_QUESTIONNAIRE_FAILED'
export const GET_AGREEMENT_DOCFILE_NAME ="GET_AGREEMENT_DOCFILE_NAME"
export const GET_TAIGA_CREATED_SPRINT_REPORT_START =  "GET_TAIGA_CREATED_SPRINT_REPORT_START"
export const GET_TAIGA_CREATED_SPRINT_REPORT_SUCCESS =  "GET_TAIGA_CREATED_SPRINT_REPORT_SUCCESS"
export const GET_TAIGA_CREATED_SPRINT_REPORT_FAILED =  "GET_TAIGA_CREATED_SPRINT_REPORT_FAILED"








