import { authHeader } from "./auth-header";
import ApiConfig from "./ApiConfig";
import { secureFetch } from "./securefetch";

// import { getQuestionnaireDetailWithCost } from "../store/actions/questionnaire";

export const questionnaire = {
  sendQuestionnaireForm,
  getQuestionnaire,
  getServiceRequest,
  getQuestionnaireDetailWithCost,
  getQuestionnaireAdminList,
  updateQuestionnire,
};

function sendQuestionnaireForm(data) {
 

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  return (
    secureFetch(`${ApiConfig.baseURL}/questionnaire/`, requestOptions)
      // return secureFetch(
      //   "http://34.216.220.172/api/v1/questionnaire/",
      //   requestOptions
      // )
      .then((user) => {
        return user;
      })
      .catch((err) => {
        return err;
      })
  );
}

function getQuestionnaire(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return (
    secureFetch(`${ApiConfig.baseURL}questionnaire/${id}`, requestOptions)
      // return secureFetch(
      //   `http://34.216.220.172/api/v1/questionnaire/${id}`,
      //   requestOptions
      // )
      .then((user) => {
        return user;
      })
      .catch((err) => {
        return err;
      })
  );
}

function getServiceRequest(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return (
    secureFetch(`${ApiConfig.baseURL}service-request/`, requestOptions)
      // return secureFetch(
      //   "http://34.216.220.172/api/v1/service-request/",
      //   requestOptions
      // )

      .then((user) => {
        return user;
      })
      .catch((err) => {
        return err;
      })
  );
}

function getQuestionnaireDetailWithCost(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return secureFetch(
    `${ApiConfig.baseURL}questionnaire/${id}/costdetails`,
    // `http://34.216.220.172/api/v1/questionnaire/${id}/costdetails`,
    requestOptions
  )
    .then((user) => {
      return user;
    })
    .catch((err) => {
      return err;
    });
}

// function getQuestionnaireAdminList() {
//   const requestOptions = {
//     method: "GET",
//     headers: authHeader(),
    
//   };

//   return (
//     secureFetch(`${ApiConfig.baseURL}questionnaire/admin-list`, requestOptions)
//       // return secureFetch(
//       //   "http://34.216.220.172/api/v1/questionnaire/admin-list",
//       //   requestOptions
//       // )
//       .then((user) => {
//         return user;
//       })
//       .catch((err) => {
//         return err;
//       })
//   );
// }


function getQuestionnaireAdminList() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  const res =   secureFetch(`${ApiConfig.baseURL}questionnaire/admin-list`, requestOptions)
  return res
}



function updateQuestionnire(id,data) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return (
    secureFetch(`${ApiConfig.baseURL}/questionnaire/${id}`, requestOptions)
      .then((user) => {
        return user;
      })
      .catch((err) => {
        return err;
      })
  );
}

