import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";
import { loginServices } from "../../service/login-services";
import Cookies from "js-cookie";

import {
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
} from "../../constents/actionType";

const twelveHour = new Date(new Date().getTime() + 11 * 60 * 60 * 1000 );
export const tryLogin = (username, password, adminLogin) => {
  const failureMessage = "Something wrong happened";
  return (dispatch) => {
    dispatch(authStart());
    loginServices
      .login(username, password)
      .then((rawData) => {
        return rawData.json();
      })
      .then((response) => {
        if (response) {
          if (response.tokens) {
            const userData = jwtDecode(response.tokens.access.token);
            localStorage.setItem(
              "userAccessToken",
              response.tokens.access.token
            );
            localStorage.setItem("isAdmin", response.user.isAdmin);
            localStorage.setItem(
              "user_id",
              JSON.stringify(response.user && response.user.id)
            );
             if(response && !response.user.isAdmin){
               Cookies.set("userAccessToken", response.tokens.access.token ,{expires:twelveHour});
               Cookies.set("isAdmin", response.user.isAdmin ,{expires:twelveHour});
            }
           
            dispatch(
              authSuccess({
                payload: userData,
                userData: response.user,
              })
            );
            if(response && response.user.isAdmin){
              toast.error("Your are not allowed to login");
            }
          } else {
            toast.error(response.message);
            dispatch(authFailed(response.message));
          }
        }
      });
  };
};

export const tryAdminLogin = (username, password) => {
  
  return (dispatch) => {
    dispatch(authStart());
    loginServices
      .login(username, password)
      .then((rawData) => {
        return rawData.json();
      })
      .then((response) => {
        if (response) {
          if (response.tokens) {
            const userData = jwtDecode(response.tokens.access.token);
            localStorage.setItem(
              "userAccessToken",
              response.tokens.access.token
            );
            localStorage.setItem("isAdmin", response.user.isAdmin);
            // localStorage.setItem("user_id", JSON.stringify(response.user));
          
             if(response && response.user.isAdmin){
             Cookies.set("userAccessToken", response.tokens.access.token ,{expires:twelveHour});
             Cookies.set("isAdmin", response.user.isAdmin ,{expires:twelveHour});
            }
            dispatch(
              authSuccess({
                payload: userData,
                userData: response.user,
              })
            );
            if(response && !response.user.isAdmin){
              toast.error("Your are not allowed to login");
            }
          } else {
            toast.error(response.message);
            dispatch(authFailed(response.message));
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
const authStart = () => {
  return {
    type: LOGIN_START,
  };
};

const authSuccess = (data) => {
  return {
    type: LOGIN_SUCCESS,
    payload: data,
  };
};

const authFailed = (data) => {
  return {
    type: LOGIN_FAILED,
    payload: data,
  };
};
