import React, { useState, useEffect } from "react";
import Multiselect from "multiselect-react-dropdown";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getQuestionnaire,
  ServiceRequest,
  getQuestionnaireDetailWithCost,
} from "../store/actions/questionnaire";
import { Card, Form, Button, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import ReactTooltip from "react-tooltip";
import FinishUp from "./FinishUp";
import DisplayCost from "./displaycost";
import Loader from '../components/loader'
import _ from 'lodash'


const ServiceAndCost = (props) => {
 
  const OrgName = useSelector((state) => state.questionnaire?.questionnaireData?.name);
  const orgEmail = useSelector((state) => state.questionnaire?.questionnaireData?.email);
  const fName = useSelector((state) => state.questionnaire?.questionnaireData?.firstName);
  const lName = useSelector((state) => state.questionnaire?.questionnaireData?.lastName);
  const [name, setName] = useState(`${fName} ${lName}`);
  const [email,setEmail] = useState(orgEmail)

  let tdIndex;
  const [isAdmin, setIsAdmin] = useState(
    JSON.parse(localStorage.getItem("isAdmin"))
    );
    const dispatch = useDispatch();
    const questionnaireLoading = useSelector((state)=>state.questionnaire.questionnaireLoading)
   
    
    const question = useSelector((state) => {
      return (
        state.questionnaire.questionnaireDetailCost &&
        state.questionnaire.questionnaireDetailCost &&
        state.questionnaire.questionnaireDetailCost
      );
    })
    
    const [questionEmptyState,setquestionEmptyState] = useState(question)
  const history = useHistory();
  const [isLogin, setIsLogin] = useState(
    Cookies.get("userAccessToken")
  );
  const [value, setValue] = useState()
  const [toggel, setToggel] = useState(true);
  const [servicesNeed, setServicesNeed] = useState("");
  const [marketServices, setMarketServices] = useState("");
  const [questionnaire_id, setQuestionnaire_id] = useState(
    localStorage.getItem("questionnaireId")
  );
  const [error, setError] = useState("");
  const authId = useSelector((state) => state.auth.loginId);
  const [userId, setUserId] = useState(authId);

  const user_id = JSON.parse(localStorage.getItem("user_id"));
  const serviceRequestStatus = useSelector((state) => {
    return (
      state.questionnaire.questionnaireDetailCost &&
      state.questionnaire.questionnaireDetailCost &&
      state.questionnaire.questionnaireDetailCost.costs
    );
  });


  const [qestionnaireWithCost,setQestionnaireWithCost] = useState(serviceRequestStatus)
 

  const questionnaireId = useSelector((state) => {
    return state.questionnaire && state.questionnaire.questionnaireData.id;
  });

  const [requstServiceId, setRequestServiceId] = useState();

  const data = useSelector((state) => {
    return state.questionnaire && state.questionnaire.questionnaireData.name;
  });

  const serviceNeed = useSelector((state) => {
    return (
      state.questionnaire && state.questionnaire.questionnaireData.servicesNeed
    );
  });

  const marketingServices = useSelector((state) => {
    return (
      state.questionnaire &&
      state.questionnaire.questionnaireData.marketingServices
    );
  });

  const advertisingServices = useSelector((state) => {
    return (
      state.questionnaire &&
      state.questionnaire.questionnaireData.advertisingServices
    );
  });

  const discription = useSelector((state) => state.questionnaire?.questionnaireData?.lookingFor);
  const development = useSelector((state) => {
    return (
      state.questionnaire &&
      state.questionnaire.questionnaireData &&
      state.questionnaire.questionnaireData.developmentServices &&
      state.questionnaire.questionnaireData.developmentServices.map(
        (value) => ({
          key: value,
        })
      )
    );
  });

  const design = useSelector((state) => {
    return (
      state.questionnaire &&
      state.questionnaire.questionnaireData &&
      state.questionnaire.questionnaireData.designServices &&
      state.questionnaire.questionnaireData.designServices.map((value) => ({
        key: value,
      }))
    );
  });

  const incubation = useSelector((state) => {
    return (
      state.questionnaire &&
      state.questionnaire.questionnaireData &&
      state.questionnaire.questionnaireData.incubationServices &&
      state.questionnaire.questionnaireData.incubationServices.map((value) => ({
        key: value,
      }))
    );
  });

  const consulting = useSelector((state) => state.questionnaire?.questionnaireData?.consultingServices?.map((value) => ({key: value,})));
 
  

  const costdetails = useSelector((state) => {
    return (
      state.questionnaire.questionnaireDetailCost &&
      state.questionnaire.questionnaireDetailCost.costList
    );
  });

  const questionnaireDetails = useSelector((state) => {
    return (
      state.questionnaire &&
      state.questionnaire.questionnaireData &&
      state.questionnaire.questionnaireData
    );
  });

  tdIndex = (costdetails && costdetails.length + 1) || 1;

  const findParams = () => {
    const urlParams = new URLSearchParams(props.location.search);
    const request_id = urlParams.get("request_id");
    return request_id;
  };

  const handleLogout = () => {
    localStorage.clear();
    Cookies.remove("userLogin");
    Cookies.remove("isAdmin")
    Cookies.remove("userAccessToken")
    history.push("/login");
    handePageRefresh();
  };

  const onDropDownValueSelect = (data) => {
    const {
      advertisingCost,
      developmentCost,
      incubationCost,
      marketingCost,
      designCost,
    } = data;

    const request_id = findParams();
    // data.organization = "hasnode";
    data.questionnaireId = request_id;
    data.userId = userId || user_id;

    

    if (data) {
      dispatch(ServiceRequest(data));
      history.push("/dashboard");
    } else {
      setError("Please fill all fields ");
    }
  };

  const handePageRefresh = () => {
    setValue({});
  };

   
  useEffect(() => {
    setIsAdmin(JSON.parse(localStorage.getItem("isAdmin")));
    setIsLogin(Cookies.get("userAccessToken"))
    const request_id = findParams();
    localStorage.setItem("questionnaireId", request_id);
    setRequestServiceId(request_id && request_id);
    setQuestionnaire_id(localStorage.getItem("questionnaireId"));
    if (request_id && isLogin !== undefined) {
      localStorage.setItem("questionnaireId", request_id);
      // dispatch(getQuestionnaire(request_id));
       dispatch(getQuestionnaireDetailWithCost(request_id));
      setQuestionnaire_id(localStorage.getItem("questionnaireId"));
    }
     
    if (isAdmin === true ||  isLogin === undefined ) {
      history.push({
        pathname: "/login",
        state: {
          request_id,
        },
      });
    }
  },[]);

  useEffect(() => {
    setUserId(authId);
    setQestionnaireWithCost(serviceRequestStatus)
  }, [authId,serviceRequestStatus]);

  useEffect(() => {
    
    OrgName ? setName(OrgName) :  setName(`${fName} ${lName}`);
  }, [OrgName,fName,lName]);

  useEffect(()=>{
     setquestionEmptyState(question)
  },[question])

  useEffect(()=>{
     setEmail(orgEmail)
  },[orgEmail])

  return (
    <>
     {questionnaireLoading &&  <Loader
        styles={{zIndex:99999 , position: "absolute" , top:"50vh" , width:"100%"}}
      />  }
      <body>
        <div className="d-flex" id="wrapper">
          {toggel && (
            <div className="border-end bg-white" id="sidebar-wrapper">
              <div className="sidebar-heading border-bottom bg-light">
                Mmi funnel portal
              </div>
              <div className="list-group list-group-flush">
                <Link
                  className="list-group-item list-group-item-action list-group-item-light p-3"
                  to={isAdmin ? "/admindashboard" : "/dashboard"}
                >
                  Dashboard{" "}
                </Link>
              </div>
            </div>
          )}

          <div id="page-content-wrapper">
            <nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom nav-padding-balance">
              <div className="container-fluid">
                <div className="">
                  <label class="switch">
                    <input
                      type="checkbox"
                      onClick={() => {
                        setToggel(!toggel);
                      }}
                    />{" "}
                    <div></div>
                  </label>
                </div>

                <div
                  className="collapse navbar-collapse "
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav ms-auto mt-2 mt-lg-0">
                    <button
                      onClick={handleLogout}
                      className="btn btn-primary"
                      id="sidebarToggle"
                    >
                      Sign out
                    </button>
                  </ul>
                </div>
              </div>
            </nav>

            <div className="div-center">
              {!_.isEmpty(questionEmptyState) ? <div className={`w-100 ServiceQuestionnaire my-20 div-center `}>
                <Card className="w-90" >
                  <Card.Header className="mainHeading" >
                    Request for Service{""}
                    <button
                      className="all-unset"
                      data-tip
                      data-for="registerTip"
                    >
                      {" "}
                      <img
                        src="../assets/images/info.png"
                        alt=""
                        className="info-icon"
                      />
                    </button>
                    <ReactTooltip
                      className="tooltip"
                      id="registerTip"
                      place="right"
                      effect="solid"
                    >
                      Here is a request for service from an incoming Marketing
                      Machine client.
                      <br />
                      <br />
                      If you or your agency is interested in providing service
                      please look at the request points of service below.
                      <br />
                      <br />
                      Then fill in your proposed cost for each point you will
                      cover. We will then submit the proposal back to the client
                      who if they move ahead on the points you are associated
                      with will then be delegated to you or your team.
                      <br />
                      <br />
                    </ReactTooltip>
                  </Card.Header>
                   <Card.Body>
                    <Form>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="questionnaire-label" >Name / Organization Name</Form.Label>
                       
                        <Form.Control value={name} readOnly />
                      </Form.Group>

                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="questionnaire-label" >Email</Form.Label>
                       
                        <Form.Control value={email} readOnly />
                      </Form.Group>

                      <Form.Group
                        className=" mt-15"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="questionnaire-label" >Which services do you need?</Form.Label>
                      </Form.Group>
                      <Multiselect
                        style={{fontFamily:'Montserrat'}}
                        displayValue="key"
                        groupBy="cat"
                        placeholder={""}
                        disable={true}
                        selectedValues={
                          serviceNeed &&
                          serviceNeed.map((element) => ({
                            key: element,
                          }))
                        }
                      />
                      {development && (
                        <div>
                          <Form.Group
                            className=" mt-15"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label className="questionnaire-label" >
                              What development services do you need?
                            </Form.Label>
                          </Form.Group>
                          <Multiselect
                            displayValue="key"
                            placeholder={""}
                            disable
                            selectedValues={development}
                          />
                        </div>
                      )}

                      {marketingServices && (
                        <div>
                          {" "}
                          <Form.Group
                            className=" mt-15"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label className="questionnaire-label" >
                              What marketing services do you need?{" "}
                            </Form.Label>
                          </Form.Group>
                          <Multiselect
                            displayValue="key"
                            placeholder={""}
                            selectedValues={
                              marketingServices &&
                              marketingServices.map((element) => ({
                                key: element,
                              }))
                            }
                            disable={true}
                          />{" "}
                        </div>
                      )}
                      {/* {advertisingServices && (
                        <div>
                          <Form.Group
                            className=" mt-15"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label className="questionnaire-label" >
                              What advertising services do you need?
                            </Form.Label>
                          </Form.Group>
                          <Multiselect
                            displayValue="key"
                            placeholder={""}
                            selectedValues={
                              advertisingServices &&
                              advertisingServices.map((element) => ({
                                key: element,
                              }))
                            }
                            disable={true}
                          />
                        </div>
                      )} */}
                      {/* {design && (
                        <div>
                          <Form.Group
                            className=" mt-15"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label className="questionnaire-label" >
                              What design services do you need?
                            </Form.Label>
                          </Form.Group>
                          <Multiselect
                            displayValue="key"
                            placeholder={""}
                            selectedValues={design}
                            disable={true}
                          />
                        </div>
                      )} */}
                      {/* <div>
                        {incubation && (
                          <div>
                            <Form.Group
                              className=" mt-15"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label className="questionnaire-label" >
                                What incubation services do you need?
                              </Form.Label>
                            </Form.Group>
                            <Multiselect
                              displayValue="key"
                              placeholder={""}
                              disable
                              selectedValues={incubation}
                            />
                          </div>
                        )}

                      </div> */}
                      <div>
                        {consulting && (
                          <div>
                            <Form.Group
                              className=" mt-15"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label className="questionnaire-label" >
                                What Consulting services do you need?
                              </Form.Label>
                            </Form.Group>
                            <Multiselect
                              displayValue="key"
                              placeholder={""}
                              disable
                              selectedValues={consulting}
                            />
                          </div>
                        )}
                        
                      </div>
                      <Form.Group
                        className=" mt-15"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="questionnaire-label" >
                          To finish up, please provide a brief yet detailed
                          description of the work needed{" "}
                        </Form.Label>
                      </Form.Group>
                      <Form.Control
                        value={discription && discription}
                        as="textarea"
                        rows={3}
                        style={{ height: "150px" }}
                        readOnly
                      />
                    </Form>

                    <DisplayCost
                      questionnaireDetails={questionnaireDetails}
                      onDropDownValueSelect={onDropDownValueSelect}
                      serviceRequestStatus={qestionnaireWithCost}
                      getQuestionnaire={getQuestionnaire}
                      getQuestionnaireDetailWithCost={
                        getQuestionnaireDetailWithCost
                      }
                      findParams={findParams}
                      costError={error}
                      questionnaireLoading={questionnaireLoading}
                    />
                    {/* 
                    <Button className="mt-15" onClick={onDropDownValueSelect}>
                      Submit
                    </Button> */}
                  </Card.Body>
                </Card>
              </div>: <div style={{marginTop:"2rem"}}><p>No record found!</p></div>}
            </div>
          </div>
        </div>
      </body>
    </>
  );
};
export default ServiceAndCost;
