import {
  GET_QUESTIONNAIRE_START,
  GET_QUESTIONNAIRE_SUCCESS,
  GET_QUESTIONNAIRE_FAILED,
  GET_ALL_SERVICE_REQUEST_START,
  GET_ALL_SERVICE_REQUEST_SUCCESS,
  GET_ALL_SERVICE_REQUEST_FAILED,
  GET_APPROVED_COST_START,
  GET_APPROVED_COST_SUCCESS,
  GET_KANBAN_FAILED,
  GET_ALL_PORTAL_REPORT_START,
} from "../../constents/actionType";

const initialState = {
  questionairList: [],
  questionairListLoading: false,
  error: null,
  approvedCost : []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_SERVICE_REQUEST_START: {
      return {
        ...state,
        questionairListLoading: true,
      };
    }
    case GET_ALL_SERVICE_REQUEST_SUCCESS: {
      return {
        ...state,
        questionairListLoading: false,
        questionairList: action.payload,
      };
    }
    case GET_ALL_SERVICE_REQUEST_FAILED: {
      return {
        ...state,
        questionairListLoading: false,
        error: action.payload,
      };
    }
     case GET_APPROVED_COST_START: {
      return {
        ...state,
        questionairListLoading: true,
        error: action.payload,
      };
      
    }
    case GET_APPROVED_COST_SUCCESS: {
    
      return {
        ...state,
        questionairListLoading: false,
        approvedCost : action.payload
      };
    }
    case GET_APPROVED_COST_SUCCESS: {
      return {
        ...state,
        questionairListLoading: false,
        error : action.payload.data
      };
    }
    default:
      return state;
  }
};
