import React from "react" 
import "../styles/loader.css"


const Spinner = (props) => (
    <div className={props.button ? `lds-spinner btn-spinner ${props.topPosition}` : "lds-spinner"}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
  );

const Loader = (props) => {
    return <>
    <div style={props.styles} className={`d-flex justify-center`} >

         <Spinner button={props.button} topPosition={props.topPosition} />
    </div>
       </>
}

export default Loader 