import { authHeader } from "./auth-header";
import ApiConfig from "./ApiConfig";
import { secureFetch } from "./securefetch";

export const loginServices = {
  login,
  crateUser,
};

function login(data) {
  data.isAdmin && delete data.isAdmin;
  data.name && delete data.name;
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return (
    secureFetch(`${ApiConfig.baseURL}auth/login`, requestOptions)
      // return secureFetch("http://34.216.220.172/api/v1/auth/login", requestOptions)
      .then((user) => {
        return user;
      })
      .catch((err) => {
        return err;
      })
  );
}

function crateUser(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return (
    secureFetch(`${ApiConfig.baseURL}/users`, requestOptions)
      //return secureFetch("http://34.216.220.172/api/v1/users", requestOptions)
      .then((user) => {
        return user;
      })
      .catch((err) => {
        return err;
      })
  );
}
