export const doYouKnowValues = [
  {
    key: "I know what I need",
    value: "I know what I need",
  },
  {
    key: "I need helping finding the best strategy",
    value: "I need helping finding the best strategy",
  },
];

export const options = [
  {
    cat: "Development",
    key: "Custom software Development",
  },
  {
    cat: "Development",
    key: "Custom website Development",
  },
  {
    cat: "Development",
    key: "Mobile app Development",
  },
  {
    cat: "Development",
    key: "Ecommerce platform Development",
  },
  {
    cat: "Development",
    key: " Integrations",
  },
  {
    cat: "Development",
    key: "API Development",
  },
  {
    cat: "Development",
    key: "Hosting, Maintenance, Server ",
  },
  {
    cat: "Development",
    key: "Devops",
  },
  {
    cat: "Development",
    key: "Artificial Intelligence / Machine Learning",
  },
  {
    cat: "Development",
    key: "Other",
  },
  {
    cat: "Marketing",
    key: " Search Engine Optimization ",
  },
  {
    cat: "Marketing",
    key: "Social Media Marketing",
  },
  {
    cat: "Marketing",
    key: " Content Creation",
  },
  {
    cat: "Marketing",
    key: " Lead Generation",
  },
  {
    cat: "Marketing",
    key: " Email Marketing",
  },
  {
    cat: "Marketing",
    key: " Other",
  },
  {
    cat: "Advertising",
    key: "Google Adwords",
  },
  {
    cat: "Advertising",
    key: "Facebook Ads",
  },
  {
    cat: "Advertising",
    key: " Instagram Advertising",
  },
  {
    cat: "Advertising",
    key: " Native Advertising ",
  },
  {
    cat: "Advertising",
    key: " Print / Non-Digital  ",
  },
  {
    cat: "Advertising",
    key: " Animation Design",
  },
  {
    cat: "Advertising",
    key: " Other",
  },
  {
    cat: "Design",
    key: " Full Service Design",
  },
  {
    cat: "Design",
    key: " Product Design",
  },
  {
    cat: "Design",
    key: " Branding Design",
  },
  {
    cat: "Design",
    key: " Website Design",
  },
  {
    cat: "Design",
    key: " Print Design",
  },
  {
    cat: "Design",
    key: " Animation Design",
  },
  {
    cat: "Design",
    key: " Other",
  },
  {
    cat: "Incubation",
    key: " Fundraising",
  },
  {
    cat: "Incubation",
    key: " Crowdfunding",
  },
  {
    cat: "Incubation",
    key: " Advising / Consulting",
  },
  {
    cat: "Incubation",
    key: " Product Conceptualization",
  },
  {
    cat: "Incubation",
    key: " Market Research",
  },
  {
    cat: "Incubation",
    key: " Advising / Consulting",
  },
  {
    cat: "Incubation",
    key: " Outsourced CMO / CTO / COO",
  },
  {
    cat: "Incubation",
    key: " Managed Resource",
  },

  {
    cat: "Incubation",
    key: " Other",
  },
];

export const marketingOptions = [
  {
    key: "Social Media Marketing ",
  },
  {
    key: "Search Engine Optimization",
  },
  {
    key: "Content Creation",
  },
  {
    key: "Lead Generation",
  },
  {
    key: "Email Marketing",
  },
  {
    key: "Other",
  },
];

export const addServices = [
  {
    key: "Google Adwords",
  },
  {
    key: "Facebook Ads   ",
  },
  {
    key: "Instagram Advertising",
  },
  {
    key: "Native Advertising",
  },
  {
    key: "Print / Non-Digital",
  },
  {
    key: "Other",
  },
];

export const needService = [
  {
    key: "Development",
  },
  {
    key: "Marketing",
  },
  {
    key: "Consulting",
  },
  // {
  //   key: "Advertising",
  // },
  // {
  //   key: "Design",
  // },
  // {
  //   key: "Incubation",
  // },
];
export const development = [
  {
    key: "Custom Software Development",
  },
  {
    key: "Custom Website Development",
  },
  {
    key: "Mobile App Development",
  },
  {
    key: "Ecommerce Platform Development",
  },
  {
    key: " Integrations",
  },
  {
    key: " API Development",
  },
  {
    key: " Hosting, Maintenance, Server ",
  },
  {
    key: " Devops",
  },
  {
    key: "Artificial Intelligence / Machine Learning",
  },
  {
    key: "Other",
  },
];

export const design = [
  {
    key: " Product Design",
  },
  {
    key: " Branding Design",
  },
  {
    key: " Website Design",
  },
  {
    key: " Print Design",
  },
  {
    key: " Animation Design",
  },
  {
    key: " Other",
  },
];

export const incubation = [
  {
    key: " Fundraising",
  },
  {
    key: " Crowdfunding",
  },
  {
    key: " Advising / Consulting",
  },
  {
    key: " Product Conceptualization",
  },
  {
    key: " Market Research",
  },
  {
    key: " Advising / Consulting",
  },
  {
    key: " Outsourced CMO / CTO / COO",
  },
  {
    key: " Managed Resource",
  },

  {
    key: " Other",
  },
];

export const initialValues = {
  company: "",
  position: "",
  link: "",
  date: "",
  note: "",
};

export const statusOptionValues = [
  { key: "approved" },
  { key: "project" },
  { key: "pending" },
];

export const developmentOptions1 = [
  {
    cat: "Development",
    key: "Software Development",
    isActive: false
  },
  {
    cat: "Development",
    key: "Website Development",
    isActive: false
  },
  {
    cat: "Development",
    key: "App Development",
    isActive: false
  },
  {
    cat: "Development",
    key: "API Development",
    isActive: false
  },
]

export const developmentOptions2 = [ 
  {
    cat: "Development",
    key: "Artificial Intelligence",
    isActive: false
  },
  {
    cat: "Development",
    key: "Blockchain/ Web 3",
    isActive: false
  },
  {
    cat: "Development",
    key: "Metaverse",
    isActive: false
  },
  {
    cat: "Development",
    key: "Integrations",
    isActive: false
  },
]

export const marketingOptions1 = [
  {
    cat: "Marketing",
    key: " Search Engine Optimization ",
    isActive: false
  },
  {
    cat: "Marketing",
    key: "Promotion & Syndication",
    isActive: false
  },
  {
    cat: "Marketing",
    key: "Social Media Marketing",
    isActive: false
  },
  {
    cat: "Marketing",
    key: "Facebook Adverting",
    isActive: false
  },
  {
    cat: "Marketing",
    key: "Copywriting",
    isActive: false
  },
]

export const marketingOptions2 = [
  {
    cat: "Marketing",
    key: "Instragram Advertising",
    isActive: false
  },
  {
    cat: "Marketing",
    key: "Content Creation",
    isActive: false
  },
  {
    cat: "Marketing",
    key: "Lead generation",
    isActive: false
  },
  {
    cat: "Marketing",
    key: "Email marketing",
    isActive: false
  },
]

export const consultingOptions1 = [
  {
    cat: "Consulting",
    key: "Product Conceptualization",
    isActive: false
  },
  {
    cat: "Consulting",
    key: "Executive Management",
    isActive: false
  },
  {
    cat: "Consulting",
    key: "Managed Resource",
    isActive: false
  },
  {
    cat: "Consulting",
    key: "Market research",
    isActive: false
  },
]

export const consultingOptions2 = [
  {
    cat: "Consulting",
    key: "Product Design",
    isActive: false
  },
  {
    cat: "Consulting",
    key: "CrowedFunding",
    isActive: false
  },
  {
    cat: "Consulting",
    key: "Fundrising",
    isActive: false
  },
  {
    cat: "Consulting",
    key: "Branding",
    isActive: false
  },
]