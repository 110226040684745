import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import { createStore, combineReducers, applyMiddleware } from "redux";
import authReducer from "./store/reducers/auth";
import questionnaireReducer from "./store/reducers/questionnaire";
import serviceRequestReducer from "./store/reducers/service-request";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { PersistGate } from "redux-persist/integration/react";
import createFilter from "redux-persist-transform-filter";
import portalReducer from "./store/reducers/portalReporting";
import usersReducer from './store/reducers/inviteUser'

//import "./styles/styles.css";

const saveSubsetFilter = createFilter("root", ["auth"]);

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["form"],
  transforms: [saveSubsetFilter],
};
const rootReducer = combineReducers({
  auth: authReducer,
  questionnaire: questionnaireReducer,
  serviceRequest: serviceRequestReducer,
  portal: portalReducer,
  users : usersReducer
});
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer, applyMiddleware(thunk));
const persistor = persistStore(store);
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.querySelector("#root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
