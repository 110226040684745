import React,{useEffect,useRef,useState} from 'react'
import WOW from 'wowjs';
import '../css/responsive.css'
import '../css/bootstrap.min.css'
import '../css/style.css'
import '../css/fonts.css'
import { toast } from "react-toastify";
import {developmentOptions1,developmentOptions2,marketingOptions1,marketingOptions2,consultingOptions1,consultingOptions2} from '../constents/dropdown-options'
import ClientResponseModal from './Responsemodal';
import _ from 'lodash'
import { questionnaire } from "../service/questionnaire";
import { slackServices } from ".././service/slackService";
import QuestionFooter from './Footer';
import QuestionNav from './Questionnav';
import { Form } from 'react-bootstrap';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Captch from './Captcha';


const clientInitialState = {firstName:"",lastName:"",email:"",lookingFor:""}

const Questionnaire = ({sendSlackMessage,redirectPipeDrive}) => {
  const [scrollPosition,setScrillPosition] = useState(0)
  const [selctedService,setSelectedService] = useState("I know what I need")
  const [developmentOptionsColOne ,setdevelopmentOptionsColOne] = useState(developmentOptions1)
  const [developmentOptionsColTwo ,setdevelopmentOptionsColTwo] = useState(developmentOptions2)
  const [selectedDevelopment,setSelectedDevelopment] = useState([])
  const [marketingOptionsColOne ,setmarketingOptionsColOne] = useState(marketingOptions1)
  const [marketingOptionsColTwo ,setmarketingOptionsColTwo] = useState(marketingOptions2)
  const [selectedMarketing,setSelectedMarketing] = useState([])
  const [consultingOptionsColOne ,setconsultingOptionsColOne] = useState(consultingOptions1)
  const [consultingOptionsColTwo ,setconsultingOptionsColTwo] = useState(consultingOptions2)
  const [selectedConsulting,setSelectedConsulting] = useState([])
  const [clientDetails,setClientDetails] = useState(clientInitialState)
  const [error,setError] = useState({categoryError:"" , clientDetails : ""})
  const [showModal,setModalShow] = useState(false)
  const [mobileNavVisible,setMobileNavVisible] = useState(false)
  const [mobContactVisible,setMobContactVisible] = useState(false)
  const [mobServicesVisible,setMobServicesVisible] = useState(false)
  const [mobCompanyVisible,setMobCompanyVisible]= useState(false)
  const [isSubmit,setIsSubmit] = useState(false)
  const [isCaptchaSubmited,setCaptchaSubmited] = useState(true)
  const [isSecondCaptchaSubmited,setSecondCaptchaSubmited] = useState(true)
  const recaptchaRef = useRef();
  const secondrecaptchaRef = useRef()


  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const handleContactClick = (mobile) => {
    const element = document.getElementById("body")
    element.scrollTo({ top: mobile ? 3000 : 2500, behavior: 'smooth' })
  }

  useEffect(()=>{
    new WOW.WOW({live:false})
  },[])



 const scrollEvent = (e) => {
 const element = document.getElementById("body")
 const wow1 = document.getElementById("wow_one");
 const wow2 = document.getElementById("wow_two");
 const wow3 = document.getElementById("wow_three")
 if(element.scrollTop > 450 && !wow1.classList.contains("animate__animated")) {
   wow1.className +="animate__animated custom_animation"
 }
 if(element.scrollTop > 650 && !wow2.classList.contains("animate__animated")) {
  wow2.className +="animate__animated custom_animation"
}
if(element.scrollTop > 700 && !wow3.classList.contains("animate__animated")) {
  wow3.className +="animate__animated custom_animation"
}

  setScrillPosition(element.scrollTop)
 }

 const onDevelopementClick = (options,index,type) => {
   if(type == "colOne"){
    const temp = developmentOptionsColOne.map((obj,index1) => index1 === index ? { ...obj, isActive: !obj.isActive } : obj);
    const seleted = temp.filter(item => item.isActive)
    setSelectedDevelopment([... new Set([ ...selectedDevelopment , ...seleted ])])
    setdevelopmentOptionsColOne(temp)
    } else {
      const temp = developmentOptionsColTwo.map((obj,index1) => index1 === index ? { ...obj, isActive: !obj.isActive } : obj);
      setdevelopmentOptionsColTwo(temp)
      const seleted = temp.filter(item => item.isActive)
      setSelectedDevelopment([... new Set([ ...selectedDevelopment , ...seleted ])])
      setError({...error , categoryError : ""})
    }
 }

 const onMarketingOptionsClick = (options,index,type) => {
  if(type == "colOne"){
    const temp = marketingOptionsColOne.map((obj,index1) => index1 === index ? { ...obj, isActive: !obj.isActive } : obj);
    setmarketingOptionsColOne(temp)
    } else {
      const temp = marketingOptionsColTwo.map((obj,index1) => index1 === index ? { ...obj, isActive: !obj.isActive } : obj);
      setmarketingOptionsColTwo(temp)
    }
    const marketing = [...marketingOptionsColOne,...marketingOptionsColTwo].filter(option => option.isActive)
    setSelectedMarketing(marketing)
    setError({...error , categoryError : ""})
 }

 const onConsultingClick = (options,index,type) => {
  if(type == "colOne"){
    const temp = consultingOptionsColOne.map((obj,index1) => index1 === index ? { ...obj, isActive: !obj.isActive } : obj);
    setconsultingOptionsColOne(temp)
    } else {
      const temp = consultingOptionsColTwo.map((obj,index1) => index1 === index ? { ...obj, isActive: !obj.isActive } : obj);
      setconsultingOptionsColTwo(temp)
    }
    const consulting = [...consultingOptionsColOne,...consultingOptionsColTwo].filter(option => option.isActive)
    setSelectedConsulting(consulting)
    setError({...error , categoryError : ""})
 }

 const checkSelected = (options) => {
    return options.isActive === true
 }

 const chooseServiceNeed = () => {
   let service = []
   if([...developmentOptionsColOne , ... developmentOptionsColTwo].some(checkSelected)){
     service.push('Development')
   }
   if([...marketingOptionsColOne , ... marketingOptionsColTwo].some(checkSelected)) {
    service.push('Marketing')
   }
   if([...consultingOptionsColOne , ... consultingOptionsColTwo].some(checkSelected)) {
    service.push('Consulting')
   }

   return service
 }

 const selectedServices = (arr1,arr2) => {
   const  temp = [...arr1,...arr2].filter(item => item.isActive).map(selectedItem => selectedItem.key)
   return  _.isEmpty(temp) ? null : temp
 }





 const handleSubmit = () => {
  setIsSubmit(true)
  const params = {
    identifyServices: [selctedService],
    servicesNeed: chooseServiceNeed(),
    developmentServices: selectedServices(developmentOptionsColOne,developmentOptionsColTwo),
    marketingServices: selectedServices(marketingOptionsColOne,marketingOptionsColTwo),
    consultingServices : selectedServices(consultingOptionsColOne,consultingOptionsColTwo),
    firstName:clientDetails.firstName,
    lastName:clientDetails.lastName,
    email:clientDetails.email,
    lookingFor:clientDetails.lookingFor
}

   if(selctedService === "I know what I need"){
      if([...developmentOptionsColOne , ... developmentOptionsColTwo ,...marketingOptionsColOne , ...marketingOptionsColTwo , ...consultingOptionsColOne , ... consultingOptionsColTwo].some(checkSelected)) {
        if(Object.values(clientDetails).filter(item => item !== "").length === 4 ){
          if(validateEmail(clientDetails.email)){
            questionnaire
          .sendQuestionnaireForm(params)
          .then((response) => response.json())
          .then((response) => {
            if (response.id) {
              redirectPipeDrive()
              setModalShow(false)
              recaptchaRef.current.reset()
              secondrecaptchaRef.current.reset()
              setCaptchaSubmited(true)
              setSecondCaptchaSubmited(true)
              sendSlackMessage(response.id,false);
              toast.success(`Submit successfully
                Message send on slack
            `);
              setError({});
            }
          })
          .catch((err) => {
            toast.error("Error");
           
          });
          } else {
            setError({...error , clientDetails : "Please enter a valid email"})
          }
          
        } else {
            setError({...error , clientDetails : "Please fill all Fields"})
        }
      } else {
        setError({...error , categoryError : "Please select what services you are needing above before submitting"})
      }
   } else {
    if(Object.values(clientDetails).filter(item => item !== "").length === 4 ){
      if(validateEmail(clientDetails.email)){
        questionnaire
      .sendQuestionnaireForm(params)
      .then((response) => response.json())
      .then((response) => {
        if (response.id) {
        
          sendSlackMessage(response.id,true);
          setClientDetails(clientInitialState)
          redirectPipeDrive()
          setModalShow(false)
          toast.success(`Submit successfully
            Message send on slack
        `);
          setError({});
        }
      })
      .catch((err) => {
        toast.error("Error");
       
      });
      }else {
        setError({...error , clientDetails : "Please enter a valid email"})
      } 
    } else {
        setError({...error , clientDetails : "Please fill all Fields"})
    }
     
   }
 }


 const handleFormChanges = (e) => {
   const {name,value} = e.target
   setClientDetails({
     ...clientDetails,
     [name] : value
   })
   setError({...error , clientDetails : ""})
 }

//  const handleScrollTop = () => {
//   const element = document.getElementById("body")
//   element.scrollTo({ top: 0, behavior: 'smooth' })
//  }
  
 const handleMouseOver = () => {
   document.getElementById("picture_div").classList.add("display-none")
   document.getElementById("one").classList.remove("z_index_2")

 }

 const handleMouseOut = (element) => {
  document.getElementById("one").classList.add("z_index_2")
  document.getElementById("picture_div").classList.remove("display-none")
}

function detectMob() {
  return window.orientation > -1;
}

console.log("selctedService===",selctedService)

 
    return <div id="question_page">
   <body id="body"  onScroll={scrollEvent} className="question_page">
   <QuestionNav scrollPosition={scrollPosition}/>



          
<div  className={`headerbg ${scrollPosition  < 2000 ? "z_index_2" : ""}  `} id="one" data-color="#ffffff">
  <div className="headerimg" > </div>
  <header  className={scrollPosition > 1 && !detectMob() ? "header_nav custom_sticky_header" : 'header_nav '}>
      <div className="navbottomborder">
        <div className="container-fluid space_left header_left home-header  ">
          <nav className="navbar navbar-expand-lg" style={{paddingTop:"0",paddingBottom:"0"}}>
            <a className="navbar-brand" href="https://rainmakers.org"><img src="../images/logo.png" alt=""/></a>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <a   onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} className="nav-link" href="#">Company </a>
                  <div class="mega-menu hight-50 overFlow_hidden" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                    <div class="mega-container-fluid space_left">
                      <div class=" d-flex flex-row" style={{marginTop:"10px",marginLeft:"40px"}}>
                      <ul className='contact-nav-container'>
                      <li><a className='icon_text-center' href="https://rainmakers.org/#about"><div className='image-wrape mr-1'><img src="../images/information.png"/> </div>About</a></li>
                      <li><a className='icon_text-center'  href="https://rainmakers.org/#process"><div className='image-wrape mr-1'><img src="../images/engineering.png"/> </div>Process</a></li>
                      <li><a className='icon_text-center' href="https://rainmakers.org/team/"><div className='image-wrape mr-1'><img src="../images/management.png"/> </div>Team</a></li>
                    </ul>

                    <div class="contact-wrap">
                      <h3 style={{paddingLeft:"0.5rem"}}>CONTACT US</h3>
                      <ul class="contact-social-list">
                      <li><a href="https://www.facebook.com/Rainmakers.org/"><i  class="fa fa-facebook-official fa-lg"></i></a></li>
                        <li><a href="https://twitter.com/Rainmakers_org"><i class="fa fa-twitter fa-lg"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/rainmakers.org/"><i class="fa fa-linkedin-square fa-lg"></i></a></li>
                      </ul>
                      </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="nav-item">
                  <a class="nav-link" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} href="#">Services</a>
                  <div class="mega-menu" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                    <div class="mega-container-fluid space_left mega-container-overflow" id="mega-container_overflow">
                      <div class="row" style={{marginTop:"10px"}}>
                      <div class="col-md-3" >
                          <a href="#" class="mega-header">
                            <div class="image-wrape"><img src="../images/coding-1.png"/></div>
                            <h5>Development</h5>
                          </a>
                          <ul class="sub_menu_list">
                            <li><a href="#">Software development</a></li>
                            <li><a href="#">Website development</a></li>
                            <li><a href="#">App development</a></li>
                            <li><a href="#">API development</a></li>
                            <li><a href="#">Artificial Intelligence</a></li>
                            <li><a href="#">Blockchain / Web3</a></li>
                            <li><a href="#">Metaverse</a></li>
                            <li><a href="#">Integrations</a></li>
                          </ul>
                        </div>
                        <div class="col-md-3">
                          <a href="#" class="mega-header">
                            <div class="image-wrape"><img src="../images/analysis.png"/></div>
                            <h5>Consulting</h5>
                          </a>

                          <ul class="sub_menu_list">
                            <li><a href="#">Product Conceptualization</a></li>
                            <li><a href="#">Executive Management</a></li>
                            <li><a href="#">Managed Resource</a></li>
                            <li><a href="#">Market Research</a></li>
                            <li><a href="#">Crowdfunding</a></li>
                            <li><a href="#">Fundraising</a></li>
                          </ul>
                        </div>

                        <div class="col-md-3">
                          <a href="#" class="mega-header">
                            <div class="image-wrape"><img src="../images/database-storage.png"/></div>
                            <h5>Server</h5>
                          </a>

                          <ul class="sub_menu_list">
                            <li><a href="#">Maintenance</a></li>
                            <li><a href="#">Migration</a></li>
                            <li><a href="#">Security</a></li>
                            <li><a href="#">Hosting</a></li>
                            <li><a href="#">Devops</a></li>
                          </ul>
                        </div>
                        <div class="col-md-3">
                          <a href="#" class="mega-header">
                            <div class="image-wrape"><img src="../images/web-design.png"/></div>
                            <h5>Design</h5>
                          </a>
                          <ul class="sub_menu_list">
                            <li><a href="#">Website Design</a></li>
                            <li><a href="#">Product Design</a></li>
                            <li><a href="#">Animation</a></li>
                            <li><a href="#">Branding</a></li>
                          </ul>
                        </div>
                        <div class="col-md-3">
                          <a href="#" class="mega-header">
                            <div class="image-wrape"><img src="../images/digital-marketing (1).png"/></div>
                            <h5>Marketing</h5>
                          </a>

                          <ul class="sub_menu_list">
                            <li><a href="#">Search Engine Optimization</a></li>
                            <li><a href="#">Promotion and Syndication</a></li>
                            <li><a href="#">Social Media Marketing</a></li>
                            <li><a href="#">Content Creation</a></li>
                            <li><a href="#">Lead Generation</a></li>
                            <li><a href="#">Email Marketing</a></li>
                            <li><a href="#">Copywriting</a></li>
                          </ul>
                        </div>
                        
                        <div class="col-md-3">
                          <a href="#" class="mega-header">
                            <div class="image-wrape"><img src="../images/bullhorn (1).png"/></div>
                            <h5>Advertising</h5>
                          </a>
                          <ul class="sub_menu_list">
                            <li><a href="#">Facebook Advertising</a></li>
                            <li><a href="#">Instagram Advertising</a></li>
                            <li><a href="#">Funnel Optimization</a></li>
                            <li><a href="#">Taboola Advertising</a></li>
                            <li><a href="#">Twitter Advertising</a></li>
                            <li><a href="#">Adroll Advertising</a></li>
                            <li><a href="#">Google Adwords</a></li>
                          </ul>
                        </div>
                        
                        
                        
                       
                        
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nav-item">
                  <a className="nav-link" onClick={()=>handleContactClick(false)} href="#">Contact</a>
                </li>
              </ul>

              <ul className="navbar-nav ml-auto navbutton">
                <li>
                  <a  href="https://intake.rainmakers.org/services#">get started</a>
                </li>
                <li>
                  <a  onClick={()=>handleContactClick(false)} href="#">Contact us</a>
                </li>
              </ul>

            </div>
            <button onClick={()=>setMobileNavVisible(!mobileNavVisible)} class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#mobile_nav"
              aria-controls="mobile_nav" aria-expanded={mobileNavVisible} aria-label="Toggle navigation">
              <span className="bar-top"></span>
              <span className="bar-middle"></span>
              <span className="bar-bottom"></span>
            </button>
            {
              // ************************** Mobile Nav code start from here *************************
              <div className={`collapse navbar-collapse ${mobileNavVisible ? "show" : ""}` } id="mobile_nav">
            <ul className="navbar-nav demo-nav ml-auto">
                <li className={`nav-item dmenu dropdown ${mobCompanyVisible ? "show" : ""}`}>
                  <a onClick={()=>{setMobCompanyVisible(!mobCompanyVisible)}}  className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded={mobCompanyVisible ? "true" : "false"}>
                    Company
                  </a>
                  <div style={{background:'transparent',border:"none"}} className={` dropdown-menu show-drop ${mobCompanyVisible ? "show":""}`} aria-labelledby="navbarDropdown">
                    <div className="custom-drop-wrap ">
                      <ul className="sub_menu_list">
                        <li><a href="https://rainmakers.org/#about">About</a></li>
                        <li><a href="https://rainmakers.org/#process">Process</a></li>
                        <li><a href="https://rainmakers.org/team/">Team</a></li>
                      </ul>
                      
                    </div>
                  </div>
                 
                 
                </li>    
                <li className={`nav-item dropdown megamenu-li dmenu ${mobServicesVisible ? "show" : ""}`}>
                  <a onClick={()=>{setMobServicesVisible(!mobServicesVisible)}} className="nav-link dropdown-toggle" href="#" id="dropdown01" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded={mobServicesVisible ? "true" : "false"}>Services</a>
                  <div style={{background:"transparent",height:"300px",overflowY:"scroll",border:"none"}} className={`dropdown-menu mega-menu"= show-drop ${mobServicesVisible ? "show" :""}`} aria-labelledby="dropdown01">
                    <div className="container-fluid space_left">
                      <div className="row">
                        <div className="col-md-6 col-lg-3">
                          <a href="#" className="mega-header">
                            <h5>Development</h5>
                          </a>
                          <ul className="sub_menu_list">
                            <li><a href="#">Software development</a></li>
                            <li><a href="#">Website development</a></li>
                            <li><a href="#">App development</a></li>
                            <li><a href="#">API development</a></li>
                            <li><a href="#">Artificial Intelligence</a></li>
                            <li><a href="#">Blockchain / Web3</a></li>
                            <li><a href="#">Metaverse</a></li>
                            <li><a href="#">Integrations</a></li>
                          </ul>
                        </div>
                        <div className="col-md-6 col-lg-3">
                          <a href="#" class="mega-header">
                            
                            <h5>Consulting</h5>
                          </a>
                          <ul className="sub_menu_list">
                            <li><a href="#">Product Conceptualization</a></li>
                            <li><a href="#">Executive Management</a></li>
                            <li><a href="#">Managed Resource</a></li>
                            <li><a href="#">Market Research</a></li>
                            <li><a href="#">Crowdfunding</a></li>
                            <li><a href="#">Fundraising</a></li>
                          </ul>
                        </div>
                        <div className="col-md-6 col-lg-3">
                          <a href="#" class="mega-header">
                            <h5>Server</h5>
                          </a>

                          <ul className="sub_menu_list">
                            <li><a href="#">Maintenance</a></li>
                            <li><a href="#">Migration</a></li>
                            <li><a href="#">Security</a></li>
                            <li><a href="#">Hosting</a></li>
                            <li><a href="#">Devops</a></li>
                          </ul>
                        </div>
                        <div className="col-md-6 col-lg-3">
                          <a href="#" class="mega-header">
                            <h5>Design</h5>
                          </a>
                          <ul className="sub_menu_list">
                            <li><a href="#">Website Design</a></li>
                            <li><a href="#">Product Design</a></li>
                            <li><a href="#">Animation</a></li>
                            <li><a href="#">Branding</a></li>
                          </ul>
                        </div>
                        <div className="col-md-6 col-lg-3">
                          <a href="#" class="mega-header">
                            <h5>Marketing</h5>
                          </a>

                          <ul className="sub_menu_list">
                            <li><a href="#">Search Engine Optimization</a></li>
                            <li><a href="#">Promotion and Syndication</a></li>
                            <li><a href="#">Social Media Marketing</a></li>
                            <li><a href="#">Content Creation</a></li>
                            <li><a href="#">Lead Generation</a></li>
                            <li><a href="#">Email Marketing</a></li>
                            <li><a href="#">Copywriting</a></li>
                          </ul>
                        </div>
                        <div className="col-md-6 col-lg-3">
                          <a href="javascript(void)" class="mega-header">
                            <h5>Advertising</h5>
                          </a>
                          <ul className="sub_menu_list">
                            <li><a href="#">Facebook Advertising</a></li>
                            <li><a href="#">Instagram Advertising</a></li>
                            <li><a href="#">Funnel Optimization</a></li>
                            <li><a href="#">Taboola Advertising</a></li>
                            <li><a href="#">Twitter Advertising</a></li>
                            <li><a href="#">Adroll Advertising</a></li>
                            <li><a href="#">Google Adwords</a></li>
                          </ul>
                        </div>

                      </div>
                    </div>
                  </div>
                  
                </li>
                <li  onClick={()=>handleContactClick(true)} className="nav-item"><a class="nav-link" href="#">Contact</a>
                </li>

              </ul>

            </div>
            // ************************** Mobile Nav code End  here *************************
            }
          </nav>
        </div>
      </div>
    </header>

  <section className="bnr-sec" id="one" data-color="#ffffff">
    <div className="container-fluid space_left">
      <div className="bnr-sec-info">
        <div className="row ms-3">
          <div className="col-md-5">
            <h1>
            We help enterprises  <span>build, execute, and operate successfully,</span> with an acute focus  on the <span>digital </span> <span> business agenda.</span>
            
              </h1>
            
          </div>
          <div className="col-md-7 hide-mobile">
            <div className="">
            <img src="../images/service_banner_img.png" alt=""/>
            </div>
          </div>
        </div>
      </div>
    </div>
      
     {
      // hide on desktop only visible in mobile 
      <div class="contact-list display-mob">
            <ul class="navbutton">
              <li>
                <a href="https://intake.rainmakers.org/services#">get started</a>
              </li>
              <li>
                <a  onClick={()=>handleContactClick(true)} href="#rainmakers-contact-from">Contact us</a>
              </li>
            </ul>

          </div>}
  </section>
</div>

<section className="sec-two" id="two" data-color="#000000">
  <div className="container-fluid space_left">
    <div className="scroll_txt">
      <div className="main-sec-left-wrap "></div>
    </div>
    <div className="sec-two-info">
      <div className="col-md-6">
        <div className="top-sec">
          <h2 className='font-black'>Let's achieve<span className='text-left'>Together</span></h2>
          <p>Rainmakers leverages its technical prowess, deep domain knowledge, consulting capabilities, intellectual property (IP) assets, and methodologies to deliver next-generation, future-ready technology products and end-to-end marketing strategies to deliver measurable business value toward helping our clients meet strategic priorities.  <span style={{display:"inline"}}  className='text-bold boder-bottom-blue'>Select from our core services below to get started. </span> </p>
            <div className='d-flex mob-flex-col' style={{marginBottom:"4rem"}} id="services-chose">
            <div className={`btn-container mob-mb-1 d-flex  pd-none ${selctedService === "I know what I need" ? "boder-bottom-blue" : ""} `} >
              <span>
              <input onClick={()=>setSelectedService("I know what I need")} style={{marginRight:"0.5rem"}} type="checkbox" checked={selctedService === "I know what I need" ? true : false} />
              </span>
            <p  onClick={()=>setSelectedService("I know what I need")} className={selctedService === "I know what I need" ? '' : "" }>I Know What Services I Need</p>
          </div>
          <div className={`btn-lf-margin mob-mb-1 btn-container d-flex  pd-none ${selctedService === "I Need Advertising on Best Strategy" ? "boder-bottom-blue" : ""} `}  >
            <span>
              <input onClick={()=>setSelectedService("I Need Advertising on Best Strategy")} style={{marginRight:"0.5rem"}} type="checkbox" checked={selctedService === "I Need Advertising on Best Strategy" ? true : false} />
            </span>
            <p  onClick={()=>setSelectedService("I Need Advertising on Best Strategy")}>I Need Advising on Best Strategy</p>
          </div>
            </div>
        
        </div>
      </div>
<div className="main-sec">

<div className="row">
  <div className="col-md-7 no_pad">
    <ul className="main-sec-wrap-list mob-no_pad pl-0">
      <li  id='wow_one' className={`wow  `} >
        
        <div className="main-sec-wrap service-main-sec-wrap line_animation">
          <div className="img-container drop-animate">
            <figure>
              <img src="../images/icon-5.png" alt=""/>
            </figure>
          </div>
          <figcaption>
            <h3 className={[...developmentOptionsColOne,...developmentOptionsColTwo].some(checkSelected) ? "boder-bottom-blue h1-width" : ""}>Development</h3>
            <div className="row">
              <div className="col-md-6">
                <ul className="what-list" style={{listStyleType:"none"}}>
                  {developmentOptionsColOne.map((options,index)=>{
                    return <li  className={options.isActive ? "boder-bottom-blue" : ""}>
                      <a onClick={()=>onDevelopementClick(options,index,"colOne")} href="#">
                      <div className='check-container'>
                      <div>
                      <input style={{marginRight:"0.5rem"}} type="checkbox" checked={options.isActive} />
                          </div>
                      <div><span > {options.key}</span> </div> 
                      </div>
                      
                       </a>
                    </li>
                  })}
                </ul>
              </div>
              <div className="col-md-6">
                <ul className="what-list">
                  {developmentOptionsColTwo.map((options,index)=>{
                    return <li className={options.isActive ? "boder-bottom-blue" : ""}>
                      <a onClick={()=>onDevelopementClick(options,index)} href="#">
                      <div className='check-container'>
                      <div>
                      <input style={{marginRight:"0.5rem"}} type="checkbox" checked={options.isActive} />
                          </div>
                      <div><span > {options.key}</span> </div> 
                      </div>
                      </a>
                    </li>
                  })}
                </ul>
              </div>
            </div>
          </figcaption>
        </div>
      </li>
      <li id="wow_two" className="wow ">
        <div className="main-sec-wrap service-main-sec-wrap second-main-sec-wrap line_animation">
          <div className="img-container second-img-container drop-animate">
            <figure>
              <img src="../images/icon-3.png" alt=""/>
            </figure>
          </div>
          <figcaption>
            <h3 className={[...marketingOptionsColOne,...marketingOptionsColTwo].some(checkSelected) ? "boder-bottom-blue h1-width" : ""}>Marketing</h3>
            <div className="row">
              <div className="col-md-6">
              <ul className="what-list">
                  {marketingOptionsColOne.map((options,index)=>{
                    return <li className={options.isActive ? "boder-bottom-blue" : ""}>
                      <a onClick={()=>onMarketingOptionsClick(options,index,"colOne")} href="#">
                      <div className='check-container'>
                      <div>
                      <input style={{marginRight:"0.5rem"}} type="checkbox" checked={options.isActive} />
                          </div>
                      <div><span > {options.key}</span> </div> 
                      </div>
                      </a>
                    </li>
                  })}
                </ul>
              </div>
              <div className="col-md-6">
              <ul className="what-list">
                  {marketingOptionsColTwo.map((options,index)=>{
                    return <li className={options.isActive ? "boder-bottom-blue" : ""}>
                      <a onClick={()=>onMarketingOptionsClick(options,index)} href="#">
                      <div className='check-container'>
                      <div>
                      <input style={{marginRight:"0.5rem"}} type="checkbox" checked={options.isActive} />
                          </div>
                      <div><span > {options.key}</span> </div> 
                      </div>
                      </a>
                    </li>
                  })}
                </ul>
              </div>

            </div>
          </figcaption>
        </div>
      </li>
      <li  id='wow_three'  className="wow ">
        <div className="main-sec-wrap service-main-sec-wrap third-main-sec-wrap line_animation">
          <div className=" img-container third-img-container drop-animate">
            <figure>
              <img src="../images/icon-4.png" alt=""/>
            </figure>
          </div>
          <figcaption>
            <h3 className={[...consultingOptionsColOne,...consultingOptionsColTwo].some(checkSelected) ? "boder-bottom-blue h1-width" : ""}>Consulting</h3>
            <div className="row">
              <div className="col-md-6">
              <ul className="what-list">
                  {consultingOptionsColOne.map((options,index)=>{
                    return <li className={options.isActive ? "boder-bottom-blue" : ""}>
                      <a onClick={()=>onConsultingClick(options,index,"colOne")} href="#">
                      <div className='check-container'>
                      <div>
                      <input style={{marginRight:"0.5rem"}} type="checkbox" checked={options.isActive} />
                          </div>
                      <div><span > {options.key}</span> </div> 
                      </div>
                      </a>
                    </li>
                  })}
                </ul>
              </div>
              <div className="col-md-6">
                <ul className="what-list">
                {consultingOptionsColTwo.map((options,index)=>{
                    return <li className={options.isActive ? "boder-bottom-blue" : ""}>
                      <a onClick={()=>onConsultingClick(options,index)} href="#">
                      <div className='check-container'>
                      <div>
                      <input style={{marginRight:"0.5rem"}} type="checkbox" checked={options.isActive} />
                          </div>
                      <div><span > {options.key}</span> </div> 
                      </div>
                      </a>
                    </li>
                  })}
                </ul>
              </div>
              <form   className="form-content  form-content-two res_form_content mob_pl_0 " >
            <div className="top-form" style={{marginTop:"3rem"}}>
              <div className="row">
                <div className="col-md-6 col-6">
                  <input  type="text" placeholder="First name" name="firstName" onChange={handleFormChanges} value={clientDetails.firstName}/>
                </div>
                <div className="col-md-6 col-6">
                  <input type="text" placeholder="Last name" name="lastName" onChange={handleFormChanges} value={clientDetails.lastName}/>
                </div>
              </div>
            </div>
            <div className="bottom-form">
              <div className="row">
                <div className="col-md-6 col-6">
                  <input type="email" required placeholder="Your email address" name="email" onChange={handleFormChanges} value={clientDetails.email}  pattern=".+@globex\.com" size="30" />
                </div>
                <div className="col-md-6 col-6">
                  <input type="text" placeholder="Looking for?" name="lookingFor" onChange={handleFormChanges} value={clientDetails.lookingFor}/>
                </div>
              </div>
              {error.categoryError == "" && error.clientDetails  == "" ? null :   error.categoryError !== "" ?   <p style={{margin:0}} className='error-text'>{error.categoryError}</p> : <p className='error-text'>{error.clientDetails} </p>}
            </div>
            <Captch captchClick={setCaptchaSubmited} recaptchaRef={recaptchaRef} detectMob={detectMob}/>
            
          </form>

            <div className="btn-container  btn-container-first mt-xl">
            <button  disabled={isCaptchaSubmited} onClick={handleSubmit} className={`btn-seclted submit-button ${isCaptchaSubmited ? "submit-button-disabled" : ""}`}>Submit</button>
          </div>

            </div>
          </figcaption>
        </div>
      </li>
    </ul>
  </div>
  
      {/* <div className="main-sec-left-wrap  hide_element"></div>  */}
      <div className="col-md-5 hide-mobile">
            <div className="picture-div" id='picture_div'>
            <img src="../images/rainmakers_logo.png" alt=""/>
            </div>
          </div>
 
</div>
</div>
    </div>

  </div>
</section>

<section className="footer-form padding-top-xxxl" style={{paddingTop:"160px"}}>
  <div className="container-fluid space_left no_space">
    <div className="form-content mob_pl_0">
      <div className="row">
        <div className="col-md-6">
          <div className="left-content">

              <h2 className='text-left mob_display_block mob_pb_0'>Get</h2>
               <h2 className='text-left pb-25'> <span>Started Today</span> </h2>
              
            <p><q>extremely creative in the ways that they look to add value in</q></p>
          </div>
        </div>
        <div className="col-md-6">
          <form className="form-content res_form_content mob_pl_0" >
            <div className="top-form">
              <div className="row">
                <div className="col-md-6 col-6">
                  <input type="text" placeholder="First name" name="firstName" onChange={handleFormChanges} value={clientDetails.firstName}/>
                </div>
                <div className="col-md-6 col-6">
                  <input type="text" placeholder="Last name" name="lastName" onChange={handleFormChanges} value={clientDetails.lastName}/>
                </div>
              </div>
            </div>
            <div className="bottom-form">
              <div className="row">
                <div className="col-md-6 col-6">
                  <input type="email" required placeholder="Your email address" name="email" onChange={handleFormChanges} value={clientDetails.email}  pattern=".+@globex\.com" size="30" />
                </div>
                <div className="col-md-6 col-6">
                  <input type="text" placeholder="Looking for?" name="lookingFor" onChange={handleFormChanges} value={clientDetails.lookingFor}/>
                </div>
              </div>
              {error.categoryError !== "" ?   <p style={{margin:0}} className='error-text'>{error.categoryError}</p> : <p className='error-text'>{error.clientDetails} </p>}
            </div>
            <Captch captchClick={setSecondCaptchaSubmited} recaptchaRef={secondrecaptchaRef} detectMob={detectMob}/>

            <input disabled={isSecondCaptchaSubmited} class={`submit_btn ${isSecondCaptchaSubmited ? "sbt-sec-btn-disabled" : ""}`} type="button" value="Submit" onClick={handleSubmit}/>
         {/* <button>Submit</button>  */}
          </form>
        </div>
      </div>
    </div>
    <div className="water_ripple_wrap">
      <div className="wrapper">
        <div className={`wave_1 outer-shadow ${isSubmit ? "submitdrop" : ""}`}>
          <div className="inner-shadow align-center"></div>
        </div>
        <div className={`wave_2 outer-shadow align-center ${isSubmit ? "submitdrop" : ""}`}>
          <div className="inner-shadow align-center"></div>
        </div>
        <div className={`wave_3 outer-shadow align-center ${isSubmit ? "submitdrop" : ""}`}>
          <div className="inner-shadow align-center"></div>
        </div>
        <div className={`drop align-center pd-1 ${isSubmit ? "submitdrop" : ""}`}>
          <img src="../images/wave-drop.png" alt=""/>
        </div>
      </div>
    </div>
    <div className={`wave_wrap ${isSubmit ? "appear_img" : ""}`}>
      <img src="../images/wave.png" alt=""/>
    </div>
  </div>

</section>


<QuestionFooter/>
{/* <footer className="footer">

  <div className="container-fluid space_left no_space">
    <div className="footer-info">
      <div className="row">
        <div className="col-md-12 col-lg-3">
          <div className="footer-logo">
            <a href="#"><img src="../images/footer-logo.png" alt=""/></a>
            <span>Copyrights © 2022</span>
          </div>
        </div>
        <div className="col-md-12 col-lg-9">
          <div className="footer-content">
            <div className="row">
              <div className="col-md-3 col-6 mt-4">
                <h6>Quick links</h6>
                <ul className="footer-list no_padding">
                  <li><a href="#">portal.rainmakers.org</a></li>
                  <li><a href="#">intake.rainmakers.org</a></li>
                  <li><a href="#">news.rainmakers.org</a></li>
                </ul>
              </div>
              <div className="col-md-3 col-6 mt-4">
                <h6>Company</h6>
                <ul className="footer-list no_padding">
                  <li><a href="">About</a></li>
                  <li><a href="">Process</a></li>
                  <li><a href="">Team</a></li>
                </ul>
              </div>
              <div className="col-md-3 col-6 mt-4">
                <h6>Services</h6>
                <ul className="footer-list no_padding">
                  <li><a href="">Development</a></li>
                  <li><a href="">Marketing</a></li>
                  <li><a href="">Consulting</a></li>
                </ul>
              </div>
              <div className="col-md-3 col-6 mt-4">
                <h6>Follow us on</h6>
                <ul className="footer-list social-list ">
                  <li><a href="https://www.facebook.com/Rainmakers.org/" target="_blank">
                    
                    <span>Facebook</span></a></li>
                  <li><a href="https://twitter.com/Rainmakers2022/" target="_blank"><span>Twitter</span></a></li>
                  <li><a href="https://www.linkedin.com/company/rainmakers.org/" target="_blank"><span>linkedin</span></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p className="bottom-copy"><span>Copyrights © 2022</span></p>
    </div>
  </div>

</footer> */}

  
</body>

{showModal &&   <ClientResponseModal
                            show={showModal}
                            onHide={() => setModalShow(false)}
                            error={error}
                            setError={setError}
                            clientDetails={clientDetails}
                            setClientDetails={setClientDetails}
                            handleFormChanges={handleFormChanges}
                            handleSubmit={handleSubmit}

                            />}

  

</div>
}

export default Questionnaire