import React from 'react'


const QuestionNav = ({scrollPosition}) => {
    return <>
    <div   className = {`socialbar ${scrollPosition == 0 ? "" : scrollPosition > 700 ? "croll-line-color-black socialbar-color-black" : "scroll-line-color-white"}`} >
  <ul>
    <li ><a href="https://www.linkedin.com/company/rainmakers.org/" target="_blank">
    <i class="fa fa-linkedin-square fa-lg"></i>
      <span>linkedin</span></a></li>
    <li><a href="https://twitter.com/Rainmakers_org" target="_blank">
    <i class="fa fa-twitter fa-lg"></i>
      <span>twitter</span></a></li>
    <li><a href="https://www.facebook.com/Rainmakers.org" target="_blank">
    <i style={{marginLeft:"0.3rem"}} class="fa fa-facebook-official fa-lg"></i>
      <span>facebook</span></a></li>
  </ul>
</div>
{/* <div className="mobile-socialbar">
  <ul>
    <li><a href="https://www.linkedin.com/company/rainmakers.org/" target="_blank">
    <i class="fa fa-linkedin-square fa-lg"></i>   </a></li>
    <li><a href="https://twitter.com/Rainmakers2022" target="_blank">
    <i class="fa fa-twitter fa-lg"></i>
      </a>
      </li>
    <li><a href="https://www.facebook.com/Rainmakers.org" target="_blank">
    <i class="fa fa-facebook-official fa-lg"></i>
      </a></li>
  </ul>
</div> */}
    </>
}

export default QuestionNav