import { Route, Redirect } from "react-router-dom";
import React,{useState,useEffect} from "react";
import { connect } from "react-redux";
import Cookies from "js-cookie";
const UserAuthRoute = ({ component: Component, ...rest }) => {
    const accessToken = Cookies.get("userAccessToken")
    const admin = useState(JSON.parse(localStorage.getItem("isAdmin")));
    const [isLogin, setIsLogin] = useState(accessToken);
    const [isAdmin, setIsAdmin] = useState(JSON.parse(localStorage.getItem("isAdmin")));

   useEffect(() => {
 }, [accessToken,admin]);
  return (
    <Route
      {...rest}
      render={props =>
        isLogin  &&  !isAdmin ? (
          <Component  {...props}/>
        ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location }
              }}
            />
          )
      }
    />
  );
};


export default UserAuthRoute;