import React,{useState} from 'react'
import { Modal, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import _ from 'lodash'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { forgotPassword } from '../store/actions/inviteagency';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};



const EmailModal = (props) => {
    const [email,setEmail] = useState()
    const [emailError,setEmailError] = useState("")
    const dispatch = useDispatch()
    const history = useHistory()

    function validateEmail(email) {
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      }
    
    
    const handleSubmit = () =>{
        if(validateEmail(email)){
            dispatch(forgotPassword({email},props.onHide))
        } else {
            setEmailError("Please enter a valid email")
        }
        
    }


    return <>
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           {"Forgot Password"}
          </Modal.Title>
        </Modal.Header>
         <Modal.Body style={{margin:"auto"}}>
          <Box
            sx={{
              width: 700,
              maxWidth: '100%',
              m: 1,
            }}
            style={{marginTop:"25px"}}
          >
            <TextField
            fullWidth
            label="Email"
            id="fullWidth"
            name="email"
            type='email'
            onChange={(e)=>{setEmail(e.target.value)}}
            value={email}
            />
          </Box>
          {emailError &&   (
              <p className="error-text ml-10">{emailError}</p>
            )}
        </Modal.Body>
        <Modal.Footer style={{marginRight:"4%"}}>
          <Button onClick={props.onHide}>Close</Button>
          <Button   onClick={handleSubmit}>Submit</Button>
        </Modal.Footer>
      </Modal>
    </>
}

EmailModal.defaultProps = {
    modalTitile: " Invite Agency",
    update: false,
  }

export default EmailModal