import React, { useState, useEffect } from "react";
import Multiselect from "multiselect-react-dropdown";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getQuestionnaire,
  getQuestionnaireDetailWithCost,
} from "../../store/actions/questionnaire";
import { Card, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import ReactTooltip from "react-tooltip";
import DisplayAdminFilledCost from "./displayadminfilledcost";
import AdminServiceCostEdit from "./adminServiceCostEdit";
import Loader from '../../components/loader'
import {getQuestionnaireAdminList} from '../../store/actions/questionnaire'
import {getApprovedCost} from '../../store/actions/service-request'
import DisplayAdminApprovedCost from "./Displayadminapprovedcost";
import _ from 'lodash'

const ServiceAndCost = (props) => {
 
  const [isAdmin, setIsAdmin] = useState(
    JSON.parse(localStorage.getItem("isAdmin"))
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLogin, setIsLogin] = useState(
    Cookies.get("userAccessToken",{path:"/"})
  );
  const [value, setValue] = useState();

  const OrgName = useSelector((state) => state.questionnaire?.questionnaireData?.name);
  const orgEmail = useSelector((state) => state.questionnaire?.questionnaireData?.email);
  const lName = useSelector((state) => state.questionnaire?.questionnaireData?.lastName);
  const fName = useSelector((state) => state.questionnaire?.questionnaireData?.firstName);
  const [name, setName] = useState({OrgName});
  const [email,setEmail] = useState(orgEmail)
  const [firstName,setFirstName] = useState(fName)
  const [lastName,setLastName] = useState(lName)
  const [toggel, setToggel] = useState(true);
  const [questionnaire_id, setQuestionnaire_id] = useState(
    localStorage.getItem("questionnaireId")
  );
  const [userId, setUserId] = useState(
    useSelector((state) => {
      return state.auth.loginId;
    })
  );

  const tempapprovedCost = useSelector(state => state.serviceRequest.approvedCost )
  const [approvedCost ,setApprovedCost] = useState(tempapprovedCost)
  const questionnaireLoading = useSelector((state)=>state.questionnaire.questionnaireLoading)
  const question = useSelector((state)=>state.questionnaire.questionnaireData)
  
  

  const helpStrategy =  useSelector((state) =>  state.questionnaire && state.questionnaire.questionnaireData.identifyServices );
  const [dropDownEdit, setDropDownEdit] = useState(helpStrategy && helpStrategy[0] === "I Need Advertising on Best Strategy" ? true:false)
  
  
  const user_id = JSON.parse(localStorage.getItem("user_id"));
  const serviceRequestStatus = useSelector((state) => {
    return (
      state.questionnaire.questionnaireDetailCost &&
      state.questionnaire.questionnaireDetailCost &&
      state.questionnaire.questionnaireDetailCost.costList
    );
  });

  const questionnaireId = useSelector((state) => {
    return state.questionnaire && state.questionnaire.questionnaireData.id;
  });

  const [requstServiceId, setRequestServiceId] = useState();

  const data = useSelector((state) => {
    return state.questionnaire && state.questionnaire.questionnaireData.name;
  });

  const serviceNeed = useSelector((state) => {
    return (
      state.questionnaire && state.questionnaire.questionnaireData.servicesNeed
    );
  });

  const marketingServices = useSelector((state) => {
    return (
      state.questionnaire &&
      state.questionnaire.questionnaireData.marketingServices
    );
  });

  const advertisingServices = useSelector((state) => {
    return (
      state.questionnaire &&
      state.questionnaire.questionnaireData.advertisingServices
    );
  });

  const discription = useSelector((state) => {
    return (
      state.questionnaire && state.questionnaire.questionnaireData.discription
    );
  });

  const lookingFor = useSelector((state) => state.questionnaire?.questionnaireData.lookingFor)
  const development = useSelector((state) => {
    return (
      state.questionnaire &&
      state.questionnaire.questionnaireData &&
      state.questionnaire.questionnaireData.developmentServices &&
      state.questionnaire.questionnaireData.developmentServices.map(
        (value) => ({
          key: value,
        })
      )
    );
  });

  const design = useSelector((state) => {
    return (
      state.questionnaire &&
      state.questionnaire.questionnaireData &&
      state.questionnaire.questionnaireData.designServices &&
      state.questionnaire.questionnaireData.designServices.map((value) => ({
        key: value,
      }))
    );
  });
  // const consulting = useSelector((state) => state.questionnaire?.questionnaireData?.consultingServices?.map((value) => ({key: value, })) );


  const incubation = useSelector((state) => {
    return (
      state.questionnaire &&
      state.questionnaire.questionnaireData &&
      state.questionnaire.questionnaireData.incubationServices &&
      state.questionnaire.questionnaireData.incubationServices.map((value) => ({
        key: value,
      }))
    );
  });

  const consulting = useSelector((state) => {
    return (
      state.questionnaire &&
      state.questionnaire.questionnaireData &&
      state.questionnaire.questionnaireData.consultingServices &&
      state.questionnaire.questionnaireData.consultingServices.map((value) => ({
        key: value,
      }))
    );
  });
  const costdetails = useSelector((state) => {
    return (
      state.questionnaire.questionnaireDetailCost &&
      state.questionnaire.questionnaireDetailCost.costList
    );
  });

  const questionnaireDetails = useSelector((state) => {
    return (
      state.questionnaire &&
      state.questionnaire.questionnaireData &&
      state.questionnaire.questionnaireData
    );
  });

  // tdIndex = (costdetails && costdetails.length + 1) || 1;

  const findParams = () => {
    const urlParams = new URLSearchParams(props.location.search);
    const request_id = urlParams.get("request_id");
    return request_id;
  };

  const handleLogout = () => {
    localStorage.clear();
    Cookies.remove("userLogin");
    Cookies.remove("isAdmin")
    Cookies.remove("userAccessToken")
    Cookies.remove("isAdmin",{path:"/admin-servicecost"})
    Cookies.remove("userAccessToken",{path:"/admin-servicecost"})
    history.push("/admin-login");
    handePageRefresh();
  };

  const adminQuestionnaireList = useSelector((state) => {
    return (
      state.questionnaire.questionnaireAdminList &&
      state.questionnaire.questionnaireAdminList.map(
        (element) => element.filledCost
      )
    );
  });
  


  const finalcost =
    adminQuestionnaireList &&
    adminQuestionnaireList.map((value) =>
      value.filter((elemet) => elemet.questionnaireId === questionnaireId)
    );

  let result = finalcost && finalcost.reduce((r, e) => (r.push(...e), r), []);

  const handePageRefresh = () => {
    setValue({});
  };

  useEffect(() => {
    setIsAdmin(JSON.parse(localStorage.getItem("isAdmin")));
    setIsLogin(Cookies.get("userAccessToken",{path:"/"}))
    const request_id = findParams();
    localStorage.setItem("questionnaireId", request_id);
    setRequestServiceId(request_id && request_id);
    setQuestionnaire_id(localStorage.getItem("questionnaireId"));
    if (request_id && isLogin !==undefined) {
      localStorage.setItem("questionnaireId", request_id);
      dispatch(getQuestionnaire(request_id));
      dispatch(getQuestionnaireDetailWithCost(request_id));
      setQuestionnaire_id(localStorage.getItem("questionnaireId"));
      dispatch(getQuestionnaireAdminList());
      dispatch(getApprovedCost(request_id));

    }
           
    if (isLogin === undefined ) {
     
      history.push({
        pathname: "/admin-login",
        state: {
          request_id,
        },
      });
    }
  }, []);

  useEffect(() => {
    fName ?  setName(`${fName} ${lName}`) :  setName(`${OrgName}`);
  }, [OrgName,fName,lName]);
  useEffect(()=>{
    setDropDownEdit(helpStrategy && helpStrategy[0] === "I Need Advertising on Best Strategy" ? true:false)
  },[helpStrategy])


  useEffect(()=>{
      setApprovedCost(tempapprovedCost)
  },[tempapprovedCost])


  useEffect(()=>{
     setEmail(orgEmail)
  },[orgEmail])


  
   

  
  return (
    <div>
    {questionnaireLoading &&  <Loader
        styles={{zIndex:99999 , position: "absolute" , top:"50vh" , width:"100%"}}
      />  }
     
       
   
    <> { dropDownEdit  ? <AdminServiceCostEdit findParams={findParams} identifyServices={helpStrategy } approvedCost={approvedCost}/>  :
      <body>
        <div className="d-flex" id="wrapper">
          {toggel && (
            <div className="border-end bg-white" id="sidebar-wrapper">
              <div className="sidebar-heading border-bottom bg-light">
                Mmi funnel portal
              </div>
              <div className="list-group list-group-flush">
                <Link
                  className=" list-group-item-action list-group-item-light p-3"
                  to={isAdmin ? "/admindashboard" : "/dashboard"}
                >
                  Dashboard{" "}
                </Link>
              </div>
            </div>
          )}

          <div id="page-content-wrapper">
            <nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom nav-padding-balance">
              <div className="container-fluid">
                <div className="">
                  <label class="switch">
                    <input
                      type="checkbox"
                      onClick={() => {
                        setToggel(!toggel);
                      }}
                    />{" "}
                    <div></div>
                  </label>
                </div>

                <div
                  className="collapse navbar-collapse "
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav ms-auto mt-2 mt-lg-0">
                    <button
                      onClick={handleLogout}
                      className="btn btn-primary"
                      id="sidebarToggle"
                    >
                      Sign out
                    </button>
                  </ul>
                </div>
              </div>
            </nav>

            <div className="div-center">
              { !_.isEmpty(question) ?   <div className={`w-100 ServiceQuestionnaire my-20 div-center `}>
                <Card className="w-90">
                  <Card.Header style={{padding:"0.1rem 1rem"}} className="card-header-background mainHeading">
                    Request For Service{""}
                    <button
                      className="all-unset"
                      data-tip
                      data-for="registerTip"
                    >
                      {" "}
                      <img
                        src="../assets/images/info.png"
                        alt=""
                        className="info-icon"
                      />
                    </button>
                    <ReactTooltip
                      className="tooltip"
                      id="registerTip"
                      place="right"
                      effect="solid"
                    >
                      Here is a request for service from an incoming Marketing
                      Machine client.
                      <br />
                      <br />
                      If you or your agency is interested in providing service
                      please look at the request points of service below.
                      <br />
                      <br />
                      Then fill in your proposed cost for each point you will
                      cover. We will then submit the proposal back to the client
                      who if they move ahead on the points you are associated
                      with will then be delegated to you or your team.
                      <br />
                      <br />
                    </ReactTooltip>
                  </Card.Header>
                  <Card.Body>
                    <Form>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="questionnaire-label">Name / Organization Name</Form.Label>
                        <Form.Control value={name} readOnly />
                      </Form.Group>

                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="questionnaire-label">Email</Form.Label>
                        <Form.Control value={email} readOnly />
                      </Form.Group>

                      <Form.Group
                        className="mt-15"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="questionnaire-label">Which services do you need?</Form.Label>
                      </Form.Group>
                      <Multiselect
                        displayValue="key"
                        groupBy="cat"
                        placeholder={""}
                        disable={true}
                        selectedValues={
                          serviceNeed &&
                          serviceNeed.map((element) => ({
                            key: element,
                          }))
                        }
                      />
                      {development && (
                        <div>
                          <Form.Group
                            className=" mt-15"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label className="questionnaire-label">
                              What development services do you need?
                            </Form.Label>
                          </Form.Group>
                          <Multiselect
                            displayValue="key"
                            placeholder={""}
                            showArrow
                            disable
                            selectedValues={development}
                          />
                        </div>
                      )}

                      {marketingServices && (
                        <div>
                          {" "}
                          <Form.Group
                            className="mt-15"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label className="questionnaire-label">
                              What marketing services do you need?{" "}
                            </Form.Label>
                          </Form.Group>
                          <Multiselect
                            displayValue="key"
                            placeholder={""}
                            selectedValues={
                              marketingServices &&
                              marketingServices.map((element) => ({
                                key: element,
                              }))
                            }
                            disable={true}
                          />{" "}
                        </div>
                      )}
                      {/* {advertisingServices && (
                        <div>
                          <Form.Group
                            className=" mt-15"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label className="questionnaire-label">
                              What advertising services do you need?
                            </Form.Label>
                          </Form.Group>
                          <Multiselect
                            displayValue="key"
                            placeholder={""}
                            selectedValues={
                              advertisingServices &&
                              advertisingServices.map((element) => ({
                                key: element,
                              }))
                            }
                            disable={true}
                          />
                        </div>
                      )} */}
                      {/* {design && (
                        <div>
                          <Form.Group
                            className=" mt-15"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label className="questionnaire-label">
                              What design services do you need?
                            </Form.Label>
                          </Form.Group>
                          <Multiselect
                            displayValue="key"
                            placeholder={""}
                            showArrow
                            selectedValues={design}
                            disable={true}
                          />
                        </div>
                      )} */}
                      {/* <div>
                        {incubation && (
                          <div>
                            <Form.Group
                              className=" mt-15"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label className="questionnaire-label">
                                What incubation services do you need?
                              </Form.Label>
                            </Form.Group>
                            <Multiselect
                              displayValue="key"
                              placeholder={""}
                              showArrow
                              disable
                              selectedValues={incubation}
                            />
                          </div>
                        )}
                      </div> */}
                      <div>
                        {consulting && (
                          <div>
                            <Form.Group
                              className=" mt-15"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label className="questionnaire-label">
                                What consulting services do you need?
                              </Form.Label>
                            </Form.Group>
                            <Multiselect
                              displayValue="key"
                              placeholder={""}
                              showArrow
                              disable
                              selectedValues={consulting}
                            />
                          </div>
                        )}
                      </div>
                      <Form.Group
                        className=" mt-15"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="questionnaire-label">
                          To finish up, please provide a brief yet detailed
                          description of the work needed{" "}
                        </Form.Label>
                      </Form.Group>
                      <Form.Control
                        value={lookingFor && lookingFor}
                        as="textarea"
                        rows={3}
                        style={{ height: "150px" }}
                        readOnly
                      />
                    </Form>
                   
                    {approvedCost.length !==0 ? <DisplayAdminApprovedCost serviceRequestStatus={result} approvedCost={approvedCost} /> : <DisplayAdminFilledCost serviceRequestStatus={result}/>}
                  </Card.Body>
                </Card>
              </div> : <div style={{marginTop:"2rem"}}><p>No record found!</p></div>}
            </div>
          </div>
        </div>
      </body>
      }
    </>
    
    </div>

  );
};
export default ServiceAndCost;
