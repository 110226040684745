import React, { useState ,useEffect} from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import _ from 'lodash'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';




const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};





const ClientResponseModal = ({error,setError,clientDetails,setClientDetails,handleFormChanges,handleSubmit,...props}) => {
    

    function validateEmail(email) {
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      }
    
    
  


    return <>
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           {""}
          </Modal.Title>
        </Modal.Header>
         <Modal.Body style={{margin:"auto",display:"flex",flexDirection:"column"}}>
             <div className="d-flex">

             
          <Box
            sx={{
              width: 350,
              maxWidth: '100%',
              m: 1,
            }}
            style={{marginTop:"25px"}}
          >
            <TextField
            fullWidth
            label="First Name"
            id="fullWidth"
            name="firstName"
            type='text'
            onChange={handleFormChanges}
            value={clientDetails.firstName}
            />
            
          </Box>
          <Box
            sx={{
              width: 350,
              maxWidth: '100%',
              m: 1,
            }}
            style={{marginTop:"25px"}}
          >
            <TextField
            fullWidth
            label="Last Name"
            id="fullWidth"
            name="lastName"
            type='text'
            onChange={handleFormChanges}
            value={clientDetails.lastName}
            />
            
          </Box>
          </div>
          <div className="d-flex">

             
<Box
  sx={{
    width: 350,
    maxWidth: '100%',
    m: 1,
  }}
  style={{marginTop:"25px"}}
>
  <TextField
  fullWidth
  label="Email"
  id="fullWidth"
  name="email"
  type='email'
  onChange={handleFormChanges}
  value={clientDetails.email}
  />
  
</Box>
<Box
  sx={{
    width: 350,
    maxWidth: '100%',
    m: 1,
  }}
  style={{marginTop:"25px"}}
>
  <TextField
  fullWidth
  label="Looking for?"
  id="fullWidth"
  name="lookingFor"
  type='text'
  onChange={handleFormChanges}
  value={clientDetails.lookingFor}
  />
  
</Box>
</div>
{error.categoryError !== "" ?   <p className='error-text ml-smr'>{error.categoryError}</p> : <p className='error-text ml-smr'>{error.clientDetails} </p>}
          
        </Modal.Body>
        <Modal.Footer style={{marginRight:"4%"}}>
          <Button className="new-blue-btn"  onClick={handleSubmit}>Submit</Button>
        </Modal.Footer>
      </Modal>
    </>

};
ClientResponseModal.defaultProps = {
  modalTitile: "",
  status: false,
}

export default ClientResponseModal;
