import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as authActions from "../../store/actions/auth";
import "../../styles/login.css";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import Loader  from "../../components/loader";
import EmailModal from '../../components/emailmodal'


const AdminLogin = (props) => {
  const [isLogin, setIsLogin] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [authToken, setAuthToken] = useState(Cookies.get("userAccessToken"));
  const [isAdmin, setIsAdmin] = useState(Cookies.get("isAdmin"));
  const isLoginLoading = useSelector((state) => state.auth.isLoading)
  const [modalShow, setModalShow] = React.useState(false);
   
  const [authData, setAuthData] = useState(
    useSelector((state) => {
      return state && state.auth;
    })
  );
  const [error, setError] = useState({
    email: "",
    password: "",
  });

  const history = useHistory();
  const dispatch = useDispatch();

  const handleLogout = () => {
    localStorage.clear(() => {
      Cookies.remove("userLogin");
      setIsLogin(false);
      history.push("/login");
    });
  };

  const readCookie = () => {
    const user = Cookies.get("userLogin");
    if (user) {
      setIsLogin(true);
    }
  };
  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  function validatePassword(passord) {
   // const re = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&+=]).{10,16}$/;
    const re = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
    return re.test(passord);
  }

  // const handleLogin = (e) => {
  //   e.preventDefault();
  //   dispatch(authActions.tryAdminLogin({ email, password }));
  //   setAuthToken(localStorage.getItem("userAccessToken"));
  // };

  const handleLogin = (e) => {
    const name = "normal user";
    e.preventDefault();
    if (validateEmail(email)) {
      if (validatePassword(password)) {
      
        dispatch(authActions.tryAdminLogin({ email, password }));
        setAuthToken(localStorage.getItem("userAccessToken"));
      } else {
        setError({
          ...error,
          password:
            "Minimum eight characters, at least one letter, one number and one special character",
        });
      }
    } else {
      setError({
        ...error,
        email: "Please enter a valid email",
      });
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (validateEmail(email)) {
      setError({
        ...error,
        email: "",
      });
    }
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (validatePassword(password)) {
      setError({
        ...error,
        password: "",
      });
    }
  };

  useEffect(() => {
    readCookie();
    const request_id = props.location.state && props.location.state.request_id;
    setAuthToken(Cookies.get("userAccessToken"));
    setIsAdmin(Cookies.get("isAdmin"));
     
    if (authToken !== undefined && ( isAdmin === "true")) {
       history.push("/admindashboard");
    }
     if (authToken !== undefined && request_id && isAdmin ==="true") {
       history.push(`/admin-servicecost/?request_id=${request_id}`);
    }
    if ( isAdmin === "false") {
      Cookies.remove("isAdmin")
      Cookies.remove("userAccessToken")
      localStorage.clear();
    }

    // if (authToken !== null && !isAdmin) {
    //   toast.error("Your are not allowed to login ");
    //   localStorage.clear();
    // 
  });
  const showEmailModal = () =>{
    setModalShow(true)
  }

  

  return (
    <>
    <form className="login login-background">
      <div className="wrapper fadeInDown">
        <div id="formContent">
          <p className="mt-15">Admin login</p>
          <div className="fadeIn first mt-15">
            <input
              type="text"
              id="login"
              className="fadeIn second"
              name="login"
              placeholder="Email"
              onChange={handleEmailChange}
            />
            {error && error.email && (
              <p className="error-text">{error.email}</p>
            )}
            <input
              type="password"
              id="password"
              className="fadeIn third"
              name="login"
              placeholder="Password"
              onChange={handlePasswordChange}
            />
            {error && error.password && (
              <p className="error-text">{error.password}</p>
            )}
              <p onClick={showEmailModal} className="forgot-password-text">Forgot Password ?</p>
           <span>
            <button
              type="submit"
              className="fadeIn fourth login-btn"
              value="Log In"
              onClick={handleLogin}
              required
            >
             <div className="d-flex justify-center aligin-center"> <span>Log In </span> <span>{isLoginLoading && <Loader button={true} topPosition={"top-30"}/>} </span>  </div>
            </button>
            </span>
          </div>
        </div>
      </div>
    </form>
    {modalShow &&   <EmailModal
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            />}
    </>
  );
};

export default AdminLogin;
