import React, { useState, useEffect } from "react";
import { selectedCostApproved ,updateApprovedCost } from "../../store/actions/service-request";
import { useDispatch } from "react-redux";
import { Dropdown, DropdownButton, Form ,Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { serviceRequest } from "../../service/service-request";
import { toast } from "react-toastify";


 const tempObj = {
       questionnaireId :"",
       developmentCost : [] ,
       marketingCost : [],
       incubationCost : [],
       advertisingCost:[],
       designCost:[],
    }

const DisplayAdminApprovedCost = ({ serviceRequestStatus,approvedCost }) => {

  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedApprovedCost ,setSelectedApprovedCost] = useState(tempObj)
  const [costApproved,setCostApproved] = useState()
  const [serviceCost,setServiceCost] = useState(serviceRequestStatus)
  const [devStatus , setDevStatus] = useState(false)
  const [disableCheck , setDisableCheck] = useState(false)
 

  const updateStatus = (costId, questionnaireId) => {
    dispatch(selectedCostApproved(costId, questionnaireId));
    history.push("/admindashboard");
  };

  const displayQuestionnaireStatus = (costId, questionnaireId, status) => {
    let titleStatus = status === "pending" ? "Pending" : status;
    return (
      <DropdownButton
        style={{marginRight:"10px"}}
        id="dropdown-item-button"
        title={titleStatus}
        onSelect={(e) => {
          updateStatus(costId, questionnaireId);
        }}
      >
        <Dropdown.Item  key="approved" eventKey="approved" as="button">
          Approved
        </Dropdown.Item>
        {/* <Dropdown.Item key={"project"} eventKey="project" as="button">
      Project
    </Dropdown.Item> */}
        <Dropdown.Item key={"pending"} eventKey="pending" as="button">
          Pending
        </Dropdown.Item>
      </DropdownButton>
    );
  };


 
  
 
  const onCostApproved = (type,list , category ,listItem) => {
    let temp = {
            userId : listItem.userId,
            organization : listItem.organization,
            category,
            cost : list[0][category],
            time : list[0][`${category}Timeline`],
    }
    const checkExist = obj => obj.category === category  ;
    if(costApproved[type].some(checkExist))
    {
       const checkSame = obj => obj.category === category && obj.userId === listItem.userId
       if(costApproved[type].some(checkSame)){
               const filterdArr = costApproved[type].filter(item => (item.category !== category ))
               setCostApproved((preState)=>( { ...preState , [type] : filterdArr }) )
       }else {
               const filterdArr = costApproved[type].filter(item => (item.category !== category ))
               filterdArr.push(temp)
                setCostApproved((preState)=>( { ...preState , [type] : filterdArr }) )
       }
      
    } else {
       
       
        //setCostApproved({...costApproved ,  [type]:[...costApproved[type] , temp]})
          setCostApproved((preState)=>( { ...preState , [type]:[...costApproved[type] , temp]}) )
    }
    setCostApproved((preState)=>( { ...preState , questionnaireId : listItem.questionnaireId }) )
   }
   
    const onSaveClick = (listItem) => {
       const {questionnaireId  } = listItem
       dispatch(updateApprovedCost(costApproved , questionnaireId))
      setTimeout(()=>{
      //history.push("/admindashboard")
       },[500])

  }

  useEffect(()=>{
     setServiceCost(serviceRequestStatus)
     setCostApproved(approvedCost && approvedCost[0])
  },[serviceRequestStatus ,approvedCost])

  const onFinalAppove = (listItem) =>{
    console.log(costApproved.developmentCost.map(x => x.category).length , Object.keys(serviceCost[0].developmentCost[0]).filter(x => !x.includes('Timeline')).length)
    if(
      (costApproved && costApproved.developmentCost.map(x => x.category).length === Object.keys(serviceCost[0].developmentCost[0]).filter(x => !x.includes('Timeline')).length)
   && (costApproved && costApproved.advertisingCost.map(x => x.category).length ===  Object.keys(serviceCost[0].advertisingCost[0]).filter(x => !x.includes('Timeline')).length)
   && (costApproved && costApproved.marketingCost.map(x => x.category).length ===  Object.keys(serviceCost[0].marketingCost[0]).filter(x => !x.includes('Timeline')).length)
   &&  (costApproved && costApproved.designCost.map(x => x.category).length ===  Object.keys(serviceCost[0].designCost[0]).filter(x => !x.includes('Timeline')).length)
   && (costApproved && costApproved.incubationCost.map(x => x.category).length ===  Object.keys(serviceCost[0].incubationCost[0]).filter(x => !x.includes('Timeline')).length)
   ){
      const {_id , questionnaireId  } = listItem
      setDisableCheck(true)
      dispatch(selectedCostApproved(_id, questionnaireId));
 }else {
   toast.error("please approve one cost from every field ")
 }
   
  }
 

 
  
 
  const handleApprovedCost = (type,list , category ,listItem) => {
  const result =   costApproved &&  costApproved[type].filter(x => (x.userId === listItem.userId && x.category === category)) 
  if(result !== undefined && result.length !==0 ){
     return   result.filter(x => x.category === category && result.userId === listItem.userId)
   }
   if(result && result.length !== 0){
      return   (result[0].category === category && result.userId === listItem.userId)
   }

  }

 


  return (
    <>
    <div className="overflow-y">
       <table style={{width:"80vw"}}>
       {serviceCost && serviceCost.map((listItem,index)=>{
          return(
                 <>
                 <div className="filedCost " >
                  
                    {index === 0 && 
                    <tr className="white-space" >
                     <span> <th className="th-width-org">Organization </th> </span>
                    {Object.keys(listItem.marketingCost[0]).length !==0 ? <span>{listItem.marketingCost.map((subItem) =>  Object.keys(subItem))[0].filter(name => !name.includes('Timeline')).map(x => <th className="th-width"> {`${x} / time(months)`} </th>)} </span> : null}
                    {Object.keys(listItem.advertisingCost[0]).length !==0 ? <span>{listItem.advertisingCost.map((subItem) =>  Object.keys(subItem))[0].filter(name => !name.includes('Timeline')).map(x => <th className="th-width"> {`${x} / time(months)`}</th>)} </span> : null}
                    {Object.keys(listItem.developmentCost[0]).length !==0 ? <span>{listItem.developmentCost.map((subItem) =>  Object.keys(subItem))[0].filter(name => !name.includes('Timeline')).map(x => <th  className="th-width" > {`${x} / time(months)`} </th>)} </span> : null} 
                    {Object.keys(listItem.incubationCost[0]).length !==0 ? <span>{listItem.incubationCost.map((subItem) =>  Object.keys(subItem))[0].filter(name => !name.includes('Timeline')).map(x => <th className="th-width"> {`${x} / time(months)`} </th>)} </span> : null}
                    {Object.keys(listItem.designCost[0]).length !==0 ? <span>{listItem.designCost.map((subItem) =>  Object.keys(subItem))[0].filter(name => !name.includes('Timeline')).map(x => <th className="th-width"> {`${x} / time(months)`} </th>)} </span> : null}
                    {listItem.consultingCost?  Object.keys(listItem.consultingCost[0]).length !==0 ? <span>{listItem.consultingCost.map((subItem) =>  Object.keys(subItem))[0].filter(name => !name.includes('Timeline')).map(x => <th className="th-width"> {`${x} / time(months)`} </th>)} </span> : null : null}
                    </tr>
                    }
                    <tr className="white-space" >
                   <span calss><td className="border-gray th-width-org" style={{paddingTop:"11px",paddingBottom:"11px"}}>{listItem.organization} </td> </span>
                   
                   {Object.keys(listItem.marketingCost[0]).length !==0 ?
                     <span>{listItem.marketingCost.map(subItem => Object.keys(subItem))[0]
                      .filter(name => !name.includes('Timeline'))
                      .map(x =>
                    <th className="th-width" style={{padding:"11px"}}>
                     <span className={`td-disabled mr-10 ${handleApprovedCost("marketingCost",listItem.developmentCost, x ,listItem)  ?   "border-green" : ""} `}>   
                    <input
                    type="text"
                    className="all-unset"
                     value={listItem.marketingCost[0][x]}
                    disabled
                  />  
                   <input
                     type="checkbox" 
                      onChange={()=>{onCostApproved("marketingCost",listItem.marketingCost, x ,listItem)}}
                       checked={handleApprovedCost("marketingCost",listItem.developmentCost, x ,listItem)}
                       disabled={serviceCost.map(cost =>  cost.status).includes("approved")}
                     />
                     </span>
                     <span className={`td-disabled ${handleApprovedCost("marketingCost",listItem.developmentCost, x ,listItem)  ?   "border-green" : ""}`}> 
                    <input
                    style={{width:"50px"}}
                    type="text"
                    className="all-unset"
                     value={listItem.marketingCost[0][`${x}Timeline`]}
                    disabled
                  />  
                     </span>

                      </th>)} 
                      </span> : null}

                    {Object.keys(listItem.advertisingCost[0]).length !==0 ? 
                    <span>{listItem.advertisingCost.map(subItem => Object.keys(subItem))[0]
                      .filter(name => !name.includes('Timeline'))
                      .map(x =>
                     <th className="th-width" style={{padding:"11px"}}>
                    <span className={`td-disabled mr-10 ${handleApprovedCost("advertisingCost",listItem.developmentCost, x ,listItem)  ?   "border-green" : ""} `}> 
                    <input
                    type="text"
                    className="all-unset"
                     value={listItem.advertisingCost[0][x]}
                    disabled
                  />  
                   <input
                     type="checkbox" 
                     onChange={()=>{onCostApproved("advertisingCost",listItem.advertisingCost, x ,listItem)}}
                     checked={handleApprovedCost("advertisingCost",listItem.developmentCost, x ,listItem)}
                     disabled={serviceCost.map(cost =>  cost.status).includes("approved")}
                     />
                     </span>
                     <span className={`td-disabled ${handleApprovedCost("advertisingCost",listItem.developmentCost, x ,listItem)  ?   "border-green" : ""}`}> 
                    <input
                    style={{width:"50px"}}
                    type="text"
                    className="all-unset"
                     value={listItem.advertisingCost[0][`${x}Timeline`]}
                    disabled
                  />  
                     </span>


                       
                       </th>)} 
                       </span> : null}
                       {Object.keys(listItem.developmentCost[0]).length !==0 ? 
                    <span>
                    {listItem.developmentCost.map(subItem => Object.keys(subItem))[0]
                    .filter(name => !name.includes('Timeline'))
                    .map(x =>
                     <th className="th-width" style={{padding:"11px"}} > 
                     <span className={`td-disabled  mr-10 ${handleApprovedCost("developmentCost",listItem.developmentCost, x ,listItem)  ?   "border-green" : ""} `}>
                    <input
                    type="text"
                    className=" all-unset"
                    value={listItem.developmentCost[0][x]}
                    disabled
                  />  
                
                   <input
                     type={ `checkbox` } 
                     onChange={()=>{onCostApproved("developmentCost",listItem.developmentCost, x ,listItem)}}
                     checked={handleApprovedCost("developmentCost",listItem.developmentCost, x ,listItem)}
                     disabled={serviceCost.map(cost =>  cost.status).includes("approved")}
                     />
                    </span>
                    <span className={`td-disabled ${handleApprovedCost("developmentCost",listItem.developmentCost, x ,listItem)  ?   "border-green" : ""}`}> 
                    <input
                    style={{width:"50px"}}
                    type="text"
                    className="all-unset"
                     value={listItem.developmentCost[0][`${x}Timeline`]}
                    disabled
                     />  
                     </span>

                  
                  </th>)}
                   </span> : null}

                    {Object.keys(listItem.incubationCost[0]).length !==0 ? 
                    <span>{listItem.incubationCost.map(subItem => Object.keys(subItem))[0]
                      .filter(name => !name.includes('Timeline'))
                      .map(x => 
                    <th className="th-width" style={{padding:"11px"}}> 
                     <span className={`td-disabled mr-10 ${handleApprovedCost("incubationCost",listItem.developmentCost, x ,listItem)  ?   "border-green" : ""} `}>  
                    <input
                    type="text"
                    className="all-unset"
                     value={listItem.incubationCost[0][x]}
                    disabled
                  />  
                    {!x.includes("Timeline") && <input
                     type="checkbox" 
                       onChange={()=>{onCostApproved("incubationCost",listItem.incubationCost, x ,listItem)}}
                       checked={handleApprovedCost("incubationCost",listItem.developmentCost, x ,listItem)}
                       disabled={serviceCost.map(cost =>  cost.status).includes("approved")}
                     />}
                     </span>
                     <span className={`td-disabled ${handleApprovedCost("incubationCost",listItem.developmentCost, x ,listItem)  ?   "border-green" : ""}`}> 
                    <input
                    style={{width:"50px"}}
                    type="text"
                    className="all-unset"
                     value={listItem.incubationCost[0][`${x}Timeline`]}
                    disabled
                     />  
                     </span>
                     
                     
                      </th>)}
                       </span> : null} 
                  
                      {Object.keys(listItem.designCost[0]).length !==0 ? 
                    <span>
                      {listItem.designCost.map(subItem => Object.keys(subItem))[0]
                      .filter(name => !name.includes('Timeline'))
                      .map(x => 
                      <th className="th-width" style={{padding:"11px"}}> 
                     <span className={`td-disabled mr-10 ${handleApprovedCost("designCost",listItem.developmentCost, x ,listItem)  ?   "border-green" : ""} `}> 
                    <input
                    type="text"
                    className="all-unset"
                    value={listItem.designCost[0][x]}
                    disabled
                  />  
                  <input
                     type="checkbox" 
                      onChange={()=>{onCostApproved("designCost",listItem.designCost, x ,listItem)}}
                       checked={handleApprovedCost("designCost",listItem.developmentCost, x ,listItem)}
                       disabled={serviceCost.map(cost =>  cost.status).includes("approved")}
                     />
                    </span>
                     <span className={`td-disabled mr-10 ${handleApprovedCost("designCost",listItem.developmentCost, x ,listItem)  ?   "border-green" : ""}`}> 
                    <input
                    style={{width:"50px"}}
                    type="text"
                    className="all-unset"
                    value={listItem.designCost[0][`${x}Timeline`]}
                    disabled
                     />  
                     </span>
                      </th>)} 
                      </span> : null}

                      {listItem.consultingCost ? <>  {Object.keys(listItem.consultingCost[0]).length !==0 ? 
                    <span>
                      {listItem.consultingCost.map(subItem => Object.keys(subItem))[0]
                      .filter(name => !name.includes('Timeline'))
                      .map(x => 
                      <th className="th-width" style={{padding:"11px"}}> 
                     <span className={`td-disabled mr-10 ${handleApprovedCost("consultingCost",listItem.developmentCost, x ,listItem)  ?   "border-green" : ""} `}> 
                    <input
                    type="text"
                    className="all-unset"
                    value={listItem.consultingCost[0][x]}
                    disabled
                  />  
                  <input
                     type="checkbox" 
                      onChange={()=>{onCostApproved("consultingCost",listItem.consultingCost, x ,listItem)}}
                       checked={handleApprovedCost("consultingCost",listItem.developmentCost, x ,listItem)}
                       disabled={serviceCost.map(cost =>  cost.status).includes("approved")}
                     />
                    </span>
                     <span className={`td-disabled mr-10 ${handleApprovedCost("consultingCost",listItem.developmentCost, x ,listItem)  ?   "border-green" : ""}`}> 
                    <input
                    style={{width:"50px"}}
                    type="text"
                    className="all-unset"
                     value={listItem.consultingCost[0][`${x}Timeline`]}
                    disabled
                     />  
                     </span>
                      </th>)} 
                      </span> : null}</> : null}


                     
                    </tr>
                  
              
               </div>
                 {index === serviceCost.length - 1  && <div>
                  <Button style={{margin:"10px"}} onClick= {()=>onSaveClick(listItem)}>Approve cost</Button>
                  <Button onClick={() => onFinalAppove(listItem)}>Final approve cost</Button>
          </div>}
               </>
               )
               
       }) }
          </table>
         
       
    </div>
         

    </>
          
           
  )              
  
};

export default DisplayAdminApprovedCost;
