import React, { useState, useEffect } from "react";
import { selectedCostApproved ,updateApprovedCost } from "../../store/actions/service-request";
import { useDispatch } from "react-redux";
import { Dropdown, DropdownButton, Form ,Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { serviceRequest } from "../../service/service-request";
import { toast } from "react-toastify";
import _ from 'lodash'


 const tempObj = {
       questionnaireId :"",
       developmentCost : [] ,
       marketingCost : [],
       incubationCost : [],
       advertisingCost:[],
       designCost:[],
       consultingCost:[],
    }

const DisplayAdminFilledCost = ({ serviceRequestStatus,approvedCost }) => {

  const dispatch = useDispatch();
  const history = useHistory();
 
  const [selectedApprovedCost ,setSelectedApprovedCost] = useState(tempObj)
  const [costApproved,setCostApproved] = useState(approvedCost)
  const [serviceCost,setServiceCost] = useState(serviceRequestStatus)
  const [devStatus , setDevStatus] = useState(false)
 

  const updateStatus = (costId, questionnaireId) => {
    dispatch(approvedCost(costId, questionnaireId));
    history.push("/admindashboard");
  };

  const displayQuestionnaireStatus = (costId, questionnaireId, status) => {
    let titleStatus = status === "pending" ? "Pending" : status;
    return (
      <DropdownButton
        style={{marginRight:"10px"}}
        id="dropdown-item-button"
        title={titleStatus}
        onSelect={(e) => {
          updateStatus(costId, questionnaireId);
        }}
      >
        <Dropdown.Item  key="approved" eventKey="approved" as="button">
          Approved
        </Dropdown.Item>
        {/* <Dropdown.Item key={"project"} eventKey="project" as="button">
      Project
    </Dropdown.Item> */}
        <Dropdown.Item key={"pending"} eventKey="pending" as="button">
          Pending
        </Dropdown.Item>
      </DropdownButton>
    );
  };

  
 
  const onCostApproved = (type,list , category ,listItem) => {

    let temp = {
            userId : listItem.userId,
            organization : listItem.organization,
            category,
            cost : list[0][category],
            time : list[0][`${category}Timeline`],
    }

    console.log("temp===",temp)
       
  
       const checkExist = obj => obj.category === category  ;
        if(selectedApprovedCost[type].some(checkExist)){
           const checkSame = obj => obj.category === category && obj.userId === listItem.userId
           if(selectedApprovedCost[type].some(checkSame)) {
             
              const filterdArr = selectedApprovedCost[type].filter(item => item.category !== category )
               setSelectedApprovedCost({...selectedApprovedCost ,  [type]:filterdArr})
           } else {
               const filterdArr = selectedApprovedCost[type].filter(item => item.category !== category)
               filterdArr.push(temp)
               setSelectedApprovedCost({...selectedApprovedCost ,  [type]:filterdArr})
           }
        } else {
         setSelectedApprovedCost({...selectedApprovedCost ,  [type]:[...selectedApprovedCost[type] , temp]})
        }
          setSelectedApprovedCost((preState)=>( { ...preState , questionnaireId : listItem.questionnaireId }) )

           
   }
  
  

  

  const onSaveClick = (listItem) => {
     const {questionnaireId  } = listItem
     
    // if(
    //      (selectedApprovedCost && selectedApprovedCost.developmentCost.map(x => x.category).length ===  Object.keys(serviceCost[0].developmentCost[0]).length)
    //   && (selectedApprovedCost && selectedApprovedCost.advertisingCost.map(x => x.category).length ===  Object.keys(serviceCost[0].advertisingCost[0]).length)
    //   && (selectedApprovedCost && selectedApprovedCost.marketingCost.map(x => x.category).length ===  Object.keys(serviceCost[0].marketingCost[0]).length)
    //   &&  (selectedApprovedCost && selectedApprovedCost.designCost.map(x => x.category).length ===  Object.keys(serviceCost[0].designCost[0]).length)
    //   && (selectedApprovedCost && selectedApprovedCost.incubationCost.map(x => x.category).length ===  Object.keys(serviceCost[0].incubationCost[0]).length)
    //   ){
    //      dispatch(updateApprovedCost(selectedApprovedCost))
    //      setTimeout(()=>{
    //      history.push("/admindashboard")
    //      },[500])
      
    // }else {
    //   toast.error("please approve one cost from every field ")
    // }

     dispatch(updateApprovedCost(selectedApprovedCost ,questionnaireId))
         setTimeout(()=>{
         //history.push("/admindashboard")
         },[500])

  }

  useEffect(()=>{
     
     setServiceCost(serviceRequestStatus)
     setCostApproved(approvedCost)
  },[serviceRequestStatus ,approvedCost])
 

 

 
  const handleApprovedCost = (type,list , category ,listItem) => {
   const result =  selectedApprovedCost[type].filter(x => x.userId === listItem.userId && x.category === category)
   if(result !== undefined && result.length !==0 ){
     return   result.filter(x => x.category === category)
   }
   if(result && result.length !== 0){
      return   result[0].category === category
   }
  }

   const onFinalAppove = (listItem) =>{
    //history.push("/admindashboard");
    if(
         (selectedApprovedCost && selectedApprovedCost.developmentCost.map(x => x.category).length ===  Object.keys(serviceCost[0].developmentCost[0]).length)
      && (selectedApprovedCost && selectedApprovedCost.advertisingCost.map(x => x.category).length ===  Object.keys(serviceCost[0].advertisingCost[0]).length)
      && (selectedApprovedCost && selectedApprovedCost.marketingCost.map(x => x.category).length ===  Object.keys(serviceCost[0].marketingCost[0]).length)
      &&  (selectedApprovedCost && selectedApprovedCost.designCost.map(x => x.category).length ===  Object.keys(serviceCost[0].designCost[0]).length)
      && (selectedApprovedCost && selectedApprovedCost.incubationCost.map(x => x.category).length ===  Object.keys(serviceCost[0].incubationCost[0]).length)
      && (selectedApprovedCost && selectedApprovedCost.consultingCost.map(x => x.category).length ===  Object.keys(serviceCost[0].consultingCost[0]).length)
      ){
        const {_id , questionnaireId  } = listItem
          dispatch(selectedCostApproved(_id, questionnaireId));
        
      
    }else {
      toast.error("please approve one cost from every field ")
    }
  }

  

  return (
    <>
    <div className="overflow-y">
       <table style={{width:"80vw"}}>
       {serviceCost && serviceCost.map((listItem,index)=>{
          return(
                 <>
                 <div className="filedCost " >
                  
                    {index === 0 && 
                    <tr className="white-space" >
                     <span> <th className="th-width-org">Organization </th> </span>
                    {Object.keys(listItem.marketingCost[0]).length !==0 ? <span>{listItem.marketingCost.map((subItem) =>  Object.keys(subItem))[0].filter(name => !name.includes('Timeline')).map(x => <th className="th-width"> {`${x} / time(months)`} </th>)} </span> : null}
                    {Object.keys(listItem.advertisingCost[0]).length !==0 ? <span>{listItem.advertisingCost.map((subItem) =>  Object.keys(subItem))[0].filter(name => !name.includes('Timeline')).map(x => <th className="th-width"> {`${x} / time(months)`} </th>)} </span> : null}
                    {Object.keys(listItem.developmentCost[0]).length !==0 ? <span>{listItem.developmentCost.map((subItem) =>  Object.keys(subItem))[0].filter(name => !name.includes('Timeline')).map(x => <th  className="th-width" > {`${x} / time(months)`} </th>)} </span> : null} 
                    {Object.keys(listItem.incubationCost[0]).length !==0 ? <span>{listItem.incubationCost.map((subItem) =>  Object.keys(subItem))[0].filter(name => !name.includes('Timeline')).map(x => <th className="th-width"> {`${x} / time(months)`} </th>)} </span> : null}
                    {Object.keys(listItem.designCost[0]).length !==0 ? <span>{listItem.designCost.map((subItem) =>  Object.keys(subItem))[0].filter(name => !name.includes('Timeline')).map(x => <th className="th-width"> {`${x} / time(months)`} </th>)} </span> : null}
                    {listItem.consultingCost?  Object.keys(listItem.consultingCost[0]).length !==0 ? <span>{listItem.consultingCost.map((subItem) =>  Object.keys(subItem))[0].filter(name => !name.includes('Timeline')).map(x => <th className="th-width"> {`${x} / time(months)`} </th>)} </span> : null : null}
                    </tr>
                    }
                    <tr className="white-space" >
                   <span calss><td className="border-gray th-width-org" style={{paddingTop:"11px",paddingBottom:"11px"}}>{listItem.organization} </td> </span>
                   {Object.keys(listItem.marketingCost[0]).length !==0 ?
                    
                     <span>{listItem.marketingCost.map(subItem => Object.keys(subItem))[0].filter(name => !name.includes('Timeline')).map(x =>
                    <th className="th-width " style={{padding:"11px"}}>
                    <span className="td-disabled mr-10"> 
                    <input
                    type="text"
                    className="all-unset"
                     value={listItem.marketingCost[0][x]}
                    disabled
                  />  
                    <input
                     type="checkbox" 
                      onChange={()=>{onCostApproved("marketingCost",listItem.marketingCost, x ,listItem)}}
                       checked={handleApprovedCost("marketingCost",listItem.developmentCost, x ,listItem)}
                     />
                     </span>

                     <span className="td-disabled"> 
                    <input
                    style={{width:"50px"}}
                    type="text"
                    className="all-unset"
                     value={listItem.marketingCost[0][`${x}Timeline`]}
                    disabled
                  />  
                     </span>
                      </th>)} 
                      </span> : null}
                  
                   

                    {Object.keys(listItem.advertisingCost[0]).length !==0 ? 
                    <span>{listItem.advertisingCost
                      .map(subItem => Object.keys(subItem))[0]
                      .filter(name => !name.includes('Timeline'))
                      .map(x =>
                     <th className="th-width" style={{padding:"11px"}}>
                    <span className="td-disabled mr-10"> 
                    <input
                    type="text"
                    className="all-unset"
                     value={listItem.advertisingCost[0][x]}
                    disabled
                  />  
                    <input
                     type="checkbox" 
                     onChange={()=>{onCostApproved("advertisingCost",listItem.advertisingCost, x ,listItem)}}
                      checked={handleApprovedCost("advertisingCost",listItem.developmentCost, x ,listItem)}
                     />
                     </span>
                     <span className="td-disabled"> 
                    <input
                    style={{width:"50px"}}
                    type="text"
                    className="all-unset"
                     value={listItem.advertisingCost[0][`${x}Timeline`]}
                    disabled
                  />  
                     </span>

                       
                       </th>)} 
                       </span> : null}

                       {Object.keys(listItem.developmentCost[0]).length !==0 ? 
                    <span>
                    {listItem.developmentCost.map(subItem => Object.keys(subItem))[0].filter(name => !name.includes('Timeline')).map(x =>
                      
                     <th className="th-width" style={{padding:"11px"}} > 
                     <span className="td-disabled mr-10">
                    <input
                    type="text"
                    className=" all-unset"
                    value={listItem.developmentCost[0][x]}
                    disabled
                  />  
                 
                   <input
                     type={ `checkbox` } 
                     onChange={()=>{onCostApproved("developmentCost",listItem.developmentCost, x ,listItem)}}
                     checked={handleApprovedCost("developmentCost",listItem.developmentCost, x ,listItem)}
                     />
                    </span>
                    <span className="td-disabled"> 
                    <input
                    style={{width:"50px"}}
                    type="text"
                    className="all-unset"
                     value={listItem.developmentCost[0][`${x}Timeline`]}
                    disabled
                     />  
                     </span>
                    
                  
                  </th>)}
                   </span> : null}

                    {Object.keys(listItem.incubationCost[0]).length !==0 ? 
                    <span>{listItem.incubationCost.map(subItem => Object.keys(subItem))[0]
                      .filter(name => !name.includes('Timeline'))
                      .map(x => 
                    <th className="th-width" style={{padding:"11px"}}> 
                    <span className="td-disabled mr-10"> 
                    <input
                    type="text"
                    className="all-unset"
                     value={listItem.incubationCost[0][x]}
                    disabled
                  />  
                    <input
                     type="checkbox" 
                       onChange={()=>{onCostApproved("incubationCost",listItem.incubationCost, x ,listItem)}}
                       checked={handleApprovedCost("incubationCost",listItem.developmentCost, x ,listItem)}
                     />
                     </span>
                     <span className="td-disabled"> 
                    <input
                    style={{width:"50px"}}
                    type="text"
                    className="all-unset"
                     value={listItem.incubationCost[0][`${x}Timeline`]}
                    disabled
                     />  
                     </span>
                      </th>)}
                       </span> : null} 
                       {Object.keys(listItem.designCost[0]).length !==0 ? 
                    <span>
                      {listItem.designCost.map(subItem => Object.keys(subItem))[0]
                      .filter(name => !name.includes('Timeline'))
                      .map(x => 
                      <th className="th-width" style={{padding:"11px"}}> 
                    <span className="td-disabled mr-10"> 
                    <input
                    type="text"
                    className="all-unset"
                    value={listItem.designCost[0][x]}
                    disabled
                  />  
                   <input
                     type="checkbox" 
                      onChange={()=>{onCostApproved("designCost",listItem.consultingCost, x ,listItem)}}
                      checked={handleApprovedCost("designCost",listItem.developmentCost, x ,listItem)}
                     />
                    </span>
                    <span className="td-disabled mr-10"> 
                    <input
                    style={{width:"50px"}}
                    type="text"
                    className="all-unset"
                     value={listItem.designCost[0][`${x}Timeline`]}
                    disabled
                     />  
                     </span>

                      </th>)} 
                      </span> : null}

                      {listItem.consultingCost ? <>
                      {Object.keys(listItem.consultingCost[0]).length !==0 ? 
                    <span>
                      {listItem.consultingCost.map(subItem => Object.keys(subItem))[0]
                      .filter(name => !name.includes('Timeline'))
                      .map(x => 
                      <th className="th-width" style={{padding:"11px"}}> 
                    <span className="td-disabled mr-10"> 
                    <input
                    type="text"
                    className="all-unset"
                    value={listItem.consultingCost[0][x]}
                    disabled
                  />  
                   <input
                     type="checkbox" 
                      onChange={()=>{onCostApproved("consultingCost",listItem.consultingCost, x ,listItem)}}
                      checked={handleApprovedCost("consultingCost",listItem.developmentCost, x ,listItem)}
                     />
                    </span>
                    <span className="td-disabled mr-10"> 
                    <input
                    style={{width:"50px"}}
                    type="text"
                    className="all-unset"
                     value={listItem.consultingCost[0][`${x}Timeline`]}
                    disabled
                     />  
                     </span>

                      </th>)} 
                      </span> : null}

                      </> : null}

                      
                    
                    </tr>
                  
              
               </div>
               {index === serviceCost.length - 1  && <div>
                  <Button style={{margin:"10px"}} onClick= {() => onSaveClick(listItem)}>Approve cost</Button>
                  <Button onClick={() => onFinalAppove(listItem)}>Final approve cost</Button>
          </div>}
               </>
               )
       }) }
          </table>
        
         
       
    </div>
         

    </>
          
           
  )              
  
};

export default DisplayAdminFilledCost;
