import React, { useState,useEffect } from "react";
import { Card, Header, Body,Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getKanban } from "../../store/actions/portal-report";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Loader from '../../components/loader';
import addDays from 'date-fns/addDays'







const PorjectPortalReporting = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [startDate, setStartDate] = useState(moment(new Date).startOf('isoweek')._d);
  const [endDate, setEndDate] = useState(moment(new Date).endOf('week')._d);
  const csvData = useSelector((state)=>state.portal.kanban && state.portal.kanban.csvData)
   const issuesCsvData = useSelector((state)=>state.portal.kanban && state.portal.kanban.issuesCsvData)
   const processedData = useSelector((state)=> state.portal.kanban && state.portal.kanban.processedData)
   const issuesProcessedData = useSelector((state)=>state.portal.kanban && state.portal.kanban.issuesProcessedData)
  const [csvDataTow,setCsvDataTwo] = useState(csvData)
  const [processDataTwo , setProcessDataTwo]= useState(processedData)
  const [issuesProcessedDataTwo,setIssuesProcessedDataTwo] =useState(issuesProcessedData)
  const projectId = props.history.location.state ? props.history.location.state.projectId : null
  const params =  {
    startDate: moment(startDate).format('YYYY-MM-DD'),
    endDate:moment(endDate).format('YYYY-MM-DD'),
  }

  const kanbanLoading = useSelector((state)=>state.portal.isKanbanLoading)

  // const onDateChange = (update) => {
  //   setDateRange(update);
  // }
   const kabanReport = () => {
     dispatch(getKanban(projectId,params ))
  }

  useEffect(()=>{
   setCsvDataTwo(csvData)
  },[csvData])

  useEffect(()=>{
    setIssuesProcessedDataTwo(issuesProcessedData)
  },[issuesProcessedData])

  useEffect(()=>{
    setProcessDataTwo(processedData)
  
   },[processedData])



  

 const  efficacy =()=> {
    const newByComplete = (processDataTwo.complete_week.new / processDataTwo.complete_week.done);
    const efficacy = ( newByComplete * (1 + 0.1) ** processDataTwo.complete_week.stuck )/ (0.5)**processDataTwo.complete_week.done_late;
    return isNaN(efficacy) || !isFinite(efficacy) ? 0 : efficacy;
  }

  const issuesEfficacy = () => {
    const efficacy =
      this.issuesProcessedDataTwo.complete_week.new /
      this.issuesProcessedDataTwo.complete_week.done;
    return isNaN(efficacy) || !isFinite(efficacy) ? 0 : efficacy;
  }

  useEffect(()=>{
    if(projectId == null){
      history.push("/admindashboard")
    }
  })

  const onBackClick = () => {
    history.push("/project")
  }

  const isMonday = (date: Date) => {
    const day = date.getDay()
    return day ===1
  }

  const isSunday = (date: Date) => {
    const day = date.getDay()
    return day === 0
  }

  useEffect(()=>{
     setEndDate(addDays(startDate, 6))
  },[startDate])




  
  return (
    <>
    
        <Card>
        <Card.Header>Portal Reporting</Card.Header>
         
        <Card.Body className="mx-20 my-20">
          <h2 className="margin-left-none">Mmi Funnel Portal</h2>
          <div className="d-flex">
          <div style={{marginRight:"20px"}}>
          <>
                 <h6>Start Date</h6>
                 <DatePicker
                  style={{width:"150px"}}
                  selected={startDate}
                  onChange={(date) => setStartDate(date) }
                  dateFormat="dd/MM/yyyy"
                  filterDate={isMonday}
                  />
                  </>
          </div>
          <div>
                <h6>End Date</h6>
                <DatePicker selected={endDate}
                 minDate={startDate}
                 onChange={(date) =>   setEndDate(date)} 
                 dateFormat="dd/MM/yyyy"
                 filterDate={isSunday}
                 maxDate={addDays(startDate, 6)}
                 
                 /> 
                <Button style={{fontSize:"10px",marginBottom:"5px"}} onClick={kabanReport}>Submit</Button>
                 
          </div>
   
    
          </div>
          <div className="my-20">
            <span> <h4>Kanban </h4></span>
            <div> <h4>Day Wise Distribution </h4></div>
          </div>
         {kanbanLoading === false ?  <> 
          <table id="adminDashboard" className="portalReporting">
            <tr>
              <th></th>
              <th>MONDAY</th>
              <th>TUESDAY</th>
              
              <th>WEDNESDAY</th>
              <th>THURSDAY</th>
              <th>FRIDAY</th>
              <th>SATURDAY</th>
              <th> SUNDAY </th>
              <th>TOTAL</th>
            </tr>
            {processDataTwo !== undefined &&<>
            <tr> 
              <td>New</td>
              <td>{processDataTwo.monday.new}</td>
             <td>  {processDataTwo.tuesday.new}  </td> 
             <td>  {processDataTwo.wednesday.new}  </td> 
             <td>  {processDataTwo.thursday.new}  </td> 
             <td>  {processDataTwo.friday.new}  </td> 
             <td>  {processDataTwo.saturday.new}  </td> 
             <td>  {processDataTwo.sunday.new}  </td> 
             <td>{processDataTwo.complete_week.new}</td>

            
            </tr>
            <tr />
            <tr>
              <td>In Progress</td>
              <td>{processDataTwo.monday.in_progress}</td>
             <td>  {processDataTwo.tuesday.in_progress}  </td> 
             <td>  {processDataTwo.wednesday.in_progress}  </td> 
             <td>  {processDataTwo.thursday.in_progress}  </td> 
             <td>  {processDataTwo.friday.in_progress}  </td> 
             <td>  {processDataTwo.saturday.in_progress}  </td> 
             <td>  {processDataTwo.sunday.in_progress}  </td> 
             <td>{processDataTwo.complete_week.in_progress}</td>
            </tr>
            <tr>
              <td>Ready For Test</td>
              <td>{processDataTwo.monday.ready_for_test}</td>
             <td>  {processDataTwo.tuesday.ready_for_test}  </td> 
             <td>  {processDataTwo.wednesday.ready_for_test}  </td> 
             <td>  {processDataTwo.thursday.ready_for_test}  </td> 
             <td>  {processDataTwo.friday.ready_for_test}  </td> 
             <td>  {processDataTwo.saturday.ready_for_test}  </td> 
             <td>  {processDataTwo.sunday.ready_for_test}  </td> 
             <td>{processDataTwo.complete_week.ready_for_test}</td>
            </tr>
            <tr>
              <td>Stuck</td>
              <td>{processDataTwo.monday.stuck}</td>
             <td>  {processDataTwo.tuesday.stuck}  </td> 
             <td>  {processDataTwo.wednesday.stuck}  </td> 
             <td>  {processDataTwo.thursday.stuck}  </td> 
             <td>  {processDataTwo.friday.stuck}  </td> 
             <td>  {processDataTwo.saturday.stuck}  </td> 
             <td>  {processDataTwo.sunday.stuck}  </td> 
             <td>{processDataTwo.complete_week.stuck}</td>
            </tr>
            <tr>
              <td>Done</td>
              <td>{processDataTwo.monday.done}</td>
             <td>  {processDataTwo.tuesday.done}  </td> 
             <td>  {processDataTwo.wednesday.done}  </td> 
             <td>  {processDataTwo.thursday.done}  </td> 
             <td>  {processDataTwo.friday.done}  </td> 
             <td>  {processDataTwo.saturday.done}  </td> 
             <td>  {processDataTwo.sunday.done}  </td> 
             <td>{processDataTwo.complete_week.done}</td>
            </tr>
            <tr>
              <td>Done On Time</td>
              <td>{processDataTwo.monday.done_on_time}</td>
             <td>  {processDataTwo.tuesday.done_on_time}  </td> 
             <td>  {processDataTwo.wednesday.done_on_time}  </td> 
             <td>  {processDataTwo.thursday.done_on_time}  </td> 
             <td>  {processDataTwo.friday.done_on_time}  </td> 
             <td>  {processDataTwo.saturday.done_on_time}  </td> 
             <td>  {processDataTwo.sunday.done_on_time}  </td> 
             <td>{processDataTwo.complete_week.done_on_time}</td>
            </tr>
            </>}
          </table>
          
          <div className="mt-20"> <h4>Weekly Metrics </h4></div>
          <table id="adminDashboard" className="portalReporting">
            <tr>
            <th><h5 className="text-green mb-none">Efficacy</h5></th>
              <th><h5 className="text-blue mb-none">Throughput </h5></th>
            </tr>
          { processDataTwo !== undefined && <tr>
              <td>{ isNaN(efficacy) ? 0 : efficacy.toFixed(2) }</td>
              <td>{processDataTwo.complete_week.done.toFixed(2)}</td>
            </tr>}
          </table>
          </> : <Loader/>}

          <div style={{marginTop:"20px"}}>
            <span><h4>Issues</h4></span>
          
          </div>
         {kanbanLoading === false ?  <>
          <table id="adminDashboard" className="portalReporting">
            <tr>
              <th></th>
              <th>MONDAY</th>
              <th>TUESDAY</th>
              <th>WEDNESDAY</th>
              <th>THURSDAY</th>
              <th>FRIDAY</th>
              <th>SATURDAY</th>
              <th> SUNDAY </th>
              <th>TOTAL</th>
            </tr>
            {processDataTwo !== undefined &&<>
            <tr> 
              <td>New</td>
              <td>{issuesProcessedDataTwo.monday.new}</td>
             <td>  {issuesProcessedDataTwo.tuesday.new}  </td> 
             <td>  {issuesProcessedDataTwo.wednesday.new}  </td> 
             <td>  {issuesProcessedDataTwo.thursday.new}  </td> 
             <td>  {issuesProcessedDataTwo.friday.new}  </td> 
             <td>  {issuesProcessedDataTwo.saturday.new}  </td> 
             <td>  {issuesProcessedDataTwo.sunday.new}  </td> 
             <td>{issuesProcessedDataTwo.complete_week.new}</td>

            
            </tr>
            <tr />
            <tr>
              <td>In Progress</td>
              <td>{issuesProcessedDataTwo.monday.in_progress}</td>
             <td>  {issuesProcessedDataTwo.tuesday.in_progress}  </td> 
             <td>  {issuesProcessedDataTwo.wednesday.in_progress}  </td> 
             <td>  {issuesProcessedDataTwo.thursday.in_progress}  </td> 
             <td>  {issuesProcessedDataTwo.friday.in_progress}  </td> 
             <td>  {issuesProcessedDataTwo.saturday.in_progress}  </td> 
             <td>  {issuesProcessedDataTwo.sunday.in_progress}  </td> 
             <td>{issuesProcessedDataTwo.complete_week.in_progress}</td>
            </tr>
            <tr>
              <td>Ready For Test</td>
              <td>{issuesProcessedDataTwo.monday.ready_for_test}</td>
             <td>  {issuesProcessedDataTwo.tuesday.ready_for_test}  </td> 
             <td>  {issuesProcessedDataTwo.wednesday.ready_for_test}  </td> 
             <td>  {issuesProcessedDataTwo.thursday.ready_for_test}  </td> 
             <td>  {issuesProcessedDataTwo.friday.ready_for_test}  </td> 
             <td>  {issuesProcessedDataTwo.saturday.ready_for_test}  </td> 
             <td>  {issuesProcessedDataTwo.sunday.ready_for_test}  </td> 
             <td>{issuesProcessedDataTwo.complete_week.ready_for_test}</td>
            </tr>
            <tr>
              <td>Stuck</td>
              <td>{issuesProcessedDataTwo.monday.stuck}</td>
             <td>  {issuesProcessedDataTwo.tuesday.stuck}  </td> 
             <td>  {issuesProcessedDataTwo.wednesday.stuck}  </td> 
             <td>  {issuesProcessedDataTwo.thursday.stuck}  </td> 
             <td>  {issuesProcessedDataTwo.friday.stuck}  </td> 
             <td>  {issuesProcessedDataTwo.saturday.stuck}  </td> 
             <td>  {issuesProcessedDataTwo.sunday.stuck}  </td> 
             <td>{issuesProcessedDataTwo.complete_week.stuck}</td>
            </tr>
            <tr>
              <td>Done</td>
              <td>{issuesProcessedDataTwo.monday.done}</td>
             <td>  {issuesProcessedDataTwo.tuesday.done}  </td> 
             <td>  {issuesProcessedDataTwo.wednesday.done}  </td> 
             <td>  {issuesProcessedDataTwo.thursday.done}  </td> 
             <td>  {issuesProcessedDataTwo.friday.done}  </td> 
             <td>  {issuesProcessedDataTwo.saturday.done}  </td> 
             <td>  {issuesProcessedDataTwo.sunday.done}  </td> 
             <td>{issuesProcessedDataTwo.complete_week.done}</td>
            </tr>
            <tr>
              <td>Done On Time</td>
              <td>{issuesProcessedDataTwo.monday.done_on_time}</td>
             <td>  {issuesProcessedDataTwo.tuesday.done_on_time}  </td> 
             <td>  {issuesProcessedDataTwo.wednesday.done_on_time}  </td> 
             <td>  {issuesProcessedDataTwo.thursday.done_on_time}  </td> 
             <td>  {issuesProcessedDataTwo.friday.done_on_time}  </td> 
             <td>  {issuesProcessedDataTwo.saturday.done_on_time}  </td> 
             <td>  {issuesProcessedDataTwo.sunday.done_on_time}  </td> 
             <td>{issuesProcessedDataTwo.complete_week.done_on_time}</td>
            </tr>
            </>}
          </table>

          <div className="mt-20"><h4> Weekly Metrics</h4></div>
          <table id="adminDashboard" className="portalReporting">
            <tr>
              <th><h5 className="text-green mb-none">Efficacy</h5></th>
              <th><h5 className="text-blue mb-none">Throughput </h5></th>
            </tr>
            {issuesProcessedDataTwo !== undefined &&<tr>
              <td>{ isNaN(issuesEfficacy) ? 0.0 : issuesEfficacy.toFixed(2) }</td>
              <td>{issuesProcessedData && issuesProcessedData.complete_week.done.toFixed(2) }</td>
            </tr>}
          </table>
          </> : <Loader/>}
          <Button style={{fontSize:"10px",width:"100px",margin:"10px 10px 10px 0px"}} onClick={onBackClick}>
            <div style={{marginRight:"10px"}}>
          <span> <img  className="pdf-img" src="../assets/images/back.png" alt="" /></span>
          Back
          </div>
          </Button>

        </Card.Body>
        
      </Card>
      

    </>
  );
};

export default PorjectPortalReporting;
