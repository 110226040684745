import { filter } from "lodash";
import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import DisplayFilledCost from "./displayfilledcost";

const DisplayCost = ({
  questionnaireDetails,
  getSelectedData,
  onDropDownValueSelect,
  serviceRequestStatus,
  getQuestionnaire,
  getQuestionnaireDetailWithCost,
  costError,
  findParams,
  questionnaireLoading
}) => {
  const [developmentCost, setDevelopmentCost] = useState({});
  const [advertisingCost, setAdvertisingCost] = useState({});
  const [marketingCost, setMarketingCost] = useState({});
  const [designCost, setDesignCost] = useState({});
  const [incubationCost, setIncubationCost] = useState({});
  const [consultingCost, setConsultingCost] = useState({});
  const [organization, setOrganization] = useState("");
  const [error, setError] = useState(null);
  const [userId, setUserId] = useState(JSON.parse(localStorage.getItem("user_id")));
  const [hideFilledCost,setHideFilledCost] = useState(false)

  const addTimeLine = (serviceType) => {
    const arr = questionnaireDetails[serviceType]
    if(Array.isArray(arr)){
      return arr.concat(arr.map(item => `${item}Timeline`))
    } else {
      return ""
    }
  }
  const development = addTimeLine("developmentServices")
  const marketingServices = addTimeLine("marketingServices")
  const incubation = addTimeLine("incubationServices")
  const advertisingServices = addTimeLine('advertisingServices')
  const design = addTimeLine("designServices")
  const consultingService = addTimeLine("consultingServices")
 
  // const options = [
  //   ...development,
  //   ...marketingServices,
  //   ...incubation,
  //   ...design,
  //   ...advertisingServices,
  // ];
  const [data, setData] = useState({});

  const handleChange = (name, value, type) => {
    let key = name;
    if (type === "developmentServices") {
      setDevelopmentCost({
        ...developmentCost,
        [key]: value,
      });
    }
    if (type === "marketingServices") {
      setMarketingCost({
        ...marketingCost,
        [key]: value,
      });
    }
    if (type === "advertisingServices") {
      setAdvertisingCost({
        ...advertisingCost,
        [key]: value,
      });
    }
    if (type === "designServices") {
      setDesignCost({
        ...designCost,
        [key]: value,
      });
    }
    if (type === "incubationServices") {
      setIncubationCost({
        ...incubationCost,
        [key]: value,
      });
    }
    if (type === "consultingServices") {
       setConsultingCost({
        ...consultingCost,
        [key]: value,
      });
    }
  };




 

   
  const handleSubmit = () => {
        if(organization !== ""){
        if((marketingServices.length === Object.values(marketingCost).filter((e)=> e !=="").length) 
         && (development.length === Object.values(developmentCost).filter((e)=> e !=="").length)
         && (consultingService.length === Object.values(consultingCost).filter((e)=> e !=="").length)
         && (advertisingServices.length === Object.values(advertisingCost).filter((e)=> e !=="").length)
         && (design.length === Object.values(designCost).filter((e)=> e !=="").length)
         && (incubation.length === Object.values(incubationCost).filter((e)=> e !=="").length)
         && (consultingService.length === Object.values(consultingCost).filter((e)=> e !=="").length))
         
         {
         
        
        //  if((marketingServices === "" || JSON.stringify(marketingServices) === JSON.stringify(Object.keys(marketingCost)))
        //  &&(development === "" ||JSON.stringify(development) === JSON.stringify(Object.keys(developmentCost)))
        //  && (advertisingServices ===""  || JSON.stringify(advertisingServices) === JSON.stringify(Object.keys(advertisingCost)))
        //  && (design ==="" || JSON.stringify(design) === JSON.stringify(Object.keys(designCost)))
        //  && (incubation ==="" || JSON.stringify(incubation) === JSON.stringify(Object.keys(incubationCost))))
        //  {

          onDropDownValueSelect({
            organization,
            marketingCost,
            advertisingCost,
            developmentCost,
            incubationCost,
            designCost,
            consultingCost
          });
       
           
       
        }else {
            setError("Please fill all fields ");
        }
      }else {
          setError("Please fill  organization name");
      }
  };


 useEffect(()=>{
    

   if(serviceRequestStatus && serviceRequestStatus.length === 0){
     setHideFilledCost(true)
   }
   serviceRequestStatus && serviceRequestStatus.map((costList)=>{
     if(costList.userId === userId){
       setHideFilledCost(false)
     }
    //  if(costList.userId !== userId && serviceRequestStatus.length !==0){
    //    setHideFilledCost(true)
    //    console.log("third")
    //      console.log("hideFilledCost===",hideFilledCost)
    //  }
   })

   const list = serviceRequestStatus && serviceRequestStatus.map((costList)=>costList.userId === userId)

   if(list && list.includes(true)){
   }else{
       setHideFilledCost(true)
   }
    setUserId(JSON.parse(localStorage.getItem("user_id")));
 },[serviceRequestStatus])

//  console.log("hidefilledCost===",hideFilledCost)
  //console.log("costList===",serviceRequestStatus)
    // console.log("setUserId===",userId)
    // console.log("hide====",hideFilledCost)

  const randerTableData = (arr, type) => {
    // console.log("serviceRequestStatus===",serviceRequestStatus.map((costList)=> costList.userId === userId))
  
    return (
      <div>
        <div className={` displayCost ${serviceRequestStatus && serviceRequestStatus.length === 0 ? "my-20":""} `}>
         {serviceRequestStatus && serviceRequestStatus.length === 0 && 
         <tr>
          
            {arr &&
              arr.map((value, index) => {
                return <>  
                <th className="th-width table-height table-heading-center">{value}</th>
                
                </>
              })}
          </tr>}
          <tr className={`${serviceRequestStatus && serviceRequestStatus.length === 0 ? '' :'row-td-width'}`}>
            {arr &&
              arr.map((value, index) => {
                return (
                  <td className="">
                    {" "}
                    <input
                      type="text"
                      className={`outline-none ${serviceRequestStatus && serviceRequestStatus.length === 0 ? '' :'row-input-width'}`}
                      onChange={(e) => {
                        handleChange(value, e.target.value, type);
                        setError("");
                      }}
                    />
                  </td>
                );
              })}
          </tr>
        </div>
      </div>
    );
            
     
  };
   


  return (
      <div className="overflow-x  p-10">
    <>
      <h1 className="mt-15 mainHeading bg-white"> Service And Cost </h1>
     { <DisplayFilledCost
        serviceRequestStatus={serviceRequestStatus}
        development={development}
        marketingServices={marketingServices}
        incubation={incubation}
        design={design}
        advertisingServices={advertisingServices}
        consultingService={consultingService}
        randerTableData={randerTableData}
        findParams={findParams}
        getQuestionnaire={getQuestionnaire}
        getQuestionnaireDetailWithCost={getQuestionnaireDetailWithCost}
      />}
       <div> 
     <div>
      { !hideFilledCost || questionnaireDetails.status !== "approved" && (
        <>
          <div className={`w-100 service-table  filled-cost ${serviceRequestStatus && serviceRequestStatus.length === 0 ? 'my-20 mb-0':''}`}>
            <table>
              <tr>
                {!questionnaireLoading && serviceRequestStatus?.length === 0 && <td>Organization name</td>}
               {Object.keys(questionnaireDetails).map((costType,index)=>{
                if(Array.isArray(questionnaireDetails[costType]) && costType !== "servicesNeed" && costType !=='identifyServices' ){
                 return questionnaireDetails[costType].map((item,index2)=>{
                    return <>
                  { serviceRequestStatus?.length === 0
                    && <th className="th-width table-height table-heading-center">
                      <td className="filled-cost-border" style={{minWidth:'230px'}}>{`${item} / time(months)`}</td>
                    </th>}
                    </> 
                 })
                }
               })}
              </tr>
              <tr>
             {!questionnaireLoading && <td>
                     <input
                      type="text"
                      placeholder="organization name"
                      className={`outline-none ${serviceRequestStatus && serviceRequestStatus.length === 0 ? '' :'row-input-width'}`}
                      onChange={(e) => {
                        setOrganization(e.target.value);
                        setError("");
                      }}
                    />
                        </td>}
               {Object.keys(questionnaireDetails).map((costType)=>{
                if(Array.isArray(questionnaireDetails[costType]) && costType !== "servicesNeed" && costType !=='identifyServices' ){
                 return questionnaireDetails[costType].map((item,index)=>{
                    return <>
                    <th className="th-width table-height table-heading-center ">
                      <td className="filled-cost-border">
                      <input
                      type="number"
                      min={0}
                      placeholder="cost"
                      className={`outline-none inputnumber ${serviceRequestStatus && serviceRequestStatus.length === 0 ? '' :'row-input-width'}`}
                      onChange={(e) => {
                        handleChange(item, e.target.value, costType);
                        setError("");
                      }}
                    />
                      </td>
                      <td className="filled-cost-border ">
                      <input
                      style={{width:"109px"}}
                      type="number"
                      min={0}
                      placeholder="timeline"
                      className={`outline-none inputnumber ${serviceRequestStatus && serviceRequestStatus.length === 0 ? '' :'row-input-width'}`}
                      onChange={(e) => {
                        handleChange(`${item}Timeline`, e.target.value, costType);
                        setError("");
                      }}
                    />
                        </td>
                    </th>
                    </> 
                 })
                }
               })}
              </tr>
            </table>
           
          </div>
          <p className="text-red">{error}</p>
          {  
             <div className="d-flex justify-center">

            
           {!questionnaireLoading && <Button
              className="mt-15"
              style={{width:'120px',height:'40px',fontSize:'18px',backgroundColor:'#0e7999',borderRadius:'0',fontFamily:'Montserrat' ,margin:'0',padding:'0'}}
              onClick={handleSubmit}
              // disabled={
              //   Object.keys(designCost).length !== 0 ||
              //   Object.keys(marketingCost).length !== 0 ||
              //   Object.keys(incubationCost).length !== 0 ||
              //   Object.keys(advertisingCost).length !== 0 ||
              //   (Object.keys(developmentCost).length !== 0 &&
              //     organization !== "")
              //     ? false
              //     : true
              // }
            >
              Submit
            </Button>}
            </div>
          }
        </>
      )}
      </div>
       </div>
    
   
    </>
      </div> 
  );
};

export default DisplayCost;
