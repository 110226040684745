import {
    CREATE_AGENCY_USER_START,
    CREATE_AGENCY_USER_SUCCESS,
    CREATE_AGENCY_USER_FAILED,
    GET_ALL_USERS_START,
    GET_ALL_USERS_SUCCESS,
    GET_ALL_USERS_FAILED,
    GET_AGREEMENT_DOCFILE_NAME
  } from "../../constents/actionType";
  
  const initialState = {
    allUsers :[],
    isLoading:false,
    error : [],
    docFileName : null
  };
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case GET_ALL_USERS_START: {
        return {
          ...state,
          isLoading: true,
        };
      }
      case GET_ALL_USERS_SUCCESS: {
        return {
          ...state,
          isLoading: false,
          allUsers: action.payload,
        };
      }
      case GET_ALL_USERS_FAILED: {
        return {
          ...state,
          isLoading: false,
          error: action.payload,
        };
      }

      case GET_AGREEMENT_DOCFILE_NAME: {
        return {
          ...state,
          isLoading: false,
          docFileName: action.payload.filePath,
        };
      }
       
      default:
        return state;
    }
  };
  