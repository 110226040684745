import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getQuestionnaireAdminList } from "../../store/actions/questionnaire";
import DashboardDetailsTable from "./DashboardDetailsTable";
import UploadCsv from "../../dashboard/UploadCsv";
import {getAllCreateProjectList} from '../../store/actions/portal-report'
import "../../styles/dashboard.css";
import Loader from '../../components/loader'
import ReactPaginate from 'react-paginate';
import Switch from '@mui/material/Switch';
import {getTaigaProjectName} from '../../store/actions/portal-report'

const AdminDashboard = () => {
  const history = useHistory();
  const [toggle, setToggle] = useState(true);
  const questionnaireLoading = useSelector((state)=>state.questionnaire.questionnaireLoading)
  const [isLogin, setIsLogin] = useState(Cookies.get("userAccessToken"));
  const [postperpage,setPostperpage] = useState(10)
  const [currentpage,setCurrentPage] = useState(1)
  
  
  const [isAdmin, setIsAdmin] = useState(
    JSON.parse(localStorage.getItem("isAdmin"))
  );
  const dispatch = useDispatch();
  const [error, setError] = useState({
    orgName: "",
    identifyingService: "",
    serviceNeed: "",
    marketingService: "",
    advertisingService: "",
  });
  const adminQuestionnaireList = useSelector((state) => {
    return state.questionnaire && state.questionnaire.questionnaireAdminList;
  });

  
  const handleLogout = () => {
    localStorage.clear();
    Cookies.remove("userLogin");
    Cookies.remove("isAdmin")
    Cookies.remove("userAccessToken")
    Cookies.remove("isAdmin",{path:"/admin-servicecost"})
    Cookies.remove("userAccessToken",{path:"/admin-servicecost"})
    history.push("/admin-login");
  };

  useEffect(() => {
    dispatch(getQuestionnaireAdminList());
    dispatch(getAllCreateProjectList())
    dispatch(getTaigaProjectName())
  }, []);

  const onPageChange = (item) => {
   
    setCurrentPage(item + 1)
  }


  const indexOfTheLastResult = currentpage * postperpage ;
  const indexOFFirstResult = indexOfTheLastResult  - postperpage ;
  const currentResult = adminQuestionnaireList.slice(indexOFFirstResult,indexOfTheLastResult)
  
  

  return (
    <> 
      {questionnaireLoading &&  <Loader
        styles={{zIndex:99999 , position: "absolute" , top:"50vh" , width:"100%"}}
      />  }
    <body>
      <div className="d-flex" id="wrapper">
        {toggle && (
          <div className="border-end bg-white side-nav-dashboard" id="sidebar-wrapper">
            <div className="sidebar-heading border-bottom bg-light">
              Mmi funnel portal
            </div>
            <div className="list-group list-group-flush">
              <Link
                className="border-bottom  list-group-item-action list-group-item-light p-3"
                to="/admindashboard"
                style={{borderBottom:"1px solid black"}}
              >
                <span className="seleted-item">  Dashboard{" "} </span>
              </Link>
              <Link
                className=" list-group-item-action list-group-item-light p-3"
                to="/project"
              >
                Projects
              </Link>
              <Link
                className=" list-group-item-action list-group-item-light p-3"
                to="/invite-agency"
              >
                Invite Agency
              </Link>
            </div>
          </div>
        )}

        <div id="page-content-wrapper">
          <nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom nav-padding-balance">
            <div className="container-fluid">
              <div className="">
                 <Switch
                      value={toggle}
                      defaultChecked={false}
                      onChange={()=> setToggle(!toggle)}
                      color="info"

                    />
              </div>

              <div
                className="collapse navbar-collapse "
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ms-auto mt-2 mt-lg-0">
                  <button
                    onClick={handleLogout}
                    className="btn btn-primary"
                    id="sidebarToggle"
                  >
                    Sign out
                  </button>
                </ul>
              </div>
            </div>
          </nav>

          {/* <DashboardContainer/> */}
          {/* <UploadCsv /> */}
        
          <div className="div-col"> 
          {!questionnaireLoading && <DashboardDetailsTable
            adminQuestionnaireList={currentResult}
            indexOFFirstResult={indexOFFirstResult}
           
          />}
          {!questionnaireLoading &&adminQuestionnaireList.length > 10 && <div style={{marginTop:"5px"}} className="pagination-outer-div"> 
             <div className="pagination-inner-div">
             <ReactPaginate
                      pageCount={Math.ceil(adminQuestionnaireList.length / postperpage)}
                       //onPageChange={({ selected }) => setFrom(selected * size)}
                      onPageChange={({ selected }) => onPageChange(selected)}
                      // forcePage={
                      //   currentpage !== undefined ? Math.ceil(currentpage / postperpage) : 1
                      // }
                      disabledClassName="disabled"
                      initialPage={0}
                      previousLabel="previous"
                      nextLabel="next"
                      breakLabel="..."
                      breakClassName="break-me"
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      subContainerClassName="pages pagination"
                      breakLinkClassName="page-link"
                      containerClassName="pagination"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      activeClassName="active"
                    />
                    </div>
          </div>}
          </div>
        </div>
      </div>
    </body>
    </>
  );
};
export default AdminDashboard;
