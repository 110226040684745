import React,{useState} from 'react'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell,{ tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import InviteAgencyModal from './inviteagencymodal';
import {deleteUser} from '../../store/actions/inviteagency'
import {useDispatch}  from 'react-redux'
import { styled } from '@mui/material/styles';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#35c4e4",
    color: theme.palette.common.white,
  },
 
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


const columns = [
  { id: 'name', label: 'Agency Name', minWidth: 170 },
  { id: 'address', label: 'Agency Address', minWidth: 100 },
  { id: 'email', label: 'Email',minWidth: 170},
  { id: 'phone', label: 'Phone', minWidth: 170},
  {id: 'edit',label: 'Edit',minWidth: 50 ,format :"image",align:"center"},
  {id: 'delete',label: 'Delete',minWidth: 50 ,format :"image",align:"center"}
];

export default function AgencyUsersTable(props) {
  const [modalShow, setModalShow] = React.useState(false);
  const [selectedRow,setSelectedRow] = useState()
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const dispatch = useDispatch()

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  
const onUserEditClick = (row) =>{
  setSelectedRow(row)
  setTimeout(()=>{
    setModalShow(true)
  },500)
}


const handleDelete = (row) => {
  dispatch(deleteUser(row.id))
}

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: "100%" }}>
        <Table stickyHeader aria-label="sticky table" style={{fontFamily:"Adobe-Garamond"}}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.users
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <StyledTableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format === 'image' ? <img style={{width:'20px',height:'20px',objectFit:"contain",cursor:'pointer'}} onClick={()=>{column.id === 'edit' ? onUserEditClick(row) : handleDelete(row)}} src={column.id === 'edit' ? 'assets/images/edit.png' : "assets/images/delete.png"}/>
                            : value}
                        </TableCell>
                      );
                    })}
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={props.users.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
       {modalShow &&   <InviteAgencyModal
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            update={true}
                            selectedRow={selectedRow}
                            modalTitile={'Update Agency User'}
                            />}
    </Paper>
  );
}