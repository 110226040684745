
const customDevelopmentBullet = ["Product Design","Product Development","DevOps Support","UI/UX implementation","API integrations and documentation","Software Application Development","Quality Assurance Testing","Responsive Mobile formatting","Responsive Mobile formatting"]
const customWebsiteDevelopment = ["Website development","Code Optimization","Ongoing reporting and analytics","Design work","Load times, network security, data compliance","Monthly hosting, servicing, and ongoing maintenance","Custom layout and functionality control"]
const adsBullet = ["Content Creation","Content Creation","Cross Platform Coordination","Custom Design","Analytics and Tracking on all Ads","Monthly reports","Recommendations based on target market",]
const marketBulletPoint = {
  seo:["Site Map Creation","Page Linking Optimization","Page Code Cleanup","W3C Compliance","Code Error Correction","Domain Verification and Security","Optimal Accessibility for Googlebot","Configuration of robots.txt Directives","Aggregated Page Rank Boost"],
  smm:["Ongoing reporting and analytics","Coordinated weekly postings to social media accounts to establish presence","Ongoing management of accounts and customization with Breathify’s brand growth","Custom content and material for accounts as needed","Advertising and PPC campaigns when required","Creation of coordinated social media accounts across multiple platforms (i.e.:  Twitter, Facebook, Instagram, LinkedIn",],
  leadGenration:["Geotargeting and Event Based Messaging","Email sending costs","CAN-SPAM Act compliant","Custom sending domains","Monthly reporting","Identity protected campaign to drive warm meetings to close upon","LinkedIn + Web research to understand the organization being targeted"],
  emailMarketing:["Geotargeting and Event Based Messaging","Email sending costs","CAN-SPAM Act compliant","Custom sending domains","Monthly reporting","Identity protected campaign to drive warm meetings to close upon","LinkedIn + Web research to understand the organization being targeted",],

}

const desingBulletPoint = ["Logo","Company information","Brand assets","Electronic communications","Brand colors","Iconography","Typography","Image guidelines"]
const incubationBulletPoint = {
  dvisingConsulting :["Platform interface brainstorming session","Advising on technology infrastructure","Evaluation of API’s, integrations, and consolidated solutions","Advising on platform roadmap and direction","Security and Compliance Advising","Early stage operations advising","Early stage operations advising","Go-to market strategy advising","Fundraising and venture capital consulting","Advising on PR strategy, marketing channels, website, and branding"],
  productConceptualization : ["Platform interface brainstorming session","Product concept","Full MVP wireframes","Full Product Design","Iterative design process with cyclical feedback"]
}

export const showHeadingText = (companyName , date) => {
    return  `This proposal is prepared for ${companyName} by Marketing Machine on the ${date}. `
}

export const getPdfCategoryText = (cost) => {

   
    let parent_category = ""
 
    let filledcost =
      cost.filledCost &&
      cost.filledCost.filter((list) => {
        return list.status === "approved" ?  cost.filledCost[0]  : null
        // return list.status === "approved" ? list.status === "approved" : list;
      });

       const final = filledcost[0]
       const developmentLength  = final !== undefined ? final.developmentCost[0] && Object.keys(final.developmentCost[0]) : null
       const designLength=   final !== undefined ? final.designCost[0] && Object.keys(final.designCost[0]):null 
       const adsLength =  final !== undefined ? final.advertisingCost[0] && Object.keys(final.advertisingCost[0]):null
       const marketLength = final !== undefined ? final.marketingCost[0] && Object.keys(final.marketingCost[0]):null
       const incubationLength = final !== undefined ? final.incubationCost[0] && Object.keys(final.incubationCost[0]):null
       if((developmentLength && developmentLength.length > 0)  && (designLength && designLength.length === 0 || adsLength &&  adsLength.length === 0 || marketLength && marketLength.length === 0 || incubationLength && incubationLength.length === 0 )){
          parent_category = "Technology"
          
       }
       if( (designLength && designLength.length > 0 || adsLength &&  adsLength.length > 0 || marketLength && marketLength.length > 0 || incubationLength && incubationLength.length > 0 )){
           parent_category = "Marketing"
           
       }

        if ((developmentLength && developmentLength.length > 0) && (designLength && designLength.length > 0 || adsLength &&  adsLength.length > 0 || marketLength && marketLength.length > 0 || incubationLength && incubationLength.length > 0 )){
          parent_category = "Technology And Marketing"
         
       }

       
    return parent_category
      
}

export const selectedCategoryText = (cost) => {

  let category = [];
  
    const development = cost && cost.developmentServices ? "Development" :"" 
    const design = cost && cost.designServices ? "Design" :""
    const ads = cost && cost.advertisingServices ? "Advertising":""
    const market = cost && cost.marketingServices ? "Marketing":""
    const incubation = cost && cost.incubationServices ? "Incubation":""

     category = [development,design,ads,market,incubation]
     let filterCat = category.filter((list)=> list !=="")
     let len = filterCat.length
     let addedText;
    
     if(len > 1){
         filterCat.splice(len -1 , 0 , "And");  
        addedText =  filterCat.map((list)=> `${list} `)
       // addedText =   filterCat.map((value)=> `${value}, `)
     }
  
    if(filterCat.length > 3 ){
      addedText = filterCat.map((value,index)=>{
        if(index < filterCat.length-3)
        {
          return addedText = `${value}, `
        } else{
           return addedText = value + " "
        }
      })
    }
      
    
   return addedText
   
}


export const renderDevelopmentShortDiscription = (filledCost) => {
   let devCost = filledCost.map((list) =>  list.developmentCost[0])
  const devKey  = Object.keys(devCost[0])
 
   
   return  devKey.map((list) => {
    console.log("list==",list)
      if(list === "Software Development"  || list === "Custom Software Development" || list === "Custom Software Devlopment"){
        return <p className="para-text d-flex"><span><img className="bullet-img"  src="assets/images/triangle.png"/> </span> <span>Build custom standalone software platform (See <b className="text-semibold"> Custom Software Development.</b>) </span></p>
      }
      if(list ==="Website Development" || list === "Custom Website Development" || list === "Custom Website Devlopment"){
        return <p className="para-text d-flex"><span><img className="bullet-img" src="assets/images/triangle.png"/> </span> <span>Enhance and coordinate brand image at all digital patient conversion points (See <b className="text-semibold"> Custom Website Development.</b>) </span></p> 
      }
        if(list ==='Artificial Intelligence' || list === "Artificial Intelligence / Machine Learning"){
        return <p className="para-text d-flex"><span><img className="bullet-img" src="assets/images/triangle.png"/> </span> <span> standalone machine learning software system  (See <b className="text-semibold"> Artificial Intelligence.</b>)  </span></p>
      }
  });
}

export const renderMarketingCostShortDiscription = (filledCost) => {
   let marketingCost = filledCost.map((list) =>  list.marketingCost[0] );
  const marketingKey  = Object.keys(marketingCost[0])

   return  marketingKey.map((list) => {
   
      if(list === "Search Engine Optimization"){
        return <p className="para-text d-flex"><span><img className="bullet-img" src="assets/images/triangle.png"/> </span><span>Optimize technical page structure to improve placement on SERPs (See <b className="text-semibold"> Search Engine Optimization.</b>) </span></p>
      }
        if(list === "Content Creation"){
        return <p className="para-text d-flex"><span><img className="bullet-img" src="assets/images/triangle.png"/> </span> <span> Creative and Content Production (See <b className="text-semibold"> Content Creation.</b>)</span></p>
      }
       if(list === "Lead Generation"){
        return <p className="para-text d-flex"><span><img className="bullet-img" src="assets/images/triangle.png"/> </span><span>Custom lead funnels targeting sponsorships, decision makers, and strategic partnerships (See <b className="text-semibold"> Lead Generation.</b>)</span></p>
      }
       if(list === "Email Marketing"){
        return <p className="para-text d-flex"><span><img className="bullet-img" src="assets/images/triangle.png"/> </span> <span>Cultivate strategic leads through targeted email campaign (See <b className="text-semibold"> Email Marketing.</b>) </span></p>
      }
       if (list.trim() === "Social Media Marketing"){
        return <p className="para-text d-flex"><span><img className="bullet-img" src="assets/images/triangle.png"/> </span> <span>Enhance and coordinate brand image at all digital patient conversion points (See <b className="text-semibold"> Social Media Marketing.</b>)</span></p>
      }
  });
}

export const advertisingShortDiscription = (filledCost) => {
 
   let advertisingCost = filledCost.map((list) => list.advertisingCost[0] );
  
  const advertisingKey  =  Object.keys( advertisingCost[0])

   return  advertisingKey.map((list) => {
  
      if(list.trim() === "Google Adwords"){
        return <p className="para-text d-flex"><span><img className="bullet-img" src="assets/images/triangle.png"/> </span><span>Targeted and coordinated AdWords and PPC campaigns to fuel growth and market adoption (See <b className="text-semibold"> Google Adwords.</b>) </span> </p>
      }
        if(list.trim() === "Facebook Ads"){
        return <p className="para-text d-flex"><span><img className="bullet-img" src="assets/images/triangle.png"/> </span> <span>Targeted and coordinated AdWords and PPC campaigns to fuel growth and market adoption  (See <b className="text-semibold"> Facebook Ads.</b>)</span></p>
      }
       if(list.trim() === "Instagram Advertising"){
        return <p className="para-text d-flex"><span><img className="bullet-img" src="assets/images/triangle.png"/> </span><span>Targeted and coordinated AdWords and PPC campaigns to fuel growth and market adoption (See <b className="text-semibold"> Instagram Advertising.</b>)</span></p>
      }
  });
}


export const designShortDiscription = (filledCost) => {
   let designCost = filledCost.map((list) => list.designCost[0] );
  const designKey  =  Object.keys( designCost[0])
   return  designKey.map((list) => {
  
      if(list.trim() === "Branding Design"){
        return <p className="para-text d-flex"><span><img className="bullet-img" src="assets/images/triangle.png"/> </span> <span>Complete Branding Package  (See <b className="text-semibold"> Branding Design.</b>)</span></p>
      }
       
  });
}

export const incubationShortDiscription = (filledCost) => {
   let incubationCost = filledCost.map((list) => list.incubationCost[0] );
  const incubationkey  =  Object.keys( incubationCost[0])
   return  incubationkey.map((list) => {
    
      if(list.trim() === "Advising / Consulting"){
        return <p className="para-text d-flex"><span><img className="bullet-img" src="assets/images/triangle.png"/> </span><span>Advise on systems infrastructure, architecture, product concept, and technology capability (See <b className="text-semibold"> Advising / Consulting.</b>)</span></p>
      }
       if(list.trim() === "Product Conceptualization"){
        return <p className="para-text d-flex"><span><img className="bullet-img" src="assets/images/triangle.png"/> </span> <span>Platform Wireframes and Design (See <b className="text-semibold"> Product Conceptualization.</b>)</span></p>
      }
      if(list.trim() === "Managed Resource"){
        return <p className="para-text d-flex"><span><img className="bullet-img" src="assets/images/triangle.png"/> </span> <span>Provide admin team support (See <b className="text-semibold"> Managed Resource.</b>)</span></p>
      }
       
  });
}


export const parentCategorySummary = (compnayName) => {
  return <p className="para-text">{`Nearly all targets of ${compnayName} will hear of ${compnayName} via the web, as online interaction is the primary determining factor on whether any given prospect becomes a customer. To that effect, we will create and solidify ${compnayName}'s digital presence at all active points of conversion, continually cleaning up and optimizing factors affecting the marketing efforts for ${compnayName}’s digital presence. We will also collect detailed analytics for the purpose of adapting future strategies and increasing customer acquisition through successful funnel conversions.

${compnayName} has a unique problem and Marketing Machine can develop a unique solution to this problem in the market. To that effect, we will engage in development on a custom proprietary software developed to function as a standalone system, advise on technology, and provide operational support as required for the software.`}</p>
}


export const developmentServices = (filledcost ,profitPercentage ,approvedCost ,name , hideCost = false) => {
   if(approvedCost.length === 0 ) return 
   const tempObj =approvedCost[0].developmentCost.map(costItem => ({[costItem.category] : costItem.cost}))
    let devCost = filledcost.map((list) => {
           return list.developmentCost[0];
  });

 
     return <> {devCost.length === 1 &&
        devCost &&
        devCost.map((list, index) => {
        
          
          return (
            <>
              {Object.keys(list).length !== 0 && (
                <div >
                  <div>                   
                    
                    {tempObj.map((name) => {
                      return (
                        <>
                          <div className="service-container">
                          <div className="main-heading-blue">
                             {Object.keys(name).toString()}
                          </div>
                          {!hideCost &&<div className="price-heading-text">
                            {`$${ ((Object.values(name).toString()).replace(/[^\d.-]/g, '') * profitPercentage) / 100 +  Number((Object.values(name).toString()).replace(/[^\d.-]/g, ''))} per month`}</div>}
                           {Object.keys(name).toString() === "Software Development" || Object.keys(name).toString() === "Custom Software Development" || Object.keys(name).toString() === "Custom Software Devlopment"?
                            <div>
                            <p className="long-heading-text">Marketing Machine Inc will develop a custom technology platform consisting of an agreed upon stack built on top of a flexible API for third party integrations. This product will be able to function as a standalone technology offering with user management, communications, industry standard security standards, documentation, and devops support throughout the build. </p> 
                              <div className="bullet-container"> 
                              {customDevelopmentBullet.map((bulletPoint)=>{
                                return <div className="bullet-text"> <span><img className="bullet-img" src="assets/images/triangle.png"/></span>  <span>{`${bulletPoint}`}</span></div>
                              })}
                              </div>
                           </div>: null}
                           {Object.keys(name).toString() === "Website Development"  || Object.keys(name).toString() === "Custom Website Development" || Object.keys(name).toString() === "Custom Website Devlopment" ? <div>
                              <p className="long-heading-text">Website Machine will hone existing branding and websites into a brand which calls customers to action when encountered. This will include strategy around structure, content, demographic analysis, design work, DNS, hosting, and server optimization. Tracking and analytics will be added throughout the site to properly track where and when engagement happens and stops.</p>
                                <div className="bullet-container"> 
                              {customWebsiteDevelopment.map((bulletPoint)=>{
                                return <div className="bullet-text"> <span><img className="bullet-img" src="assets/images/triangle.png"/></span> <span>{`${bulletPoint}`}</span></div>
                              })}
                              </div>
                               </div>:null}
                           {Object.keys(name).toString() === "Artificial Intelligence" || Object.keys(name).toString() === "Artificial Intelligence / Machine Learning" ? <div>
                            <p className="long-heading-text">Marketing Machine Inc will develop a custom technology platform consisting of an agreed upon stack built on top of a flexible API for third party integrations. This product will be able to function as a standalone technology offering with user management, communications, industry standard security standards, documentation, and devops support throughout the build. </p> 
                                <div className="bullet-container"> 
                              {customDevelopmentBullet.map((bulletPoint)=>{
                                return <div className="bullet-text"> <span><img className="bullet-img" src="assets/images/triangle.png"/></span> <span>{`${bulletPoint}`}</span></div>
                              })}
                              </div>
                             </div>
                             :null}
                           <div>

                           </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              )}
            </>
          );
        })}
        </>
  
}


export const advertisingServicesDiscription = (filledcost ,profitPercentage ,approvedCost ,compnayName) => {
    if(approvedCost.length === 0 ) return 
  const tempObj = approvedCost[0].advertisingCost.map(costItem => ({[costItem.category] : costItem.cost}))
    let adsCpst = filledcost.map((list) => {
           return list.advertisingCost[0];
  });
     return <> {adsCpst.length === 1 &&
        adsCpst &&
        adsCpst.map((list, index) => {
          
          return (
            <>
              {Object.keys(list).length !== 0 && (
                <div >
                  <div>                   
                    
                    {tempObj.map((name) => {
                   
                     

                      return (
                        <>
                          <div className="service-container">
                          <div className="main-heading-blue">
                             {Object.keys(name).toString()}
                          </div>
                          <div className="price-heading-text"
                          >
                            {`$${ ((Object.values(name).toString()).replace(/[^\d.-]/g, '') * profitPercentage) / 100 +  Number((Object.values(name).toString()).replace(/[^\d.-]/g, ''))} per month`}</div>
                           {Object.keys(name).toString().trim() === "Google Adwords" ?
                            <div>
                            <p className="long-heading-text">Google and Facebook are responsible for 58.5% of all advertising dollars spent.  This is primarily due to their positioning in the market and their ability to directly engage with a large portion of consumers online.  Marketing Machine will run coordinated, targeted AdWords and PPC campaigns through Google and Facebook (as well as Instagram).  This will complement {compnayName}'s branding, while enhancing existing marketing efforts. These variable campaigns will be proofed through rigorous A/B testing and analysis.</p>
                               <div className="bullet-container"> 
                              {adsBullet.map((bulletPoint)=>{
                                return <div className="bullet-text"> <span><img className="bullet-img" src="assets/images/triangle.png"/></span>  <span>{`${bulletPoint}`}</span></div>
                              })}
                              </div>
                           </div>: null}
                           {Object.keys(name).toString().trim() === "Facebook Ads" ? <div>
                                <p className="long-heading-text">Google and Facebook are responsible for 58.5% of all advertising dollars spent.  This is primarily due to their positioning in the market and their ability to directly engage with a large portion of consumers online.  Marketing Machine will run coordinated, targeted AdWords and PPC campaigns through Google and Facebook (as well as Instagram).  This will complement {compnayName}'s branding, while enhancing existing marketing efforts. These variable campaigns will be proofed through rigorous A/B testing and analysis.</p>
                               <div className="bullet-container"> 
                              {adsBullet.map((bulletPoint)=>{
                                return <div className="bullet-text"> <span><img className="bullet-img" src="assets/images/triangle.png"/></span>  <span>{`${bulletPoint}`}</span></div>
                              })}
                              </div>
                               </div>:""}
                           {Object.keys(name).toString().trim() === "Instagram Advertising" ? <div>
                               <p className="long-heading-text">Google and Facebook are responsible for 58.5% of all advertising dollars spent.  This is primarily due to their positioning in the market and their ability to directly engage with a large portion of consumers online.  Marketing Machine will run coordinated, targeted AdWords and PPC campaigns through Google and Facebook (as well as Instagram).  This will complement {compnayName}'s branding, while enhancing existing marketing efforts. These variable campaigns will be proofed through rigorous A/B testing and analysis.</p>
                                <div className="bullet-container"> 
                              {adsBullet.map((bulletPoint)=>{
                                return <div className="bullet-text"> <span><img className="bullet-img" src="assets/images/triangle.png"/></span>  <span>{`${bulletPoint}`}</span></div>
                              })}
                              </div>
                             </div>
                             :null}
                           <div>

                           </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              )}
            </>
          );
        })}
        </>
  
}


export const marketServicesDiscription = (filledcost ,profitPercentage ,approvedCost) => {
    if(approvedCost.length === 0 ) return 
    const tempObj = approvedCost[0].marketingCost.map(costItem => ({[costItem.category] : costItem.cost}))
    let marketCost = filledcost.map((list) => {
           return list.marketingCost[0];
  });
     return <> {marketCost.length === 1 &&
        marketCost &&
        marketCost.map((list, index) => {
          
          return (
            <>
              {Object.keys(list).length !== 0 && (
                <div >
                  <div>                   
                    
                    {tempObj.map((name) => {
                     
                     

                      return (
                        <>
                          <div className="service-container">
                          <div className="main-heading-blue">
                             {Object.keys(name).toString()}
                          </div>
                          <div className="price-heading-text"
                          >
                            {`$${ ((Object.values(name).toString()).replace(/[^\d.-]/g, '') * profitPercentage) / 100 +  Number((Object.values(name).toString()).replace(/[^\d.-]/g, ''))} per month`}</div>
                           {Object.keys(name).toString().trim() === "Search Engine Optimization" ?
                            <div>
                            <p className="long-heading-text">{`Marketing Machine will perform a full marketing audit on ${filledcost.firstName ? `${filledcost.firstName} ${filledcost.lastName}`:`${filledcost.name}`}'s online presence. This will include primary websites, satellite websites (if applicable), social media channels, and any other mediums which ${filledcost.firstName ? `${filledcost.firstName} ${filledcost.lastName}`:``} is actively maintaining.  After keywords, traffic, code, framework, engagement, security, and all other factors have been properly analyzed, our team will clean up and address any / all reasons that Google, Yahoo, and Bing are not optimally crawling and ranking your digital footprint.  We recognize that SEO is integral to a marketing strategy, and use our technology expertise to ensure SEO that beats the competition.`}</p>
                              <div className="bullet-container"> 
                              { marketBulletPoint.seo.map((bulletPoint)=>{
                                return <div className="bullet-text"> <span><img className="bullet-img" src="assets/images/triangle.png"/></span>  <span>{`${bulletPoint}`}</span></div>
                              })}
                              </div>
                           </div>: null}
                           {Object.keys(name).toString().trim() === "Content Creation" || Object.keys(name).toString().trim() === "Content Creating" ? <div>
                                <p className="long-heading-text">{`Marketing Machine will run an efficient and rich content creation process.  This will be optimized for creation and syndication of existing content across all channels.  By crafting optimized content, ${filledcost.firstName ? `${filledcost.firstName} ${filledcost.lastName}`:``} is providing free and useful information to the target audience, attracting potential customers to its website, and retaining existing customers through quality engagement.`}</p>
                               
                               </div>:null}
                           {Object.keys(name).toString().trim().toLocaleLowerCase() === "lead generation" ? <div>
                               <p className="long-heading-text">{`Marketing Machine will create and perform a warm lead generation campaign via passively  occurring, high-engagement email marketing campaigns.  Marketing Machine will tailor content specific to region, demographic, and perceived service interest.  Such refined targeting will be done through multivariate testing and analysis.  Each campaign is run with complete anonymity to ${filledcost.firstName ? `${filledcost.firstName} ${filledcost.lastName}`:``} until the point that the prospective customer, sponsor, or partner will have affirmed interest in the mutually beneficial proposition.  The hand-off will be seamless, and occur once the target expresses interest in setting up a meeting to discuss the opportunity.  At this point, Marketing Machine will set up the meeting, and hand off the baton to you. `}</p>
                                 <div className="bullet-container"> 
                              { marketBulletPoint.leadGenration.map((bulletPoint)=>{
                                return <div className="bullet-text"> <span><img className="bullet-img" src="assets/images/triangle.png"/></span>  <span>{`${bulletPoint}`}</span></div>
                              })}
                              </div>
                             </div>
                  
                             :null}
                           <div>
                              {Object.keys(name).toString().trim().toLocaleLowerCase() === "email marketing" ? <div>
                               <p className="long-heading-text">{`Marketing Machine will create and perform a warm lead generation campaign via passively  occurring, high-engagement email marketing campaigns.  Marketing Machine will tailor content specific to region, demographic, and perceived service interest.  Such refined targeting will be done through multivariate testing and analysis.  Each campaign is run with complete anonymity to ${filledcost.firstName ? `${filledcost.firstName} ${filledcost.lastName}`:``} until the point that the prospective customer, sponsor, or partner will have affirmed interest in the mutually beneficial proposition.  The hand-off will be seamless, and occur once the target expresses interest in setting up a meeting to discuss the opportunity.  At this point, Marketing Machine will set up the meeting, and hand off the baton to you.`} </p>
                                <div className="bullet-container"> 
                              {  marketBulletPoint.emailMarketing.map((bulletPoint)=>{
                                return <div className="bullet-text"> <span><img className="bullet-img" src="assets/images/triangle.png"/></span>  <span>{`${bulletPoint}`}</span></div>
                              })}
                              </div>
                             </div>
                  
                             :null}
                          
                            {Object.keys(name).toString().trim() === "Social Media Marketing" ? <div>
                               <p className="long-heading-text">{`Social media plays an important role in B2C and B2B for reputation validity and search  engine optimization. Marketing Machine will build and populate these platforms with relevant  content creating exposure and a cohesive brand identity across platforms. Included will be relevant backlinks, posting management and strategy, target audience, and passive advertising and customer outreach bringing awareness to ${filledcost.firstName ? `${filledcost.firstName} ${filledcost.lastName}`:``}. Through effective management and content creation Marketing Machine will create a positive feedback loop which reinforces brand identity funneling prospective customers and partners to the primary points of conversion. Each of these platforms will  be built by our experts and maintained with creative oversight from you. You will have  access to 24/7 support and customization on all platforms.`}</p>
                              <div className="bullet-container"> 
                              { marketBulletPoint.seo.map((bulletPoint)=>{
                                return <div className="bullet-text"> <span><img className="bullet-img" src="assets/images/triangle.png"/></span>  <span>{`${bulletPoint}`}</span></div>
                              })}
                              </div>
                             </div>
                  
                             :null}
                          

                           </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              )}
            </>
          );
        })}
        </>
  
}


export const designServicesDiscription = (filledcost ,profitPercentage,approvedCost) => {
    if(approvedCost.length === 0 ) return 
   const tempObj = approvedCost[0].designCost.map(costItem => ({[costItem.category] : costItem.cost}))
    let designKey = filledcost.map((list) => {
           return list.designCost[0];
  });
     return <> {designKey.length === 1 &&
        designKey &&
        designKey.map((list, index) => {
          
          return (
            <>
              {Object.keys(list).length !== 0 && (
                <div >
                  <div>                   
                    
                    {tempObj.map((name) => {
                     

                      return (
                        <>
                          <div className="service-container">
                          <div className="main-heading-blue">
                             {Object.keys(name).toString()}
                          </div>
                          <div className="price-heading-text"
                          >
                            {`$${ ((Object.values(name).toString()).replace(/[^\d.-]/g, '') * profitPercentage) / 100 +  Number((Object.values(name).toString()).replace(/[^\d.-]/g, ''))} per month`}</div>
                           {Object.keys(name).toString().trim() === "Branding Design" ?
                            <div>
                            <p className="long-heading-text">Every business has a brand. We believe that your brand is your reputation. Every business rises or falls by how it contends with its reputation.  This is the result of people's experience of using your business.  A brand can be an identification or a mark that differentiates one business from another.  Building a brand helps customers in their decision-making process.  Savvy branding creates a perceived knowledge of what each customer is inclined to buy - before they decide to buy it.  A brand builds confidence and provides trust, thereby influencing how those pivotal decisions are made in the marketplace.</p>
                              <div className="bullet-container"> 
                              {  desingBulletPoint.map((bulletPoint)=>{
                                return <div className="bullet-text"> <span><img className="bullet-img" src="assets/images/triangle.png"/></span>  <span>{`${bulletPoint}`}</span></div>
                              })}
                              </div>
                           </div>: null}
                           <div>

                           </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              )}
            </>
          );
        })}
        </>
  
}



export const incubationServicesDiscription = (filledcost ,profitPercentage,approvedCost ,hideCost = false) => {
    if(approvedCost.length === 0 ) return 
   const tempObj = approvedCost[0].incubationCost.map(costItem => ({[costItem.category] : costItem.cost}))
    let incubationkey = filledcost.map((list) => {
           return list.incubationCost[0];
  });
     return <> {incubationkey.length === 1 &&
        incubationkey &&
        incubationkey.map((list, index) => {
          
          return (
            <>
              {Object.keys(list).length !== 0 && (
                <div >
                  <div>                   
                    
                    {tempObj.map((name) => {
                    

                      return (
                        <>
                          <div className="service-container">
                          <div className="main-heading-blue">
                             {Object.keys(name).toString()}
                          </div>
                         {hideCost &&  <div className="price-heading-text"
                          >
                            {`$${ ((Object.values(name).toString()).replace(/[^\d.-]/g, '') * profitPercentage) / 100 +  Number((Object.values(name).toString()).replace(/[^\d.-]/g, ''))} per month`}</div>}
                           {Object.keys(name).toString().trim() === "Advising / Consulting" ?
                            <div>
                            <p className="long-heading-text">{`Marketing Machine Inc and Rainmakers will advise ${filledcost.firstName ? `${filledcost.firstName} ${filledcost.lastName}`:``} on product roadmap strategy, path to market, early stage operations strategy in the technology space, platform growth modeling, fundraising and venture capital, and user journey based on our expertise and experience bringing over 200 companies to market. Additionally, we will offer the use of our internal proprietary marketing, fundraising, and lead gen technologies to assist with incubation and early stage growth acceleration of the ${filledcost.firstName ? `${filledcost.firstName} ${filledcost.lastName}`:``}'s platform.`}</p>
                                <div className="bullet-container"> 
                              { incubationBulletPoint.dvisingConsulting.map((bulletPoint)=>{
                                return <div className="bullet-text"> <span><img className="bullet-img" src="assets/images/triangle.png"/></span>  <span>{`${bulletPoint}`}</span></div>
                              })}
                              </div>
                           </div>: null}
                          
                          
                           <div>

                           </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              )}
            </>
          );
        })}
        </>
  
}

export const consultingServiceDiscription = (filledcost ,profitPercentage,approvedCost ,hideCost = false) => {
  if(approvedCost.length === 0 ) return 
 const tempObj = approvedCost[0].consultingCost.map(costItem => ({[costItem.category] : costItem.cost}))
  let consultingekey = filledcost.map((list) => {
         return list.consultingCost[0];
});

   return <> {consultingekey.length === 1 &&
      consultingekey &&
      consultingekey.map((list, index) => {
        return (
          <>
            {Object.keys(list).length !== 0 && (
              <div >
                <div>                  
                  {tempObj.map((name) => {
                    return (
                      <>
                        <div className="service-container">
                        <div className="main-heading-blue">
                           {Object.keys(name).toString()}

                        </div>
                       {hideCost &&  <div className="price-heading-text"
                        >
                          {`$${ ((Object.values(name).toString()).replace(/[^\d.-]/g, '') * profitPercentage) / 100 +  Number((Object.values(name).toString()).replace(/[^\d.-]/g, ''))} per month`}</div>}
                         {Object.keys(name).toString().trim() === "Product Conceptualization" ?
                          <div>
                          <p className="long-heading-text">{`Marketing Machine Inc and Rainmakers will advise ${filledcost.firstName ? `${filledcost.firstName} ${filledcost.lastName}`:``} on product roadmap strategy, path to market, early stage operations strategy in the technology space, platform growth modeling, fundraising and venture capital, and user journey based on our expertise and experience bringing over 200 companies to market. Additionally, we will offer the use of our internal proprietary marketing, fundraising, and lead gen technologies to assist with incubation and early stage growth acceleration of the ${filledcost.firstName ? `${filledcost.firstName} ${filledcost.lastName}`:``}'s platform.`}</p>
                              <div className="bullet-container"> 
                            { incubationBulletPoint.dvisingConsulting.map((bulletPoint)=>{
                              return <div className="bullet-text"> <span><img className="bullet-img" src="assets/images/triangle.png"/></span>  <span>{`${bulletPoint}`}</span></div>
                            })}
                            </div>
                         </div>: null}
                         {Object.keys(name).toString().trim() === "Facebook Adverting" ? <div>
                              <p className="long-heading-text">{`Marketing Machine Inc will wireframe a preliminary, thorough MVP of ${filledcost.firstName ? `${filledcost.firstName} ${filledcost.lastName}`:``}'s platform and work with ${filledcost.firstName ? `${filledcost.firstName} ${filledcost.lastName}`:``} to develop an initial professional design for this wireframe. The end result of this will be to have a wireframe and design which once approved can be handed off to directly develop the platform against. To that effect, we will develop custom standalone wireframes and designs to prepare for a technical specification and platform build. Marketing Machine Inc will advise throughout the process based on our expertise incubating early stage technology platforms.`}</p>
                             <div className="bullet-container"> 
                            { incubationBulletPoint.productConceptualization.map((bulletPoint)=>{
                              return <div className="bullet-text"> <span><img className="bullet-img" src="assets/images/triangle.png"/></span>  <span>{`${bulletPoint}`}</span></div>
                            })}
                            </div>
                             </div>:""}

                             {Object.keys(name).toString().trim() === "Branding" ?
                            <div>
                            <p className="long-heading-text">Every business has a brand. We believe that your brand is your reputation. Every business rises or falls by how it contends with its reputation.  This is the result of people's experience of using your business.  A brand can be an identification or a mark that differentiates one business from another.  Building a brand helps customers in their decision-making process.  Savvy branding creates a perceived knowledge of what each customer is inclined to buy - before they decide to buy it.  A brand builds confidence and provides trust, thereby influencing how those pivotal decisions are made in the marketplace.</p>
                              <div className="bullet-container"> 
                              {  desingBulletPoint.map((bulletPoint)=>{
                                return <div className="bullet-text"> <span><img className="bullet-img" src="assets/images/triangle.png"/></span>  <span>{`${bulletPoint}`}</span></div>
                              })}
                              </div>
                           </div>: null}
                        
                         <div>

                         </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            )}
          </>
        );
      })}
      </>

}


  



// agreementpdf code start from here 

export const showAgreementPdfHeadingText = (cost,filledCost,type = "lowercase") => {
 const approvedCost = typeof filledCost == 'undefined'  ? Object.assign({},cost.approvedcosts && cost.approvedcosts[0])  : Object.assign({},filledCost[0])
 const {advertisingCost,designCost,developmentCost,incubationCost,marketingCost,consultingCost} = approvedCost
 const adsTime = advertisingCost.length !==0 ? advertisingCost.map(approveCost => approveCost.category) : null
 const devTime = developmentCost.length !==0 ? developmentCost.map(approveCost => approveCost.category) : []
 const designTime = designCost.length !==0 ? designCost.map(approveCost => approveCost.category) : null
 const incubationTime = incubationCost.length !==0 ? incubationCost.map(approveCost => approveCost.category) : null
 const marketTime = marketingCost.length !==0 ? marketingCost.map(approveCost => approveCost.category) : null
 const consultingTime = consultingCost && consultingCost.length !==0 ? consultingCost.map(approveCost => approveCost.category) : null
 const  allCategory = devTime.concat(adsTime,designTime,incubationTime,marketTime,consultingTime).filter(x => x !== null && x !== undefined)

 let len = allCategory.length
 let addedText;
 
 if(type === "uppercase"){
   if(len > 1){
     allCategory.splice(len -1 , 0 , "And");  
     addedText =  allCategory.map((list)=> `${list.toUpperCase()} `)
   // addedText =   filterCat.map((value)=> `${value}, `)
 } else {
   addedText = allCategory.toString().toUpperCase()
 }

if(allCategory.length > 3 ){
  addedText = allCategory.map((value,index)=>{
    if(index < allCategory.length-3)
    {
      return addedText = `${value.toUpperCase()}, `
    } else{
       return addedText = value.toUpperCase() + " "
    }
  })
}

 } else {
   if(len > 1){
     allCategory.splice(len -1 , 0 , "And");  
     addedText =  allCategory.map((list)=> `${list} `)
   // addedText =   filterCat.map((value)=> `${value}, `)
 } else {
   addedText = allCategory
 }

if(allCategory.length > 3 ){
  addedText = allCategory.map((value,index)=>{
    if(index < allCategory.length-3)
    {
      return addedText = `${value}, `
    } else{
       return addedText = value + " "
    }
  })
}

 }
return  addedText
}

const bulletImg = () =>{
  return <span><img className="bullet-circle-img" src="assets/images/bullet-circle.png"/></span>
}


export const showAllCategory = (cost,filledCost,compnayName,filledcost) => {
  const approvedCost = Object.assign({},filledCost[0])
  const {advertisingCost,designCost,developmentCost,incubationCost,marketingCost,consultingCost} = approvedCost
  const adsTime = advertisingCost.length !==0 ? advertisingCost.map(approveCost => approveCost.category) : null
  const devTime = developmentCost.length !==0 ? developmentCost.map(approveCost => approveCost.category) : []
  const designTime = designCost.length !==0 ? designCost.map(approveCost => approveCost.category) : null
  const incubationTime = incubationCost.length !==0 ? incubationCost.map(approveCost => approveCost.category) : null
  const marketTime = marketingCost.length !==0 ? marketingCost.map(approveCost => approveCost.category) : null
  const consultingtime = consultingCost && consultingCost.length !==0 ? consultingCost.map(approveCost => approveCost.category) : null
  const  allCategory = devTime.concat(adsTime,designTime,incubationTime,marketTime,consultingtime).filter(x => x !== null && x !== undefined)
    return <div className='main container'> 
     <div  className="list-style-none" style={{color:"black"}} >
 {  allCategory.map((services)=>{
    return <>
      <div>
      <div className="service-container">
       <div className="">
        <div className="text-left"> {bulletImg()} <span className="main-heading-blue font-black font-16 font-bold-contract ">{services} </span>  </div> 
                             {services.toString() === "Software Development" ?
                             <div>
                            <p className="long-heading-text font-black font-bold-contract-light">Marketing Machine Inc will develop a custom technology platform consisting of an agreed upon stack built on top of a flexible API for third party integrations. This product will be able to function as a standalone technology offering with user management, communications, industry standard security standards, documentation, and devops support throughout the build. </p> 
                              <div className="bullet-container mb-10"> 
                              {customDevelopmentBullet.map((bulletPoint)=>{
                                return <div className="bullet-text font-black font-bold-contract-light"> <span style={{paddingRight:'5px'}}><img className="bullet-circle-img" src="assets/images/bullet-circle.png"/></span>  <span>{`${bulletPoint}`}</span></div>
                              })}
                              </div>
                           </div>: null}
                           {services.toString() === "Website Development" || services.toString() === "Custom Website Devlopment" ? <div>
                              <p className="long-heading-text font-black font-bold-contract-light">Website Machine will hone existing branding and websites into a brand which calls customers to action when encountered. This will include strategy around structure, content, demographic analysis, design work, DNS, hosting, and server optimization. Tracking and analytics will be added throughout the site to properly track where and when engagement happens and stops.</p>
                                <div className="bullet-container mb-10"> 
                              {customWebsiteDevelopment.map((bulletPoint)=>{
                                return <div className="bullet-text font-black font-bold-contract-light"> <span style={{paddingRight:'5px'}}><img className="bullet-circle-img" src="assets/images/bullet-circle.png"/></span> <span>{`${bulletPoint}`}</span></div>
                              })}
                              </div>
                               </div>:null}
                           {services.toString() === "Artificial Intelligence" ? <div>
                            <p className="long-heading-text font-black font-bold-contract-light">Marketing Machine Inc will develop a custom technology platform consisting of an agreed upon stack built on top of a flexible API for third party integrations. This product will be able to function as a standalone technology offering with user management, communications, industry standard security standards, documentation, and devops support throughout the build. </p> 
                                <div className="bullet-container mb-10"> 
                              {customDevelopmentBullet.map((bulletPoint)=>{
                                return <div className="bullet-text font-black font-bold-contract-light"> <span style={{paddingRight:'5px'}}><img className="bullet-circle-img" src="assets/images/bullet-circle.png"/></span> <span>{`${bulletPoint}`}</span></div>
                              })}
                              </div>
                             </div>
                             :null}
                              {services.toString().trim() === "Google Adwords" ?
                            <div>
                            <p className="long-heading-text font-black font-bold-contract-light">Google and Facebook are responsible for 58.5% of all advertising dollars spent.  This is primarily due to their positioning in the market and their ability to directly engage with a large portion of consumers online.  Marketing Machine will run coordinated, targeted AdWords and PPC campaigns through Google and Facebook (as well as Instagram).  This will complement {compnayName}'s branding, while enhancing existing marketing efforts. These variable campaigns will be proofed through rigorous A/B testing and analysis.</p>
                               <div className="bullet-container mb-10"> 
                              {adsBullet.map((bulletPoint)=>{
                                return <div className="bullet-text font-black font-bold-contract-light"> <span style={{paddingRight:'5px'}}><img className="bullet-circle-img" src="assets/images/bullet-circle.png"/></span>  <span>{`${bulletPoint}`}</span></div>
                              })}
                              </div>
                           </div>: null}
                           {services.toString().trim() === "Facebook Adverting" ? <div>
                                <p className="long-heading-text font-black font-bold-contract-light">Google and Facebook are responsible for 58.5% of all advertising dollars spent.  This is primarily due to their positioning in the market and their ability to directly engage with a large portion of consumers online.  Marketing Machine will run coordinated, targeted AdWords and PPC campaigns through Google and Facebook (as well as Instagram).  This will complement {compnayName}'s branding, while enhancing existing marketing efforts. These variable campaigns will be proofed through rigorous A/B testing and analysis.</p>
                               <div className="bullet-container font-black mb-10"> 
                              {adsBullet.map((bulletPoint)=>{
                                return <div className="bullet-text font-black font-bold-contract-light"> <span style={{paddingRight:'5px'}}><img className="bullet-circle-img" src="assets/images/bullet-circle.png"/></span>  <span>{`${bulletPoint}`}</span></div>
                              })}
                              </div>
                               </div>:""}
                           {services.toString().trim() === "Instragram Advertising" ? <div>
                               <p className="long-heading-text font-black font-bold-contract-light">Google and Facebook are responsible for 58.5% of all advertising dollars spent.  This is primarily due to their positioning in the market and their ability to directly engage with a large portion of consumers online.  Marketing Machine will run coordinated, targeted AdWords and PPC campaigns through Google and Facebook (as well as Instagram).  This will complement {compnayName}'s branding, while enhancing existing marketing efforts. These variable campaigns will be proofed through rigorous A/B testing and analysis.</p>
                                <div className="bullet-container mb-10"> 
                              {adsBullet.map((bulletPoint)=>{
                                return <div className="bullet-text font-black font-bold-contract-light"> <span style={{paddingRight:'5px'}}><img className="bullet-circle-img" src="assets/images/bullet-circle.png"/></span>  <span>{`${bulletPoint}`}</span></div>
                              })}
                              </div>
                             </div>
                             :null}
                             {services.toString().trim() === "Search Engine Optimization" ?
                            <div>
                            <p className="long-heading-text font-black font-bold-contract-light">{`Marketing Machine will perform a full marketing audit on ${filledcost.firstName ? `${filledcost.firstName} ${filledcost.lastName}` : `${filledcost.firstName ? `${filledcost.firstName} ${filledcost.lastName}`:``}`}'s online presence. This will include primary websites, satellite websites (if applicable), social media channels, and any other mediums which ${compnayName} is actively maintaining.  After keywords, traffic, code, framework, engagement, security, and all other factors have been properly analyzed, our team will clean up and address any / all reasons that Google, Yahoo, and Bing are not optimally crawling and ranking your digital footprint.  We recognize that SEO is integral to a marketing strategy, and use our technology expertise to ensure SEO that beats the competition.`}</p>
                              <div className="bullet-container mb-10"> 
                              { marketBulletPoint.seo.map((bulletPoint)=>{
                                return <div className="bullet-text font-black font-bold-contract-light"> <span style={{paddingRight:'5px'}}><img className="bullet-circle-img" src="assets/images/bullet-circle.png"/></span>  <span>{`${bulletPoint}`}</span></div>
                              })}
                              </div>
                           </div>: null}
                           {services.toString().trim() === "Content Creation" ? <div>
                                <p className="long-heading-text font-black font-bold-contract-light">{`Marketing Machine will run an efficient and rich content creation process.  This will be optimized for creation and syndication of existing content across all channels.  By crafting optimized content, ${compnayName} is providing free and useful information to the target audience, attracting potential customers to its website, and retaining existing customers through quality engagement.`}</p>
                               
                               </div>:null}
                           {services.toString().trim() === "Lead generation" ? <div>
                               <p className="long-heading-text font-black font-bold-contract-light">{`Marketing Machine will create and perform a warm lead generation campaign via passively  occurring, high-engagement email marketing campaigns.  Marketing Machine will tailor content specific to region, demographic, and perceived service interest.  Such refined targeting will be done through multivariate testing and analysis.  Each campaign is run with complete anonymity to ${compnayName} until the point that the prospective customer, sponsor, or partner will have affirmed interest in the mutually beneficial proposition.  The hand-off will be seamless, and occur once the target expresses interest in setting up a meeting to discuss the opportunity.  At this point, Marketing Machine will set up the meeting, and hand off the baton to you. `}</p>
                                 <div className="bullet-container mb-10"> 
                              { marketBulletPoint.leadGenration.map((bulletPoint)=>{
                                return <div className="bullet-text font-black font-bold-contract-light"> <span style={{paddingRight:'5px'}}><img className="bullet-circle-img" src="assets/images/bullet-circle.png"/></span>  <span>{`${bulletPoint}`}</span></div>
                              })}
                              </div>
                             </div>
                  
                             :null}
                           <div>
                              {services.toString().trim() === "Email marketing" ? <div>
                               <p className="long-heading-text font-black font-bold-contract-light">{`Marketing Machine will create and perform a warm lead generation campaign via passively  occurring, high-engagement email marketing campaigns.  Marketing Machine will tailor content specific to region, demographic, and perceived service interest.  Such refined targeting will be done through multivariate testing and analysis.  Each campaign is run with complete anonymity to ${compnayName} until the point that the prospective customer, sponsor, or partner will have affirmed interest in the mutually beneficial proposition.  The hand-off will be seamless, and occur once the target expresses interest in setting up a meeting to discuss the opportunity.  At this point, Marketing Machine will set up the meeting, and hand off the baton to you.`} </p>
                                <div className="bullet-container mb-10"> 
                              {  marketBulletPoint.emailMarketing.map((bulletPoint)=>{
                                return <div className="bullet-text font-black font-bold-contract-light"> <span style={{paddingRight:'5px'}}><img className="bullet-circle-img" src="assets/images/bullet-circle.png"/></span>  <span>{`${bulletPoint}`}</span></div>
                              })}
                              </div>
                             </div>
                  
                             :null}
                          
                            {services.toString().trim() === "Social Media Marketing" ? <div>
                               <p className="long-heading-text font-black font-bold-contract-light">{`Social media plays an important role in B2C and B2B for reputation validity and search  engine optimization. Marketing Machine will build and populate these platforms with relevant  content creating exposure and a cohesive brand identity across platforms. Included will be relevant backlinks, posting management and strategy, target audience, and passive advertising and customer outreach bringing awareness to ${compnayName}. Through effective management and content creation Marketing Machine will create a positive feedback loop which reinforces brand identity funneling prospective customers and partners to the primary points of conversion. Each of these platforms will  be built by our experts and maintained with creative oversight from you. You will have  access to 24/7 support and customization on all platforms.`}</p>
                              <div className="bullet-container mb-10"> 
                              { marketBulletPoint.seo.map((bulletPoint)=>{
                                return <div className="bullet-text font-black font-bold-contract-light"> <span style={{paddingRight:'5px'}}><img className="bullet-circle-img" src="assets/images/bullet-circle.png"/></span>  <span>{`${bulletPoint}`}</span></div>
                              })}
                              </div>
                             </div>
                  
                             :null}
                             </div>
                             {services.toString().trim() === "Branding" ?
                            <div>
                            <p className="long-heading-text font-black font-bold-contract-light">Every business has a brand. We believe that your brand is your reputation. Every business rises or falls by how it contends with its reputation.  This is the result of people's experience of using your business.  A brand can be an identification or a mark that differentiates one business from another.  Building a brand helps customers in their decision-making process.  Savvy branding creates a perceived knowledge of what each customer is inclined to buy - before they decide to buy it.  A brand builds confidence and provides trust, thereby influencing how those pivotal decisions are made in the marketplace.</p>
                              <div className="bullet-container mb-10"> 
                              {  desingBulletPoint.map((bulletPoint)=>{
                                return <div className="bullet-text font-black font-bold-contract-light"> <span style={{paddingRight:'5px'}}><img className="bullet-circle-img" src="assets/images/bullet-circle.png"/></span>  <span>{`${bulletPoint}`}</span></div>
                              })}
                              </div>
                           </div>: null}
                           {services.toString().trim() === "Advising / Consulting" ?
                            <div>
                            <p className="long-heading-text font-black font-bold-contract-light">{`Marketing Machine Inc and Rainmakers will advise ${filledcost.firstName ? `${filledcost.firstName} ${filledcost.lastName}`:``} on product roadmap strategy, path to market, early stage operations strategy in the technology space, platform growth modeling, fundraising and venture capital, and user journey based on our expertise and experience bringing over 200 companies to market. Additionally, we will offer the use of our internal proprietary marketing, fundraising, and lead gen technologies to assist with incubation and early stage growth acceleration of the ${filledcost.firstName ? `${filledcost.firstName} ${filledcost.lastName}`:``}'s platform.`}</p>
                                <div className="bullet-container mb-10"> 
                              { incubationBulletPoint.dvisingConsulting.map((bulletPoint)=>{
                                return <div className="bullet-text font-black font-bold-contract-light"> <span style={{paddingRight:'5px'}}><img className="bullet-circle-img" src="assets/images/bullet-circle.png"/></span>  <span>{`${bulletPoint}`}</span></div>
                              })}
                              </div>
                           </div>: null}
                           {services.toString().trim() === "Product Conceptualization" ? <div>
                                <p className="long-heading-text font-black font-bold-contract-light">{`Marketing Machine Inc will wireframe a preliminary, thorough MVP of ${filledcost.firstName ? `${filledcost.firstName} ${filledcost.lastName}`:``}'s platform and work with ${filledcost.firstName ? `${filledcost.firstName} ${filledcost.lastName}`:``} to develop an initial professional design for this wireframe. The end result of this will be to have a wireframe and design which once approved can be handed off to directly develop the platform against. To that effect, we will develop custom standalone wireframes and designs to prepare for a technical specification and platform build. Marketing Machine Inc will advise throughout the process based on our expertise incubating early stage technology platforms.`}</p>
                               <div className="bullet-container mb-10"> 
                              { incubationBulletPoint.productConceptualization.map((bulletPoint)=>{
                                return <div className="bullet-text font-black font-bold-contract-light"> <span style={{paddingRight:'5px'}}><img className="bullet-circle-img" src="assets/images/bullet-circle.png"/></span>  <span>{`${bulletPoint}`}</span></div>
                              })}
                              </div>
                               </div>:""}
                             

          
        </div>
      </div>
      </div>
    </>
  })}
  </div>

    </div>
  
 
 }


 export const totalCost = (approvedCost,cost = 20) => {
  const {advertisingCost,designCost,developmentCost,incubationCost,marketingCost} = approvedCost
  const adsTime = advertisingCost.length !==0 ? advertisingCost.map(approveCost =>  parseInt(approveCost.cost)) : null
  const devTime = developmentCost.length !==0 ? developmentCost.map(approveCost => parseInt(approveCost.cost)) : []
  const designTime = designCost.length !==0 ? designCost.map(approveCost => parseInt(approveCost.cost)) : null
  const incubationTime = incubationCost.length !==0 ? incubationCost.map(approveCost => parseInt(approveCost.cost)) : null
  const marketTime = marketingCost.length !==0 ? marketingCost.map(approveCost => parseInt(approveCost.cost)) : null
  let  allTime = devTime.concat(adsTime,designTime,incubationTime,marketTime).filter(x => x !== null && x !== undefined).reduce((partialSum, a) => partialSum + a, 0);
  return  `${allTime += allTime * cost.price / 100 }`
}


export const renderShortDiscription = (filledCost) =>{
  const approvedCost = Object.assign({},filledCost[0])
  const {advertisingCost,designCost,developmentCost,incubationCost,marketingCost,consultingCost} = approvedCost
  const adsTime = advertisingCost.length !==0 ? advertisingCost.map(approveCost => approveCost.category) : null
  const devTime = developmentCost.length !==0 ? developmentCost.map(approveCost => approveCost.category) : []
  const designTime = designCost.length !==0 ? designCost.map(approveCost => approveCost.category) : null
  const incubationTime = incubationCost.length !==0 ? incubationCost.map(approveCost => approveCost.category) : null
  const marketTime = marketingCost.length !==0 ? marketingCost.map(approveCost => approveCost.category) : null
  const consultingTime =consultingCost && consultingCost.length !==0 ? consultingCost.map(approveCost => approveCost.category) : null
  const  allCategory = devTime.concat(adsTime,designTime,incubationTime,marketTime,consultingTime).filter(x => x !== null && x !== undefined)

  return   allCategory.map((list)=>{
    // development short discription 
    if(list === "Custom Software Development" || list === "Custom Software Devlopment"){
      return <p className="para-text d-flex"><span><img className="bullet-img"  src="assets/images/triangle.png"/> </span> <span>Build custom standalone software platform (See <b className="text-semibold"> Custom Software Development.</b>) </span></p>
    }
    if(list === "Custom Website Development" || list === "Custom Website Devlopment"){
      return <p className="para-text d-flex"><span><img className="bullet-img" src="assets/images/triangle.png"/> </span> <span>Enhance and coordinate brand image at all digital patient conversion points (See <b className="text-semibold"> Custom Website Development.</b>) </span></p> 
    }
      if(list === "Artificial Intelligence / Machine Learning"){
      return <p className="para-text d-flex"><span><img className="bullet-img" src="assets/images/triangle.png"/> </span> <span> standalone machine learning software system  (See <b className="text-semibold"> Artificial Intelligence.</b>)  </span></p>
    }
    // marketing short discription

    if(list === "Search Engine Optimization"){
      return <p className="para-text d-flex"><span><img className="bullet-img" src="assets/images/triangle.png"/> </span><span>Optimize technical page structure to improve placement on SERPs (See <b className="text-semibold"> Search Engine Optimization.</b>) </span></p>
    }
      if(list === "Content Creation"){
      return <p className="para-text d-flex"><span><img className="bullet-img" src="assets/images/triangle.png"/> </span> <span> Creative and Content Production (See <b className="text-semibold"> Content Creation.</b>)</span></p>
    }
     if(list === "Lead Generation"){
      return <p className="para-text d-flex"><span><img className="bullet-img" src="assets/images/triangle.png"/> </span><span>Custom lead funnels targeting sponsorships, decision makers, and strategic partnerships (See <b className="text-semibold"> Lead Generation.</b>)</span></p>
    }
     if(list === "Email Marketing"){
      return <p className="para-text d-flex"><span><img className="bullet-img" src="assets/images/triangle.png"/> </span> <span>Cultivate strategic leads through targeted email campaign (See <b className="text-semibold"> Email Marketing.</b>) </span></p>
    }
     if (list.trim() === "Social Media Marketing"){
      return <p className="para-text d-flex"><span><img className="bullet-img" src="assets/images/triangle.png"/> </span> <span>Enhance and coordinate brand image at all digital patient conversion points (See <b className="text-semibold"> Social Media Marketing.</b>)</span></p>
    }

    //ads short discription 

    if(list.trim() === "Google Adwords"){
      return <p className="para-text d-flex"><span><img className="bullet-img" src="assets/images/triangle.png"/> </span><span>Targeted and coordinated AdWords and PPC campaigns to fuel growth and market adoption (See <b className="text-semibold"> Google Adwords.</b>) </span> </p>
    }
      if(list.trim() === "Facebook Ads"){
      return <p className="para-text d-flex"><span><img className="bullet-img" src="assets/images/triangle.png"/> </span> <span>Targeted and coordinated AdWords and PPC campaigns to fuel growth and market adoption  (See <b className="text-semibold"> Facebook Ads.</b>)</span></p>
    }
     if(list.trim() === "Instagram Advertising"){
      return <p className="para-text d-flex"><span><img className="bullet-img" src="assets/images/triangle.png"/> </span><span>Targeted and coordinated AdWords and PPC campaigns to fuel growth and market adoption (See <b className="text-semibold"> Instagram Advertising.</b>)</span></p>
    }

    //design short discription 

    if(list.trim() === "Branding"){
      return <p className="para-text d-flex"><span><img className="bullet-img" src="assets/images/triangle.png"/> </span> <span>Complete Branding Package  (See <b className="text-semibold"> Branding Design.</b>)</span></p>
    }

    // inqubation short discription

    if(list.trim() === "Advising / Consulting"){
      return <p className="para-text d-flex"><span><img className="bullet-img" src="assets/images/triangle.png"/> </span><span>Advise on systems infrastructure, architecture, product concept, and technology capability (See <b className="text-semibold"> Advising / Consulting.</b>)</span></p>
    }
     if(list.trim() === "Product Conceptualization"){
      return <p className="para-text d-flex"><span><img className="bullet-img" src="assets/images/triangle.png"/> </span> <span>Platform Wireframes and Design (See <b className="text-semibold"> Product Conceptualization.</b>)</span></p>
    }
    if(list.trim() === "Managed Resource"){
      return <p className="para-text d-flex"><span><img className="bullet-img" src="assets/images/triangle.png"/> </span> <span>Provide admin team support (See <b className="text-semibold"> Managed Resource.</b>)</span></p>
    }
   return  <p className="para-text d-flex"><span><img className="bullet-img" src="assets/images/triangle.png"/> </span> <span>{list} (See <b className="text-semibold"> {list}.</b>)</span></p>

  })

  // consulting short discription 

  
}