import * as apiURL from "./ApiConfig";
import { secureFetch } from "./securefetch";

export const slackServices = {
  sendMessage,
};

function sendMessage(text) {
  const requestOptions = {
    method: "POST",
    headers: {},
    body: JSON.stringify({ text }),
  };

  return secureFetch(
     //"https://hooks.slack.com/services/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",   // for  localhost
     "https://hooks.slack.com/services/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",    // production 
    requestOptions
  )
    .then((user) => {
      return user;
    })
    .catch((err) => {
      return err;
    });
}
