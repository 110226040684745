import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PDFDownloadLink, Document, Page } from "@react-pdf/renderer";
import MyDocument from "../../components/Pdf";
import Multiselect from "multiselect-react-dropdown";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { isArray, isEmpty } from "lodash";
import PortalReport from "../reporting/portalReport";
import {FormControl} from 'react-bootstrap'
import {questionnaire} from '../../service/questionnaire'
import ProjectReportPdf from  '../../components/ProjectReportPdf'
import { PDFExport , Component, ViewEncapsulation} from "@progress/kendo-react-pdf";
import {getTaigaProjectName} from '../../store/actions/portal-report'
import Switch from '@mui/material/Switch';
import AgreementModal from "./agreementmodal";
import _ from 'lodash'
import moment from "moment";

import {
  Card,
  Form,
  Button,
  Dropdown,
  DropdownButton,
  ButtonToolbar,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { statusOptionValues } from "../../constents/dropdown-options";
import {slackServices} from '../../service/slackService'
import { developmentServices } from "../../constents/PdfText";
import { array } from "joi";


const DashboardDetailsTable =({ adminQuestionnaireList,indexOFFirstResult, ...props }) => {
  const [priceChange,setPriceChange] = useState(20)
  const [status, setStatus] = useState("pending");
  const [modalShow, setModalShow] = React.useState(false);
  const [listItem,setListItem] = useState()
  const [adminList,setadminList] = useState(  adminQuestionnaireList  )
  const  [cost,setCost] = useState();
  const [showPdf,setshowPdf] = useState(false);
  const [adminCost,setAdminCost] = useState()
  const [filledCost,setFilledCost] = useState()
  const [tempApprovedCost , setTempApprovedCost] = useState()
  const dispatch = useDispatch()
  const [showAgreementModal, setShowAgreementModal] = React.useState(false);
  
  

  
  const handleStatusChange = (e, _id) => {};
  const displayStatus = (dropdownStatus, _id) => {
    return (
      <DropdownButton
        id="dropdown-item-button"
        title={dropdownStatus}
        onSelect={(e) => {
          handleStatusChange(e, _id);
        }}
      >
        <Dropdown.Item key="approved" eventKey="approved" as="button">
          Approved
        </Dropdown.Item>
        <Dropdown.Item key={"project"} eventKey="project" as="button">
          Project
        </Dropdown.Item>
        <Dropdown.Item key={"pending"} eventKey="pending" as="button">
          Pending
        </Dropdown.Item>
      </DropdownButton>
    );
  };

  const LazyDownloadPDFButton = (cost,status,profitPercentage) => {
    
    let filledcost =
      cost.filledCost &&
      cost.filledCost.filter((list) => {
        return list.status === "approved" ?  cost.filledCost[0]  : null
        // return list.status === "approved" ? list.status === "approved" : list;
      });

    return (
      <button
        className={`all-unset ${status ==="approved" ? "curser-pointer":""}`}
        disabled={status ==="approved" ? false :true}
        onClick={async () => {
          //const props = await getProps();
          const doc = <MyDocument filledcost={filledcost} profitPercentage={profitPercentage}/>;
          const asPdf = pdf([]); // {} is important, throws without an argument
          asPdf.updateContainer(doc);
          const blob = await asPdf.toBlob();
          saveAs(blob, "mmi-funnel/" + cost.name + ".pdf");
        }}
      >
        <img className="pdf-img" src="../assets/images/pdf.png" />
      </button>
    );
  };

  const downloadPdf = () => {
    return (
      <PDFDownloadLink document={<MyDocument />} fileName="somename.pdf">
        {({ blob, url, loading, error }) =>
          loading ? "Loading document..." : "Download now!"
        }
      </PDFDownloadLink>
    );
  };

   
  const  onPriceChange = (e,list) => { 

     setadminList((preArr)=>{
      const newArrAdmin =   preArr.map((qlist)=>{
        if(qlist._id === list._id){
          qlist.price = e.target.value 
        } 
        return qlist
      }) 
       const updatePrice = newArrAdmin.filter((updatelist) => updatelist._id === list._id)
        questionnaire.updateQuestionnire(updatePrice[0]._id,{
        price:updatePrice[0].price

       })
       return newArrAdmin

     }) 
      
  }

  function replaceCommaLine(data) {
    if(data){
       let dataToArray = data.split(",").map((item ,index) => {
      if(index === 0 ){
        return  `    ◦ ${item.trim()}`
      }else {
        return  `         ◦ ${item.trim()}`
      }

    });
    return dataToArray.join("\n")
    }
    
    
}

   const  onFundedClick = (status,list) => { 
     setadminList((preArr)=>{
      const newArrAdmin =   preArr.map((qlist)=>{
        if(qlist._id === list._id){
          qlist.funded = status 
        } 
        return qlist
      }) 
       const updatePrice = newArrAdmin.filter((updatelist) => updatelist._id === list._id)
        questionnaire.updateQuestionnire(updatePrice[0]._id,{
        funded:updatePrice[0].funded
       })
       return newArrAdmin
     }) 

     const devCost = list.developmentServices && Object.values(list.developmentServices).join(',')
     const devService = replaceCommaLine(devCost)
     const marketCost = list.marketingServices  && Object.values(list.marketingServices).join(',')
     const marketService = replaceCommaLine(marketCost)
     const adsCost = list.advertisingServices  && Object.values(list.advertisingServices).join(',')
     const adsService = replaceCommaLine(adsCost)
     const designCost = list.designServices  && Object.values(list.designServices).join(',')
     const desigService = replaceCommaLine(designCost)
     const incubationCost = list.incubationServices  && Object.values(list.incubationServices).join(',')
     const incubationService = replaceCommaLine(incubationCost)
     


    const message = `  Hi Team,
     A new project has been funded, please setup project on portal. Details are given below : 
     *Client* : ${list.name}
     *Services Requested* : 

     ${list.developmentServices &&  `• Development` } 
     ${devService}
     ${list.marketingServices &&  `• Marketing` } 
     ${marketService}
     ${list.advertisingServices && `• Advertising`  }
     ${adsService}
     ${list.designServices &&  `• Design`}
     ${desigService}
      ${list.incubationServices &&  `• Incubation`}
     ${incubationService}
     *Description:*  ${list.discription}
     `
     //console.log("message===",message.replaceAll("null","").replaceAll("undefined","").replaceAll(/(^[ \t]*\n)/gm, ""))
      if(status){
        slackServices.sendMessage(message.replaceAll("null","").replaceAll("undefined","").replaceAll(/(^[ \t]*\n)/gm, "" ))
      }
      
  }

 

  const onProjectCreate = (item) => {
    setListItem(item)
    setModalShow(true)
    // dispatch(getTaigaProjectName())
  }
  
  useEffect(()=>{
    setadminList(adminQuestionnaireList)
  },[adminQuestionnaireList])

  const showAgreementPropsalModal = (list,price,approvedCost) => {
    if(list.status !== 'pending'){
    setCost(list)
    setAdminCost(price)
    setTempApprovedCost(approvedCost)
    setShowAgreementModal(true)
    }
    
  }


  const showTimeLine = (approvedCost,list) => {
      let totalTime = 0
      for(let cost in approvedCost) {
        if(Array.isArray(approvedCost[cost])){
           for(let times of approvedCost[cost]){
                totalTime += times.time           }
        }
      }
      // const {advertisingCost,designCost,developmentCost,incubationCost,marketingCost,consultingCost} = approvedCost
      // const adsTime = advertisingCost.length !==0 ? advertisingCost.map(approveCost => approveCost.time) : null
      // const devTime = developmentCost.length !==0 ? developmentCost.map(approveCost => approveCost.time) : []
      // const designTime = designCost.length !==0 ? designCost.map(approveCost => approveCost.time) : null
      // const incubationTime = incubationCost.length !==0 ? incubationCost.map(approveCost => approveCost.time) : null
      // const marketTime = marketingCost.length !==0 ? marketingCost.map(approveCost => approveCost.time) : null
      // const consulTime = consultingCost && consultingCost.length !==0 ? consultingCost.map(approveCost => approveCost.time) : null
      // const  allTime = devTime.concat(adsTime,designTime,incubationTime,marketTime,consulTime).filter(x => x !== null && x !== undefined).reduce((partialSum, a) => partialSum + a, 0);
      // return  `${allTime} months`
      return `${totalTime} months`

  }


  return (
    <>
      <table id="adminDashboard">
        <tr>
          <th>#</th>
          <th>Client</th>
          <th>Id</th>
          <th>Link</th>
          <th> Price change </th>
          <th> Payout </th>
          <th> Delivery timeline </th>
          <th>Submission Date</th>
          <th> Funded </th>
          <th>Status</th>
          <th> Action </th>
        </tr>
        {adminQuestionnaireList &&
          adminQuestionnaireList.map((list, index) => {
          
            return (
              <tr key={list._id}>
                <td>{index + 1 + indexOFFirstResult}</td>
                <td>
                  <p className="p mb-none font-600"> {list.firstName ?   `${list.firstName} ${list.lastName}` : list.name}</p>{" "}
                </td>
                {/* <td>{list.questionnaire._id}</td> */}
                <td>#</td>
                <td>#
                 </td>
                <td style={{width:"150px"}}>
                <FormControl style={{width:"150px"}}  type="number" step={5} value={list.price} onChange={(e)=>onPriceChange(e,list)}/>
                </td>
                <td>
                 #
                </td>
                <td>  {list.approvedcosts.length !== 0 ? showTimeLine(Object.assign({},list.approvedcosts[0]),list) : "#"}</td>
                <td>{moment(list.createdAt).format("DD/MM/YYYY")}</td>
                <td>
                    <Switch
                      
                      value={list.funded}
                      checked={list.funded}
                      onChange={(e)=>onFundedClick(!list.funded,list)}
                       color="info"
                       disabled={list.status === "pending" ? true : false}
                    />
                </td>
                <td>
                  <p className="p mb-none font-600">{list.status}</p>
                </td>
                <td className=" ">
                  <div className="td-link">
                    <span>
                      <Link
                        className="d-flex justify-center"
                        to={`admin-servicecost/?request_id=${list._id}`}
                      >
                        <img src="../assets/images/link.png" alt="" />
                      </Link>
                    </span>
                   
                      <img  onClick={() => showAgreementPropsalModal(list,list.price,list.approvedcosts ,list.status)} className="pdf-img curser-pointer" src="../assets/images/pdf.png" />

                      {/* {list.status === "approved" ? <span onClick={()=>{
                        // do not remove this is for pdf view 
                        setshowPdf(!showPdf)
                        setCost(list)
                        setAdminCost(list.price)
                        setTempApprovedCost(list.approvedcosts)

                      }}>  <img className="pdf-img" src="../assets/images/pdf.png" /> </span> :  <img className="pdf-img" src="../assets/images/pdf.png" />} */}


                    <>
                    <span
                      className="curser-pointer"
                      onClick={()=>onProjectCreate(list)}
                      style={{ fontSize: "12px" }}

                    >
                       <img className="pdf-img" src="../assets/images/project.png" alt="" />
                    </span>

                   
                  </>
                  </div>
                </td>
              </tr>
            );
          })}
      </table>
    
      {modalShow &&   <PortalReport
      show={modalShow}
      onHide={() => setModalShow(false)}
      questionnaireData={listItem}
    />}
  {/* {showPdf &&  <ProjectReportPdf cost={cost} adminCost={adminCost} filledCost={ cost.filledCost &&
                      cost.filledCost.filter((costList) => {
                       return costList.status === "approved" ?  cost.filledCost[0]  : null
                       // return list.status === "approved" ? list.status === "approved" : list;
                      })} approvedcosts ={tempApprovedCost}/>} */}

                            {showAgreementModal &&   <AgreementModal
                            show={showAgreementModal}
                            onHide={() => setShowAgreementModal(false)}
                            status={cost.status === "approved"  ? true : false}
                            selectedRow={""}
                            modalTitile={'Agreement and Proposal'}
                            cost={cost}
                            adminCost={adminCost}
                            filledCost={ cost.filledCost &&
                            cost.filledCost.filter((costList) => {
                            return costList.status === "approved" ?  cost.filledCost[0]  : null
                            // return list.status === "approved" ? list.status === "approved" : list;
                            })}
                            approvedcosts ={tempApprovedCost}
                            adminQuestionnaireList={adminList}
                            />}
    </>
  );
};

export default DashboardDetailsTable;
