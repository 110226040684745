import React from 'react'
import ReCAPTCHA from "react-google-recaptcha"


function Captch ({captchClick,detectMob,recaptchaRef}) {
    return <div style={{marginTop:"2rem"}}>
    <ReCAPTCHA
     //sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"   // localhost
     sitekey="6Ld6LyUhAAAAALr8G-XXwFZStBaaRqVyHl-dA5gR"    // production
     onChange={()=>{captchClick(false)}}
     style={{transform:detectMob() ? "scale(0.80)" : "",transformOrigin:"0"}}
     ref={recaptchaRef}
     />
    </div>
}

export default Captch