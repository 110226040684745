import {
  GET_ALL_PORTAL_REPORT_START,
  GET_ALL_PORTAL_REPORT_SUCCESS,
  GET_ALL_PORTAL_REPORT_FAILED,
  GET_KANBAN_START,
  GET_KANBAN_SUCCESS,
  GET_KANBAN_FAILED,
  GET_KANBAN_SPRINT_START,
  GET_KANBAN_SPRINT_SUCCESS,
  GET_KANBAN_SPRINT_FAILED,
  GET_PROJECT_FROM_TAIGA_START,
  GET_PROJECT_FROM_TAIGA_SUCCESS,
  GET_PROJECT_FROM_TAIGA_FAILED,
  GET_TAIGA_CREATED_SPRINT_REPORT_FAILED,
  GET_TAIGA_CREATED_SPRINT_REPORT_SUCCESS,
  GET_TAIGA_CREATED_SPRINT_REPORT_START
} from "../../constents/actionType";

const initialState = {
  allCreateProtal: {},
  portalLoading: false,
  error: null,
  kanban:{},
  usersSprintData : [],
  issueSprintData : [],
  isKanbanLoading:false,
  taigaProjectList : [],
  taigaUserstories : [],
  taigaIssues : [],
  createdSprints : [],
  emailLoading : false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_PORTAL_REPORT_START: {
      return {
        ...state,
        portalLoading: true,
      };
    }
    case GET_ALL_PORTAL_REPORT_SUCCESS: {
      return {
        ...state,
        portalLoading: false,
        allCreateProtal: action.payload,
      };
    }
    case GET_ALL_PORTAL_REPORT_FAILED: {
      return {
        ...state,
        portalLoading: false,
        error: action.payload,
      };
    }
    case GET_KANBAN_START : {
     
      return {
        ...state,
        isKanbanLoading:true,
        error:null
      }
    }
    case GET_KANBAN_SUCCESS :{
      return {
        ...state,
        kanban:action.payload,
        error:null,
        isKanbanLoading:false
      }
    }
    case GET_KANBAN_FAILED : {
      return {
        ...state,
        isKanbanLoading:false,
        error:action.payload,

      }
    }
    case GET_KANBAN_SPRINT_START : {
      return {
        ...state,
        isKanbanLoading:true,
        error:null
      }

    }
    case GET_KANBAN_SPRINT_SUCCESS :{
      return {
        ...state,
       usersSprintData:action.payload === undefined ? [] : action.payload.processedData,
       issueSprintData:action.payload === undefined ? [] :action.payload.issuesProcessedData,
        error:null,
        isKanbanLoading:false
      }
    }
    case GET_KANBAN_SPRINT_FAILED : {
      return {
        ...state,
        isKanbanLoading:false,
        error:action.payload,
      }
    }
    case GET_PROJECT_FROM_TAIGA_START : {
      return {
        ...state,
        isKanbanLoading:true,
        error:null
      }
    }

    case GET_PROJECT_FROM_TAIGA_SUCCESS : {
      return {
        ...state,
        taigaProjectList : action.payload,
        error:null,
        isKanbanLoading:false
      }
    }
    
    case GET_PROJECT_FROM_TAIGA_FAILED : {
      return {
        ...state,
        isKanbanLoading:false,
        error:action.payload,
      }
    }

    case GET_TAIGA_CREATED_SPRINT_REPORT_START : {
      return {
        ...state,
        emailLoading:true,
        error:null
      }
    }

    case GET_TAIGA_CREATED_SPRINT_REPORT_SUCCESS : {
      return {
        ...state,
        createdSprints : action.payload,
        error:null,   
        emailLoading : false
         }
    }
    
    case GET_TAIGA_CREATED_SPRINT_REPORT_FAILED : {
      return {
        ...state,
        error:action.payload,
        emailLoading : false
      }
    }
 
    default:
      return state;
  }
};
