import React, { useState, useEffect } from "react";
import Multiselect from "multiselect-react-dropdown";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getQuestionnaire,
  getQuestionnaireDetailWithCost,
  ServiceRequest
} from "../../store/actions/questionnaire";
import { Card, Form,Button , } from "react-bootstrap";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import ReactTooltip from "react-tooltip";
import DisplayAdminFilledCost from "./displayadminfilledcost";
import {questionnaire} from '../../service/questionnaire'
import { toast } from "react-toastify";
import { slackServices } from "../../service/slackService";
import {doYouKnowValues,
    marketingOptions,
    addServices,
    needService,
    incubation,
    design,
    development,
    consultingOptions1,consultingOptions2,
    developmentOptions1,developmentOptions2,
    marketingOptions1,marketingOptions2
  } from '../../constents/dropdown-options'
import { getApprovedCost } from "../../store/actions/service-request";
import DisplayAdminApprovedCost from "./Displayadminapprovedcost";




const AdminServiceCostEdit = ({findParams,identifyServices ,approvedCost}) => {


  // const [error, setError] = useState("");
  const [isAdmin, setIsAdmin] = useState(
    JSON.parse(localStorage.getItem("isAdmin"))
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLogin, setIsLogin] = useState(
    Cookies.get("userAccessToken",{path:"/"})
  );
  const [value, setValue] = useState();

  const OrgName = useSelector((state) => state.questionnaire?.questionnaireData?.firstName);
  const lastName = useSelector((state) => state.questionnaire?.questionnaireData?.lastName);
  const orgEmail = useSelector((state) => state.questionnaire?.questionnaireData?.email);
  const [name, setName] = useState(`${OrgName} ${lastName}`)
  const [email,setEmail] = useState(orgEmail)
  const [error, setError] = useState({
    orgName: "",
    identifyingService: "",
    serviceNeed: "",
    marketingService: "",
    advertisingService: "",
    discription: "",
    incubation: "",
    design: "",
    development: "",
    consultingServices : "",
  });



  const [toggel, setToggel] = useState(true);
  const [questionnaire_id, setQuestionnaire_id] = useState(
    localStorage.getItem("questionnaireId")
  );
  const authId = useSelector((state) => state.auth.loginId);
  const helpStrategy =  useSelector((state) =>  state.questionnaire && state.questionnaire.questionnaireData.identifyServices );
  const discription = useSelector((state) => state.questionnaire && state.questionnaire.questionnaireData.discription);
  const lookingFor = useSelector((state) => state.questionnaire && state.questionnaire.questionnaireData.lookingFor);

  const [userId, setUserId] = useState(authId);

  const user_id = JSON.parse(localStorage.getItem("user_id"));
  const serviceRequestStatus = useSelector((state) => {
    return (
      state.questionnaire.questionnaireDetailCost &&
      state.questionnaire.questionnaireDetailCost &&
      state.questionnaire.questionnaireDetailCost.costList
    );
  });

  const questionnaireStatus = useSelector((state)=>state.questionnaire.questionnaireData && state.questionnaire.questionnaireData.status ) ;
  const questionnaireDisable = useSelector((state)=>state.questionnaire.questionnaireDetailCost.costs && state.questionnaire.questionnaireDetailCost.costs.length !==0 ?  true : false)



  const developmentOption = useSelector((state) => {
    return (
      state.questionnaire.questionnaireData.developmentServices &&
      state.questionnaire.questionnaireData.developmentServices !== null &&
      state.questionnaire.questionnaireData.developmentServices.map(
        (value) => ({
          key: value,
        })
      )
    );
  });




  // const handleDropdownClose =() => {
  //   setDevelopmentVisible(false),
  //   setMarketingDropVisible(false),
  //   setAdminAdvertising(false)
  // }

  const questionnaireId = useSelector((state) => {
    return state.questionnaire && state.questionnaire.questionnaireData.id;
  });

  const [requstServiceId, setRequestServiceId] = useState();

  const questionnaireData = useSelector((state) => {
    return state.questionnaire && state.questionnaire.questionnaireData;
  });

  // const helpStrategy =  useSelector((state) => {
  //   return (
  //     state.questionnaire && state.questionnaire.questionnaireData.identifyServices
  //   );
  // });
  const marketingServices = useSelector((state) => {
    return (
      state.questionnaire.questionnaireData.marketingServices &&
      state.questionnaire.questionnaireData.marketingServices !== null &&
       state.questionnaire.questionnaireData.marketingServices.map(
        (value) => ({
          key: value,
        })
      )
    );
  });


  const advertisingServices = useSelector((state) => {
    return (
      state.questionnaire.questionnaireData.advertisingServices &&
      state.questionnaire.questionnaireData.advertisingServices !== null &&
      state.questionnaire.questionnaireData.advertisingServices.map(
        (value) => ({
          key: value,
        })
      )
    );
  });



  const designCost = useSelector((state) => {
    return (
      state.questionnaire.questionnaireData.designServices &&
      state.questionnaire.questionnaireData.designServices !== null &&
      state.questionnaire.questionnaireData.designServices.map((value) => ({
        key: value,
      }))
    );
  });

  const incubationCost = useSelector((state) => {
    return state.questionnaire.questionnaireData &&  state.questionnaire.questionnaireData.incubationServices !== null &&
    state.questionnaire.questionnaireData.incubationServices.map((value) => ({
          key: value,
        }))
  });

   const consultingCost = useSelector((state) =>  state.questionnaire?.questionnaireData?.consultingServices !== null && state.questionnaire.questionnaireData.consultingServices.map((value) => ({ key: value, })))
 

   // console.log("state===",state.questionnaire.questionnaireData)
    // return (
    //   state.questionnaire.questionnaireData &&
    //   state.questionnaire.questionnaireData.incubationServices &
    //   state.questionnaire.questionnaireData.incubationServices.map((value) => ({
    //     key: value,
    //   }))
    // );


   const selectedServiceNeed =useSelector((state)=>state.questionnaire && state.questionnaire.questionnaireData.servicesNeed )




  const [serviceNeed,setServicesNeed] = useState(selectedServiceNeed)
  const [newServiceNeed,setNewServiceNeed] = useState(selectedServiceNeed)
  const [adminDevelopment,setAdminDevelopment] = useState(developmentOption)
  const [adminMarketing,setAdminMaketing] = useState(marketingServices)
  const [adminAdvertising,setAdminAdvertising] = useState(advertisingServices)
  const [adminDesign,setAdminDesign] = useState(designCost)
  const [adminIncubation,setAdminIncubation] = useState(incubationCost)
  const [adminConsulting,setAdminConsulting] = useState(consultingCost)
  
  const [detail, setDetail] = useState(discription);


  const [marketingDropVisible, setMarketingDropVisible] = useState(false);
  const [adsVisible, setAdsVisible] =useState(false);
  const [developmentVisibile, setDevelopmentVisible] = useState(false);
  const [designVisibile, setDesignVisible] =useState(false);
  const [incubationVisibile, setIncubationVisible] = useState(false);
  const [consultingVisibile, setConsultingVisible] = useState(false);



  // useEffect(()=>{

  //   setServicesNeed(selectedServiceNeed)

  // },[selectedServiceNeed])



  const costdetails = useSelector((state) => {
    return (
      state.questionnaire.questionnaireDetailCost &&
      state.questionnaire.questionnaireDetailCost.costList
    );
  });

  const questionnaireDetails = useSelector((state) => {
    return (
      state.questionnaire &&
      state.questionnaire.questionnaireData &&
      state.questionnaire.questionnaireData
    );
  });




  // const onDropDownValueSelect = (data) => {
  //   const {
  //     advertisingCost,
  //     developmentCost,
  //     incubationCost,
  //     marketingCost,
  //     designCost,
  //   } = data;

  //   const request_id = findParams();
  //   data.questionnaireId = request_id;
  //   data.userId = userId || user_id;
  //   if (data) {
  //     dispatch(ServiceRequest(data));
  //     history.push("/dashboard");
  //   } else {
  //     setError("Please fill all fields ");

  //   }
  // };

  const questionnaireCostList = useSelector((state) => {
    return (
      state.questionnaire.questionnaireDetailCost &&
      state.questionnaire.questionnaireDetailCost &&
      state.questionnaire.questionnaireDetailCost.costList
    );
  });
  // tdIndex = (costdetails && costdetails.length + 1) || 1;

//   const findParams = () => {
//     const urlParams = new URLSearchParams(props.location.search);
//     const request_id = urlParams.get("request_id");
//     return request_id;
//   };


   const handleLogout = () => {
    localStorage.clear();
    Cookies.remove("userLogin");
    Cookies.remove("isAdmin")
    Cookies.remove("userAccessToken")
    Cookies.remove("isAdmin",{path:"/admin-servicecost"})
    Cookies.remove("userAccessToken",{path:"/admin-servicecost"})
    history.push("/admin-login");
     handePageRefresh();
  };

  const onDashBoradClick = () => {
    setServicesNeed(null)
  }

  const adminQuestionnaireList = useSelector((state) => {
    return (
      state.questionnaire.questionnaireAdminList &&
      state.questionnaire.questionnaireAdminList.map(
        (element) => element.filledCost
      )
    );
  });

  const finalcost =
    adminQuestionnaireList &&
    adminQuestionnaireList.map((value) =>
      value.filter((elemet) => elemet.questionnaireId === questionnaireId)
    );

  let result = finalcost && finalcost.reduce((r, e) => (r.push(...e), r), []);

  const handePageRefresh = () => {
    setValue({});
  };

  useEffect(() => {
    setIsLogin(Cookies.get("userAccessToken",{path:"/"}))
    setIsAdmin(JSON.parse(localStorage.getItem("isAdmin")));
    const request_id = findParams();
    localStorage.setItem("questionnaireId", request_id);
    setRequestServiceId(request_id && request_id);
    setQuestionnaire_id(localStorage.getItem("questionnaireId"));
     if (request_id && isLogin !==undefined){
      localStorage.setItem("questionnaireId", request_id);
      dispatch(getQuestionnaire(request_id));
      dispatch(getQuestionnaireDetailWithCost(request_id));
      setQuestionnaire_id(localStorage.getItem("questionnaireId"));
      dispatch(getApprovedCost(request_id))
    }


    if (isLogin === undefined) {
      history.push({
        pathname: "/admin-login",
        state: {
          request_id,
        },
      });
    }

    // setAdminDevelopment(developmentOption)


  }, []);

  useEffect(() => {
     setUserId(authId);
     discription ? setDetail(discription) : setDetail(lookingFor)
  }, [authId ,discription,lookingFor]);


  useEffect(() => {
    setName(`${OrgName} ${lastName}`);
    setNewServiceNeed(selectedServiceNeed)
     setServicesNeed(selectedServiceNeed)


    // setDevelopmentVisible(developmentOption && developmentOption.length !== 0 ? true: false)
    // setMarketingDropVisible(marketingServices && marketingServices.length !== 0 ? true: false)
    // setAdsVisible(advertisingServices && advertisingServices.length !== 0 ? true: false)
    // setDesignVisible(designCost && designCost.length !== 0 ? true: false)
    // setIncubationVisible(incubationCost && incubationCost.length !== 0 ? true: false)
    setAdminDevelopment(developmentOption)
    setAdminAdvertising(advertisingServices)
    setAdminMaketing(marketingServices)
    setAdminDesign(designCost)
    setAdminIncubation(incubationCost)
    setAdminConsulting(consultingCost)
    //setDropDownVisible()
  }, [OrgName]);

  useEffect(()=>{
    setServicesNeed(selectedServiceNeed)
    setDropDownVisible()
    return () => {
      handleDropDownClose()
    }

  },[selectedServiceNeed])

  const sendSlackMessage = (id) => {
    slackServices.sendMessage(
      `Request for Teams - <${process.env.REACT_APP_HOST}/serviceandcost/?request_id=${id}|${process.env.REACT_APP_HOST}/serviceandcost/?request_id=${id} >
     `
    );


  };

  const redirectPipeDrive = () => {
    setTimeout(() => {
      window.open(
        "https://rainmakers.pipedrive.com/scheduler/JJ5xxH3/intro-call",
        "_self"
      );
    }, 3000);
  }


  const setDropDownVisible = () => {
    if(selectedServiceNeed && selectedServiceNeed !==null){
     setMarketingDropVisible(selectedServiceNeed && selectedServiceNeed.includes("Marketing"))
      setAdsVisible(selectedServiceNeed && selectedServiceNeed.includes("Advertising"))
     setDesignVisible(selectedServiceNeed && selectedServiceNeed.includes("Design"))
     setIncubationVisible(selectedServiceNeed && selectedServiceNeed.includes("Incubation"))
     setConsultingVisible(selectedServiceNeed && selectedServiceNeed.includes("Consulting"))
     if(selectedServiceNeed && selectedServiceNeed.includes(`Devlopment` ) || selectedServiceNeed && selectedServiceNeed.includes(`Development`)){
      setDevelopmentVisible(true)
     }else {
      setDevelopmentVisible(false)
     }

     }

  }

  const handleAdminQuestionnaire = () => {
    const request_id = findParams();

    if(newServiceNeed && newServiceNeed.length !== 0){

      if((developmentVisibile === true &&  adminDevelopment && adminDevelopment.length !== 0) || developmentVisibile === false ){

          if((marketingDropVisible === true &&  adminMarketing && adminMarketing.length !== 0) || marketingDropVisible === false ){

            if((consultingVisibile === true &&  adminConsulting && adminConsulting.length !== 0) || consultingCost === false ){

              if((designVisibile === true &&  adminDesign && adminDesign.length !== 0) || designVisibile === false ){

                   if((incubationVisibile === true &&  adminIncubation && adminIncubation.length !== 0) || incubationVisibile === false ){

                    const params = {
                      name: name,
                      identifyServices:identifyServices,
                      servicesNeed:
                        newServiceNeed && newServiceNeed.map((a) => a.key),
                      developmentServices:
                      adminDevelopment && adminDevelopment.length !== 0  ?
                        adminDevelopment.map((a) => a.key) : null,
                      marketingServices:
                      adminMarketing && adminMarketing.length !== 0  ?  adminMarketing.map((a) => a.key) : null,
                      advertisingServices:
                      adminAdvertising && adminAdvertising.length !== 0 ?
                        adminAdvertising.map((a) => a.key) :null,
                      discription: detail,
                      incubationServices:
                      adminIncubation && adminIncubation.length !==0 ?
                        adminIncubation.map((a) => a.key):null,
                        consultingServices:
                      adminConsulting && adminConsulting.length !==0 ? adminConsulting.map((a) => a.key):null,
                      designServices: adminDesign &&  adminDesign.length !== 0 ?  adminDesign.map((a) => a.key): null,
                    };

                    questionnaire
                    .updateQuestionnire(questionnaireDetails.id,params)
                    .then((response) => response.json())
                    .then((response) => {
                      if (response.id) {

                        sendSlackMessage(response.id,true);
                        dispatch(getQuestionnaireDetailWithCost(request_id));
                        setRequestServiceId(request_id && request_id);

                        history.push("/admindashboard")
                        toast.success(`Submit successfully
                          Message send on slack
                      `);
                        setError({});
                      }
                    })
                    .catch((err) => {
                      toast.error("Error");

                    });



                   }
                   else {
                    setError({
                      ...error,
                      incubation: "Please  fill incubation service field",
                    });
                   }
              } else {
                setError({
                  ...error,
                  design: "Please  fill design service field",
                });

              }
            } else {
              setError({
                ...error,
                advertisingService: "Please  fill consultingservice field",
              });
            }
          } else {
            setError({
              ...error,
              marketingService: "Please  fill marketing service field",
            });
          }

      }
      else {
        if (developmentVisibile) {
          setError({
            ...error,
            development: "Please  fill development service field",
          });
        }
      }

    } else {
      setError({
        ...error,
        serviceNeed: "Please  fill service need  field",
      });

    }

  }

  const handleExpendDropDown = (data, item) => {


   // setServicesNeed(data);

    if (item && item.key === "Marketing") {
      setMarketingDropVisible(true);
    }
    if (item && item.key === "Advertising") {
      setAdsVisible(true);
    }
    if (item && item.key === "Design") {
      setDesignVisible(true);
    }
    if (item && item.key === "Devlopment" || item && item.key ==="Development") {
      setDevelopmentVisible(true);

    }
    if (item && item.key === "Incubation") {
      setIncubationVisible(true);
    }

    if (item && item.key === "Consulting") {
      setConsultingVisible(true);
    }
  };

  const handleRemove = (item,type) => {
    if (item && item.key === "Marketing") {
      setMarketingDropVisible(false);
      setAdminMaketing(null)
      setError({
        ...error,
        marketingService: "",
      });

    }
    if (item && item.key === "Advertising") {
      setAdsVisible(false);
      setAdminAdvertising(null)
      setError({
        ...error,
        advertisingService: "",
      });
    }
    if (item && item.key === "Design") {
      setDesignVisible(false);
      setAdminDesign(null)
      setError({
        ...error,
        design: "",
      });
    }
    if (item && item.key === "Devlopment" || item && item.key ==="Development") {
      setDevelopmentVisible(false);
      setAdminDevelopment(null)
      setError({
        ...error,
        development: "",
      });

    }
    if (item && item.key === "Incubation") {
      setIncubationVisible(false);
      setAdminIncubation(null)
      setError({
        ...error,
        incubation: "",
      });
    }

    if (item && item.key === "Consulting") {
      setConsultingVisible(false);
      setAdminConsulting(null)
      setError({
        ...error,
        consultingServices: "",
      });
    }

    // if(type ==="Development" && adminDevelopment.length === 0) {
    //     Object.values(newServiceNeed).filter((service)=>{
    //         if(service.key !=="Devlopment"){
    //            setDevelopmentVisible(false)
    //         }
    //     })

    //     console.log("setNewServiceNeed===",newServiceNeed)
    //     setDevelopmentVisible(false)

    // }
  };


  // console.log("addVisible===",adsVisible)
  // console.log("marketvisible",marketingDropVisible)
  //  console.log("developmentVisibile",developmentVisibile)
  //  console.log("design===",designVisibile)
  //  console.log("inbuation===",incubationVisibile)



  // console.log("serviceNeed==",serviceNeed)
  // console.log("selectedServiceNeed===",selectedServiceNeed)
  // console.log("newService===",newServiceNeed)


  // useEffect(()=>{
  //   //setDevelopmentVisible(adminDevelopment && adminDevelopment.length !== 0 ? true: false)
  // },[developmentOption])
  const  handleDropDownClose = () => {
    setDevelopmentVisible(false)
    setIncubationVisible(false);
    setDesignVisible(false);
    setMarketingDropVisible(false);
    setAdsVisible(false);
    setConsultingVisible(false)
  }

  useEffect(()=>{
    setAdminAdvertising(advertisingServices)
    setAdminMaketing(marketingServices)
    setAdminDesign(designCost)
    setAdminIncubation(incubationCost)
    setAdminConsulting(consultingCost)
     setAdminDevelopment(developmentOption)
     setAdminConsulting(consultingCost)
  },[questionnaireData])

  useEffect(()=>{
     setEmail(orgEmail)
  },[orgEmail])



  return (
    <>
      <body>
        <div className="d-flex" id="wrapper">
          {toggel && (
            <div className="border-end bg-white" id="sidebar-wrapper">
              <div className="sidebar-heading border-bottom bg-light">
                Mmi funnel portal
              </div>
              <div className="list-group list-group-flush">
                <Link
                  className=" list-group-item-action list-group-item-light p-3"
                  to={"/admindashboard"}
                  onClick={()=>handleDropDownClose()}
                >
                  Dashboard{" "}
                </Link>
              </div>
            </div>
          )}

          <div id="page-content-wrapper">
            <nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom nav-padding-balance">
              <div className="container-fluid">
                <div className="">
                  <label class="switch">
                    <input
                      type="checkbox"
                      onClick={() => {
                        setToggel(!toggel);
                      }}
                    />{" "}
                    <div></div>
                  </label>
                </div>

                <div
                  className="collapse navbar-collapse "
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav ms-auto mt-2 mt-lg-0">
                    <button
                      onClick={handleLogout}
                      className="btn btn-primary"
                      id="sidebarToggle"
                    >
                      Sign out
                    </button>
                  </ul>
                </div>
              </div>
            </nav>

            <div className="div-center">
              <div className={`w-100 ServiceQuestionnaire my-20 div-center `}>
                <Card className="w-90">
                  <Card.Header className="card-header-background">
                    Request for Service{""}
                    <button
                      className="all-unset"
                      data-tip
                      data-for="registerTip"
                    >
                      {" "}
                      <img
                        src="../assets/images/info.png"
                        alt=""
                        className="info-icon"
                      />
                    </button>
                    <ReactTooltip
                      className="tooltip"
                      id="registerTip"
                      place="right"
                      effect="solid"
                    >
                      Here is a request for service from an incoming Marketing
                      Machine client.
                      <br />
                      <br />
                      If you or your agency is interested in providing service
                      please look at the request points of service below.
                      <br />
                      <br />
                      Then fill in your proposed cost for each point you will
                      cover. We will then submit the proposal back to the client
                      who if they move ahead on the points you are associated
                      with will then be delegated to you or your team.
                      <br />
                      <br />
                    </ReactTooltip>
                  </Card.Header>
                  <Card.Body>
                    <Form>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="questionnaire-label">Name / Organization Name</Form.Label>
                        <Form.Control value={name && name} readOnly />
                      </Form.Group>

                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="questionnaire-label">Email</Form.Label>
                        <Form.Control value={email  && email} readOnly />
                      </Form.Group>

                      <Form.Group
                        className=" mt-15"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="questionnaire-label">Which services do you need?(multiple selection allowed)</Form.Label>
                      </Form.Group>

                      <Multiselect
                        displayValue="key"
                        //groupBy="cat"
                        avoidHighlightFirstOption={true}
                        placeholder={""}
                        disable={ questionnaireDisable}
                        selectedValues={
                          serviceNeed &&
                          serviceNeed.map((element) => ({
                            key: element,
                          }))
                        }
                        onRemove={
                            (selecteList, item) => {
                                setNewServiceNeed(selecteList, item);
                                handleRemove(item,"servicesneed");

                              }
                        }
                        onSelect={(selecteList, item) => {
                          setNewServiceNeed(selecteList, item);
                          handleExpendDropDown(selecteList, item);
                          setError({
                            ...error,
                            serviceNeed: "",
                          });

                        }}
                        options={needService}
                        showArrow
                      />
                        <p className="error-text-questionnaire">{error.serviceNeed}</p>

                       {developmentVisibile && <div>
                          <Form.Group
                            className=" mt-15"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label className="questionnaire-label">
                              What development services do you need?(multiple selection allowed )
                            </Form.Label>
                          </Form.Group>
                          <Multiselect
                            displayValue="key"
                            placeholder={""}
                            avoidHighlightFirstOption={true}
                            showArrow
                            disable={questionnaireDisable}
                            selectedValues={adminDevelopment}
                            options={[...developmentOptions1, ...developmentOptions2]}
                            onRemove={
                                (selecteList, item) => {
                                    setAdminDevelopment(selecteList);
                                    handleRemove(item,"Development");
                                  }
                            }

                            onSelect={(selecteList, item) => {
                              setAdminDevelopment(selecteList);
                              handleExpendDropDown(selecteList, item);
                              setError({
                                ...error,
                                development: "",
                              });

                            }}
                           
                            showArrow
                          />
                        </div>}
                        <p className="error-text-questionnaire">{error.development}</p>



                        { marketingDropVisible   &&<div>

                          <Form.Group
                            className=" mt-15"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label className="questionnaire-label">
                              What marketing services do you need?(multiple selection allowed)
                            </Form.Label>
                          </Form.Group>
                          <Multiselect
                            displayValue="key"
                            placeholder={""}
                            avoidHighlightFirstOption={true}
                            selectedValues={
                                adminMarketing
                            }
                            disable={questionnaireDisable}
                            options={[...marketingOptions1,...marketingOptions2]}

                            showArrow
                            onSelect={(selecteList, item) => {
                                setAdminMaketing(selecteList);
                                handleExpendDropDown(selecteList, item);
                                setError({
                                  ...error,
                                  marketingService: "",
                                });
                              }}
                            onRemove={
                                (selecteList, item) => {
                                    setAdminMaketing(selecteList);
                                    handleRemove(selecteList, item);
                                  }
                            }
                          />
                        </div>}
                        <p className="error-text-questionnaire">
                    {error.marketingService}
                  </p>


                       {/* {adsVisible  && <div>
                          <Form.Group
                            className=" mt-15"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label className="questionnaire-label">
                              What advertising services do you need?(multiple selection allowed)
                            </Form.Label>
                          </Form.Group>
                          <Multiselect
                            displayValue="key"
                            placeholder={""}
                            avoidHighlightFirstOption={true}
                            selectedValues={
                              adminAdvertising
                            }
                            onSelect={(selecteList, item) => {
                                setAdminAdvertising(selecteList);
                                handleExpendDropDown(selecteList, item);
                                setError({
                                  ...error,
                                  advertisingService: "",
                                });

                              }}
                            onRemove={
                                (selecteList, item) => {
                                    setAdminAdvertising(selecteList);
                                    handleRemove(selecteList, item);

                                  }
                            }
                            disable={ questionnaireDisable}
                            options={addServices}
                            showArrow
                          />
                        </div>}
                        <p className="error-text-questionnaire">
                    {error.advertisingService}
                  </p> */}


                       {/* {designVisibile && <div>
                          <Form.Group
                            className=" mt-15"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label className="questionnaire-label">
                              What design services do you need?(multiple selection allowed)
                            </Form.Label>
                          </Form.Group>
                          <Multiselect
                            displayValue="key"
                            placeholder={""}
                            avoidHighlightFirstOption={true}
                            showArrow
                            selectedValues={adminDesign}
                            disable={ questionnaireDisable}
                            showArrow
                            options={design}
                            onSelect={(selecteList, item) => {
                                setAdminDesign(selecteList);
                                handleExpendDropDown(selecteList, item);
                                setError({
                                  ...error,
                                  design: "",
                                });

                              }}
                            onRemove={
                                (selecteList, item) => {
                                    setAdminDesign(selecteList);
                                    handleRemove(selecteList, item);

                                  }
                            }

                          />
                        </div>}
                        <p className="error-text-questionnaire">{error.design}</p> */}

                      <div>

                         {/* {incubationVisibile  &&  <div>
                            <Form.Group
                              className=" mt-15"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>
                                What incubation services do you need?(multiple selection allowed)
                              </Form.Label>
                            </Form.Group>
                            <Multiselect
                              displayValue="key"
                              placeholder={""}
                              avoidHighlightFirstOption={true}
                              showArrow
                              disable={questionnaireDisable}
                              selectedValues={adminIncubation}
                              options={incubation}
                              showArrow
                              onSelect={(selecteList, item) => {
                                setAdminIncubation(selecteList);
                                handleExpendDropDown(selecteList, item);
                                setError({
                                  ...error,
                                  incubation: "",
                                });

                              }}
                              onRemove={
                                (selecteList, item) => {
                                    setAdminIncubation(selecteList);
                                    handleRemove(selecteList, item);
                                  }
                              }
                            />
                          </div>}
                          <p className="error-text-questionnaire">{error.incubation}</p> */}

                          {consultingVisibile  &&  <div>
                            <Form.Group
                              className=" mt-15"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>
                                What consulting services do you need?(multiple selection allowed)
                              </Form.Label>
                            </Form.Group>
                            <Multiselect
                              displayValue="key"
                              placeholder={""}
                              avoidHighlightFirstOption={true}
                              showArrow
                              disable={questionnaireDisable}
                              selectedValues={adminConsulting}
                              options={[...consultingOptions1 , ... consultingOptions2]}
                              showArrow
                              onSelect={(selecteList, item) => {
                                setAdminConsulting(selecteList);
                                handleExpendDropDown(selecteList, item);
                                setError({
                                  ...error,
                                  consultingServices: "",
                                });

                              }}
                              onRemove={
                                (selecteList, item) => {
                                    setAdminConsulting(selecteList);
                                    handleRemove(selecteList, item);
                                  }
                              }
                            />
                          </div>}
                          <p className="error-text-questionnaire">{error.consultingServices}</p>

                      </div>
                      <Form.Group
                        className=" mt-15"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="questionnaire-label">
                          To finish up, please provide a brief yet detailed
                          description of the work needed{" "}
                        </Form.Label>
                      </Form.Group>
                      <Form.Control
                        value={detail}
                        as="textarea"
                        rows={3}
                        style={{ height: "150px" }}
                        onChange={(e) => {
                            setDetail(e.target.value);
                            setError({
                              ...error,
                              discription: "",
                            });
                          }}
                        disabled={questionnaireDisable}
                      />
                       {questionnaireDisable === false  && <div className="d-flex justify-center"> <Button onClick={handleAdminQuestionnaire} className="mt-15">
                Submit
              </Button> </div>}
                    </Form>

                    {approvedCost && approvedCost.length !==0 ? <DisplayAdminApprovedCost serviceRequestStatus={result} approvedCost={approvedCost}/> :<DisplayAdminFilledCost serviceRequestStatus={result} />}
                    {/* <DisplayAdminCost
                      questionnaireDetails={questionnaireDetails}
                      onDropDownValueSelect={onDropDownValueSelect}
                      serviceRequestStatus={questionnaireCostList}
                      getQuestionnaire={getQuestionnaire}
                      getQuestionnaireDetailWithCost={
                        getQuestionnaireDetailWithCost
                      }
                      findParams={findParams}
                      costError={error}
                    /> */}
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  );
};
export default AdminServiceCostEdit;
