import React from "react";
import UploadCsv from "./UploadCsv";
import UserResponseDetailTable from "./UserResponseDetailTable";

const DashboardContainer = (props) => {
  return (
    <div className="container-fluid DashboardContainer pd-none">
      {/* <UploadCsv /> */}
      <UserResponseDetailTable questionairList={props.questionairList}  indexOFFirstResult={props.indexOFFirstResult} />
    </div>
  );
};

export default DashboardContainer;
