import {
  SAVE_CREATE_PROJECT_DATA_START,
  SAVE_CREATE_PROJECT_DATA_SUCCESS,
  SAVE_CREATE_PROJECT_DATA_FAILED,
  GET_ALL_PORTAL_REPORT_START,
  GET_ALL_PORTAL_REPORT_SUCCESS,
  GET_ALL_PORTAL_REPORT_FAILED,
  GET_KANBAN_START,
  GET_KANBAN_SUCCESS,
  GET_KANBAN_FAILED,
  GET_KANBAN_SPRINT_START,
  GET_KANBAN_SPRINT_SUCCESS,
  GET_KANBAN_SPRINT_FAILED,
  GET_PROJECT_FROM_TAIGA_START,
  GET_PROJECT_FROM_TAIGA_SUCCESS,
  GET_PROJECT_FROM_TAIGA_FAILED,
  UPDATE_TAIGA_PROJECT_START,
  UPDATE_TAIGA_PROJECT_SUCCESS,
  UPDATE_TAIGA_PROJECT_FAILED,
  GET_TAIGA_CREATED_SPRINT_REPORT_START,
  GET_TAIGA_CREATED_SPRINT_REPORT_FAILED,
  GET_TAIGA_CREATED_SPRINT_REPORT_SUCCESS
} from "../../constents/actionType";
import { toast } from "react-toastify";
import { createPortal } from "../../service/portalReport";
import axios from "axios";
import { authHeader } from "../../service/auth-header";
import ApiConfig from '../../service/ApiConfig'


const inOneDay = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);

export const savePortalReportData = (data) => {
  return (dispatch) => {
    dispatch(savePortalReportDataStart());
    createPortal
      .savePortalData(data)
      .then((rawData) => {
        return rawData.json();
      })
      .then((response) => {
        if (response.status === 201) {
          dispatch(savePortalReportDataSuccess(response.data));
        } else {
          toast.error(response.message);
          dispatch(savePortalReportDataFailed(response.data));
        }
      });
  };
};


export const getAllCreateProjectList =  () => {
  return async (dispatch) => {
    dispatch(getAllCreateProjectListStart());
    try {
      const res = await createPortal.getAllProject();
      const response = await res.json()
      dispatch(getAllCreateProjectListSucess(response.data));
    } catch (err) {
      console.log(err)
     
      dispatch(getAllCreateProjectListFailed(err.data));
      toast.error(`${err.statusText}`);
    }
  };
};

export const getKanban = (id,params) =>{
  return (dispatch)=>{
    dispatch(getKanbanStart());
    createPortal.kanbanReport(id,params)
    .then((rawData)=>{
      return rawData.json();
    })
    .then((response) => {
     
        dispatch(getKanbanSuccess(response.data));
    })
    .catch((err)=>{
      dispatch(getKanbanFailed(err))
    })
  }
}

export const getKanbanSprint = (id,params) =>{
  return (dispatch)=>{
    dispatch(getKanbanSprintStart());
    createPortal.kanbanSprintReport(id,params)
    .then((rawData)=>{
      return rawData.json();
    })
    .then((response) => {
     
        dispatch(getKanbanSprintSuccess(response.data));
    })
    .catch((err)=>{
      dispatch(getKanbanSprintFailed(err))
    })
  }
}



export const getTaigaProjectName = () => {
  return async (dispatch) => {
    dispatch({ type: GET_PROJECT_FROM_TAIGA_START });
    try{
     const res = await axios.get(`${ApiConfig.baseURL}/create-project/taiga-project-list` ,{
      headers: authHeader(),
     })
      dispatch({ type: GET_PROJECT_FROM_TAIGA_SUCCESS, payload: res.data });
    } catch (err) {
      if(err.response && err.response.status === 429){
        toast.warning("Please update your taiga credential")
      }
      if(err.response?.status){
        const {data:{data:{message:{_error_message}}}} = err.response
        // toast.error(`${_error_message}`)
      }
      dispatch({type :GET_PROJECT_FROM_TAIGA_FAILED , payload: err})
    }
  }
}

export const updateTaigaProject = (id,data) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_TAIGA_PROJECT_START });
    try{
     const res = await axios.put(`${ApiConfig.baseURL}/create-project/${id}` , data , {
      headers: authHeader(),
     })
      dispatch({ type: UPDATE_TAIGA_PROJECT_SUCCESS, payload: res.data });
    } catch (err) {
      dispatch({type :UPDATE_TAIGA_PROJECT_FAILED , payload: err})
    }
  }
}

export const getCreatedProjectsDetails = (id) => {
  return async (dispatch) => {
    dispatch({ type: GET_TAIGA_CREATED_SPRINT_REPORT_START });
    try{
     const res = await axios.get(`${ApiConfig.baseURL}/taiga/${id}`)
      dispatch({ type: GET_TAIGA_CREATED_SPRINT_REPORT_SUCCESS, payload: res.data.data });
    } catch (err) {
      dispatch({type :GET_TAIGA_CREATED_SPRINT_REPORT_FAILED , payload: err})
    }
  }
}



const savePortalReportDataStart = () => {
  return {
    type: SAVE_CREATE_PROJECT_DATA_START,
  };
};

const savePortalReportDataSuccess = (data) => {
  return {
    type: SAVE_CREATE_PROJECT_DATA_SUCCESS,
    payload: data,
  };
};

const savePortalReportDataFailed = (data) => {
  return {
    type: SAVE_CREATE_PROJECT_DATA_FAILED,
    payload: data,
  };
};

const getAllCreateProjectListStart = () => {
  return {
    type: GET_ALL_PORTAL_REPORT_START,
  };
};

const getAllCreateProjectListSucess = (data) => {
  return {
    type: GET_ALL_PORTAL_REPORT_SUCCESS,
    payload: data,
  };
};

const getAllCreateProjectListFailed = (data) => {
  return {
    type: GET_ALL_PORTAL_REPORT_FAILED,
    payload: data,
  };
};


const getKanbanStart = (data) => {
  return {
    type: GET_KANBAN_START,
  };
};


const getKanbanSuccess = (data) => {
  return {
    type: GET_KANBAN_SUCCESS,
    payload: data,
  };
};


const getKanbanFailed = (data) => {
  return {
    type: GET_KANBAN_FAILED,
    payload: data,
  };
};


const getKanbanSprintStart = (data) => {
  return {
    type: GET_KANBAN_SPRINT_START,
  };
};


const getKanbanSprintSuccess = (data) => {
  return {
    type: GET_KANBAN_SPRINT_SUCCESS,
    payload: data,
  };
};


const getKanbanSprintFailed = (data) => {
  return {
    type: GET_KANBAN_SPRINT_FAILED,
    payload: data,
  };
};


