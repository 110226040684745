import  React,{useEffect ,useState} from "react";
import { PDFExport , Component, ViewEncapsulation ,savePDF } from "@progress/kendo-react-pdf";
import moment from "moment";
import {getPdfCategoryText,
  showHeadingText,selectedCategoryText,
  developmentServiceShortDis ,
  renderDevelopmentShortDiscription ,
  renderMarketingCostShortDiscription,
  advertisingShortDiscription,
  designShortDiscription,
  incubationShortDiscription,
  parentCategorySummary,
  developmentServices,
  advertisingServicesDiscription,
  marketServicesDiscription,
  designServicesDiscription,
  incubationServicesDiscription,
  showAgreementPdfHeadingText,
  showAllCategory,
  totalCost
} from '../../constents/PdfText'

import '../../styles/kendo.css'
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getQuestionnaireAdminList } from "../../store/actions/questionnaire";






const AgreementPdf = (props) => {
    const {cost,filledCost,_id ,getDocFileUrl} = props
    const dispatch = useDispatch()
    const history = useHistory()




  const pdfExportComponent = React.useRef(null);
    const container = React.useRef(null);
    const PageTemplate = (props) => {
        return (
          <div
            style={{
              position: "absolute",
              marginRight:"50px",
              bottom: "5px",
              left: "20px",
              fontSize:"10px",
              fontFamily:"calibril-light",
              paddingRight:"40px",
              paddingLeft:"20px"
            }}
            className="d-flex flex-col w-100 text-left"
          >  
             <span className="font-footer text-left">{`${cost.firstName ? `${cost.firstName} ${cost.lastName}` : `${cost.firstName}`}, Inc./Marketing Machine,Inc `}</span> 
             <span className="font-footer text-left">{showAgreementPdfHeadingText(cost,props.approvedcosts)}  {time}  </span> 
             <span style={{marginLeft:"540px"}} className="font-footer"> {props.pageNum}  </span> 
          </div>
        );
      };


  const exportPDFWithComponent = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
      if(!cost?.contractFileUrl){
          dispatch(getDocFileUrl(_id,closeModal))
      }
      }
  };

  const closeModal = () => {
    //dispatch(getQuestionnaireAdminList())
    props.onHide()
  }
 

const address = props.cost.address ? props.cost.address :  "Minnesota limited liability company, 5576 Bristol Lane, Minnetonka, MN 55343"

const bulletImg = () =>{
  return <span><img className="bullet-circle-img" src="assets/images/bullet-circle.png"/></span>
}


const bulletImghollow = () =>{
    return <span><img className="bullet-circle-img" src="assets/images/bullet-img-hollow.png"/></span>
}

const tempDate = moment(cost.createdAt).format('Do MMMM YYYY')
const time = tempDate.slice(0,4) + " of " + tempDate.slice(4)

  
  return ( 
    <div>
      <div className="example-config">
        <button
          className="k-button"
          style={{all:"unset",cursor:"pointer"}}
          onClick={exportPDFWithComponent}
        >
           <img className="pdf-img" src="../assets/images/pdf.png" />
           Download Agreement
        </button>
      </div>

      <div
       style={{
        position: "absolute",
        left: "-10000000px",
        top: 0,
        zIndex :9999999
        }}
      >
        <PDFExport forcePageBreak=".page-break" paperSize="A4"  ref={pdfExportComponent}  fileName={`mmi-funnel-${props.cost.firstName ? `${props.cost.firstName} ${props.cost.lastName}` : `$`}`}  pageTemplate={PageTemplate} margin={{  bottom: 50  ,right:20 ,left:20 }}  >
               
              {/* <>
               <div style={{height:"900px"}}>
               <div className="page-body" style={{backgroundImage: "url(assets/images/rainlogo.jpg)",backgroundRepeat:"no-repeat",objectFit:"fill",height:"100%", width:"100%",backgroundSize:"cover"}}>
               
                <div className="d-flex" style={{justifyContent:"center",paddingTop:"200px"}} >
                   <img style={{width:"150px"}} src="assets/images/logo_1.png"/> 
                   </div> 
                <div className="front-page d-flex justify-center align-center">
                  <p className="front-page-title-text" >{showAgreementPdfHeadingText(cost,props.approvedcosts,"uppercase")} AGREEMENT</p>
               
              </div>
              </div>
              </div>
               
              </> */}
              
            <div style={{height:"900px"}}>
            <div className="page-body" style={{backgroundImage: "url(assets/images/rainlogo.jpg)",backgroundRepeat:"no-repeat",objectFit:"fill",height:"100%", width:"100%",backgroundSize:"cover"}}>
                <div style={{marginTop:"40px",marginBottom:"40px"}}> 
                <div className="">
                  <p className="front-page-title-text-contract" >{showAgreementPdfHeadingText(cost,props.approvedcosts,"uppercase")} AGREEMENT</p>
              </div>
                <p className="para-text-agreement"> "THIS Agreement to provide Media Management Services (“Agreement”) is made <span>{time} </span>, between <b className="sub-heading font-bold-contract">{props.cost.firstName ? `${props.cost.firstName} ${props.cost.lastName}` : `${props.cost.name}`}</b> a {address}  (“Client”) and <b className="sub-heading font-bold-contract">Marketing Machine Inc.</b>, a Delaware corporation, 523 Trace Circle, #208, Deerfield Beach FL 33441 (“MMI”), collectively (“the parties”)."
                 </p> 
                </div>
                <div>
                    <p className="para-text-agreement"><span>{`1) `}</span><u className="sub-heading font-bold-contract">{`Engagement`}</u><span> {` Client engages MMI and MMI accepts the engagement for the services described in paragraph 2 hereof upon the terms and conditions of this Agreement.`}</span></p>
                    <p className="para-text-agreement"><span>{`2) `}</span><u className="sub-heading font-bold-contract">{`Services`}</u><span>{` The services to be provided pursuant to this Agreement are: `}  </span> </p>
                    {showAllCategory(cost,props.approvedcosts,props.cost.firstName ? `${props.cost.firstName} ${props.cost.lastName}` : `${props.cost.name}`,filledCost)}
                   
                  
                   <p className="para-text-agreement"><span>{`3) `}</span><u className="sub-heading font-bold-contract">{`Use of MMI Proprietary Tools and Technology`}</u><span> {`  In the performance of the described services, MMI will use its proprietary knowledge, tools, and technology. Said proprietary knowledge, tools, and technology are the property of and will remain the property of MMI. Part of MMI’s proprietary knowledge is its sprint process for expediting software development while maintaining the highest quality. That process is detailed in the MMI Client’s Sprint Guide attached hereto as Appendix A. Love agrees to perform its responsibilities in the development process in accordance with that document.`}</span></p>
                   <p className="para-text-agreement"><span>{`4) `}</span><u className="sub-heading font-bold-contract">{`Other MMI Proprietary Tools and Technology`}</u> <span>{`  It is understood by the parties that MMI has an entire menu of digital and conventional marketing services beyond those contracted for in this Agreement. It may be decided by the parties that additional services may be useful to Love. If so, they can be added at standard rates during the Agreement without extending the term of the Agreement. The parties expect that this will happen. In all cases, MMI will review with Love regularly to increase, decrease, add, or eliminate.`} </span></p>
                   <p className="para-text-agreement"><span>{`5) `}</span><u className="sub-heading font-bold-contract">{`Term`}</u> <span>The term of this Agreement shall begin on the date hereof and continue until thework is complete. At the end of the term the agreement will automatically renewfor an additional 90 days thereafter unless terminated in writing prior to theend of the term.</span></p>
                   <p className="para-text-agreement"><span>{`6) `}</span><u className="sub-heading font-bold-contract">{`Compenstion`}</u>  MMI will deliver the requested services for a monthly fee of {totalCost(Object.assign({},props.approvedcosts[0]),props.cost)}$ payable
                     upon the execution of this Agreement and on the same day each month thereafter.</p>
                  
                </div>
              </div>
           </div> 
            <>
           <div style={{height:"900px"}}>
               <div className="page-body" style={{backgroundImage: "url(assets/images/rainlogo.jpg)",backgroundRepeat:"no-repeat",objectFit:"fill",height:"100%", width:"100%",backgroundSize:"cover"}}>
               <div style={{marginTop:"40px",marginBottom:"40px"}}> 
               <p className="para-text-agreement"><span>{`7) `}</span><u className="sub-heading">{`Standard Terms and Conditions`}</u> <span>{`  The Standard Terms and Conditions contained in Appendix B is made a part hereof by reference.`} </span></p>
                   <p  className="para-text-agreement"><b className="font-bold-contract " style={{marginRight:'20px'}}>IN WITNESS WHEREOF </b> <spna  className="para-text-agreement">the parties have executed this Agreement on the date and year first above mentioned.  </spna></p>
                   <div className="d-flex flex-col col-flex-end mr-10" style={{color:'black'}}>
                       <div className="d-flex flex-col flex-end" style={{width:'300px'}}>
                           <b className="mr-20 text-left font-bold-contract">Marketing Machine Inc.</b>
                           <div className="mr-20 text-left"><p>By:</p><span><hr/></span>  </div> 
                           <p className="text-right">"MMI"</p>
                       </div>
                       <div className="d-flex flex-col flex-end" style={{width:'300px'}}>
                           <b className="mr-20 text-left font-bold-contract">{props.cost.firstName ? `${props.cost.firstName} ${props.cost.lastName}` : `${props.cost.name}`}</b>
                           <div className="mr-20 text-left"><p>By:</p><span><hr/></span>  </div> 
                           <p className="text-right">"{props.cost.firstName ? `${props.cost.firstName} ${props.cost.lastName}` : `${props.cost.name}`}"</p>
                       </div>
                   </div>                   
               
               </div>
               </div>
          </div>   
           </>
            <>
               <div style={{height:"900px"}}>
               <div className="page-body" style={{backgroundImage: "url(assets/images/rainlogo.jpg)",backgroundRepeat:"no-repeat",objectFit:"fill",height:"100%", width:"100%",backgroundSize:"cover"}}>
               <div className="d-flex flex-col">
                   <div className="text-right"><b className="font-bold-contract">APPENDIX A</b></div>
                   <div><b className="agreement-heading-text font-18 text-left">MMI Client’s Sprint Guide</b></div>
                   <div>
                       <p className="agreement-heading-text font-16">1 Introduction</p>
                       <p className="para-text-agreement">This documents the <span className="underlinetext-blue">Marketing Machine</span>'s sprint processes for new clients. It outlines </p>
                           <div>
                               <p className="para-text-agreement ml-30">{bulletImg()} The client (and MMI) roles that must be filled and those roles’ general responsibilities,</p>
                               <p className="para-text-agreement ml-30">{bulletImg()} Describes some Internet assets MMI will set-up for project communications and reporting, and,</p>
                               <p className="para-text-agreement ml-30">{bulletImg()} Defines some project start-up activities</p>
                           </div>
                       <p className="agreement-heading-text font-16">2 Roles and Responsibilities</p>
                       <p className="para-text-agreement">The following roles will be identified in the contract and other project documentation and will be identified and introduced to the joint team early in the project meetings. These roles must be staffed for the life of the project.</p>
                   </div>
                   <div className="d-flex" style={{justifyContent:"center",paddingTop:"20px"}} > <img style={{width:"550px"}} src="assets/images/statictext.png"/> </div> 
                   <p className="para-text-agreement">Additional personnel required within initial meetings for the project include:</p>
                   <div className="d-flex" style={{justifyContent:"center",paddingTop:"20px"}} > <img style={{width:"550px"}} src="assets/images/statictext1.png"/> </div> 
                   <p className="para-text-agreement">We will ask you to identify staff fulfilling the above roles and any other key staff so we can configure logins for assets described in the next section.</p>
                   <p className="agreement-heading-text font-16">3 Customer Specific Assets</p>
                       <p className="para-text-agreement">MMI will create a number of Internet assets to allow MMI staff to communicate internally and externally to carry out the project. A relatively short word will be chosen representing you (the client) which will be the name or prefix of the name for each of the assets (CLIENT in the following examples). Beyond the assets listed in the following subsections this identifier might also be used in other configurations. For example, client-specific logins to domain name registrars or cloud service providers would typically be <span className="underlinetext-blue">CLIENT@marketingmachine.ai.</span></p>
                       <p className="agreement-heading-text">3.1 Portal</p>
                       <p className="para-text-agreement">A project will be created on <span className="underlinetext-blue">https://portal.marketingmachine.ai/ </span>either named CLIENT or prefixed with CLIENT.</p>
                       <p className="para-text-agreement"><u className="sub-heading">{`3.2 The portal will be the source of all MMI’s project status reporting.`}</u></p>
                       <p className="agreement-heading-text">3.3 Slack Channels</p>
                       <p className="para-text-agreement">A “CLIENT-dev” channel will be created in the mm-development-group.slack.com workspace for internal communication amongst the developers and the MMI project team.</p>
                       <p className="para-text-agreement">Other channels may be created as appropriate. For example, if there is a substantial operational component to the project then a “CLIENT-ops” channel will be created.</p>
                       <p className="agreement-heading-text">3.4 Cloud Assets</p>
                       <p className="para-text-agreement">Your work may require us to obtain, configure, and/and maintain various cloud assets. For example, AWS configurations, domain name registrations, CMS configurations, etc. Where possible, these assets will be configured in such a way that they may be handed off to you without divulging secrets to any other asset. This might be done in a number of ways:</p>
                       <p className="para-text-agreement" style={{paddingLeft:"30px"}}>{`1.  	AWS and other cloud providers support the configuration concept of organizations: When it is most convenient to use the common MMI account for initial configuration and deployment and where asset(s) is destined for transfer a separate sub-organization for the client should be configured for the future transfer of the asset(s)`}</p>
                       <p className="para-text-agreement" style={{paddingLeft:"30px"}}>2.  		If there is no advantage to using a common MMI account (e.g., Cloudflare), a client-specific account (e.g., <spna className='underlinetext-blue'>CLIENT@marketingmachine.ai)</spna> should be created for managing the asset</p>
                       <p className="para-text-agreement">MMI will configure all individual accounts with the minimum access required for that individual’s role.</p>
                       <p className="para-text-agreement">You (the client’s team) should be prepared to bring your own security experts at the time any assets are transferred.</p>
                       <p className="agreement-heading-text">3.5 GitHub Repositories</p>
                       <p className="para-text-agreement">Should we create a source code repository we will create it in  <span className="underlinetext-blue">https://github.com/Marketing-Machine-Inc</span>again prefixed or named CLIENT. Depending on the requirements of the project, we will grant client’s team staff access as needed. Similar to other cloud assets, you (the client team) should be prepared to provide their experts in transferring the repo at the appropriate contract milestone.</p>
                       <p className="agreement-heading-text font-16">4 Project Calendar</p>
                       <p className="para-text-agreement">The following subsections outline regular project meetings and other events over the lifetime of the project.</p>
                       <p className="agreement-heading-text">4.1 Contract Review Meeting</p>
                       <p className="para-text-agreement">When: Within days of contract signing</p>
                       <br/>
                       <p className="para-text-agreement">Chair: MMI Executive Sponsor</p>
                       <p className="para-text-agreement">Attendees: Contract signers, Client Executive Sponsors, MMI and Client Project Managers, MMI </p>
                       <p className="para-text-agreement">A/R representative and Client A/P representative</p>
                       <p className="para-text-agreement">Agenda:</p>
                       <p className="para-text-agreement" style={{paddingLeft:"30px"}}>1.  Introductions (All)</p>
                       <p className="para-text-agreement" style={{paddingLeft:"30px"}}>2.  Contract Review </p>
                       <p className="para-text-agreement" style={{paddingLeft:"60px"}}>a.  Background (Client Executive Sponsor)</p>
                       <p className="para-text-agreement" style={{paddingLeft:"60px"}}>b.  Contract Review (MMI Executive Sponsor)</p>
                       <p className="para-text-agreement" style={{paddingLeft:"60px"}}>c.  Change Management Process (MMI Executive Sponsor)</p>
                       <p className="para-text-agreement" style={{paddingLeft:"30px"}}>3.  Invoicing </p>
                       <p className="para-text-agreement" style={{paddingLeft:"60px"}}>a.	Invoicing: Invoices and detail (MMI A/R)</p>
                       <p className="para-text-agreement" style={{paddingLeft:"60px"}}>b.	Client A/P processes (Client A/P)</p>
                       <p className="para-text-agreement" style={{paddingLeft:"30px"}}>4.	Project Governance</p>
                       <p className="para-text-agreement" style={{paddingLeft:"60px"}}>a.	Portal Overview (MMI Project Manager)</p>
                       <p className="para-text-agreement" style={{paddingLeft:"60px"}}>b.	Reporting (MMI Project Manager)</p>
                       <p className="agreement-heading-text">4.2 Project Kick-Off Meeting</p>
                       <p className="para-text-agreement">[May be combined with <span className="underlinetext-blue">5.1 Contract Review Meeting </span>.]</p>
                       <p className="para-text-agreement">When: After or simultaneously with <span className="underlinetext-blue">5.1 Contract Review Meeting</span>but before the first sprint session.</p>
                       <br/>
                       <p className="para-text-agreement">Chair: MMI Project Manager</p>
                       <p className="para-text-agreement">Attendees: Client Project Manager, Client and MMI technical staff as needed</p>
                       <p className="para-text-agreement">Agenda:</p>
                       <p className="para-text-agreement" style={{paddingLeft:"30px"}}>1.	Project Background (optional) (Client Team)</p>
                       <p className="para-text-agreement" style={{paddingLeft:"30px"}}>2.	Review communications</p>
                       <p className="para-text-agreement" style={{paddingLeft:"60px"}}>a.  <span className="underlinetext-blue">ahttps://portal.marketingmachine.ai/ </span>logins (MMI PM)</p>
                       <p className="para-text-agreement" style={{paddingLeft:"60px"}}>b.	Slack channels (if any) (MMI PM)</p>
                       <p className="para-text-agreement" style={{paddingLeft:"60px"}}>c.	Email contacts (MMI and Client PMs)</p>
                       <p className="para-text-agreement" style={{paddingLeft:"30px"}}>2.	<span className="underlinetext-blue">ahttps://portal.marketingmachine.ai/ </span>training  (MMI PM)</p>
                       <p className="para-text-agreement" style={{paddingLeft:"30px"}}>4.	Invoicing and Detail</p>
                       <p className="para-text-agreement" style={{paddingLeft:"60px"}}>a.	Example invoice</p>
                       <p className="para-text-agreement" style={{paddingLeft:"60px"}}>b.	Sprint Reports (MMI PM)</p>
                       <p className="agreement-heading-text">4.3 Sprint</p>
                       <p className="para-text-agreement">Sprints typically consist of one week of planning followed by two weeks of development</p>
                       <div className="d-flex" style={{justifyContent:"center",paddingTop:"20px"}} > <img style={{width:"550px"}} src="assets/images/sprintchart.png"/> </div> 
                       <p className="agreement-heading-text">4.3.1 Session (Meeting)</p>
                       <p className="para-text-agreement">Meeting led by MMI PM including Client PM and any required critical staff.</p>
                       <p className="para-text-agreement">Deliverables:</p>
                       <p className="para-text-agreement" style={{paddingLeft:"30px"}}>1.	Updated portal with Items marked for Sprint (demo)</p>
                       <p className="para-text-agreement" style={{paddingLeft:"30px"}}>2.	Report of Sprint and On-Deck Items</p>
                       <p className="para-text-agreement" style={{paddingLeft:"30px"}}>3.	Outline of required wireframes</p>
                       <p className="para-text-agreement" style={{paddingLeft:"30px"}}>4.	Meeting minutes capturing any other required details</p>
                       <p className="para-text-agreement">Due: First day of Sprint (Monday of first week)</p>
                       <p className="agreement-heading-text">4.3.2 Post-Session Wireframing</p>
                       <p className="para-text-agreement">MMI technical resource generates the wireframes from the outline produced in the Sprint Session.</p>
                       <p className="para-text-agreement">Deliverables:</p>
                       <p className="para-text-agreement" style={{paddingLeft:"30px"}}>{bulletImg()}	Wireframes</p>
                       <p className="para-text-agreement" style={{paddingLeft:"30px"}}>{bulletImg()}	As needed communication from MMI PM to Client PM in case of unforeseen challenges and/or potential Change Management</p>
                       <p className="para-text-agreement">Due: COB Week 1/Day 3 (Wednesday)</p>
                       <p className="agreement-heading-text">4.3.3 Sprint Lock-In</p>
                       <p className="para-text-agreement">Deadline for Client PM to reprioritize Sprint Items.</p>
                       <p className="para-text-agreement">Deliverables:</p>
                       <p className="para-text-agreement" style={{paddingLeft:"30px"}}>{bulletImg()}	Meetings (if necessary) for:</p>
                       <p className="para-text-agreement" style={{paddingLeft:"60px"}}>{bulletImghollow()}	A Change Request (with or without commercial implications)</p>
                       <p className="para-text-agreement" style={{paddingLeft:"60px"}}>{bulletImghollow()}	Revisiting scope after reviewing wireframes</p>
                       <p className="para-text-agreement" style={{paddingLeft:"60px"}}>{bulletImghollow()}	Discussion of implications and possible trade-offs</p>
                       <p className="para-text-agreement" style={{paddingLeft:"30px"}}>{bulletImg()}	MMI & Client PM Scope Agreement</p>
                       <p className="para-text-agreement">Due: COB Week 1/Day 5 (Friday)</p>
                       <p className="agreement-heading-text">4.3.4 Issue Creation</p>
                       <p className="para-text-agreement">The MMI project team will translate in-scope items into issues on the portal.</p>
                       <br/>
                       <p className="para-text-agreement">Deliverables:</p>
                       <p className="para-text-agreement" style={{paddingLeft:"30px"}}>{bulletImg()}	MMI PM reports to the Client PM via email outlining the number and types of issues created. (This report will be the baseline MMI PM’s status reporting during the development phase of the Sprint.)</p>
                       <p className="para-text-agreement">Due: Week 2/Day 1 (Monday)</p>
                       <p className="agreement-heading-text">4.3.5 Sprint</p>
                       <p className="para-text-agreement">The Sprint (development) phase is two weeks. MMI technical staff, at the MMI PM’s direction, develop new functionality and resolve issues.</p>
                       <p className="para-text-agreement">No deliverables are explicitly required for invoicing, but the MMI PM should track staff’s time and provide periodic updates to the number of issues resolved.</p>
                       <p className="para-text-agreement">If the Client is responsible for QA, the Client PM ensure their test plan is complete for the next task and should coordinate any MMI requests for tests. This is critical in that any Issue must pass through the state “Ready for test” with a corresponding successful test before it can be “Closed.”</p>
                       <p className="para-text-agreement">Due: COB Week 3/Day 5 (Friday)</p>
                       <br/>
                       <p className="agreement-heading-text">4.3.6 Sprint Quality Assurance</p>
                       <p className="para-text-agreement">This step requires the MMI PM to review all Sprint issues and verify that those issues are Closed. If the Client is responsible for QA, they must complete testing for any issue still in “Ready for Test.”</p>
                       <p className="para-text-agreement">Deliverables:</p>
                       <p className="para-text-agreement" style={{paddingLeft:"30px"}}>{bulletImg()}	Sprint Issues Report showing all Issues “Closed.”</p>
                       <br/>
                       <p className="para-text-agreement">Due: Week 4/Day 1</p>

                       <p className="agreement-heading-text">4.3.7 Production Push</p>
                       <p className="para-text-agreement">Upon completion of the Sprint Quality Assurance, the Client PM will authorize the MMI team to make the Production Push via email. The MMI PM will coordinate the MMI team to make the Production Push and notify the Client PM by email when the push is complete.</p>
                       <p className="para-text-agreement">Deliverables:</p>
                       <p className="para-text-agreement" style={{paddingLeft:"30px"}}>{bulletImg()}	Client PM email authorizing the push</p>
                       <p className="para-text-agreement" style={{paddingLeft:"30px"}}>{bulletImg()}	MMI PM email indicating the push is complete</p>
                       <br/>
                       <p className="para-text-agreement">Due: Week 4/Day 2 (Tuesday)</p>
                       <p className="para-text-agreement">Note: These formal emails do not preclude the teams from using other communications (e.g., Slack) to communicate during the push.</p>

                       <p className="agreement-heading-text">4.3.8 Post-Push Quality Assurance</p>
                       <p className="para-text-agreement">Per the plan developed within the Sprint, the MMI and Client team will perform any post-push tests and the Client team will perform any regression testing.</p>
                       <p className="para-text-agreement">Deliverables:</p>
                       <p className="para-text-agreement" style={{paddingLeft:"30px"}}>{bulletImg()}	Client PM email notifying the MMI PM that all tests have been completed and noting any failures.</p>
                       <br/>
                       <p className="para-text-agreement">Due: Week 4/Day 2 (Tuesday)</p>
                       <p className="para-text-agreement">Note: These formal emails do not preclude the teams from using other communications (e.g., Slack) to communicate during testing.</p>

                       <p className="agreement-heading-text">4.3.9 Sprint Close (Meeting)</p>
                       <p className="para-text-agreement">At the completion of the Sprint, the MMI PM will produce both a Client Sprint Report and an internal summary.</p>
                       <p className="para-text-agreement">Deliverables:</p>
                       <p className="para-text-agreement" style={{paddingLeft:"30px"}}>{bulletImg()}	Client Sprint Report.</p>
                       <p className="para-text-agreement" style={{paddingLeft:"30px"}}>{bulletImg()}	Internal summary</p>
                       <br/>
                       <p className="para-text-agreement">Due: Week 4/Day 2 (Tuesday)</p>

                       <p className="agreement-heading-text">4.4  Invoicing</p>
                       <p className="para-text-agreement">MMI will generate invoices on the first day of each month. Sprint activities will be included in the detail with the Sprint Reports previously delivered to the Client Project Manager serving as detail. In addition, any charges for any Change Requests executed in the previous month will be included on the invoice.</p>

                       <p className="agreement-heading-text">4.5 Project Termination</p>
                       <p className="para-text-agreement">Projects are typically terminated by executing a contract amendment with 30-days’ notice with the expectation that any current sprints will be completed during the negotiated timeframe. Sprint management and reporting continues as previously described for those remaining sprints. A project may also be terminated for client abandonment or non-payment. </p>
                       <p className="para-text-agreement">Final invoices will be sent on the termination date</p>
                       <br/>
                       <p className="para-text-agreement">If the client’s account is in good standing, any digital assets will be transferred to the client as outlined in the contract documents. Otherwise, MMI, at its own discretion, may or may not take ownership and may or may not continue to pay any required fees to maintain those assets</p>
                       <div style={{marginTop:'50px',width:'300px',color:"black"}}> <hr/></div>
                       <p className="para-text-agreement">  Should this happen, MMI will make every effort to re-engage with you (the client).</p>
                    </div>
                </div>
              </div>
              </>  
               <div style={{height:"900px"}}>
                <div className="page-body" style={{backgroundImage: "url(assets/images/rainlogo.jpg)",backgroundRepeat:"no-repeat",objectFit:"fill",height:"100%", width:"100%",backgroundSize:"cover"}}>
                    <div className="d-flex flex-col">
                      <div className="text-right"><b className="font-bold-contract">APPENDIX B</b></div>
                        <div className="self-center"><p className="agreement-heading-text font-18 text-blue  font-900">STANDARD TERMS AND CONDITIONS</p></div>
                      </div>
                    <p className="para-text-agreement"><span>{`1 `}</span><u className="sub-heading">{` DUTIES.`}</u><span> {` MMI will use its best efforts in performing the Services and agrees to abide with all terms. During the Term of the Agreement, MMI shall not engage in any business activity that would directly or indirectly negatively impact Client’s interests.`}</span></p>
                    <p className="para-text-agreement"><span>{`2 `}</span><u className="sub-heading">{` REPRESENTATIONS.`}</u><span> {`  MMI represents that they will not market, link or list Client or its affiliates, or link to any content, web page or site that contains any:`}</span></p>
                    <p className="para-text-agreement" style={{paddingLeft:"30px"}}>a.	nudity, pornography, or other sexual or adult material,</p>
                    <p className="para-text-agreement" style={{paddingLeft:"30px"}}>b.	hate propaganda or material that encourages or promotes illegal activity or violence,</p>
                    <p className="para-text-agreement" style={{paddingLeft:"30px"}}>c.	content that violates or infringes in any way upon the statutory, common law, or proprietary rights of others, including but not limited to copyrights, trademark rights, patents, or any other third-party intellectual property, contract, privacy, or publicity rights,</p>
                    <p className="para-text-agreement" style={{paddingLeft:"30px"}}>d.	material that promotes or utilizes software or services designed to deliver unsolicited email,</p>
                    <p className="para-text-agreement" style={{paddingLeft:"30px"}}>e.	material that violates any local, state, or national law, rule or regulation,</p>
                    <p className="para-text-agreement" style={{paddingLeft:"30px"}}>f.	viruses, Trojan horses, worms, time bombs, cancel bots or other similar harmful or deleterious programming routines, or</p>
                    <p className="para-text-agreement" style={{paddingLeft:"30px"}}>g.	misrepresentations or material that is threatening, abusive, harassing, defamatory, obscene, profane, indecent, or otherwise objectionable, offensive, or harmful, as determined in the other Party’s sole discretion.</p>
                    <p className="para-text-agreement"><span>{`3 `}</span><u className="sub-heading">{`FURTHER REPRESENTATIONS.`}</u><span> {` MMI will not, undertake any “black-hat” or deceptive or fraudulent methods including, but not limited to keyword or cookie stuffing, spamdexing, malware, adware, hidden text or links, doorway or cloaked pages, link farming, blog comment spam, spyware, parasiteware techniques, automated “robot” techniques, software, downloads, context triggering, or other similar tactics to increase Customer Referrals, or any activity that results in the obligation of Client to pay for or accrue any Fees not previously agreed to in writing.`}</span></p>
                    <p className="para-text-agreement"><span>{`4 `}</span><u className="sub-heading">{`COMPLIANCE.`}</u><span> {` MMI always uses its best efforts to comply with all laws encompassing the technologies and services employed, however as an organization Client is responsible for all marketing materials and communications from its organization. MMI will always provide on request a complete breakdown of hosting, registration, copy, domains, and legal material for Client to review. Any damage or claims against Client for the above, any damage to reputation, or any other liability regarding internet content is the responsibility of Client.`}</span></p>
                    <p className="para-text-agreement"><span>{`5 `}</span><u className="sub-heading">{`EXTENT OF SERVICES.`}</u><span> {`MMI shall devote such time, attention and energies necessary to Client’s business to accomplish the Services in a timely manner.  `}</span></p>
                    <p className="para-text-agreement"><span>{`6 `}</span><u className="sub-heading">{`OWNERSHIP.`}</u><span> {`Client shall own all intellectual property created for it by MMI pursuant this Agreement . Client shall not own any of MMI’s proprietary knowledge, tools, and technology used in its performance of the contracted tasks. `}</span></p>
                    <p className="para-text-agreement"><span>{`7 `}</span><u className="sub-heading">{`EXPENSES`}</u><span> {`MMI may incur reasonable expenses for attending meetings and promoting Client business, including expenses for automobi¬le, entertainment, travel, and comparable items in accordance with such policies as Client may establish from time to time. Expenses shall include all reasonable air, automobile rental, food and lodging expenses incurred in furtherance of Client business. Expenses must be approved in advance by Client. Unless otherwise authorized, said expenses shall be incurred in MMI’s name and not that of Client then subsequently presented for reimbursement.  `}</span></p>
                    <p className="para-text-agreement"><span>{`8 `}</span><u className="sub-heading">{`INDEMNIFICATION.`}</u><span> {`Each party agrees to defend, indemnify, and hold harmless the other party, its affiliates, licensors, and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys’ fees) arising out of or relating to either’s violation of this Agreement, including, but not limited to, any use of the Services provided by MMI.`}</span></p>
                    <p className="para-text-agreement"><span>{`9 `}</span><u className="sub-heading">{`GOVERNING LAW; DISPUTE RESOLUTION, ATTORNEY FEES. `}</u><span> {`By entering into this Agreement, Client agrees that Florida law will govern the relationship between MMI and Client, as well as this Agreement, and that any action, suit, proceeding, or claim arising out of or related to this Agreement must be brought exclusively in federal or state courts located in the State of Florida. Both Parties hereby submit to the personal jurisdiction and venue of said Florida courts and waive any objection based on inconvenience.  `}</span></p>
                    <p className="para-text-agreement"><span>{`10 `}</span><u className="sub-heading">{`WAIVER AND SEVERABILITY.`}</u><span> {`No waiver by either party of any term or condition shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of a party to assert a right or provision under this Agreement shall not constitute a waiver of such right or provision. If any provision of this Agreement is held by a herein about court or other tribunal of competent jurisdiction to be invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Agreement will continue in full force and effect. `}</span></p>
                    <p className="para-text-agreement"><span>{`11`}</span><u className="sub-heading">{`DISCLAIMER.`}</u><span> {`Nothing in this Agreement or any Appendices or Exhibits attached hereto  including statements by MMI personnel will be construed as a promise or guarantee about the outcome of the services described herein or any performance by the specific dates referenced. MMI makes no such promises or guarantees. Comments and statements herein about goals, objectives and time frame are expressions of opinion only. `}</span></p>
                    <p className="para-text-agreement"><span>{`12 `}</span><u className="sub-heading">{`ENTIRE AGREEMENT.`}</u><span> {`This Agreement contains the entire understanding of the parties and supersedes all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral. `}</span></p>
                    <p className="para-text-agreement"><span>{`13 `}</span><u className="sub-heading">{`SUCCESSORS AND ASSIGNS.`}</u><span> {`This Agreement shall be binding upon and shall inure to the benefit of the Parties and their respective successors and permitted assigns. However, neither this Agreement nor any of the rights of the Parties hereunder otherwise may be transferred or assigned by a Party without the prior written consent of the other Party (which consent shall not be unreasonably withheld). Any attempted transfer or assignment in violation of this Section 13 shall be void.  `}</span></p>
                    <p className="para-text-agreement"><span>{`14 `}</span><u className="sub-heading">{`AMENDMENT AND MODIFICATION; WAIVER.`}</u><span> {`Except as otherwise expressly set forth herein, this Agreement may be amended, modified, or supplemented only by an agreement in writing signed by each Party. No waiver by a Party of any provision hereof shall be effective unless explicitly set forth in writing and signed by the Party so waiving. No waiver by a Party shall operate or be construed as a waiver in respect of any failure, breach, or default not expressly identified by such written waiver, whether of a similar or different character, and whether occurring before or after that waiver. No failure to exercise, or delay in exercising, any right, remedy, power, or privilege arising from this Agreement shall operate or be construed as a waiver thereof; nor shall any single or partial exercise of any right, remedy, power, or privilege hereunder preclude any other or further exercise thereof of the exercise of any other right, remedy, power, or privilege.  `}</span></p>
                    <p className="para-text-agreement"><span>{`15 `}</span><u className="sub-heading">{`MISCELLANEOUS.`}</u><span> {`Certain phrases herein are highlighted in bold. The highlighting is for ease of reference only and is not intended to expand or diminish the services specifically described in this Agreement. Any notice required or desired to be given under this Agreement shall be deemed given if in writing sent by certified mail to the other’s principal office. `}</span></p>
                    </div>
              </div>      
            

         
        </PDFExport>
      </div>
    </div>
  );
};

export default AgreementPdf
