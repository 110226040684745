import { toast } from "react-toastify";
import { questionnaire } from "../../service/questionnaire";
import { getAllServiceRequest } from "../../store/actions/service-request";
import Cookies from "js-cookie";
import axios from "axios";
import { authHeader } from "../../service/auth-header";
import ApiConfig from "../../service/ApiConfig";

import {
  GET_QUESTIONNAIRE_START,
  GET_QUESTIONNAIRE_SUCCESS,
  GET_QUESTIONNAIRE_FAILED,
  REQUEST_SERVICE_START,
  REQUEST_SERVICE_SUCESS,
  REQUEST_SERVICE_FAILED,
  GET_QUESTIONNAIRE_DETAILS_WITHCOST_START,
  GET_QUESTIONNAIRE_DETAILS_WITHCOST_SUCCESS,
  GET_QUESTIONNAIRE_DETAILS_WITHCOST_FAILED,
  GET_QUESTIONNAIRE_ADMIN_LIST_START,
  GET_QUESTIONNAIRE_ADMIN_LIST_SUCCESS,
  GET_QUESTIONNAIRE_ADMIN_LIST_FAILED,
  SAVE_SERVICE_REQUEST_DATA,
  UPDATE_QUESTIONNAIRE_START,
  UPDATE_QUESTIONNAIRE_SUCCESS,
  UPDATE_QUESTIONNAIRE_FAILED
} from "../../constents/actionType";

 const clearCookies = () => {
    localStorage.clear();
    Cookies.remove("userLogin");
    Cookies.remove("isAdmin")
    Cookies.remove("userAccessToken")
    Cookies.remove("isAdmin",{path:"/admin-servicecost"})
    Cookies.remove("userAccessToken",{path:"/admin-servicecost"})
}

export const getQuestionnaire = (id) => {
  const failureMessage = "Something wrong happened";

  return (dispatch) => {
    dispatch(questionnaireStart());
    questionnaire
      .getQuestionnaire(id)
      .then((rawData) => {
        return rawData.json();
      })
      .then((response) => {
        if(response.code ===401){
          // toast.error(response.message);
           if (response.code === 401) {
           localStorage.clear();
      }
        }
        if (response) {
          dispatch(questionnaireSuccess(response.data));
        } else {
          toast.error(response.message);
          dispatch(questionnaireFailed(response.message));
        }
      })
      .catch((err) => {
        dispatch(questionnaireFailed(failureMessage));
       
      });
  };
};

export const ServiceRequest = (data) => {
  const failureMessage = "Something wrong happened";
  return (dispatch) => {
    dispatch(serviceRequestStart());
    questionnaire
      .getServiceRequest(data)
      .then((rawData) => {
        dispatch(saveServiceRequestData(rawData.status));
        localStorage.setItem("serviceRequestStatus", rawData.status);
        return rawData.json();
      })
      .then((response) => {
        //console.log(response);
        dispatch(getAllServiceRequest());
        toast.success("Record  submit sucessfully");
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };
};

export const getQuestionnaireDetailWithCost = (id) => {
  const failureMessage = "Something wrong happened";

  return (dispatch) => {
    dispatch(questionnaireDetailWithCostStart());
    questionnaire
      .getQuestionnaireDetailWithCost(id)
      .then((rawData) => {
        return rawData.json();
      })
      .then((response) => {
        if (response) {
          dispatch(questionnaireDetailWithCostSuccess(response.data));
        } else {
          toast.error(response.message);
          dispatch(questionnaireDetailWithCostFailed(response.message));
        }
      })
      .catch((err) => {
        dispatch(questionnaireFailed(failureMessage));
      });
  };
};

// export const getQuestionnaireAdminList = () => {
//   const failureMessage = "Something wrong happened";

//   return (dispatch) => {
//     dispatch(questionnaireAdminListStart());
//     questionnaire
//       .getQuestionnaireAdminList()
//       .then((rawData) => {
//         return rawData.json();
//       })
//       .then((response) => {
//         if (response.code === 401) {
//           localStorage.clear();
//         }
//         if (response) {
//           dispatch(questionnaireAdminListSuccess(response.data));
//         } else {
//           toast.error(response.message);
//           dispatch(questionnaireFailed(response.message));
//         }
//       })
//       .catch((err) => {
//         dispatch(questionnaireAdminListFailed(failureMessage));
//       });
//   };
// };

export const getQuestionnaireAdminList = () => {
   return async(dispatch) => {
     dispatch(questionnaireAdminListStart());
     try{
      const res = await questionnaire.getQuestionnaireAdminList();
      const response = await res.json()
      dispatch(questionnaireAdminListSuccess(response.data));

     } catch (err){
        if (err.status === 401) {
          clearCookies()
        }
      // toast.error(err.message);
       dispatch(questionnaireFailed(err.message));
     }
   }
}


export const updateQuestionnireData = (id ,data) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_QUESTIONNAIRE_START });
    try{
     const res = await axios.put(`${ApiConfig.baseURL}/questionnaire/${id}` , data , {
      headers: authHeader(),
     })
      dispatch({ type: UPDATE_QUESTIONNAIRE_SUCCESS, payload: res.data });
      dispatch(getQuestionnaireAdminList())
    } catch (err) {
      dispatch({type :UPDATE_QUESTIONNAIRE_FAILED , payload: err})
    }
  }
}



const questionnaireStart = () => {
  return {
    type: GET_QUESTIONNAIRE_START,
  };
};

const questionnaireSuccess = (data) => {
  return {
    type: GET_QUESTIONNAIRE_SUCCESS,
    payload: data,
  };
};

const questionnaireFailed = (data) => {
  return {
    type: GET_QUESTIONNAIRE_FAILED,
    payload: data,
  };
};

const serviceRequestStart = () => {
  return {
    type: REQUEST_SERVICE_START,
  };
};

const serviceRequestSuccess = (data) => {
  return {
    type: REQUEST_SERVICE_SUCESS,
    payload: data,
  };
};

const serviceRequestFailed = (data) => {
  return {
    type: REQUEST_SERVICE_FAILED,
    payload: data,
  };
};

const questionnaireDetailWithCostStart = () => {
  return {
    type: GET_QUESTIONNAIRE_DETAILS_WITHCOST_START,
  };
};

const questionnaireDetailWithCostSuccess = (data) => {
  return {
    type: GET_QUESTIONNAIRE_DETAILS_WITHCOST_SUCCESS,
    payload: data,
  };
};

const questionnaireDetailWithCostFailed = (data) => {
  return {
    type: GET_QUESTIONNAIRE_DETAILS_WITHCOST_FAILED,
    payload: data,
  };
};

const questionnaireAdminListStart = () => {
  return {
    type: GET_QUESTIONNAIRE_ADMIN_LIST_START,
  };
};

const questionnaireAdminListSuccess = (data) => {
  return {
    type: GET_QUESTIONNAIRE_ADMIN_LIST_SUCCESS,
    payload: data,
  };
};

const questionnaireAdminListFailed = (data) => {
  return {
    type: GET_QUESTIONNAIRE_ADMIN_LIST_FAILED,
    payload: data,
  };
};

const saveServiceRequestData = (data) => {
  return {
    type: SAVE_SERVICE_REQUEST_DATA,
    payload: data,
  };
};
